import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientActionWithPrices } from '../../models/patient-action.model';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-action-technician-price-dialog',
  templateUrl: './edit-action-technician-price-dialog.component.html',
  styleUrls: ['./edit-action-technician-price-dialog.component.scss'],
})
export class EditActionTechnicianPriceDialogComponent {
  form = this.fb.group({});
  action: PatientActionWithPrices;
  constructor(
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<EditActionTechnicianPriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: PatientActionWithPrices;
    }
  ) {
    this.action = this.data.action;
    this.action?.technicianPrices?.forEach((technicianPrice) =>
      this.form.addControl(
        `${technicianPrice?.technicianId}-price`,
        new UntypedFormControl(technicianPrice?.price, Validators.min(0))
      )
    );
  }

  public update(): void {
    if (this.form.invalid) {
      return;
    }
    const technicianPrices = this.action.technicianPrices
      .map((tPrice) => {
        const newPrice =
          Math.round(
            this.form.controls[`${tPrice.technicianId}-price`].value * 100
          ) / 100;
        return newPrice !== tPrice.price
          ? {
              ...tPrice,
              price: newPrice,
            }
          : undefined;
      })
      .filter((p) => !!p);
    const newAction = { ...this.action, technicianPrices };
    this.dialogRef.close(newAction);
  }
}
