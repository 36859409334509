import { ADMINISTRATORS, ADMINS, AGENTS, ASSISTANTS, DOCTORS, TECHNICIANS } from './pages.constants';

export const UserTranslations = new Map<string, string>(
[
  [ADMINS, 'Admini'],
  [AGENTS, 'Agenti'],
  [ASSISTANTS, 'Asistenti'],
  [ADMINISTRATORS, 'Administratori'],
  [DOCTORS, 'Doktorji'],
  [TECHNICIANS, 'Tehniki'],

]);
