import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInfoTypeToText'
})
export class UserInfoTypeToTextPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case  'Admin':
        return 'Admin';
      case  'Doctor':
        return 'Doktor';
      case  'Assistant':
        return 'Asistent';
      case  'Agent':
        return 'Agent';
      case  'Technician':
        return 'Tehnik';
    }
    return value;
  }

}
