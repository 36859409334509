import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReceiptHeader } from '../../models/receipt.model';
import { DEFAULT_STYLE, getHeaderText, PDF_STYLES } from './pdf.utility';
import { ReceiptSumPipe } from '../../pipes/receipt-sum.pipe';
import { LocalStorageService } from '../api-services/local-storage.service';
import { CashRegisterInfo } from '../../models/cash-register.model';
import { formatNumber } from '@angular/common';
import { GOOGLE_DRIVE_IMG_URL } from '../../utils/constants/constants';
import { CashRegisterReceiptOverview } from '../../shared-components/cash-register-page/cash-register-page.component';

pdfMake.vfs = pdfFonts?.pdfMake?.vfs;
pdfMake.fonts = {
  Roboto: {
    normal: `${window.location.origin}/assets/fonts/Roboto/Roboto-Light.ttf`,
    bold: `${window.location.origin}/assets/fonts/Roboto/Roboto-Bold.ttf`,
    italics: `${window.location.origin}/assets/fonts/Roboto/Roboto-LightItalic.ttf`,
    bolditalics: `${window.location.origin}/assets/fonts/Roboto/Roboto-BoldItalic.ttf`,
  },
};

@Injectable({
  providedIn: 'root',
})
export class CashRegisterPdfService {
  private receiptSumPipe = new ReceiptSumPipe();

  constructor(private localStorage: LocalStorageService) {}

  public async createCashRegisterPdf(
    header: ReceiptHeader,
    date: string,
    receipts: CashRegisterReceiptOverview[],
    info: CashRegisterInfo,
    city: string,
  ) {
    const win = window.open('', '_blank');
    const imgPart = !header.logo
      ? {}
      : { logo: `${GOOGLE_DRIVE_IMG_URL}${header.logo}` };
    const docDefinition = {
      footer: (currentPage: number, pageCount: number) =>
        this.getFooter(currentPage, pageCount),
      content: this.getDocDefinitionContent(
        header,
        'logo',
        date,
        receipts,
        info,
        city,
      ),
      defaultStyle: DEFAULT_STYLE,
      styles: PDF_STYLES,
      images: {
        ...imgPart,
      },
    };
    pdfMake.createPdf(docDefinition).open({}, win);
  }

  private getFooter(currentPage: number, pageCount: number) {
    return {
      text: `${currentPage}/${pageCount}`,
      alignment: 'right',
      fontSize: 10,
      margin: [0, 0, 15, 0],
    };
  }

  private getDocDefinitionContent(
    header: ReceiptHeader,
    img: string,
    date: string,
    receipts: CashRegisterReceiptOverview[],
    registerInfo: CashRegisterInfo,
    city: string,
  ) {
    const content: any = [
      this.getGeneralHeader(header, img),
      this.getTitle(date, city, registerInfo),
      this.getMainTable(receipts),
      this.getSumTable(receipts),
      this.getSignaturePart(),
    ];

    return content;
  }

  private getGeneralHeader(header: ReceiptHeader, img: string) {
    return {
      columns: [
        [...getHeaderText(header)],
        {
          alignment: 'right',
          image: img,
          fit: [100, 100],
        },
      ],
      columnGap: 10,
      margin: [0, 0, 0, 20],
    };
  }

  private getTitle(
    date: string,
    address: string,
    registerInfo: CashRegisterInfo,
  ) {
    const formattedStartingAmount = registerInfo?.startingAmount
      ? formatNumber(registerInfo.startingAmount, 'sl', '.2-2')
      : '-';
    const formattedFinalAmount = registerInfo?.finalAmount
      ? formatNumber(registerInfo.finalAmount, 'sl', '.2-2')
      : '-';
    return [
      {
        text: `BLAGAJNIŠKI DNEVNIK na dan ${date}`,
        fontSize: 20,
      },
      {
        text: `Blagajna: Lux Dental ${address}`,
        fontSize: 12,
        margin: [0, 15, 0, 20],
      },
      {
        columns: [
          {
            text: `ZAČETNO STANJE: ${formattedStartingAmount} ${
              formattedStartingAmount !== '-' ? 'EUR' : ''
            }`,
            fontSize: 12,
            bold: true,
            margin: [30, 0, 30, 0],
          },
          {
            alignment: 'right',
            text: `KONAČNO STANJE: ${formattedFinalAmount} ${
              formattedFinalAmount !== '-' ? 'EUR' : ''
            }`,
            bold: true,
            fontSize: 12,
            margin: [30, 0, 30, 0],
          },
        ],
        margin: [0, 0, 0, 30],
      },
    ];
  }

  private getMainTable(receipts: CashRegisterReceiptOverview[]) {
    return {
      style: {
        alignment: 'left',
        fontSize: 10,
      },
      table: {
        widths: [150, 100, 100, 150],
        body: [
          [
            { text: 'Pacijent', bold: true, alignment: 'left' },
            { text: 'Premjek', bold: true, alignment: 'center' },
            { text: 'Korisnik', bold: true, alignment: 'center' },
            { text: 'Lokacija', bold: true, alignment: 'center' },
          ],
          ...this.getActionsTable(receipts),
        ],
      },
      layout: {
        hLineWidth: (i, node) =>
          i === 0 ? 0 : i === 1 || i === node.table.body.length ? 2 : 0,
        vLineWidth: () => 0,
      },
    };
  }

  private getActionsTable(receipts: CashRegisterReceiptOverview[]) {
    const rows = [];
    for (const receipt of receipts) {
      rows.push([
        receipt.patientName,
        {
          text: formatNumber(receipt.amount, 'sl', '.2-2'),
          alignment: 'center',
        },
        { text: receipt.author, alignment: 'center' },
        { text: receipt.address, alignment: 'center' },
      ]);
    }
    return rows;
  }

  private getSumTable(receipts: CashRegisterReceiptOverview[]) {
    const sum = this.receiptSumPipe.transform(receipts);
    const deposit =
      receipts.find((c) => c.patientName.includes('Polog'))?.amount ?? 0;

    return {
      style: {
        alignment: 'left',
        fontSize: 10,
      },
      table: {
        widths: [150, 100, 250],
        body: [
          [
            { text: 'Skupaj:', bold: true },
            {
              text: `${formatNumber(sum, 'sl', '.2-2')} EUR`,
              bold: true,
              alignment: 'center',
            },
            {},
          ],
          [
            { text: 'Izdatek:', bold: true },
            {
              text: `${formatNumber(deposit, 'sl', '.2-2')} EUR`,
              bold: true,
              alignment: 'center',
            },
            {},
          ],
          [
            { text: 'Saldo:', bold: true },
            {
              text: `${formatNumber(sum + deposit, 'sl', '.2-2')} EUR`,
              bold: true,
              alignment: 'center',
            },
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: (i, node) =>
          i === 0 ? 0 : i === 1 || i === node.table.body.length ? 2 : 0,
        vLineWidth: () => 0,
      },
    };
  }

  private getSignaturePart() {
    return [
      {
        columns: [
          {
            ul: [
              {
                text: '_________________________________',
              },
              {
                text: 'Likvidator',
                fontSize: 10,
                alignment: 'center',
              },
            ],
            type: 'none',
            margin: [20, 0, 50, 0],
          },
          {
            ul: [
              {
                text: '_________________________________',
              },
              {
                text: `Blagajnik: ${this.localStorage.getUserFullName()}`,
                fontSize: 10,
                alignment: 'center',
              },
            ],
            type: 'none',
            margin: [20, 0, 50, 0],
          },
        ],
        margin: [0, 100, 0, 30],
      },
    ];
  }
}
