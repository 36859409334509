import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Doctor } from '../../models/doctor.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { filterUserInfo } from '../../utils/calendar-working-hours.utils';
import { Finding } from '../../models/finding.model';
import { Data } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-findings-dialog',
  templateUrl: './findings-dialog.component.html',
  styleUrls: ['./findings-dialog.component.scss'],
})
export class FindingsDialogComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  form = this.fb.group({
    doctor: [null as Doctor | null, Validators.required],
    date: [
      this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      Validators.required,
    ],
    text: ['', Validators.required],
    address: [0, Validators.required],
  });

  filteredDoctors$: Observable<Doctor[]> = combineLatest([
    this.data.doctors,
    this.form.controls.doctor.valueChanges.pipe(
      startWith(''),
      filter((val) => typeof val === 'string'),
      takeUntil(this.destroy$)
    ),
  ]).pipe(
    map(([doctors, filterValue]) =>
      doctors.filter((doctor) =>
        filterUserInfo(doctor.userInfo, filterValue as string)
      )
    ),
    takeUntil(this.destroy$)
  );

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<FindingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      doctors: Observable<Doctor[]>;
      patientId: number;
      currentDoctor: number | null;
      addressData: Observable<Data>;
      finding: Finding | null;
    }
  ) {}

  ngOnInit(): void {
    if (this.data.currentDoctor) {
      this.setCurrentDoctor();
    }
    if (this.data.finding) {
      const finding = this.data.finding;
      this.form.patchValue({
        ...finding,
        date: this.datePipe.transform(finding.modificationDate, 'yyyy-MM-dd'),
        address: +finding.receiptAddressId.toString(),
      });
    }
  }

  private setCurrentDoctor() {
    this.data.doctors
      .pipe(
        map((doctors) =>
          doctors.find(
            (doctor) => doctor.userInfoId === this.data.currentDoctor
          )
        ),
        filter((doc) => !!doc),
        take(1)
      )
      .subscribe((doctor: Doctor) => {
        if (doctor) {
          this.form.controls.doctor.setValue(doctor);
        }
      });
  }

  displayFn(doctor: Doctor): string {
    return doctor && doctor.userInfo
      ? `${doctor.userInfo.name} ${doctor.userInfo.lastName}`
      : '';
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    const finding: Finding = {
      doctorId: this.form.controls.doctor.value.id,
      modificationDate: new Date(this.form.controls.date.value),
      text: this.form.controls.text.value,
      patientId: this.data.patientId,
      receiptAddressId: +this.form.controls.address.value,
    };
    this.dialogRef.close(finding);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

