import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StaffWorkingTime } from '../../models/staff-working-time.model';
import { EDIT_RECURRING_SWT } from '../../utils/constants/constants';
import { getTitleForDeletion } from '../../utils/calendar-working-hours.utils';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-staff-working-time-recurring-edit-dialog',
  templateUrl: './staff-working-time-recurring-edit-dialog.component.html',
  styleUrls: ['./staff-working-time-recurring-edit-dialog.component.scss'],
})
export class StaffWorkingTimeRecurringEditDialogComponent {
  EDIT_RECURRING_SWT = EDIT_RECURRING_SWT;
  protected readonly msg;
  constructor(
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<StaffWorkingTimeRecurringEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { swt: StaffWorkingTime; eventDate: Date },
  ) {
    this.msg =
      this.datePipe.transform(data.swt.startDateTime, 'dd.MM.yyyy') +
      ' ' +
      getTitleForDeletion(data.swt);
  }
}
