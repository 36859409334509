import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Doctor } from '../../models/doctor.model';
import { ClinicLocation } from '../../models/clinic-location.model';
import { Agent } from '../../models/agent.model';
import { AppointmentType } from '../../models/appointment-type.model';
import { AppointmentStatus } from '../../models/appointment-status.model';
import { PatientAction } from '../../models/patient-action.model';

@Component({
  selector: 'app-appointments-multiple-filters',
  templateUrl: './appointments-multiple-filters.component.html',
  styleUrls: ['./appointments-multiple-filters.component.scss'],
})
export class AppointmentsMultipleFiltersComponent implements OnChanges {
  @Output() public filtersChanged = new EventEmitter<any>();

  @Input() public doctors: Doctor[];
  @Input() public clinicLocations: ClinicLocation[];
  @Input() public agents: Agent[];
  @Input() public appointmentTypes: AppointmentType[];
  @Input() public appointmentStatuses: AppointmentStatus[];
  @Input() public actionTypes: PatientAction[];
  @Input() public disabledFilters: number[];
  @Input() public initFilters: any;
  public displayedDoctors: Doctor[];
  public displayedClinicLocations: ClinicLocation[];
  public displayedAgents: Agent[];
  public displayedAppointmentTypes: AppointmentType[];
  public displayedAppointmentStatuses: AppointmentStatus[];
  public displayedActionTypes: PatientAction[];

  public filteredDoctors: Doctor[];
  public filteredClinicLocations: ClinicLocation[];
  public filteredAgents: Agent[];
  public filteredAppointmentTypes: AppointmentType[];
  public filteredAppointmentStatuses: AppointmentStatus[];
  public filteredActionTypes: PatientAction[];
  public onlyOpen: boolean;
  public firstTime: boolean;

  constructor() {
    this.displayedDoctors = [];
    this.displayedClinicLocations = [];
    this.displayedAgents = [];
    this.displayedAppointmentTypes = [];
    this.displayedAppointmentStatuses = [];
    this.displayedActionTypes = [];

    this.filteredDoctors = [];
    this.filteredClinicLocations = [];
    this.filteredAgents = [];
    this.filteredAppointmentTypes = [];
    this.filteredAppointmentStatuses = [];
    this.filteredActionTypes = [];

    this.onlyOpen = false;
    this.firstTime = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fillDisplayed();
  }

  public initializeFilters() {
    if (this.initFilters) {
      this.initFilters.doctorIds.forEach((docId) => {
        this.addDoctorFilter(parseInt(docId, 10));
      });
      this.initFilters.clinicLocationIds.forEach((clinicId) => {
        this.addClinicLocationFilter(clinicId);
      });
      this.initFilters.agentIds.forEach((agentId) => {
        this.addAgentFilter(agentId);
      });
      this.initFilters.appointmentTypeIds.forEach((appointmentTypeId) => {
        this.addAppointmentTypeFilter(appointmentTypeId);
      });
      this.initFilters.appointmentStatusIds.forEach((appointmentStatusId) => {
        this.addAppointmentStatusFilter(appointmentStatusId);
      });
      this.initFilters.actionTypeIds.forEach((actionTypeId) => {
        this.addActionTypeFilter(actionTypeId);
      });
      if (this.initFilters.onlyOpen) {
        this.toggleOnlyOpenFilter();
      }
    }
  }

  public fillDisplayed() {
    if (!this.doctors) {
      return;
    }
    this.displayedDoctors = [...this.doctors];
    this.displayedDoctors.sort((a, b) => {
      return a.userInfo.name.localeCompare(b.userInfo.name);
    });
    this.displayedClinicLocations = [...this.clinicLocations];
    this.displayedClinicLocations.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.displayedAgents = [...this.agents];
    this.displayedAgents.sort((a, b) => {
      return a.userInfo.name.localeCompare(b.userInfo.name);
    });
    this.displayedAppointmentTypes = [...this.appointmentTypes];
    this.displayedAppointmentTypes.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.displayedAppointmentStatuses = [...this.appointmentStatuses];
    this.displayedAppointmentStatuses.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.displayedActionTypes = [...this.actionTypes];
    this.displayedActionTypes.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    if (this.firstTime) {
      this.initializeFilters();
    }
    this.firstTime = false;
  }

  public clearAll(): void {
    this.onlyOpen = false;
    this.displayedDoctors = [...this.doctors];
    this.displayedClinicLocations = [...this.clinicLocations];
    this.displayedAgents = [...this.agents];
    this.displayedAppointmentTypes = [...this.appointmentTypes];
    this.displayedAppointmentStatuses = [...this.appointmentStatuses];

    this.filteredDoctors = [];
    this.filteredClinicLocations = [];
    this.filteredAgents = [];
    this.filteredAppointmentTypes = [];
    this.filteredAppointmentStatuses = [];
    this.filteredActionTypes = [];

    this.triggerFiltersChanged();
  }

  public addDoctorFilter(doctorId: number): void {
    const index = this.displayedDoctors.findIndex((d) => d.id === doctorId);
    this.filteredDoctors.push(this.displayedDoctors[index]);
    this.displayedDoctors.splice(index, 1);
    this.triggerFiltersChanged();
  }

  public removeDoctorFilter(doctorId: number): void {
    const index = this.filteredDoctors.findIndex((d) => d.id === doctorId);
    this.displayedDoctors.push(this.filteredDoctors[index]);
    this.filteredDoctors.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public addClinicLocationFilter(locationId: number): void {
    const index = this.displayedClinicLocations.findIndex(
      (d) => d.id === locationId
    );
    this.filteredClinicLocations.push(this.displayedClinicLocations[index]);
    this.displayedClinicLocations.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public removeClinicLocationFilter(locationId: number): void {
    const index = this.filteredClinicLocations.findIndex(
      (d) => d.id === locationId
    );
    this.displayedClinicLocations.push(this.filteredClinicLocations[index]);
    this.filteredClinicLocations.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public addAgentFilter(agentId: number): void {
    const index = this.displayedAgents.findIndex((d) => d.id === agentId);
    this.filteredAgents.push(this.displayedAgents[index]);
    this.displayedAgents.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public removeAgentFilter(agentId: number): void {
    const index = this.filteredAgents.findIndex((d) => d.id === agentId);
    this.displayedAgents.push(this.filteredAgents[index]);
    this.filteredAgents.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public addAppointmentTypeFilter(appointmentTypeId: number): void {
    const index = this.displayedAppointmentTypes.findIndex(
      (d) => d.id === appointmentTypeId
    );
    this.filteredAppointmentTypes.push(this.displayedAppointmentTypes[index]);
    this.displayedAppointmentTypes.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public removeAppointmentTypeFilter(appointmentTypeId: number): void {
    const index = this.filteredAppointmentTypes.findIndex(
      (d) => d.id === appointmentTypeId
    );
    this.displayedAppointmentTypes.push(this.filteredAppointmentTypes[index]);
    this.filteredAppointmentTypes.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public addAppointmentStatusFilter(appointmentStatusId: number): void {
    const index = this.displayedAppointmentStatuses.findIndex(
      (d) => d.id === appointmentStatusId
    );
    this.filteredAppointmentStatuses.push(
      this.displayedAppointmentStatuses[index]
    );
    this.displayedAppointmentStatuses.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public removeAppointmentStatusFilter(appointmentStatusId: number): void {
    const index = this.filteredAppointmentStatuses.findIndex(
      (d) => d.id === appointmentStatusId
    );
    this.displayedAppointmentStatuses.push(
      this.filteredAppointmentStatuses[index]
    );
    this.filteredAppointmentStatuses.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public addActionTypeFilter(actionTypeId: number): void {
    const index = this.displayedActionTypes.findIndex(
      (d) => d.id === actionTypeId
    );
    this.filteredActionTypes.push(this.displayedActionTypes[index]);

    this.displayedActionTypes.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public removeActionTypeFilter(actionTypeId: number): void {
    const index = this.filteredActionTypes.findIndex(
      (d) => d.id === actionTypeId
    );
    this.displayedActionTypes.push(this.filteredActionTypes[index]);
    this.filteredActionTypes.splice(index, 1);

    this.triggerFiltersChanged();
  }

  public toggleOnlyOpenFilter(): void {
    this.onlyOpen = !this.onlyOpen;
    this.triggerFiltersChanged();
  }

  private triggerFiltersChanged(): void {
    const filteredDoctorIds = [];
    this.filteredDoctors.forEach((f) => {
      filteredDoctorIds.push(f.id);
    });

    const filteredClinicLocationIds = [];
    this.filteredClinicLocations.forEach((f) => {
      filteredClinicLocationIds.push(f.id);
    });
    const filteredAgentIds = [];
    this.filteredAgents.forEach((f) => {
      filteredAgentIds.push(f.id);
    });
    const filteredAppointmentTypeIds = [];
    this.filteredAppointmentTypes.forEach((f) => {
      filteredAppointmentTypeIds.push(f.id);
    });
    const filteredAppointmentStatusIds = [];
    this.filteredAppointmentStatuses.forEach((f) => {
      filteredAppointmentStatusIds.push(f.id);
    });
    const filteredActionTypeIds = [];
    this.filteredActionTypes.forEach((f) => {
      filteredActionTypeIds.push(f.id);
    });

    if (!this.firstTime) {
      this.filtersChanged.emit({
        onlyOpen: this.onlyOpen,
        doctorIds: filteredDoctorIds,
        clinicLocationIds: filteredClinicLocationIds,
        agentIds: filteredAgentIds,
        appointmentTypeIds: filteredAppointmentTypeIds,
        appointmentStatusIds: filteredAppointmentStatusIds,
        actionTypeIds: filteredActionTypeIds,
      });
    }
  }
}
