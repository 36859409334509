<mat-dialog-content>
  <h3>Potrditev</h3>

  <p>Ali ste prepričani, da želite {{ data.msg }}</p>

  <div id="btnGroup">
    <div></div>
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" [mat-dialog-close]="true">Potrdi</button>
    </div>
  </div>
</mat-dialog-content>