import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TableColumnDef } from '../../models/table-column-def.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GroupedDoctorPatientActionResult } from '../../models/action-type-doctor-price.model';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  @Input() dataSource: MatTableDataSource<any>;
  @Input() columns: TableColumnDef<any>[];
  @Input() showHeader = true;

  @Output() onCellClick = new EventEmitter<{
    row: GroupedDoctorPatientActionResult;
    column: string;
  }>();

  displayedColumns: string[];

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.columns?.map((c) => c.columnDef);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
