// teeth sorted 18,17,...,12,11,21,22,...,27,28
// 48,47,46,...,42,41,31,32,33,...37,38
// separated with ',' example: '23,24,25'
import {
  PatientOfferedAction,
  TeethEnum,
} from '../models/patient-offered-action.model';

export function poasToTeethString(poas: PatientOfferedAction[]): string {
  const teeth = poas?.map((poa) => poa?.teeth);
  return generateTeethArrayString(teeth);
}
export function generateTeethArrayString(teeth: TeethEnum[]): string {
  if (
    teeth.some((t) =>
      [TeethEnum.all, TeethEnum.bottom, TeethEnum.top].includes(t)
    )
  ) {
    return teeth.map((t) => teethEnumTranslation(t)).join(', ');
  }
  return generateTeethString(teeth.map((t) => Number(t)));
}

export function generateTeethString(teethArray: number[]): string {
  teethArray = teethArray.sort((a, b) => {
    const aDiv = Math.floor(a / 10);
    const bDiv = Math.floor(b / 10);
    if (aDiv !== bDiv) {
      if (aDiv === 4 && bDiv === 3) {
        return -1;
      } else if (aDiv === 3 && bDiv === 4) {
        return 1;
      }
      return a - b;
    }
    if (aDiv === 1 || aDiv === 4) {
      return b - a;
    } else {
      return a - b;
    }
  });
  return teethArray.join(', ');
}

export function teethEnumTranslation(teeth: TeethEnum | string): string {
  switch (teeth) {
    case TeethEnum.bottom:
      return 'spodnji';
    case TeethEnum.top:
      return 'zgornji';
    case TeethEnum.all:
      return 'vsi';
    case 'top, bottom':
    case 'bottom, top':
      return 'zgornji, spodnji';
    default:
      return teeth;
  }
}
