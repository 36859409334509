import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerFooterType',
})
export class HeaderFooterTypePipe implements PipeTransform {
  transform(type: string): string {
    switch (type) {
      case 'companyName':
        return 'Ime firme';
      case 'address':
        return 'Adresa';
      case 'email':
        return 'E-mail';
      case 'phone':
        return 'Telefon';
      case 'logo':
        return 'Logo';
      case 'registrationNumber':
        return 'Matična številka';
      case 'taxNumber':
        return 'Davčna številka';
      case 'bankAccount':
        return 'Transakcijski račun';
      default:
        return 'nepoznato polje';
    }
  }
}
