import { Pipe, PipeTransform } from '@angular/core';
import { BillActionBasic } from '../models/bill.model';

@Pipe({
  name: 'billActionsSumNumbers',
})
export class BillActionsSumNumbersPipe implements PipeTransform {
  transform(billActions: BillActionBasic[]): number {
    return billActions.map(a => a.currentPrice).reduce((totalPrice, price) => totalPrice + price, 0);
  }
}
