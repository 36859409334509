import { Pipe, PipeTransform } from '@angular/core';
import { sumWithRabat } from '../utils/receipt.utils';
import { BillActionBasic, BillBasic } from '../models/bill.model';

@Pipe({
  name: 'invoiceSumWithRabat',
})
export class InvoiceSumWithRabatPipe implements PipeTransform {
  transform(billActionBasics: BillActionBasic[], bill: BillBasic): number {
    return sumWithRabat(billActionBasics, bill);
  }
}
