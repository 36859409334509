import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumberFilter',
})
export class PhoneNumberFilterPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Check if the first character is a '+'
    let result = '';
    if (value.charAt(0) === '+') {
      result = '+';
      value = value.substring(1); // Remove the '+' from the original string for further processing
    }

    // Extract numbers only
    result += value.replace(/\D/g, ''); // \D matches any non-digit character and replaces them with an empty string

    return result;
  }
}
