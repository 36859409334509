import { Pipe, PipeTransform } from '@angular/core';
import { CashRegisterReceiptOverview } from '../shared-components/cash-register-page/cash-register-page.component';

@Pipe({
  name: 'receiptSum',
})
export class ReceiptSumPipe implements PipeTransform {
  transform(receipts: CashRegisterReceiptOverview[]): number {
    return receipts
      .filter(r => !r.patientName.includes('Polog'))
      .map((r) => r.amount)
      .reduce((totalPrice, receiptPrice) => totalPrice + receiptPrice, 0);
  }
}
