import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function digitLengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const valueLength = value?.toString().length || 0;
    return valueLength === length
      ? null
      : { digitLength: { requiredLength: length, actualLength: valueLength } };
  };
}
