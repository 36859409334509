<div>
  <mat-form-field style='width: 100%'>
    <div class='input-container'>
      <input type='text'
             [placeholder]='placeholder'
             matInput
             [formControl]='nameFormControl'
             [matAutocomplete]='auto'
             (blur)='checkIsValid()'
      >
      <button *ngIf='nameFormControl.value' matSuffix mat-icon-button aria-label='Clear'
              (click)="nameFormControl.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-autocomplete #auto='matAutocomplete' (optionSelected)='selectOption($event?.option?.value)'>
      <mat-option *ngFor='let option of filteredList$ | async' [value]='option.name'>{{ option.name }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
