import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Invoice, InvoicePagination } from '../../models/invoice.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/invoice';
  }

  public add(invoice: Invoice): Observable<number> {
    return this.http.post<number>(this.apiUrl, invoice);
  }

  public getPatientInvoices(patientId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(
      this.apiUrl + `/by-patient-id/${patientId}`
    );
  }

  public getPatientActiveTherapyInvoice(
    patientId: number
  ): Observable<Invoice> {
    return this.http.get<Invoice>(
      this.apiUrl + `/by-patient-id-active-invoice/${patientId}`
    );
  }

  public searchInvoices(
    date: Date,
    creatorId: number,
    address: string,
    page: number
  ): Observable<InvoicePagination> {
    let query = page ? `page=${page}` : 'page=0';
    if (date) {
      query += `&date=${date.toISOString()}`;
    }
    if (creatorId) {
      query += `&creatorId=${creatorId}`;
    }
    if (address) {
      query += `&address=${address}`;
    }

    return this.http.get<InvoicePagination>(
      this.apiUrl + `/pagination?${query}`
    );
  }
}
