import { Pipe, PipeTransform } from '@angular/core';
import { PatientActionTechnicianPrices } from '../../models/technician-action-type-price.model';

@Pipe({
  name: 'sumTechnicianCosts',
})
export class SumTechnicianCostsPipe implements PipeTransform {
  transform(
    actionTypeTechnicianPrices: PatientActionTechnicianPrices[],
    onlyFinished: boolean = false,
  ): number {
    return actionTypeTechnicianPrices
      .filter((a) => a.isFinished === onlyFinished)
      .map((a) => a.price)
      .reduce((totalPrice, price) => totalPrice + price, 0);
  }
}
