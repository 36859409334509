import { Pipe, PipeTransform } from '@angular/core';
import { Chat, ChatWithLastMsg, CompactUserInfo } from '../models/chat.model';

@Pipe({
  name: 'chatName',
})
export class ChatNamePipe implements PipeTransform {
  transform(
    chat: ChatWithLastMsg | Chat,
    currentUserId: number,
    userIdMap: Map<number, CompactUserInfo>
  ): string {
    if (chat?.users?.length > 2) {
      return chat?.name ?? 'Chat sa vise korisnika nema ime';
    }

    const otherUserId = chat?.users?.find((id) => id !== currentUserId);
    const otherUser = otherUserId ? userIdMap.get(otherUserId) : undefined;
    return otherUser
      ? otherUser.name + ' ' + otherUser?.lastName
      : 'Ime korisnika nije pronadjeno';
  }
}
