import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface DropdownItem {
  id: any;
  name: string;
}
@Component({
  selector: 'app-dropdown-search',
  templateUrl: './dropdown-search.component.html',
  styleUrls: ['./dropdown-search.component.scss'],
})
export class DropdownSearchComponent implements OnInit {
  public inputList$: BehaviorSubject<DropdownItem[]> = new BehaviorSubject([]);

  @Input() placeholder = 'Išči...';
  @Input() set selected(value: DropdownItem) {
    this.nameFormControl.setValue(
      !value?.name || value?.id === -1 ? '' : value?.name
    );
  }
  @Input() set markAsTouched(value: boolean) {
    if (!value) {
      return;
    }
    this.nameFormControl?.markAsTouched();
  }
  @Output() public selectedChange = new EventEmitter<DropdownItem>();
  @Input() set list(value: DropdownItem[]) {
    this.inputList$.next([...value]);
  }
  @Input() doNotShowError = false;
  public nameFormControl: UntypedFormControl;
  public filteredList$: Observable<DropdownItem[]>;

  constructor() {
    this.nameFormControl = new FormControl<string>('', []);
    this.filteredList$ = combineLatest([
      this.nameFormControl.valueChanges.pipe(startWith('')),
      this.inputList$,
    ]).pipe(
      map(([filter, list]) =>
        list?.filter((item) =>
          item?.name?.toLowerCase().includes(filter.toLowerCase())
        )
      )
    );
  }

  ngOnInit() {
    this.nameFormControl.setValidators(inputValidator(this.doNotShowError));
  }

  checkIsValid() {
    setTimeout(() => {
      const option = this.inputList$.value?.find(
        (a) => a.name === this.nameFormControl.value
      );
      if (!option) {
        this.nameFormControl.setValue('');
      }
      this.selectedChange.emit(option?.id ? option : null);
    }, 200);
  }

  selectOption(selectedOption: string) {
    this.nameFormControl.setValue(selectedOption);
  }
}

function inputValidator(doNotShowError): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = doNotShowError || control.value?.length;
    return isValid ? null : { invalidInput: { value: control.value } };
  };
}
