import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ReceiptAddress } from '../../models/receipt.model';

@Injectable({
  providedIn: 'root',
})
export class ReceiptAddressService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/receipt-address';
  }

  public add(receiptAddress: ReceiptAddress): Observable<number> {
    return this.http.post<number>(this.apiUrl, receiptAddress);
  }

  public delete(proformaInvoiceId: number): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + `/${proformaInvoiceId}`);
  }

  public getReceiptAddresses(): Observable<ReceiptAddress[]> {
    return this.http.get<ReceiptAddress[]>(this.apiUrl);
  }
}
