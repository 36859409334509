export class PatientAction {
  public id: number;
  public name: string;
  public price: number;
  public clinicId: number;
  public type: TeethType;
  public category: string;
  public onlyTechnician: boolean;
  public isColorMandatory: boolean;
}

export class PatientActionCategory {
  public id: number;
  public name: string;
}

export enum TeethType {
  single = 'single',
  all = 'all',
  top_bottom = 'top_bottom',
}

export interface PatientActionWithPrices {
  patientAction: PatientAction;
  doctorPrices?: DoctorPrice[];
  technicianPrices?: TechnicianPrice[];
}

export interface DoctorPrice {
  actionTypeDoctorPriceTypeId?: number;
  name?: string;
  price?: number;
}

export interface TechnicianPrice {
  technicianId?: number;
  name?: string;
  price?: number;
}
