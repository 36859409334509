import { BillActionBasic, BillBasic } from '../models/bill.model';
import { AdvanceReceipt, Receipt, ReceiptStatus } from '../models/receipt.model';
import { DiscountValueOptions } from './constants/constants';
import { ADVANCE_RECEIPT, RECEIPT } from './constants/pages.constants';

export function sumWithDiscount(billActionBasics: BillActionBasic[], bill: BillBasic): number {
  return billActionBasics?.map(
      (ia) => {
        if (!bill.patientActionsDiscountType || DiscountValueOptions.PERCENT === bill.patientActionsDiscountType) {
          return (ia?.currentPrice * (100 - ia.discount)) / 100;
        } else if (DiscountValueOptions.EURO === bill.patientActionsDiscountType) {
          const reducedPrice = ia?.currentPrice - ia.discount;
          return reducedPrice <= 0 ? 0 : reducedPrice;
        }
      }
    )
    .reduce((totalPrice, itemPrice) => totalPrice + itemPrice, 0);
}

export function sumWithRabat(
  billActionBasics: BillActionBasic[],
  bill: BillBasic
): number {
  const swD = sumWithDiscount(billActionBasics, bill);
  return (swD * (100 - bill.discount)) / 100;
}

export function getFullCreatorName(billBasic: BillBasic) {
  return `${billBasic.creationUserInfo?.name} ${billBasic.creationUserInfo?.lastName}`;
}

export function getFullPatientName(billBasic: BillBasic) {
  return `${billBasic.patient?.name} ${billBasic.patient?.lastName}`;
}


export function isPaymentOverdue(receipt: Receipt | AdvanceReceipt) {
  return ReceiptStatus.pending_payment === receipt.status && new Date() > new Date(receipt.dueDate);
}

export function isReceiptOrAdvance(receipt: Receipt | AdvanceReceipt): typeof RECEIPT | typeof ADVANCE_RECEIPT {
  if ('startingBalance' in receipt) {
    return ADVANCE_RECEIPT;
  }
  return RECEIPT;
}
