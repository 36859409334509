import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceAction } from '../models/invoice.model';
import { BillActionBasic } from '../models/bill.model';

@Pipe({
  name: 'groupPatientInvoiceActions',
})
export class GroupPatientInvoiceActionsPipe implements PipeTransform {
  transform(arr: BillActionBasic[]): Map<string, BillActionBasic[]> {
    return groupPatientInvoiceActions(arr);
  }
}

export function groupPatientInvoiceActions(
  arr: BillActionBasic[]
): Map<string, BillActionBasic[]> {
  const resultMap = new Map<string, InvoiceAction[]>();
  arr.forEach((ia) => {
    if (resultMap.has(ia?.patientOfferedAction?.patientAction?.category)) {
      resultMap.get(ia?.patientOfferedAction?.patientAction?.category).push(ia);
    } else {
      resultMap.set(ia?.patientOfferedAction?.patientAction?.category, [ia]);
    }
  });
  return resultMap;
}
