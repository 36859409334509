import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  PatientAction,
  TeethType,
} from '../../../../../models/patient-action.model';
import {
  PatientOfferedAction,
  PatientOfferedActionStatus,
  TeethEnum,
} from '../../../../../models/patient-offered-action.model';
import {
  FormBuilder,
  FormControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ToastService } from '../../../../../services/other-services/toast.service';
import { DropdownItem } from '../../../../../shared-components/dropdown-search/dropdown-search.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-select-patient-action-dialog',
  templateUrl: './select-patient-action-dialog.component.html',
  styleUrls: ['./select-patient-action-dialog.component.scss'],
})
export class SelectPatientActionDialogComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  teethArray: number[] = [];
  patientActionType = TeethType;

  patientActionTypeFormControl = new FormControl<TeethType>(
    undefined,
    Validators.required
  );

  topBottomForm = this.fb.group({
    top: new UntypedFormControl(false, Validators.required),
    bottom: new UntypedFormControl(false, Validators.required),
  });
  patientActions: PatientAction[];
  dropdownMarkAsTouched = false;
  public selectedPatientActions: BehaviorSubject<PatientAction>[] = [
    new BehaviorSubject(undefined),
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientActions: PatientAction[];
      patientId: number;
    },
    private toast: ToastService,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<SelectPatientActionDialogComponent>
  ) {
    this.patientActionTypeFormControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.selectedPatientActions = [new BehaviorSubject(undefined)];
        this.patientActions = this.data?.patientActions.filter(
          (pa) => pa.type === value
        );
      });
    this.patientActionTypeFormControl.setValue(TeethType.all);
  }

  save(): void {
    this.dropdownMarkAsTouched = true;
    const patientOfferedActions: PatientOfferedAction[] = [];
    if (this.selectedPatientActions.some((spa) => !spa.value)) {
      return;
    }

    if (this.patientActionTypeFormControl?.value === TeethType.single) {
      patientOfferedActions.push(...this.createPatientOfferedActionsSingle());
    } else if (this.patientActionTypeFormControl?.value === TeethType.all) {
      this.selectedPatientActions.forEach((selectedPatientAction) =>
        patientOfferedActions.push(
          createNewPatientOfferedAction(
            selectedPatientAction.value.id,
            TeethEnum.all,
            this.data.patientId
          )
        )
      );
    } else if (
      this.patientActionTypeFormControl?.value === TeethType.top_bottom
    ) {
      patientOfferedActions.push(
        ...this.createPatientOfferedActionsTopBottom()
      );
    }

    if (!patientOfferedActions.length) {
      return;
    }
    this.dialogRef.close(patientOfferedActions);
  }

  private createPatientOfferedActionsTopBottom(): PatientOfferedAction[] {
    const patientOfferedActionArray = [];
    if (this.topBottomForm.controls.top.value) {
      this.selectedPatientActions.forEach((selectedPatientAction) =>
        patientOfferedActionArray.push(
          createNewPatientOfferedAction(
            selectedPatientAction.value.id,
            TeethEnum.top,
            this.data.patientId
          )
        )
      );
    }
    if (this.topBottomForm.controls.bottom.value) {
      this.selectedPatientActions.forEach((selectedPatientAction) =>
        patientOfferedActionArray.push(
          createNewPatientOfferedAction(
            selectedPatientAction.value.id,
            TeethEnum.bottom,
            this.data.patientId
          )
        )
      );
    }
    if (!patientOfferedActionArray.length) {
      this.toast.error('Izaberi bar jednu čeljust!');
      return [];
    }
    return patientOfferedActionArray;
  }

  private createPatientOfferedActionsSingle(): PatientOfferedAction[] {
    const patientOfferedActionArray = [];
    this.teethArray.forEach((tooth) =>
      this.selectedPatientActions.forEach((selectedPatientAction) =>
        patientOfferedActionArray.push(
          createNewPatientOfferedAction(
            selectedPatientAction.value.id,
            tooth.toString() as TeethEnum,
            this.data.patientId
          )
        )
      )
    );
    if (!this.teethArray.length) {
      this.toast.error('Izberite vsaj en zob!');
      return [];
    }
    return patientOfferedActionArray;
  }

  addOrRemoveTeeth(id: number): void {
    const index = this.teethArray.indexOf(id);
    if (index > -1) {
      this.teethArray.splice(index, 1);
    } else {
      this.teethArray.push(id);
    }
  }

  selectedChange(dropdownSelection: DropdownItem, index: number) {
    this.selectedPatientActions[index].next(
      this.data.patientActions.find((pa) => pa.id === dropdownSelection?.id)
    );
  }

  removeSelectedPatientAction(index: number) {
    this.selectedPatientActions.splice(index, 1);
  }

  addNewSelectedPatientAction() {
    this.selectedPatientActions.push(new BehaviorSubject(undefined));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

function createNewPatientOfferedAction(
  patientActionId: number,
  teeth: TeethEnum,
  patientId: number
): PatientOfferedAction {
  return {
    patientActionId,
    teeth,
    patientId,
    createdAt: new Date(),
    status: PatientOfferedActionStatus.open,
    isSelectedAsNext: true,
    isActive: true,
  };
}
