import { Pipe, PipeTransform } from '@angular/core';
import { PatientActionTechnicianPrices } from '../../models/technician-action-type-price.model';

@Pipe({
  name: 'countTechnicianCosts',
})
export class CountTechnicianCostsPipe implements PipeTransform {
  transform(
    actionTypeTechnicianPrices: PatientActionTechnicianPrices[],
    onlyFinished: boolean = false,
  ): number {
    return actionTypeTechnicianPrices.filter(
      (a) => a.isFinished === onlyFinished,
    ).length;
  }
}
