import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { FileInfoService } from './file-info.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    private fileInfoService: FileInfoService,
    private storageService: LocalStorageService
  ) {
    this.apiUrl = environment.apiUrl + '/file';
  }

  public uploadWithProgress(
    file: File,
    folderId: number
  ): Observable<HttpEvent<string>> {
    const clinicId = this.storageService.getClinicId();
    const uploadData = new FormData();
    uploadData.append('file', file, file.name);

    return this.http.post<string>(
      this.apiUrl + `/${clinicId}/${folderId}`,
      uploadData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  public openFilePreview(fileInfoId: number) {
    this.fileInfoService.get(fileInfoId).subscribe((fileInfo) => {
      window.open(
        `https://drive.google.com/file/d/${fileInfo.googleDriveId}/view`,
        'newwindow',
        'width=800,height=800'
      );
    });
  }

  public upload(file: File, folderId: number): Observable<number> {
    const clinicId = this.storageService.getClinicId();
    const uploadData = new FormData();
    uploadData.append('file', file, file.name);

    return this.http.post<number>(
      this.apiUrl + `/${clinicId}/${folderId}`,
      uploadData
    );
  }
}
