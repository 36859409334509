<h2 mat-dialog-title>Unesi izdatek</h2>

<mat-dialog-content [formGroup]='form'>

  <mat-form-field>
    <mat-label>Korisnik</mat-label>
    <input matInput formControlName='user' readonly>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Adresa</mat-label>
    <input matInput formControlName='address' readonly>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Date</mat-label>
    <input type='date' matInput formControlName='date' readonly>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Iznos</mat-label>
    <input type='number' matInput formControlName='amount'>
  </mat-form-field>
  <mat-error *ngIf="form.get('amount').hasError('maxAmountExceeded')" style='font-size: 11px'>
    Iznos pologa ne moze da bude veći od trenutnog stanja
  </mat-error>
</mat-dialog-content>

<mat-dialog-actions>
  <div id='actionButtons'>
    <button class='btn btn-light' mat-dialog-close>Prekliči</button>
    <button class='btn btn-primary'
            [disabled]='form.invalid'
            [mat-dialog-close]='form.getRawValue()'>
      Spremi
    </button>
  </div>
</mat-dialog-actions>