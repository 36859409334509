import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  InvoiceReceiptInfo,
  ReceiptFooter,
  ReceiptHeader,
  ReceiptHeaderFooter,
} from '../../models/receipt.model';

@Injectable({
  providedIn: 'root',
})
export class ReceiptHeaderFooterService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/receipt-header-footer';
  }

  public add(receiptHeaderFooter: ReceiptHeaderFooter): Observable<number> {
    return this.http.post<number>(this.apiUrl, receiptHeaderFooter);
  }

  public addLogo(receiptHeaderFooter: ReceiptHeaderFooter): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/add-logo`,
      receiptHeaderFooter
    );
  }

  public getReceiptHeader(): Observable<ReceiptHeader> {
    return this.http.get<ReceiptHeader>(`${this.apiUrl}/header`);
  }

  public getReceiptFooter(): Observable<ReceiptFooter> {
    return this.http.get<ReceiptFooter>(`${this.apiUrl}/footer`);
  }

  public getInvoiceReceiptInfo(): Observable<InvoiceReceiptInfo> {
    return this.http.get<InvoiceReceiptInfo>(
      `${this.apiUrl}/invoice-receipt-info`
    );
  }
}
