import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '../../models/notification.model';
import { NOTIFICATION_PAGE_AMOUNT } from '../../utils/constants/constants';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent {
  NOTIFICATION_PAGE_AMOUNT = NOTIFICATION_PAGE_AMOUNT;
  @Output() public clickOnNotificationEmitter = new EventEmitter<
    Notification
  >();
  @Output() public loadMoreNotificationsEmitter = new EventEmitter<number>();

  @Input() public notifications: Notification[];
  @Input() public hasUnopenedNotification: boolean;
  @Input() public notificationsPage: number;

  constructor() {}

  clickOnNotification(notification: Notification) {
    if (!notification?.id) {
      return;
    }
    this.clickOnNotificationEmitter.emit(notification);
  }

  loadMoreNotifications(
    loadedNotificationsAmount: number,
    currentPage: number
  ) {
    if (loadedNotificationsAmount === currentPage * NOTIFICATION_PAGE_AMOUNT) {
      this.loadMoreNotificationsEmitter.next(currentPage + 1);
    }
  }
}
