import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-go-to-date-dialog',
  templateUrl: './go-to-date-dialog.component.html',
  styleUrls: ['./go-to-date-dialog.component.scss'],
})
export class GoToDateDialogComponent {
  public dateFormControl: UntypedFormControl;

  constructor(
    private dialogRef: MatDialogRef<GoToDateDialogComponent>,
    private datePipe: DatePipe
  ) {
    this.dateFormControl = new UntypedFormControl(
      this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    );
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.selectDate();
    }
  }
  public selectDate(): void {
    this.dialogRef.close(this.dateFormControl.value);
  }
  public selectThisWeek(): void {
    this.dialogRef.close(new Date(Date.now()));
  }
}
