import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subtractAdvancePaidAmount',
})
export class SubtractAdvancePaidAmountPipe implements PipeTransform {
  transform(price?: number | string, advanceUsed?: number): any {
    if (!advanceUsed || !price) return price;
    price = Number(price);
    const advance = Number(advanceUsed?.toString().replace(',', '.'));
    return price - advance > 0 ? price - advance : 0;
  }
}
