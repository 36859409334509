import { PatientCallStatus } from './patient-call-status.model';
import { Patient } from './patient.model';
import { ClinicLocation } from './clinic-location.model';
import { UserInfo } from './user-info.model';
import { PartOfDay } from './paty-of-day.model';

export class PatientCall {
  public id: number;
  public date: Date;
  public patientCallStatusId: number;
  public patientCallStatus: PatientCallStatus;
  public patientId: number;
  public patient: Patient;
  public clinicLocationId: number;
  public clinicLocation: ClinicLocation;
  public content: string;
  public authorUserInfoId: number;
  public authorUserInfo: UserInfo;
  public partOfDayId: number;
  public partOfDay: PartOfDay;
  public isUrgent: boolean;
  public isSummary: boolean;
}
