import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileInfo } from '../../models/file-info.model';

@Injectable({
  providedIn: 'root',
})
export class FileInfoService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/file-info';
  }

  public get(fileInfoId: number): Observable<FileInfo> {
    return this.http.get<FileInfo>(this.apiUrl + `/${fileInfoId}`);
  }

  public delete(fileInfoId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${fileInfoId}`);
  }
}
