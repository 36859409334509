import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CashDeposit } from '../../models/cash-register.model';

@Injectable({
  providedIn: 'root'
})
export class CashDepositService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/cash-deposit';
  }

  public addCashDeposit(cashDeposit: CashDeposit): Observable<CashDeposit> {
    return this.http.post<CashDeposit>(this.apiUrl + '/add', cashDeposit);
  }

  public getCashDepositByDateAndAddress(date: Date, addressId: number): Observable<CashDeposit[]> {
    const dateQuery = date ? `date=${date.toISOString()}` : `date=${new Date().toISOString()}`;
    const addressQuery = addressId ? `addressId=${addressId}` : '';
    const queryParams = [dateQuery, addressQuery].filter(param => !!param).join('&');

    const urlWithParams = `${this.apiUrl}${queryParams.length > 0 ? '?' + queryParams : ''}`;

    return this.http.get<CashDeposit[]>(urlWithParams);
  }
}

