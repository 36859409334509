import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileService } from '../../services/api-services/file.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastService } from '../../services/other-services/toast.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { GOOGLE_DRIVE_FOLDER_ID } from '../../utils/constants/constants';

@Component({
  selector: 'app-cropped-image-upload-dialog',
  templateUrl: './cropped-image-upload-dialog.component.html',
  styleUrls: ['./cropped-image-upload-dialog.component.scss'],
})
export class CroppedImageUploadDialogComponent {
  public imageChangedEvent: any = '';
  public croppedImage: string;
  public loadingProgress: string;

  constructor(
    private dialogRef: MatDialogRef<CroppedImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      msg: string;
      actionText: string;
      googleDirectoryId: GOOGLE_DRIVE_FOLDER_ID;
    },
    private fileService: FileService,
    private toast: ToastService
  ) {
    this.loadingProgress = null;
    this.croppedImage = '';
  }

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.toast.error('Slike ni bilo možno naložiti. Poskusite z drugo sliko.');
  }

  public save(): void {
    if (!this.croppedImage) {
      return;
    }

    this.loadingProgress = '0';

    this.fileService
      .uploadWithProgress(
        this.b64toFile(this.croppedImage),
        this.data.googleDirectoryId
      )
      .subscribe((httpEvent: HttpEvent<any>) => {
        switch (httpEvent.type) {
          case HttpEventType.UploadProgress:
            this.loadingProgress = Math.round(
              (httpEvent.loaded / httpEvent.total) * 100
            ).toString();
            break;
          case HttpEventType.Response:
            this.loadingProgress = '100';
            this.dialogRef.close(httpEvent.body);
            break;
        }
      });
    /*
     */
  }

  private b64toFile(dataURI): File {
    // convert the data URL to a byte string
    const byteString = atob(dataURI.split(',')[1]);

    // pull out the mime type from the data URL
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Convert to byte array
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a blob that looks like a file.
    const blob = new Blob([ab], { type: mimeString });
    blob['lastModifiedDate'] = new Date().toISOString();
    blob['name'] = 'logo';

    // Figure out what extension the file should have
    switch (blob.type) {
      case 'image/jpeg':
        blob['name'] += '.jpg';
        break;
      case 'image/png':
        blob['name'] += '.png';
        break;
    }
    // cast to a File
    return blob as File;
  }
}
