import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Comment } from '../../models/comment.model';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
})
export class CommentCardComponent {
  @Input() public comment: Comment;
  @Input() public editMode: boolean;

  @Output() public commentClicked: EventEmitter<Comment> = new EventEmitter<Comment>();

  constructor() {}

  public onCommentClicked(): void {
    this.commentClicked.emit(this.comment);
  }
}
