import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Appointment } from '../../models/appointment.model';
import { Comment } from '../../models/comment.model';

@Component({
  selector: 'app-first-examination-card',
  templateUrl: './first-examination-card.component.html',
  styleUrls: ['./first-examination-card.component.scss'],
})
export class FirstExaminationCardComponent {
  @Output() public editClicked = new EventEmitter<number>();
  @Output() public addCommentClicked = new EventEmitter<number>();
  @Output() public editCommentClicked = new EventEmitter<{
    comment: Comment;
    appointmentId: number;
  }>();
  @Output() refreshAppointments = new EventEmitter<void>();

  @Input() public appointment: Appointment;
  @Input() public disabled: boolean;
  @Input() public index: number;
  @Input() public editMode: boolean;

  public onEditClicked(): void {
    this.editClicked.emit(this.appointment.id);
  }

  public addComment(): void {
    this.addCommentClicked.emit(this.appointment.id);
  }

  public onCommentClicked(comment: Comment): void {
    this.editCommentClicked.emit({
      comment: comment,
      appointmentId: this.appointment.id,
    });
  }
}
