import { PatientAction } from './patient-action.model';
import { UserInfo } from './user-info.model';
import { Patient } from './patient.model';
import { Doctor } from './doctor.model';
import { Appointment } from './appointment.model';
import { TechnicianAction } from './technician-action.model';

export type PatientOfferedAction = {
  id?: number;
  isSelectedAsNext?: boolean;
  patientActionId: number;
  patientAction?: PatientAction;
  teeth: TeethEnum;
  creationUserInfoId?: number;
  creationUserInfo?: UserInfo;
  createdAt?: Date;
  status?: PatientOfferedActionStatus;
  comment?: string;
  isActive: boolean;
  patientId: number;
  patient?: Patient;
  selectedInTable?: boolean;
  appointmentId?: number;
  appointment?: Appointment;
  technicianActionId?: number;
  technicianAction?: TechnicianAction;
  appointmentComment?: string;
  appointmentToothPlane?: string;
};
export interface ActiveTherapy {
  id?: number;
  doctorId: number;
  doctor?: Doctor;
  patientId: number;
  patient?: Patient;
  createdAt?: Date;
  finishedAt?: Date;
  finishedComment?: string;
  patientOfferedActions?: PatientOfferedAction[];
  invoiceId?: number;
}
export interface PoaAppointmentView {
  appointmentComment?: string;
  appointmentToothPlane?: string;
  teeth?: TeethEnum[];
  patientActionName?: string;
  patientActionId?: number;
}

export enum PatientOfferedActionStatus {
  open = 'open',
  canceled = 'canceled',
  paid = 'paid',
  pending_payment = 'pending-payment',
  deleted = 'deleted',
}

export enum TeethEnum {
  _11 = '11',
  _12 = '12',
  _13 = '13',
  _14 = '14',
  _15 = '15',
  _16 = '16',
  _17 = '17',
  _18 = '18',
  _21 = '21',
  _22 = '22',
  _23 = '23',
  _24 = '24',
  _25 = '25',
  _26 = '26',
  _27 = '27',
  _28 = '28',
  _31 = '31',
  _32 = '32',
  _33 = '33',
  _34 = '34',
  _35 = '35',
  _36 = '36',
  _37 = '37',
  _38 = '38',
  _41 = '41',
  _42 = '42',
  _43 = '43',
  _44 = '44',
  _45 = '45',
  _46 = '46',
  _47 = '47',
  _48 = '48',
  top = 'top',
  bottom = 'bottom',
  all = 'all',
}
