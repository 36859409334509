export const NOTIFICATION_PAGE_AMOUNT = 10;

// Change the values if they change in the database
export const RELEVANT_PAYMENT_METHOD_IDS = [1, 2, 3, 4];

export const HEADER_FIELDS = [
  'companyName',
  'address',
  'email',
  'phone',
  'logo',
];
export const FOOTER_FIELDS = ['registrationNumber', 'taxNumber', 'bankAccount'];

export const PAYMENT_METHOD_CHANGE_COMPATIBILITY_MAP = new Map<
  number,
  number[]
>([
  [1, [1, 2]], // from 'gotovina' to 'kartica'
  [2, [1, 2]], // from 'kartica' to 'gotovina'
  [3, []], // 'TRR placeno' cannot be changed
  [4, [1, 2, 3, 4]], // from 'TRR neplaceno' to all, but with certain conditions
]);

export enum PAYMENT_METHODS {
  GOTOVINA = 1,
  KARTICA = 2,
  TRR_PLACENO = 3,
  TRR_NEPLACENO = 4,
}

export enum DiscountSymbolOptions {
  EURO = '€',
  PERCENT = '%',
}

export enum DiscountValueOptions {
  EURO = 'euro',
  PERCENT = 'percent',
}

export const DISCOUNT_OPTIONS = [
  { symbol: DiscountSymbolOptions.EURO, value: DiscountValueOptions.EURO },
  {
    symbol: DiscountSymbolOptions.PERCENT,
    value: DiscountValueOptions.PERCENT,
  },
];

export const PAGE_SIZE = 15;

export enum ADVANCE_HANDLING_AFTER_CANCELLATION {
  P = 'Platimo pacijentu',
  A = 'Vratimo na avansni račun',
}

export enum ADVANCES {
  ADVANCE = 562,
  TREATMENT_PLAN = 394,
  VISUALIZATION_PREP = 513,
}

export enum ADVANCE_SEARCH_OPTION {
  ALL = 'Svi',
  USED = 'Iskoristeni',
  OPEN = 'Otvoreni',
}

export const GOOGLE_DRIVE_IMG_URL = 'https://lh3.googleusercontent.com/d/';

export enum GOOGLE_DRIVE_FOLDER_ID {
  RTG_ORTOPAN = 1,
  NACRT_ZDRAVLJENJA = 2,
  PAROSTATUS = 3,
  SLIKE_PRED_POSEGOM = 4,
  SLIKE_PO_POSEGU = 5,
  IZJAVE = 6,
  TEHNIK_NALOG = 7,
  TEHNIK = 8,
  OTHER = 10,
}

export enum APPOINTMENT_STATUS {
  ORDERED = 1,
  CONFIRMED_MAIL = 2,
  CONFIRMED_SMS = 3,
  CONFIRMED_CALL = 8,
  ARRIVED = 4,
  NO_SHOW = 5,
  OVERORDERED = 6,
  CANCELED = 7,
  CANCELED_THERAPY = 9,
}

export enum EDIT_RECURRING_SWT {
  DELETE = 1,
  ADD_EXCEPTION = 2,
  ADD_END_DATE = 3,
}
