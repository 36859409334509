import { Injectable } from '@angular/core';
import { AdminService } from '../api-services/admin.service';
import { LocalStorageService } from '../api-services/local-storage.service';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { AgentService } from '../api-services/agent.service';
import {
  ADMINISTRATORS,
  ADMINS,
  AGENTS,
  ASSISTANTS,
  DOCTORS,
  TECHNICIANS,
  WORKERS,
} from '../../utils/constants/pages.constants';
import { AssistantService } from '../api-services/assistant.service';
import { DoctorService } from '../api-services/doctor.service';
import { ClinicService } from '../api-services/clinic.service';
import { TechnicianService } from '../api-services/technician.service';
import { TechnicianLogoService } from '../api-services/technician-logo.service';
import { map, switchMap } from 'rxjs/operators';
import { TechnicianWorkerService } from '../api-services/technician-worker.service';
import { AdministratorService } from '../api-services/administrator.service';

@Injectable({
  providedIn: 'root',
})
export class UserTypeService {
  constructor(
    private storageService: LocalStorageService,
    private adminService: AdminService,
    private agentService: AgentService,
    private assistantService: AssistantService,
    private doctorService: DoctorService,
    private clinicService: ClinicService,
    private technicianService: TechnicianService,
    private technicianLogoService: TechnicianLogoService,
    private technicianWorkerService: TechnicianWorkerService,
    private administratorService: AdministratorService
  ) {}

  public getUsers(type: string): Observable<User[]> {
    const clinicId = this.storageService.getClinicId();
    if (ADMINS === type) {
      return this.adminService.getByClinicId(clinicId);
    } else if (AGENTS === type) {
      return this.agentService.getByClinicId(clinicId);
    } else if (ASSISTANTS === type) {
      return this.assistantService.getByClinicId(clinicId);
    } else if (DOCTORS === type) {
      return this.doctorService.getByClinicId(clinicId);
    } else if (TECHNICIANS === type) {
      return this.clinicService.getClinicTechnicians(clinicId);
    } else if (WORKERS === type) {
      return this.technicianWorkerService.getByTechnicianUserInfoId();
    } else if (ADMINISTRATORS === type) {
      return this.administratorService.getByClinicId(clinicId);
    }
  }

  addUser(user: User, type: string) {
    user.clinicId = this.storageService.getClinicId();
    if (ADMINS === type) {
      return this.adminService.add(user);
    } else if (AGENTS === type) {
      return this.agentService.add(user);
    } else if (ASSISTANTS === type) {
      return this.assistantService.add(user);
    } else if (DOCTORS === type) {
      return this.doctorService.add(user);
    } else if (TECHNICIANS === type) {
      return this.addTechnicianAndLogo(user);
    } else if (WORKERS === type) {
      return this.technicianWorkerService.add(user);
    } else if (ADMINISTRATORS === type) {
      return this.administratorService.add(user);
    }
  }

  private addTechnicianAndLogo(user: User) {
    const logo = user.technicianLogo;
    user.technicianLogo = null; // this has to be overwritten due to the way the DB is set up
    return this.technicianService.add(user).pipe(
      switchMap((userId) => {
        logo.technicianId = userId;
        return this.technicianLogoService
          .add({ ...logo, fileInfo: null })
          .pipe(map(() => userId));
      })
    );
  }

  updateUser(user: User, type: string) {
    user.clinicId = this.storageService.getClinicId();
    if (ADMINS === type) {
      return this.adminService.update(user);
    } else if (AGENTS === type) {
      return this.agentService.update(user);
    } else if (ASSISTANTS === type) {
      return this.assistantService.update(user);
    } else if (DOCTORS === type) {
      return this.doctorService.update(user);
    } else if (TECHNICIANS === type) {
      return this.updateTechnicianAndLogo(user);
    } else if (WORKERS === type) {
      return this.technicianWorkerService.update(user);
    } else if (ADMINISTRATORS === type) {
      return this.administratorService.update(user);
    }
  }

  private updateTechnicianAndLogo(user: User) {
    return this.technicianService.update(user).pipe(
      switchMap((userId) => {
        return this.technicianLogoService
          .add({ ...user.technicianLogo, fileInfo: null })
          .pipe(map(() => userId));
      })
    );
  }

  deleteUser(id: number, type: string) {
    if (ADMINS === type) {
      return this.adminService.delete(id);
    } else if (AGENTS === type) {
      return this.agentService.delete(id);
    } else if (ASSISTANTS === type) {
      return this.assistantService.delete(id);
    } else if (DOCTORS === type) {
      return this.doctorService.delete(id);
    } else if (TECHNICIANS === type) {
      return this.technicianService.delete(id);
    } else if (WORKERS === type) {
      return this.technicianWorkerService.delete(id);
    } else if (ADMINISTRATORS === type) {
      return this.administratorService.delete(id);
    }
  }
}
