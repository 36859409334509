import { Pipe, PipeTransform } from '@angular/core';
import {
  PatientOfferedAction,
  TeethEnum,
} from '../models/patient-offered-action.model';

@Pipe({
  name: 'sortPoaOnTeeth',
})
export class SortPoaOnTeethPipe implements PipeTransform {
  transform(poas: PatientOfferedAction[]): PatientOfferedAction[] {
    return poas.sort((a, b) => compareTeeth(a.teeth, b.teeth));
  }
}

function compareTeeth(teethA: TeethEnum, teethB: TeethEnum): number {
  const nonNumericOrder = ['top', 'bottom', 'all'];

  // Handle non-numeric values by putting 'top' > 'bottom' > 'all'
  if (nonNumericOrder.includes(teethA) || nonNumericOrder.includes(teethB)) {
    return nonNumericOrder.indexOf(teethA) - nonNumericOrder.indexOf(teethB);
  }

  // Otherwise, sort by numeric value
  return parseInt(teethA, 10) - parseInt(teethB, 10);
}
