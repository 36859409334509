<mat-dialog-content class='overview-info'>
  <form [formGroup]='fg'>
    <h2>{{ data?.title || 'Otkaži' }}</h2>

    <mat-form-field>
      <mat-label>Način povračila</mat-label>
      <select matNativeControl required formControlName='refundMethod'>
        <option *ngFor='let method of data.availablePaymentMethods' [value]='method.id'>{{ method.name }}</option>
      </select>
      <mat-error>Polje je obavezno</mat-error>
    </mat-form-field>

    <mat-form-field class='comment'>
      <mat-label>Opomba</mat-label>
      <textarea matInput formControlName='comment'></textarea>
    </mat-form-field>

    <div *ngIf='data.showCancellationOptions'>
      <mat-radio-group aria-label='Select an option' formControlName='advanceHandling'>
        <mat-label>Iskorišćeni avans:</mat-label>
        <mat-radio-button [value]='getEnumKeyForValue(ADVANCE_HANDLING_AFTER_CANCELLATION.P)'>{{ ADVANCE_HANDLING_AFTER_CANCELLATION.P }}
        </mat-radio-button>
        <mat-radio-button
          [value]='getEnumKeyForValue(ADVANCE_HANDLING_AFTER_CANCELLATION.A)'>{{ ADVANCE_HANDLING_AFTER_CANCELLATION.A }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <br />

    <div *ngIf='data.receiptActions.length > 0' [formGroup]='actionsFormGroup'>
      <span class='receipt-actions-list'>
        <mat-checkbox
          [checked]='allChecked'
          [indeterminate]='someComplete()'
          (change)='setAll($event.checked)'>
          Storniraj sve storitve
        </mat-checkbox>
      </span>
      <span *ngIf='!allChecked'>
        <ul>
          <li *ngFor='let action of data.receiptActions; let i = index'>
               <mat-checkbox
                 [formControlName]='action.id'
                 (change)='updateIndeterminateState()'>
                {{ data.receiptActions[i]?.patientOfferedAction.patientAction.name }}
          </mat-checkbox>
          </li>
        </ul>
      </span>
    </div>


    <div class='actionButtons'>
      <div></div>
      <div>
        <button class='back' mat-button [mat-dialog-close]='false'>Nazaj</button>
      </div>
      <div>
        <button class='cancel' mat-button (click)='cancel()'>Prekliči</button>
      </div>
      <div></div>
    </div>

  </form>
</mat-dialog-content>