import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PartOfDay } from '../../models/paty-of-day.model';

@Injectable({
  providedIn: 'root',
})
export class PartOfDayService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/part-of-day';
  }

  public add(partOfDay: PartOfDay): Observable<number> {
    return this.http.post<number>(this.apiUrl, partOfDay);
  }

  public update(partOfDay: PartOfDay): Observable<PartOfDay> {
    return this.http.patch<PartOfDay>(this.apiUrl, partOfDay);
  }

  public deleteMany(partOfDayIds: number[]): Observable<boolean> {
    let parameters = '?';
    partOfDayIds.forEach((a, i) => {
      parameters += 'partOfDayIds=' + a;
      if (i !== partOfDayIds.length - 1) {
        parameters += '&';
      }
    });
    return this.http.delete<boolean>(this.apiUrl + '/many' + parameters);
  }

  public getByClinicId(clinicId: number): Observable<PartOfDay[]> {
    return this.http.get<PartOfDay[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}`
    );
  }
}
