import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayToMonthName',
})
export class DayToMonthNamePipe implements PipeTransform {
  transform(value: Date): string {
    switch (value.getMonth()) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'Maj';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Avg';
      case 8:
        return 'Sep';
      case 9:
        return 'Okt';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
    }
  }
}
