<mat-dialog-content>
  <div class='container'>

    <h2>Dodaj Akcijo</h2>


    <div class='header' *ngIf='patientOfferedActions$ | async as patientOfferedActions'>
      <app-dropdown-search
        [(selected)]='selectedPoaFilter'
        placeholder='Naziv'
        [list]='allPoasDropdown$ | async'
        [doNotShowError]='true'>
      </app-dropdown-search>
      <button class='btn btn-primary' (click)='addNewPatientOfferedAction(patientOfferedActions)'>
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div class='content'>
      <form [formGroup]='form'>
        <div *ngFor='let poa of poasToAdd$ | async' class='poa'>
          <button
            style='margin: auto;'
            type='button'
            mat-icon-button
            (click)='removePoa(poa?.id)'
          >
            <mat-icon>delete</mat-icon>
          </button>
          <b style='margin: auto auto auto 0;'>{{ poa.patientAction?.name }} ({{ poa.teeth | teethName }})</b>
          <mat-form-field
            style='margin: auto 0 auto 0;'
          >
            <mat-label>Ploskov</mat-label>
            <input type='text'
                   [placeholder]="'Ploskov'"
                   matInput
                   formControlName='{{poa.id}}-plane'
            >
          </mat-form-field>
          <mat-form-field
            style='margin: auto 0 auto 0;'

          >
            <mat-label>Opomba</mat-label>
            <input type='text'
                   [placeholder]="'Opomba'"
                   matInput
                   formControlName='{{poa.id}}-comment'
            >
          </mat-form-field>
        </div>
      </form>
    </div>

    <div class='actionButtons'>
      <button class='btn btn-primary' (click)='createNewPoa()'>
        Kreiraj nove storitve u terapiji
      </button>
      <div>
        <button class='back' mat-button [mat-dialog-close]='false'>Nazaj</button>
      </div>
      <div>
        <button class='edit' mat-button (click)='add()'>Spremeni</button>
      </div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>