<mat-dialog-content>
  <h2>{{ title }}</h2>

  <form id='form' [formGroup]='fg'>
    <div class='form-group'>
      <label for='nameInput'>Ime</label>
      <input type='text'
             [class]="'form-control ' + (formControls.name.touched && formControls.name.hasError('required') ? 'is-invalid' : '')"
             id='nameInput' placeholder='Ime' formControlName='name'>
      <div class='invalid-feedback text-danger' *ngIf="formControls.name.hasError('required')">
        Polje je obvezno!
      </div>
    </div>
    <div class='form-group'>
      <label for='lastNameInput'>Priimek</label>
      <input type='text'
             [class]="'form-control ' + (formControls.lastName.touched && formControls.lastName.hasError('required') ? 'is-invalid' : '')"
             id='lastNameInput' placeholder='Priimek' formControlName='lastName'>
      <div class='invalid-feedback text-danger' *ngIf="formControls.lastName.hasError('required')">
        Polje je obvezno!
      </div>
    </div>

    <ng-container *ngIf="isDoctor()">
      <div class='form-group'>
        <label for='titleBeforeNameInput'>Titula pred ime</label>
        <input type='text'
               [class]="'form-control ' + (formControls.titleBeforeName.touched && formControls.titleBeforeName.hasError('maxLength') ? 'is-invalid' : '')"
               id='titleBeforeNameInput' placeholder='Titula' formControlName='titleBeforeName'>
        <div class='invalid-feedback text-danger' *ngIf="formControls.titleBeforeName.hasError('maxLength')">
          Maksimum je 50 karaktera!
        </div>
      </div>
      <div class='form-group'>
        <label for='titleAfterNameInput'>Titula posle imena</label>
        <input type='text'
               [class]="'form-control ' + (formControls.titleAfterName.touched && formControls.titleAfterName.hasError('maxLength') ? 'is-invalid' : '')"
               id='titleAfterNameInput' placeholder='Titula' formControlName='titleAfterName'>
        <div class='invalid-feedback text-danger' *ngIf="formControls.titleAfterName.hasError('maxLength')">
          Maksimum je 50 karaktera!
        </div>
      </div>
    </ng-container>

    <div class='form-group'>
      <label for='emailInput'>Email</label>
      <input type='email'
             [class]="'form-control ' + (formControls.email.touched && !formControls.email.valid ? 'is-invalid' : '')"
             id='emailInput' placeholder='Email' formControlName='email'>
      <div class='invalid-feedback text-danger' *ngIf="formControls.email.hasError('required')">
        Polje je obvezno!
      </div>
      <div class='invalid-feedback text-danger' *ngIf="formControls.email.hasError('email')">
        Email ni veljaven!
      </div>
    </div>
    <div class='form-group'>
      <label for='passwordInput'>Geslo</label>
      <input type='text'
             [class]="'form-control ' + (formControls.password.touched && !formControls.password.valid ? 'is-invalid' : '')"
             id='passwordInput' placeholder='Geslo' formControlName='password'>
      <div class='invalid-feedback text-danger' *ngIf="formControls.password.hasError('required')">
        Polje je obvezno!
      </div>
    </div>
    <div class='form-group'>
      <label for='phoneInput'>Telefon</label>
      <input type='tel'
             [class]="'form-control ' + (formControls.phone.touched && !formControls.phone.valid ? 'is-invalid' : '')"
             id='phoneInput' placeholder='Telefon' formControlName='phone'>
    </div>
    <div class='form-group'>
      <label for='colorInput'>Barva</label>
      <input type='color'
             class='form-control'
             id='colorInput' placeholder='Barva uporabnika' formControlName='color'>
    </div>
    <div class='form-group' *ngIf='!(isDoctor() || isTechnician())'>
      <label for='taxNumber'>Davčna številka</label>
      <input type='text'
             class='form-control'
             id='taxNumber' placeholder='Davčna številka' formControlName='operatorTaxNumber'>
    </div>

    <div class='form-group' *ngIf='isDoctor()'>
      <label for='agentInput'>Cenik</label>
      <select id='agentInput'
              [class]="'form-control ' + (this.formControls.actionTypeDoctorPriceType.touched && !this.formControls.actionTypeDoctorPriceType.valid ? 'is-invalid' : '')"
              formControlName='actionTypeDoctorPriceType'>
        <option value=''>Izberi cenik...</option>
        <option *ngFor='let type of data.availableActionTypeDoctorPriceTypes | async'
                [value]='type.id'>{{ type.name }}
        </option>
      </select>
      <div class='invalid-feedback text-danger' *ngIf="this.formControls.actionTypeDoctorPriceType.hasError('required')">
        Polje je obvezno!
      </div>
    </div>

    <div *ngIf='isTechnician()'>
      <button mat-button class='btn-secondary' (click)="setTechnicianLogo()">Nastavi logo</button>
    </div>
    <div></div>
    <div *ngIf="shouldShowLogo()">
      <img referrerPolicy="no-referrer" [src]="getLogoUrl()" alt="ni slike" width='200' height='100'>
    </div>

  </form>

  <div id='actionButtons'>
    <div></div>
    <div></div>
    <div>
      <button class='btn btn-light' [mat-dialog-close]='false'>Prekliči</button>
    </div>
    <div>
      <button class='btn btn-primary' (click)='save()' [disabled]='fg.invalid'>{{ actionText }}</button>
    </div>
  </div>
</mat-dialog-content>
