import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-box',
  templateUrl: './analytics-box.component.html',
  styleUrls: ['./analytics-box.component.scss'],
})
export class AnalyticsBoxComponent implements OnInit {
  @Input() title: string;
  @Input() showHeader = true;
  @Input() isClickable = true;

  constructor() {}

  ngOnInit(): void {}
}
