<svg style='max-height: 500px' version="1.1" viewBox="0 0 3627 5335.7" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" stroke="#000" stroke-width="10">
    <path d="m624.38 2303.4c57.484 5.2531 109.32 18.931 144.23 50.378 49.842 25.76 81.641 52.762 79.011 82.132 15.953 44.768 3.5367 77.288-5.8074 111.14l-26.554 55.564c-16.796 32.339-51.507 53.866-105.8 63.573-63.917 10.595-127.84 23.303-191.63-13.06-72.63-27.093-76.086-65.528-83.063-103.39-21.436-38.835-35.788-85.598-27.286-157.94 7.5326-33.066 17.106-75.103 63.98-94.316s88.903-9.2449 152.92 5.9168z" stroke-linejoin="round"/>
    <path d="m470.92 2102c-20.159-27.624-17.522-67.834-3.9267-114.1 51.88-118.83 145.08-144.44 290.28-52.761 45.544 18.384 81.649 53.736 105.4 89.009 23.364 34.698 45.057 51.4 33.508 112.76-26.665 103.37-72.724 184.45-203.26 168.48-51.865-6.3091-97.667 7.2725-163.27-44.09-25.393-42.031-74.326-39.231-58.738-159.31z" stroke-linecap="round"/>
    <path d="m606.23 1524.9c99.024-85.947 172.03-36.305 245.04 13.318 19.715 24.465 39.002 48.929 66.005 73.412 82.689 79.467 67.135 133.4 19.25 178.92-29.845 33.111-58.429 65.595-73.479 91.357-17.249 17.76-33.108 31.385-46.578 37.906-63.743 28.623-88.08-11.297-123.86-31.31-128.56-67.758-174.6-162.14-141.52-282.06 3.6442-46.232 25.388-69.067 55.143-81.546z"/>
    <g stroke-linecap="round">
      <path d="m804.19 1228.2c-97.758 13.946-144.12 64.372-114.53 168.71 31.172 38.293 59.511 79.404 112.42 96.069 18.749-0.3888 35.212 1.496 46.457 8.5723 16.233 4.4402 34.831 4.4102 54.926 1.5523 37.843-20.098 88.727-3.3775 110.04-70.135 17.597-53.414 14.593-100.45-27.815-135.28-83.815-70.093-129.88-64.198-181.51-69.49z"/>
      <path d="m883.2 954.97c-81.594 28.415-86.958 67.621-87.351 107.53-4.1507 175.54 144.86 172.57 173.72 159.08 45.403 5.6227 76.937 0.3992 101.75-10.078 46.334-3.5547 68.651-31.252 76.056-73.939 6.7825-22.914 9.0947-48.078-2.3763-80.179-8.3857-17.929-2.6833-35.117-54.166-55.314-54.286-5.9835-71.47-19.753-93.875-34.729-32.228-34.973-72.317-27.343-113.76-12.367z"/>
      <path d="m1029.7 963.11c-54.046-18.031-79.355-51.018-85.099-94.189-6.6772-42.21 0.69596-83.38 26.185-123.21 22.289-62.026 44.406-58.591 66.544-62.757 19.747 2.4904 37.695 1.358 55.174-0.71763 52.834-2.341 72.515 21.619 85.248 37.292 44.583 59.699 39.66 94.483 50.503 137.2 5.5775 47.168 3.1173 87.5-20.976 109.43-73.307 54.828-85.768 27.963-116.45 25.557-57.13-24.062-42.436-19.737-61.132-28.608z"/>
      <path d="m1197.9 608.38c133.38-87.561 106.06-62.003 163.14-81.47 19.899-7.1119 36.417-8.1753 44.814 5.2898l30.953 31.116c21.035 26.436 19.635 32.048 22.305 41.438 9.909 81.743-1.5425 80.717-3.7507 115.51-15.253 29.612-33.673 56.041-64.838 69.658-19.232 13.568-45.866 12.236-74.158 7.5645l-46.522-11.329c-18.241 2.743-33.956-9.4224-49.948-19.958-33.749-23.691-42.472-57.316-54.075-89.797-2.3344-21.009 3.643-39.62 17.388-55.989z"/>
      <path d="m1458.4 554.75c0.6515 6.0073 35.733 67.865 44.577 95.807 11.959 30.166 31.002 57.436 81.709 71.766 41.404-5.0843 83.397-6.1716 118.25-55.725l85.685-129.95 15.626-41.339c11.283-29.849-13.152-57.295-36.026-69.06l-62.026-16.542c-36.227-2.3212-60.272-12.939-131.06 8.2776l-66.417 14.481c-24.162 10.655-56.427 6.4844-57.054 60.196 1.2698 20.53-6.7716 39.484 6.7341 62.087z"/>
    </g>
    <g>
      <g>
        <path d="m474.19 3078c-5.3481-37.443-29.281-41.025-10.022-123.3 10.033-58.703 38.808-107.19 103.8-135.94 39.344-5.601 75.203-33.653 120.69 0.316 13.258 11.587 23.135 17.764 31.838 22.497 18.081 18.823 53.225 19.936 70.45 91.564-0.0153 27.296-6.6718 37.862 5.7817 96.567 3.9476 22.622 15.434 36.807 2.3808 78.455-14.068 19.262-12.797 39.659-56.183 56.75-11.907 10.998-106.51 21.376-137.12 20.331-30.608-1.0453-29.085 9.4074-75.772-34.681-18.515-23.374-35.332-32.857-55.845-72.56z"/>
        <path d="m594.59 3190.4c-32.141 14.94-64.949 24.433-94.976 56.648-24.864 19.581-43.364 54.836-47.741 124.88-2.0872 49.247-14.023 97.237 18.57 150.91 27.983 23.59 10.841 31.618 86.881 71.779 39.173 16.411 67.293 52.594 127.5 31.368 29.127-8.2492 38.965-4.993 88.861-25.63 21.14-4.8161 38.652-16.938 48.393-44.701 10.492-17.963 19.259-42.903 25.208-79.245-2.0426-31.396-6.7746-44.313-11.049-60.374-6.8349-42.214-4.2714-59.8 0.18958-72.414 0.46203-21.263-3.7757-42.539-17.074-63.838-6.9846-23.018-11.583-44.786-52.403-69.965-37.169-27.942-61.292-48.956-172.36-19.417z"/>
        <path d="m800.36 3621.1c20.132-6.2601 42.261 5.8837 64.603 19.997l30.082 34.562c21.195 20.17 30.081 40.309 28.29 60.419-1.9262 35.053 7.4049 70.135 30.759 105.25 14.939 43.093 24.693 84.732-1.1668 116.38-15.793 28.729-34.986 56.088-73.474 75.67-40.243 16.6-75.722 39.186-124.26 45.364-31.724 2.4545-57.488 12.9-108.59-10.629l-69.705-46.734c-11.736-8.5918-22.915-13.402-41.205-66.487-5.9842-49.324-28.957-96.569-1.3314-150l44.232-101.61c20.914-26.116 34.797-54.81 79.5-72.206 26.055-8.4072 43.546-21.514 93.148-16.998z"/>
        <path d="m953.51 4065.5c84.63 42.063 83.396 64.68 85.954 96.777-6.4067 38.442-4.6963 52.743-4.4977 71.54 1.1438 39.517-23.47 49.904-38.978 70.588-49.733 49.76-76.227 33.678-111.32 41.95-32.268-4.9695-51.931-9.9061-70.651-14.84-52.622-12.773-48.023-32.546-67.975-49.316-25.579-34.325-27.828-78.186-14.319-128.49 11.078-29.924 33.561-52.303 57.09-73.989l57.835-29.159c44.4-18.359 95.267 6.8433 106.86 14.935z"/>
        <path d="m938.93 4364.6c-33.56 46.276-41.614 79.474-38.211 106.8 10.039 47.539 15.234 98.558 65.21 117.41 79.755 26.572 96.922 4.5195 129.33-5.696 37.079-18.028 66.236-40.61 87.247-67.875 16.158-11.165 14.951-22.375 15.605-33.58-2.4957-20.817 1.9828-34.871 3.5837-51.715 2.4404-32.671-11.662-55.475-19.613-81.948-23.516-19.148-40.745-45.441-79.186-47.621l-66.374-1.8979c-40.886-2.1426-68.775 33.325-97.587 66.124z"/>
        <path d="m1220.4 4525.7c57.685-8.1057 108.17-8.3717 118.01 35.654l29.084 86.283c20.91 65.764-1.0445 52.453-4.5022 73.264l-43.236 50.749c-16.523 16.726-34.326 32.551-73.364 33.429-57.235-2.1167-115.15-3.0229-127.36-85.678l-4.9196-96.565c11.869-46.142 24.861-91.627 106.29-97.136z"/>
        <path d="m1527.8 4663.6c-16.382-45.752-56.388-46.275-83.557-24.357l-38.922 49.036-45.805 43.846c-6.6605 20.522-24.578 19.257-3.692 93.094 12.56 39.939 37.821 57.786 68.794 65.698 39.721 11.407 73.73 4.0746 104.35-14.382 20.872-21.504 53.91-31.651 45.04-80.917-8.853-22.156-9.2739-27.336-28.265-69.902-5.9806-20.614-11.988-30.775-17.941-62.116z"/>
        <path d="m1627.6 4732.8c-12.991 28.541-22.196 60.127-45.899 80.053-10.634 10.656-19.789 24.29-7.9685 80.152 12.269 23.274 33.275 36.89 66.282 37.243 26.622-5.0044 49.539-12.139 91.402-8.3814 20.156-4.8702 38.527 1.6612 63.012-30.87 8.0863-15.363 20.681-25.148 0.1986-75.862l-62.712-83.785c-17.014-12.672-18.876-32.184-58.53-34.636-31.009 0.9444-36.823 19.623-45.784 36.087z"/>
      </g>
      <path d="m3002.6 2303.9c-57.484 5.2531-109.32 18.931-144.23 50.378-49.842 25.76-81.641 52.762-79.011 82.132-15.954 44.768-3.5367 77.288 5.8074 111.14l26.554 55.564c16.796 32.339 51.507 53.866 105.8 63.573 63.917 10.595 127.84 23.303 191.63-13.06 72.63-27.093 76.086-65.528 83.063-103.39 21.436-38.835 35.788-85.598 27.286-157.94-7.5327-33.066-17.106-75.103-63.98-94.316-46.874-19.213-88.903-9.2449-152.92 5.9168z" stroke-linejoin="round"/>
    </g>
    <path d="m3156.1 2102.4c20.159-27.624 17.522-67.834 3.9267-114.1-51.88-118.83-145.08-144.44-290.28-52.761-45.544 18.384-81.649 53.736-105.4 89.009-23.364 34.698-45.057 51.4-33.508 112.76 26.665 103.37 72.724 184.45 203.26 168.48 51.865-6.3091 97.667 7.2725 163.27-44.09 25.393-42.031 74.326-39.231 58.738-159.31z" stroke-linecap="round"/>
    <path d="m3020.8 1525.3c-99.024-85.947-172.03-36.305-245.04 13.318-19.716 24.465-39.002 48.929-66.005 73.412-82.689 79.467-67.135 133.4-19.25 178.92 29.845 33.111 58.429 65.595 73.479 91.357 17.249 17.76 33.108 31.385 46.578 37.906 63.743 28.623 88.08-11.297 123.86-31.31 128.56-67.758 174.6-162.14 141.52-282.06-3.6442-46.232-25.388-69.067-55.143-81.546z"/>
    <g stroke-linecap="round">
      <path d="m2822.8 1228.7c97.758 13.946 144.12 64.372 114.53 168.71-31.172 38.293-59.511 79.404-112.42 96.069-18.749-0.3888-35.212 1.4959-46.457 8.5723-16.233 4.4402-34.831 4.4102-54.926 1.5523-37.843-20.098-88.727-3.3775-110.04-70.135-17.597-53.414-14.593-100.45 27.815-135.28 83.815-70.093 129.88-64.198 181.51-69.49z"/>
      <path d="m2743.8 955.44c81.594 28.415 86.958 67.621 87.351 107.53 4.1507 175.54-144.86 172.57-173.72 159.08-45.403 5.6227-76.937 0.3992-101.75-10.078-46.334-3.5547-68.651-31.252-76.056-73.939-6.7825-22.914-9.0947-48.078 2.3763-80.179 8.3857-17.929 2.6833-35.117 54.166-55.314 54.286-5.9835 71.47-19.753 93.875-34.729 32.228-34.973 72.317-27.343 113.76-12.367z"/>
      <path d="m2597.3 963.58c54.046-18.031 79.355-51.018 85.098-94.189 6.6773-42.21-0.6959-83.38-26.185-123.21-22.289-62.026-44.406-58.591-66.544-62.757-19.747 2.4904-37.695 1.358-55.174-0.71762-52.834-2.341-72.515 21.619-85.248 37.292-44.583 59.699-39.66 94.483-50.503 137.2-5.5775 47.168-3.1173 87.5 20.976 109.43 73.307 54.828 85.768 27.963 116.45 25.557 57.13-24.062 42.436-19.737 61.132-28.608z"/>
      <path d="m2429.1 608.85c-133.38-87.561-106.06-62.003-163.14-81.47-19.899-7.1119-36.417-8.1753-44.814 5.2898l-30.953 31.116c-21.035 26.436-19.635 32.048-22.305 41.438-9.909 81.743 1.5425 80.717 3.7507 115.51 15.253 29.612 33.673 56.041 64.838 69.658 19.232 13.568 45.866 12.236 74.158 7.5645l46.522-11.329c18.241 2.743 33.956-9.4224 49.948-19.958 33.749-23.691 42.472-57.316 54.075-89.797 2.3344-21.009-3.643-39.62-17.388-55.989z"/>
      <path d="m2168.6 555.22c-0.6515 6.0073-35.733 67.865-44.577 95.807-11.959 30.166-31.002 57.436-81.709 71.766-41.404-5.0843-83.397-6.1716-118.25-55.725l-85.685-129.95-15.626-41.339c-11.283-29.849 13.152-57.295 36.026-69.06l62.026-16.542c36.227-2.3212 60.272-12.939 131.06 8.2776l66.417 14.481c24.162 10.655 56.427 6.4844 57.054 60.196-1.2698 20.53 6.7716 39.484-6.7341 62.087z"/>
    </g>
    <g>
      <path d="m3152.8 3078.5c5.3481-37.443 29.281-41.025 10.022-123.3-10.033-58.703-38.808-107.19-103.8-135.94-39.344-5.601-75.203-33.653-120.69 0.316-13.258 11.587-23.135 17.764-31.838 22.497-18.081 18.823-53.225 19.936-70.45 91.564 0.015 27.296 6.6718 37.862-5.7816 96.567-3.9477 22.622-15.434 36.807-2.3809 78.455 14.068 19.262 12.797 39.659 56.183 56.75 11.907 10.998 106.51 21.376 137.12 20.331 30.608-1.0453 29.085 9.4074 75.772-34.681 18.515-23.374 35.332-32.857 55.845-72.56z"/>
      <path d="m3032.4 3190.9c32.141 14.94 64.949 24.433 94.976 56.648 24.864 19.581 43.364 54.836 47.741 124.88 2.0873 49.247 14.022 97.237-18.57 150.91-27.983 23.59-10.841 31.618-86.881 71.78-39.173 16.411-67.293 52.594-127.5 31.368-29.127-8.2492-38.964-4.993-88.86-25.63-21.14-4.8161-38.652-16.938-48.393-44.701-10.492-17.963-19.259-42.903-25.208-79.245 2.0426-31.396 6.7745-44.313 11.049-60.374 6.835-42.214 4.2715-59.8-0.1895-72.414-0.4621-21.263 3.7756-42.539 17.074-63.838 6.9846-23.018 11.583-44.785 52.403-69.965 37.169-27.942 61.292-48.956 172.36-19.417z"/>
      <path d="m2826.6 3621.6c-20.132-6.2601-42.261 5.8837-64.603 19.997l-30.082 34.562c-21.195 20.17-30.081 40.309-28.29 60.419 1.9262 35.053-7.4049 70.135-30.759 105.25-14.939 43.093-24.693 84.732 1.1667 116.38 15.792 28.729 34.986 56.088 73.474 75.67 40.243 16.6 75.722 39.186 124.26 45.364 31.724 2.4545 57.488 12.9 108.59-10.629l69.706-46.734c11.736-8.5918 22.914-13.402 41.205-66.487 5.9841-49.324 28.956-96.569 1.3314-150l-44.232-101.61c-20.914-26.117-34.797-54.81-79.5-72.206-26.055-8.4072-43.546-21.514-93.148-16.998z"/>
      <path d="m2673.5 4066c-84.63 42.063-83.396 64.68-85.954 96.777 6.4067 38.442 4.6963 52.743 4.4977 71.54-1.1438 39.517 23.47 49.904 38.978 70.588 49.733 49.76 76.227 33.678 111.32 41.95 32.268-4.9696 51.931-9.9061 70.651-14.84 52.622-12.773 48.024-32.546 67.974-49.316 25.579-34.325 27.828-78.186 14.319-128.49-11.078-29.924-33.561-52.303-57.09-73.989l-57.835-29.159c-44.4-18.359-95.267 6.8433-106.86 14.935z"/>
      <path d="m2688.1 4365.1c33.56 46.276 41.614 79.474 38.211 106.8-10.039 47.539-15.234 98.558-65.21 117.41-79.755 26.572-96.922 4.5196-129.33-5.6959-37.079-18.028-66.236-40.61-87.247-67.875-16.158-11.165-14.951-22.375-15.605-33.58 2.4957-20.817-1.9828-34.871-3.5837-51.715-2.4404-32.671 11.662-55.475 19.613-81.948 23.516-19.148 40.745-45.441 79.186-47.621l66.374-1.8979c40.886-2.1426 68.776 33.325 97.587 66.124z"/>
      <path d="m2406.6 4526.2c-57.685-8.1056-108.17-8.3716-118.01 35.654l-29.085 86.283c-20.91 65.764 1.0445 52.453 4.5022 73.264l43.236 50.749c16.523 16.726 34.326 32.551 73.364 33.429 57.235-2.1167 115.15-3.0229 127.36-85.679l4.9196-96.565c-11.869-46.142-24.861-91.627-106.29-97.136z"/>
      <path d="m2099.2 4664c16.382-45.752 56.388-46.275 83.557-24.357l38.922 49.036 45.805 43.846c6.6605 20.522 24.578 19.257 3.692 93.094-12.56 39.939-37.821 57.786-68.794 65.698-39.721 11.407-73.73 4.0746-104.35-14.382-20.872-21.504-53.91-31.651-45.04-80.917 8.853-22.156 9.2739-27.336 28.265-69.902 5.9805-20.614 11.988-30.775 17.941-62.116z"/>
      <path d="m1999.4 4733.3c12.991 28.541 22.196 60.127 45.899 80.053 10.634 10.656 19.789 24.29 7.9685 80.152-12.269 23.274-33.275 36.89-66.282 37.243-26.622-5.0044-49.539-12.139-91.402-8.3814-20.156-4.8702-38.527 1.6612-63.012-30.87-8.0863-15.363-20.681-25.148-0.1986-75.862l62.712-83.785c17.014-12.672 18.876-32.184 58.53-34.636 31.009 0.9444 36.823 19.623 45.784 36.087z"/>
    </g>
  </g>
  <text x="91.666664" y="2602.3799" fill="#000000" font-family="sans-serif" letter-spacing="0px" word-spacing="0px" style="line-height:0%" xml:space="preserve"><tspan x="91.666664" y="2602.3799" font-family="sans-serif" font-size="400px" style="line-height:1.25"/></text>
  <g>
    <g transform="translate(1497.7 2594.8)">
      <path d="m249.81 2585.7h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5857 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7779 23.031-12.777 32.227-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
    </g>
    <g transform="translate(1139.5 2995.9)">
      <path d="m334.19 2097.9v21.118h-118.29c-0.16277-5.2897 0.69172-10.376 2.5635-15.259 3.0111-8.0566 7.8328-15.991 14.465-23.804 6.6324-7.8124 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7076-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9722-10.579 2e-4 -19.043 3.174-25.391 9.5215-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3194c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.771 14.282 10.01 9.5216 15.015 21.322 15.015 35.4-1.3e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.851-6.7954 7.6498-18.087 18.148-33.875 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7435 3.947-6.836 7.9143-9.2773 11.902z"/>
    </g>
    <g transform="translate(2470.8 3051.1)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(44.893 3410.7)">
      <path d="m502.04 972.17 23.071-1.9531c1.709 11.23 5.6762 19.674 11.902 25.33 6.2255 5.6559 13.733 8.4839 22.522 8.4838 10.579 1e-4 19.531-3.9876 26.855-11.963 7.3241-7.9752 10.986-18.555 10.986-31.738-1.1e-4 -12.532-3.5198-22.42-10.559-29.663-7.0395-7.2428-16.256-10.864-27.649-10.864-7.0801 1e-4 -13.468 1.6074-19.165 4.8218-5.6967 3.2146-10.173 7.3853-13.428 12.512l-20.63-2.6855 17.334-91.919h88.989v20.996h-71.411l-9.6436 48.096c10.742-7.4869 22.013-11.23 33.813-11.23 15.625 1.2e-4 28.808 5.4119 39.551 16.235 10.742 10.824 16.113 24.74 16.113 41.748-1.3e-4 16.195-4.7202 30.192-14.16 41.992-11.475 14.486-27.14 21.728-46.997 21.728-16.276 0-29.561-4.5572-39.856-13.672-10.295-9.1146-16.174-21.2-17.639-36.255z"/>
    </g>
    <g transform="translate(-297.18 3287)">
      <path d="m774.39 567.25-21.851 1.709c-1.9532-8.6262-4.7202-14.892-8.3008-18.799-5.9408-6.2661-13.265-9.3993-21.973-9.3994-6.9988 1.6e-4 -13.143 1.9533-18.433 5.8594-6.9174 5.0457-12.37 12.411-16.357 22.095-3.9877 9.6844-6.0629 23.478-6.2256 41.382 5.2897-8.0565 11.759-14.038 19.409-17.944 7.6497-3.9061 15.666-5.8593 24.048-5.8594 14.648 1.1e-4 27.12 5.3916 37.415 16.174 10.294 10.783 15.442 24.719 15.442 41.809-1.3e-4 11.231-2.4212 21.668-7.2632 31.311-4.8422 9.6436-11.495 17.029-19.958 22.156-8.4636 5.127-18.066 7.6904-28.809 7.6904-18.311 0-33.244-6.7342-44.8-20.203-11.556-13.468-17.334-35.665-17.334-66.589-1e-5 -34.586 6.3883-59.733 19.165-75.439 11.149-13.672 26.164-20.508 45.044-20.508 14.079 1.8e-4 25.614 3.9471 34.607 11.841 8.9924 7.894 14.384 18.799 16.174 32.715zm-89.722 77.148c-4e-5 7.5684 1.6072 14.811 4.8218 21.729 3.2145 6.9174 7.7107 12.187 13.489 15.808 5.7779 3.6214 11.841 5.4321 18.188 5.4321 9.2773 1e-5 17.253-3.7435 23.926-11.23 6.6731-7.487 10.01-17.659 10.01-30.518-1.1e-4 -12.37-3.296-22.115-9.8877-29.236-6.5919-7.1207-14.893-10.681-24.902-10.681-9.9284 1e-4 -18.351 3.5605-25.269 10.681-6.9174 7.1208-10.376 16.459-10.376 28.015z"/>
    </g>
    <g transform="translate(-627.87 3104.3)">
      <path d="m895.17 296.86v-21.118h115.84v17.09c-11.393 12.126-22.685 28.239-33.875 48.34-11.19 20.101-19.836 40.772-25.94 62.012-4.3946 14.974-7.2022 31.372-8.4228 49.194h-22.583c0.2441-14.079 3.011-31.087 8.3008-51.025 5.2897-19.938 12.878-39.164 22.766-57.678 9.8876-18.514 20.406-34.119 31.555-46.814z"/>
    </g>
    <g transform="translate(-1093.8 2870)">
      <path d="m1394.2 113.67c-9.1147-3.3365-15.869-8.0972-20.264-14.282-4.3946-6.1848-6.5918-13.59-6.5918-22.217 0-13.021 4.6793-23.966 14.038-32.837 9.3587-8.8703 21.81-13.305 37.354-13.306 15.625 1.8e-4 28.198 4.5371 37.72 13.611 9.5214 9.074 14.282 20.121 14.282 33.142-1e-4 8.3009-2.177 15.523-6.5307 21.667-4.354 6.1443-10.966 10.885-19.836 14.221 10.986 3.5808 19.348 9.3588 25.085 17.334 5.7372 7.9753 8.6058 17.497 8.606 28.564-2e-4 15.3-5.4119 28.158-16.235 38.574-10.824 10.417-25.065 15.625-42.725 15.625-17.66-1e-5 -31.901-5.2287-42.725-15.686-10.824-10.457-16.235-23.498-16.235-39.124 0-11.637 2.9501-21.383 8.8501-29.236 5.9001-7.8531 14.303-13.204 25.208-16.052zm-4.3946-37.231c0 8.4637 2.7262 15.381 8.1787 20.752 5.4525 5.3712 12.532 8.0567 21.24 8.0566 8.4634 1e-4 15.401-2.6651 20.813-7.9956 5.4116-5.3303 8.1175-11.861 8.1176-19.592-1e-4 -8.0565-2.7873-14.831-8.3618-20.325-5.5746-5.493-12.512-8.2396-20.813-8.2397-8.3822 1.62e-4 -15.34 2.6857-20.874 8.0566-5.5339 5.3712-8.3008 11.8-8.3008 19.287zm-7.0801 82.642c0 6.2663 1.4852 12.329 4.4556 18.188 2.9703 5.8594 7.3852 10.396 13.245 13.611 5.8594 3.2145 12.166 4.8218 18.921 4.8218 10.498 2e-5 19.165-3.3773 26.001-10.132 6.8358-6.7545 10.254-15.34 10.254-25.757-1e-4 -10.579-3.5198-19.328-10.559-26.245-7.0394-6.9172-15.849-10.376-26.428-10.376-10.335 9e-5 -18.901 3.4181-25.696 10.254-6.7953 6.836-10.193 15.381-10.193 25.635z"/>
    </g>
    <g transform="translate(-1295.9 -173.43)">
      <path d="m2843.1 510.71h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7778 23.03-12.777 32.226-20.996 9.1959-8.2192 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m2982.3 510.71h-21.973v-140.01c-5.2897 5.0457-12.227 10.091-20.813 15.137-8.5857 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7778 23.031-12.777 32.227-20.996 9.1959-8.2192 15.706-16.194 19.531-23.926h14.16z"/>
    </g>
    <g transform="translate(-2013.1 -362.33)">
      <path d="m3118.1 860.71h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7778 23.03-12.777 32.226-20.996 9.1959-8.2192 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3290 839.6v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804 6.6325-7.8124 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9722-10.58 1.6e-4 -19.043 3.174-25.391 9.5215-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 1.8e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-1e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.851-6.7954 7.6498-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7435 3.947-6.8359 7.9142-9.2773 11.902z"/>
    </g>
    <g transform="translate(-2422.9 -555.61)">
      <path d="m3193.1 1285.7h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7779 23.03-12.777 32.226-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3249.7 1238.5 21.973-2.9297c2.5227 12.451 6.8155 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2397 22.156 8.2397 10.335 0 19.063-3.5807 26.184-10.742 7.1207-7.1614 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.551-14.974-9.8265-25.146-9.8266-4.1505 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1628 2.6448 0.2442 3.54 0.2441 9.3587 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-1e-4 -8.0565-2.7263-14.73-8.1787-20.02-5.4525-5.2896-12.492-7.9345-21.118-7.9346-8.545 1e-4 -15.666 2.6857-21.362 8.0566-5.6967 5.3713-9.3588 13.428-10.986 24.17l-21.973-3.9062c2.6855-14.73 8.789-26.143 18.31-34.241 9.5215-8.0972 21.362-12.146 35.522-12.146 9.7655 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1912 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5307 23.071-1e-4 7.7312-2.0753 14.771-6.2255 21.118-4.1505 6.3478-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8593 7.6905 8.789 17.314 8.7891 28.87-1e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6794-39.124-14.038s-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(-2731.3 -543.39)">
      <path d="m3326.5 1585.7h-21.973v-140.01c-5.2897 5.0457-12.227 10.091-20.813 15.137-8.5857 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7779 23.031-12.777 32.227-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3453.3 1585.7v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(-3024.6 -708.21)">
      <path d="m3459.8 2077.4h-21.973v-140.01c-5.2897 5.0457-12.227 10.091-20.813 15.137-8.5857 5.0456-16.296 8.8298-23.132 11.352v-21.24c12.288-5.7779 23.031-12.777 32.227-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3516.2 2030.5 23.071-1.9531c1.7089 11.23 5.6762 19.674 11.902 25.33 6.2256 5.656 13.733 8.4839 22.522 8.4839 10.579 0 19.531-3.9876 26.856-11.963 7.3241-7.9752 10.986-18.555 10.986-31.738-1e-4 -12.532-3.5198-22.42-10.559-29.663-7.0395-7.2428-16.256-10.864-27.649-10.864-7.0802 1e-4 -13.468 1.6074-19.165 4.8218-5.6966 3.2146-10.172 7.3853-13.428 12.512l-20.63-2.6855 17.334-91.919h88.989v20.996h-71.411l-9.6435 48.096c10.742-7.4869 22.013-11.23 33.814-11.23 15.625 2e-4 28.808 5.4119 39.551 16.235 10.742 10.824 16.113 24.74 16.113 41.748-1e-4 16.195-4.7202 30.192-14.16 41.992-11.475 14.486-27.14 21.728-46.997 21.728-16.276 0-29.561-4.5572-39.856-13.672s-16.174-21.2-17.639-36.255z"/>
    </g>
    <g transform="translate(-3188 -711.82)">
      <path d="m3493.1 2452.4h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0456-16.296 8.8298-23.132 11.352v-21.24c12.288-5.7779 23.03-12.777 32.226-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3663.5 2317.2-21.851 1.709c-1.9532-8.6261-4.7201-14.892-8.3008-18.799-5.9408-6.2661-13.265-9.3992-21.973-9.3994-6.9988 2e-4 -13.143 1.9533-18.433 5.8594-6.9174 5.0457-12.37 12.411-16.358 22.095-3.9876 9.6844-6.0628 23.478-6.2255 41.382 5.2896-8.0565 11.759-14.038 19.409-17.944 7.6497-3.9061 15.666-5.8593 24.048-5.8594 14.648 1e-4 27.12 5.3916 37.414 16.174 10.294 10.783 15.442 24.719 15.442 41.809-1e-4 11.23-2.4212 21.668-7.2632 31.311-4.8422 9.6436-11.495 17.029-19.958 22.156-8.4637 5.127-18.066 7.6904-28.809 7.6904-18.311 0-33.244-6.7342-44.8-20.203-11.556-13.468-17.334-35.665-17.334-66.589 0-34.586 6.3883-59.733 19.165-75.439 11.149-13.672 26.164-20.508 45.044-20.508 14.079 2e-4 25.614 3.9471 34.607 11.841 8.9924 7.894 14.384 18.799 16.174 32.715zm-89.722 77.148c0 7.5684 1.6072 14.811 4.8218 21.728 3.2145 6.9173 7.7107 12.187 13.489 15.808 5.7779 3.6214 11.841 5.4321 18.188 5.4321 9.2773 0 17.253-3.7434 23.926-11.23 6.6731-7.487 10.01-17.66 10.01-30.518-1e-4 -12.37-3.296-22.115-9.8877-29.236-6.5919-7.1206-14.893-10.681-24.902-10.681-9.9285 1e-4 -18.351 3.5605-25.269 10.681-6.9174 7.1209-10.376 16.459-10.376 28.015z"/>
    </g>
    <g transform="translate(-3158.5 -853.08)">
      <path d="m3393.1 3035.7h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7779 23.03-12.777 32.226-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3451 2880.2v-21.118h115.84v17.09c-11.393 12.126-22.685 28.239-33.874 48.34-11.19 20.101-19.836 40.772-25.94 62.012-4.3946 14.974-7.2023 31.372-8.4229 49.194h-22.583c0.2441-14.079 3.011-31.087 8.3008-51.025 5.2896-19.938 12.878-39.164 22.766-57.678 9.8876-18.514 20.406-34.118 31.555-46.814z"/>
    </g>
    <g transform="translate(-3267.7 -940.46)">
      <path d="m3443.1 3510.7h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0457-16.296 8.8299-23.132 11.353v-21.24c12.288-5.7779 23.03-12.777 32.226-20.996 9.1959-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
      <path d="m3533.3 3413.7c-9.1146-3.3365-15.869-8.0972-20.264-14.282-4.3945-6.1848-6.5918-13.59-6.5918-22.217 0-13.021 4.6794-23.966 14.038-32.837 9.3587-8.8702 21.81-13.305 37.354-13.306 15.625 2e-4 28.198 4.5371 37.72 13.611 9.5213 9.0741 14.282 20.121 14.282 33.142-1e-4 8.3009-2.177 15.523-6.5308 21.668-4.3539 6.1443-10.966 10.885-19.836 14.221 10.986 3.5808 19.348 9.3588 25.086 17.334 5.7371 7.9753 8.6058 17.497 8.6059 28.564-1e-4 15.3-5.4119 28.158-16.235 38.574-10.824 10.417-25.065 15.625-42.725 15.625-17.66 0-31.901-5.2286-42.725-15.686-10.824-10.457-16.235-23.498-16.235-39.124 0-11.637 2.95-21.383 8.8501-29.236 5.9-7.8531 14.302-13.204 25.208-16.052zm-4.3945-37.232c-1e-4 8.4637 2.7262 15.381 8.1787 20.752 5.4524 5.3712 12.532 8.0567 21.24 8.0566 8.4635 1e-4 15.401-2.6651 20.813-7.9956 5.4117-5.3303 8.1176-11.861 8.1177-19.592-1e-4 -8.0565-2.7874-14.831-8.3618-20.325-5.5747-5.493-12.512-8.2396-20.813-8.2397-8.3822 1e-4 -15.34 2.6857-20.874 8.0566-5.5339 5.3713-8.3009 11.8-8.3008 19.287zm-7.0801 82.642c0 6.2664 1.4852 12.329 4.4556 18.188 2.9703 5.8594 7.3852 10.396 13.245 13.611 5.8593 3.2145 12.166 4.8217 18.921 4.8217 10.498 0 19.165-3.3772 26.001-10.132 6.8358-6.7545 10.254-15.34 10.254-25.757-1e-4 -10.579-3.5198-19.328-10.559-26.245-7.0395-6.9172-15.849-10.376-26.428-10.376-10.335 1e-4 -18.901 3.418-25.696 10.254-6.7953 6.836-10.193 15.381-10.193 25.635z"/>
    </g>
    <g transform="translate(-1020.3 -4415.1)">
      <path d="m3034.2 4731.3v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804s16.215-16.846 28.748-27.1c19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.58 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-1e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.85-6.7954 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522s-6.8359 7.9142-9.2773 11.902z"/>
      <path d="m3140.6 4752.4h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5856 5.0456-16.296 8.8298-23.132 11.352v-21.24c12.288-5.7779 23.03-12.777 32.227-20.996 9.1958-8.2193 15.706-16.194 19.531-23.926h14.16z"/>
    </g>
    <g transform="translate(-300.78 -4595.7)">
      <path d="m2709.2 5072.9v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804s16.215-16.846 28.748-27.1c19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.58 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-1e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.85-6.7954 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522s-6.8359 7.9142-9.2773 11.902z"/>
      <path d="m2848.3 5072.9v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5634-15.259 3.0111-8.0566 7.8329-15.991 14.465-23.804 6.6324-7.8125 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8359-9.3179 10.254-18.127 10.254-26.428-2e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.579 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6435 26.367l-22.583-2.3193c1.5462-16.846 7.3648-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-2e-4 7.1616-1.465 14.201-4.3946 21.118-2.9298 6.9174-7.7922 14.201-14.587 21.85-6.7953 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7435 3.9469-6.836 7.9142-9.2774 11.902z"/>
    </g>
    <g transform="translate(517.89 -4405.6)">
      <path d="m2292.5 5114.6v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804s16.215-16.846 28.748-27.1c19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.58 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-1e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.85-6.7954 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522s-6.8359 7.9142-9.2773 11.902z"/>
      <path d="m2316.3 5088.5 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916s13.448 8.2397 22.156 8.2397c10.335 0 19.063-3.5807 26.184-10.742 7.1206-7.1614 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.551-14.974-9.8265-25.146-9.8266-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1628 2.6448 0.2442 3.5401 0.2441 9.3586 1e-4 17.782-2.4413 25.268-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-1e-4 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9345-21.118-7.9346-8.545 1e-4 -15.666 2.6857-21.362 8.0566-5.6967 5.3713-9.3588 13.428-10.986 24.17l-21.973-3.9062c2.6855-14.73 8.7891-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1912 14.506 9.9082 18.86 17.151 4.3538 7.243 6.5307 14.933 6.5308 23.071-1e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3478-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-2e-4 15.625-5.6968 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6794-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(1004.7 -4110.1)">
      <path d="m1984.2 5131.3v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804s16.215-16.846 28.748-27.1c19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.58 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-1e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.85-6.7954 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522s-6.8359 7.9142-9.2773 11.902z"/>
      <path d="m2078.3 5152.4v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(1443.6 -3841.5)">
      <path d="m1700.9 5189.6v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5634-15.259 3.0111-8.0566 7.8329-15.991 14.465-23.804 6.6324-7.8125 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8359-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1128-16.052-9.3383-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.579 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6435 26.367l-22.583-2.3193c1.5462-16.846 7.3648-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-2e-4 7.1616-1.465 14.201-4.3946 21.118-2.9298 6.9174-7.7922 14.201-14.587 21.85-6.7953 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7436 3.9469-6.836 7.9142-9.2774 11.902z"/>
      <path d="m1724.5 5163.8 23.071-1.9531c1.709 11.23 5.6763 19.674 11.902 25.33 6.2255 5.656 13.733 8.4839 22.522 8.4839 10.579 0 19.531-3.9876 26.855-11.963 7.3241-7.9752 10.986-18.555 10.986-31.738-1e-4 -12.532-3.5197-22.42-10.559-29.663-7.0395-7.2428-16.256-10.864-27.649-10.864-7.0801 1e-4 -13.468 1.6074-19.165 4.8218-5.6967 3.2146-10.173 7.3853-13.428 12.512l-20.63-2.6855 17.334-91.919h88.989v20.996h-71.411l-9.6436 48.096c10.742-7.4869 22.013-11.23 33.814-11.23 15.625 2e-4 28.808 5.4119 39.551 16.235 10.742 10.824 16.113 24.74 16.113 41.748-2e-4 16.195-4.7202 30.192-14.16 41.992-11.475 14.486-27.14 21.728-46.997 21.728-16.276 0-29.561-4.5573-39.856-13.672-10.295-9.1146-16.174-21.2-17.639-36.255z"/>
    </g>
    <g transform="translate(1883.8 -3386.8)">
      <path d="m1384.2 5106.3v21.118h-118.29c-0.1628-5.2897 0.6917-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804s16.215-16.846 28.748-27.1c19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.58 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.772 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-1e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.85-6.7954 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522s-6.8359 7.9142-9.2773 11.902z"/>
      <path d="m1521.9 4992.2-21.851 1.709c-1.9533-8.6261-4.7202-14.892-8.3008-18.799-5.9409-6.2661-13.265-9.3992-21.973-9.3994-6.9987 2e-4 -13.143 1.9533-18.433 5.8594-6.9173 5.0457-12.37 12.411-16.357 22.095-3.9877 9.6844-6.0629 23.478-6.2256 41.382 5.2897-8.0565 11.759-14.038 19.409-17.944 7.6497-3.9061 15.666-5.8593 24.048-5.8594 14.648 1e-4 27.12 5.3916 37.415 16.174 10.294 10.783 15.442 24.719 15.442 41.809-1e-4 11.23-2.4212 21.668-7.2632 31.311-4.8422 9.6436-11.495 17.029-19.958 22.156-8.4636 5.127-18.066 7.6904-28.809 7.6904-18.311 0-33.244-6.7342-44.8-20.203s-17.334-35.665-17.334-66.589c0-34.586 6.3884-59.733 19.165-75.439 11.149-13.672 26.164-20.508 45.044-20.508 14.079 2e-4 25.614 3.9471 34.607 11.841 8.9924 7.894 14.384 18.799 16.174 32.715zm-89.722 77.148c-1e-4 7.5684 1.6072 14.811 4.8218 21.728 3.2144 6.9173 7.7107 12.187 13.489 15.808s11.841 5.4321 18.188 5.4321c9.2773 0 17.252-3.7434 23.926-11.23 6.6731-7.487 10.01-17.66 10.01-30.518-1e-4 -12.37-3.296-22.115-9.8876-29.236-6.5919-7.1206-14.893-10.681-24.902-10.681-9.9284 1e-4 -18.351 3.5605-25.268 10.681-6.9174 7.1209-10.376 16.459-10.376 28.015z"/>
    </g>
    <g transform="translate(2322.7 -2786.4)">
      <path d="m1025.9 4947.9v21.118h-118.29c-0.16277-5.2897 0.69172-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804 6.6324-7.8125 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7075-3.1128-16.052-9.3383-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.579 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.771 14.282 10.01 9.5216 15.014 21.322 15.015 35.4-2e-4 7.1616-1.465 14.201-4.3946 21.118-2.9298 6.9174-7.7922 14.201-14.587 21.85-6.7953 7.6499-18.087 18.148-33.874 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7435 3.9469-6.836 7.9142-9.2773 11.902z"/>
      <path d="m1051 4813.5v-21.118h115.84v17.09c-11.393 12.126-22.685 28.239-33.874 48.34-11.19 20.101-19.836 40.772-25.94 62.012-4.3946 14.974-7.2023 31.372-8.4229 49.194h-22.583c0.2441-14.079 3.011-31.087 8.3008-51.025 5.2896-19.938 12.878-39.164 22.766-57.678 9.8876-18.514 20.406-34.118 31.555-46.814z"/>
    </g>
    <g transform="translate(2636.6 -2115.5)">
      <path d="m759.19 4664.6v21.118h-118.29c-0.16277-5.2897 0.69173-10.376 2.5635-15.259 3.011-8.0566 7.8328-15.991 14.465-23.804 6.6325-7.8125 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1.1e-4 -8.7075-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9721-10.579 1e-4 -19.043 3.1739-25.391 9.5214-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3193c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.771 14.282 10.01 9.5216 15.015 21.322 15.015 35.4-1.2e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.85-6.7954 7.6499-18.087 18.148-33.875 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7435 3.9469-6.836 7.9142-9.2773 11.902z"/>
      <path d="m816.68 4588.7c-9.1146-3.3365-15.869-8.0972-20.264-14.282-4.3946-6.1848-6.5918-13.59-6.5918-22.217-1e-5 -13.021 4.6793-23.966 14.038-32.837 9.3587-8.8702 21.81-13.305 37.354-13.306 15.625 2e-4 28.198 4.5371 37.72 13.611 9.5214 9.0741 14.282 20.121 14.282 33.142-1.2e-4 8.3009-2.177 15.523-6.5308 21.668-4.354 6.1443-10.966 10.885-19.836 14.221 10.986 3.5808 19.348 9.3588 25.085 17.334 5.7372 7.9753 8.6058 17.497 8.606 28.564-1.3e-4 15.3-5.4119 28.158-16.235 38.574-10.824 10.417-25.065 15.625-42.725 15.625-17.66 0-31.901-5.2286-42.725-15.686-10.824-10.457-16.235-23.498-16.235-39.124-1e-5 -11.637 2.95-21.383 8.8501-29.236 5.9-7.8531 14.303-13.204 25.208-16.052zm-4.3945-37.232c-4e-5 8.4637 2.7262 15.381 8.1787 20.752 5.4524 5.3712 12.532 8.0567 21.24 8.0566 8.4635 1e-4 15.401-2.6651 20.813-7.9956 5.4117-5.3303 8.1176-11.861 8.1177-19.592-1e-4 -8.0565-2.7874-14.831-8.3618-20.325-5.5746-5.493-12.512-8.2396-20.813-8.2397-8.3822 1e-4 -15.34 2.6857-20.874 8.0566-5.5339 5.3713-8.3008 11.8-8.3008 19.287zm-7.0801 82.642c-3e-5 6.2664 1.4852 12.329 4.4556 18.188 2.9703 5.8594 7.3852 10.396 13.245 13.611 5.8593 3.2145 12.166 4.8217 18.921 4.8217 10.498 0 19.165-3.3772 26.001-10.132 6.8358-6.7545 10.254-15.34 10.254-25.757-1.1e-4 -10.579-3.5198-19.328-10.559-26.245-7.0395-6.9172-15.849-10.376-26.428-10.376-10.335 1e-4 -18.901 3.418-25.696 10.254-6.7953 6.836-10.193 15.381-10.193 25.635z"/>
    </g>
    <g transform="translate(1726.8 1836.4)">
      <path d="m118.83 3296.8 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5807 26.184-10.742 7.1207-7.1614 10.681-16.032 10.681-26.611-1.1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.551-14.974-9.8266-25.146-9.8267-4.1505 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2443 3.54 0.2442 9.3587 1e-4 17.782-2.4413 25.269-7.3243 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2895-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6967 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0971 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2867 8.2193 4.1912 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7313-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.3e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6794-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
      <path d="m314.63 3344h-21.973v-140.01c-5.2898 5.0457-12.227 10.091-20.813 15.137-8.5857 5.0457-16.296 8.8299-23.132 11.352v-21.24c12.288-5.7778 23.031-12.776 32.227-20.996 9.1959-8.2192 15.706-16.194 19.531-23.926h14.16z"/>
    </g>
    <g transform="translate(1975.3 2095.9)">
      <path d="m160.5 2971.8 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5807 26.184-10.742 7.1207-7.1614 10.681-16.032 10.681-26.611-1.1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.551-14.974-9.8266-25.146-9.8267-4.1505 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2443 3.54 0.2442 9.3587 1e-4 17.782-2.4413 25.269-7.3243 7.4869-4.8827 11.23-12.41 11.23-22.583-1e-4 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2895-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6967 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0971 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2867 8.2193 4.1912 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.1e-4 7.7313-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.3e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6794-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
      <path d="m415.01 2997.9v21.118h-118.29c-0.16277-5.2897 0.69172-10.376 2.5635-15.259 3.0111-8.0566 7.8328-15.991 14.465-23.804 6.6325-7.8124 16.215-16.846 28.748-27.1 19.45-15.95 32.593-28.585 39.429-37.903 6.8358-9.3179 10.254-18.127 10.254-26.428-1e-4 -8.7076-3.1129-16.052-9.3384-22.034-6.2257-5.9813-14.343-8.972-24.353-8.9722-10.579 2e-4 -19.043 3.174-25.391 9.5215-6.3477 6.3478-9.5622 15.137-9.6436 26.367l-22.583-2.3194c1.5462-16.846 7.3649-29.683 17.456-38.513 10.091-8.8296 23.641-13.244 40.649-13.245 17.171 2e-4 30.762 4.7609 40.771 14.282 10.01 9.5216 15.015 21.322 15.015 35.4-1.3e-4 7.1616-1.465 14.201-4.3945 21.118-2.9298 6.9174-7.7923 14.201-14.587 21.851-6.7953 7.6498-18.087 18.148-33.875 31.494-13.184 11.068-21.647 18.575-25.391 22.522-3.7435 3.947-6.836 7.9143-9.2774 11.902z"/>
    </g>
  </g>
  <path class="UnoptimicedTransforms" d="m0 0"/>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(18)}" (click)="toothClicked.emit(18)">
    <path d="m597.95 2671.4c-9.1385-0.6538-24.946-3.2562-34.03-5.6022-12.976-3.3513-22.582-6.95-35.355-13.244-5.8336-2.8746-13.192-6.2859-16.352-7.5807-21.418-8.7764-38.902-20.666-49.385-33.582-10.1-12.445-15.769-26.484-20.441-50.616-2.2958-11.859-3.0463-14.095-8.2226-24.499-19.23-38.652-26.391-79.393-22.663-128.96 1.5333-20.39 8.7479-46.796 17.329-63.426 12.465-24.157 32.964-39.611 62.471-47.096 12.059-3.059 20.319-3.9913 35.366-3.9913 21.025 0 38.488 2.6006 77.028 11.471 10.209 2.3497 24.13 4.9277 30.936 5.7289 56.807 6.6877 96.682 20.678 125.95 44.192 3.1599 2.5383 10.717 7.3459 16.794 10.684 31.295 17.188 52.58 35.268 60.643 51.51 2.6728 5.3844 3.325 7.833 3.8843 14.584 0.36969 4.4624 1.2961 9.7984 2.0587 11.858 6.0626 16.372 7.3024 42.536 2.9848 62.989-1.2828 6.0767-4.1444 17.474-6.3589 25.327-3.601 12.769-5.5016 17.349-17.986 43.336-7.6776 15.982-15.643 31.537-17.7 34.567-14.984 22.065-41.322 38.227-77.465 47.536-12.232 3.1506-44.999 8.8743-67.617 11.812-23.765 3.086-45.756 4.1519-61.872 2.9989z" fill="#fff" stroke-width=".88388"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m453.34 2362.5c-53.366 75.737-16.802 109.1 5.2429 149.31-5.9989 68.163-4.2056 120.57 41.955 114.19 68.055 48.493 117.69 22.464 171.79 14.534 7.76-12.313 8.2527-28.284 29.666-33.725 0 0 47.657-102.22 53.831-118.17s11.154-45.511-20.924-77.52c0 0-49.63-51.864-81.517-66.411-15.168-6.9199-33.028-4.8333-49.273-8.5798-33.305-7.6807-98.517-28.427-98.517-28.427-31.247-6.9064-50.68 9.278-52.256 54.793z"/>
      <g stroke-linecap="round">
        <path d="m672.52 2568.7c2.0362-1.3498-33.876-24.229-59.066-33.958-12.881-4.9747-20.583-20.451-23.855-33.865-6.7345-27.609 20.003-62.635 11.489-84.477-8.5145-21.842-26.413-22.824-42.176-29.688-10.553-4.595-16.817-0.7629-33.784-7.1307"/>
        <path d="m651.85 2391.2c-22.45 6.6093-50.963 12.192-52.171 22.399" stroke-linejoin="round"/>
        <g>
          <path d="m527.6 2509.2c9.0774-4.5257 20.762-9.9916 35.226-5.5416 14.464 4.4501 22.015 5.8376 26.779-6.9721"/>
          <path d="m650.16 2501c-21.143-0.867-44.258 11.821-61.938-12.838"/>
          <path d="m602.18 2373.9c5.0958 12.605 7.8121 24.728-1.1568 34.482"/>
        </g>
      </g>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(17)}" (click)="toothClicked.emit(17)">
    <path d="m694.38 2299.9c-4.4688-0.5411-20.941-1.5975-36.605-2.3474s-32.82-1.9462-38.125-2.6584c-20.278-2.7223-44.275-10.927-60.823-20.794-13.329-7.9483-25.456-17.127-27.736-20.993-1.2185-2.0656-10.68-12.504-21.026-23.196s-20.678-22.598-22.961-26.457c-5.1324-8.6751-9.6032-21.333-11.604-32.853-1.8736-10.789-1.9112-45.149-0.0645-59.037l1.3679-10.287-4.8577-9.0876c-2.6717-4.9982-5.7664-12.181-6.8771-15.963-7.115-24.223-0.86453-69.759 13.663-99.538 31.31-64.179 80.058-96.082 138.37-90.557 38.286 3.6277 85.971 21.596 127.9 48.195 3.4375 2.1805 12.217 6.8416 19.509 10.358 26.439 12.748 53.047 33.308 73.028 56.427 13.014 15.058 41.73 55.813 47.393 67.262 7.0161 14.185 8.564 22.916 7.819 44.104-0.69651 19.81-3.5533 32.397-14.542 64.073-25.719 74.139-64.666 112.76-123.74 122.69-12.557 2.1124-45.13 2.468-60.092 0.6561z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m530.48 1948.6c54.627-118.08 144.01-24.203 230.85 54.126 17.769 21.355 40.331 34.771 47.695 73.365 26.765 68.05-39.607 106.8-70.824 153.34s-63.449 69.736-119.85 51.799c-131.9-48.621-136.94-112.38-116.43-179.18-41.655-98.874-13.44-104.08-2.4703-132.4 10.344-5.7086 20.679-8.4043 31.041-21.046z"/>
      <g stroke-linecap="round">
        <path d="m740.19 2004.1c-13.265 7.2095-22.831 7.0669-32.413 6.9574-25.59-3.7984-36.343 9.8041-39.521 32.291-0.33993 14.139 3.4362 29.662-18.406 36.572-18.82 17.164-9.4315 25.007-7.1344 35.193-0.51132 15.982-1.0089 26.708-1.5006 35.207-0.43176 57.37 15.283 56.14 25.153 76.122 5.9469 6.4783 13.298 12.493 8.3917 22.557l11.242 9.8887"/>
        <path d="m653.04 1936.3c5.3748 11.751 10.6 23.502 19.626 35.263 10.022 7.4989 14.256 23.63 16.791 42.298"/>
        <path d="m552.98 1959.9c18.958 0.8084 37.95 1.5819 42.209 17.012 24.017 17.77 48.106 35.594 74.504 55.125"/>
        <path d="m517.44 2088 54.911 7.1861c20.655 8.9116 42.291 14.904 70.419 1.5928"/>
        <path d="m625.44 2260.1 14.118-12.639c-3.9094-36.139 3.2606-41.559 14.18-36.583"/>
        <path d="m642.7 2120.7 29.585-2.7395c13.122 9.051 26.253 14.436 39.385 19.822"/>
      </g>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(16)}" (click)="toothClicked.emit(16)">
    <path d="m759.38 1921.9c-11.941-3.3614-22.069-9.1616-43.943-25.166-5.9498-4.3535-18.887-12.523-28.75-18.154-33.688-19.235-55.821-35.436-77.139-56.466-34.519-34.053-54.015-70.628-60.84-114.14-1.6894-10.77-1.9996-18.513-1.4731-36.768 0.65219-22.612 1.9362-32.603 6.9237-53.875 1.354-5.7748 2.8485-13.593 3.3211-17.375 2.1739-17.391 8.8847-34.513 18.027-45.995 5.9846-7.5156 17.314-16.289 28.026-21.704 4.0035-2.0237 11.316-6.9489 16.25-10.945 32.761-26.532 66.948-39.219 100.2-37.184 32.045 1.9607 63.566 15.515 112.88 48.538 13.086 8.7632 15.179 10.759 28.75 27.419 8.0501 9.8822 27.858 31.474 44.017 47.982 29.649 30.288 40.607 43.317 50.09 59.553 10.966 18.776 16.207 40.182 13.737 56.102-3.6275 23.38-10.182 33.885-44.629 71.529-27.904 30.494-53.682 62.271-62.7 77.293-3.6228 6.0342-25.188 26.753-35.415 34.024-20.191 14.356-48.171 20.726-67.338 15.331z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m621.45 1629.1c-20.799-96.421 29.392-85.75 56.625-109.71 47.313-11.376 86.945-39.078 166.15 17.336 58.991 82.088 41.355 128.17 48.809 186.04 3.6421 55.256-12.948 88.839-35.48 102.72-5.6075 29.806-4.2834 58.33-53.742 84.367-42.09 37.53-71.672 5.2489-102.77-18.579-46.825-12.586-54.347-43.351-53.296-86.055-95.291-41.441-42.007-119.7-26.3-176.13z"/>
      <g stroke-linecap="round">
        <path d="m806.2 1539.5c-9.6886 11.374-22.62 12.934-25.47 45.004 4.3796 32.876-13.677 65.692-31.244 98.51-18.166 17.678-12.269 65.598-17.163 99.955 17.796 16.962 35.636 17.152 53.48 15.633l33.725 29.666"/>
        <path d="m639.73 1641.9c12.074 13.086 21.09 28.2 54.859 26.904 19.255-2.4641 38.507-3.7069 57.724 8.6018"/>
        <path d="m852.38 1653.7c-36.901 6.3442-91.443 3.8098-101.47 23.678"/>
        <path d="m660.45 1798.2c37.577 17.006 53.495-0.8439 73.272-12.484"/>
        <path d="m691.3 1850.4 17.038-52.068"/>
      </g>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(15)}" (click)="toothClicked.emit(15)">
    <path d="m864.06 1498.6c-5.3271-0.5634-12.358-2.237-15.625-3.719-9.8131-4.4521-17.753-6.1979-31.564-6.9405-15.657-0.8419-18.501-1.605-33.125-8.8889-23.934-11.921-44.145-29.896-71.875-63.923-8.5938-10.545-16.08-19.969-16.635-20.941-0.55566-0.9726-2.495-8.0879-4.3097-15.812-2.9027-12.355-3.3073-16.749-3.3647-36.543-0.0586-20.202 0.23367-23.457 2.8621-31.875 12.584-40.3 46.08-64.177 104.46-74.462 8.559-1.5077 12.844-1.4869 31.875 0.1553 41.69 3.5974 62.889 8.9134 90.594 22.718 27.396 13.651 64.293 40.8 79.199 58.277 7.5585 8.862 15.462 24.085 18.593 35.812 2.9942 11.215 3.0059 36.834 0.024 51.83-5.4189 27.249-14.467 48.119-25.837 59.594-11.091 11.194-21.152 15.506-53.306 22.844-10.434 2.3811-22.809 5.8653-27.5 7.7427-5.0997 2.0408-12.551 3.7992-18.529 4.3729-11.717 1.1242-13.162 1.1108-25.936-0.2403z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m828.12 1235.3c-12.39 14.373-23.91 27.897-19.286 43.381-5.3953 17.92-5.3801 23.185-6.2582 29.792-7.6607 14.232-8.234 17.893-8.506 21.104 0.25586 24.689 4.4057 30.171 8.3548 36.642 11.158 32.676 0.94176 39.567-5.7665 50.689-6.797 11.017-16.294 5.5657-19.814 36.568"/>
      <path d="m900.77 1459.4c-47.478-16.442-50.494-41.655-42.073-69.124-5.0032-44.202 12.994-69.905 25.614-99.933"/>
      <path d="m927.92 1310.2c-19.725 2.3906-39.445 3.0111-59.188 12.521"/>
      <path d="m836.45 1281.8c21.525 18.478 28.193 33.2 35.086 47.979"/>
      <path d="m805.01 1455c9.0761-9.2571 25.766-23.056 53.635-43.522"/>
      <path d="m880.07 1492c37.319-25.388 87.158-4.1283 103.66-80.763 2.9171-24.435 19.642-53.276-18.679-109.53"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(14)}" (click)="toothClicked.emit(14)">
    <path d="m909.67 1219.5c-45.641-8.6444-78.28-34.379-95.206-75.066-4.815-11.575-8.3447-25.362-10.828-42.298-2.6914-18.352-1.8929-62.558 1.336-73.962 8.9027-31.445 34.344-52.123 85.28-69.314 23.849-8.0491 34.505-10.293 49.124-10.344 20.08-0.07 33.374 4.8012 47.539 17.419 14.694 13.089 37.56 26.315 55.772 32.26 9.4155 3.0735 28.705 7.1689 41.064 8.7181 8.7126 1.0923 29.921 11.622 37.952 18.842 3.6086 3.2445 7.8594 8.4908 9.4462 11.658 4.3051 8.5941 12.724 34.519 14.509 44.677 5.2658 29.975-6.9117 81.62-23.758 100.76-10.344 11.751-25.39 19.283-44.399 22.224-4.8125 0.7447-12.406 2.661-16.875 4.2584-20.879 7.4633-45.912 10.229-73.901 8.1645-16.226-1.1967-19.178-1.1091-25.058 0.7431-9.0957 2.865-39.62 3.6051-51.997 1.2607z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m960.79 955.17c0.36675 8.6217 6.6302 17.259-14.723 25.824-36.978 29.984-34.475 52.18-26.054 73.208 17.121 32.529 11.016 47.931-6.1789 55.156-53.328 51.726-41.182 62.338-55.407 89.51"/>
      <path d="m894.7 1172.2c49.839-7.0662 89.963-26.773 101.08-84.218 5.8792-33.262-13.377-52.248-39.479-67.345"/>
      <path d="m1075.2 1037.4c-65.132 6.5321-86.084 23.247-77.701 43.762-0.17522 54.123 16.99 41.28 29.157 58.697"/>
      <path d="m967.11 1175.9c9.2429-12.045 6.148-24.122 1.8189-36.202"/>
      <path d="m1021 1021.7c-22.737 20.908-25.033 31.662-25.108 41.314"/>
      <path d="m1096 1016.7c15.967 35.621 0.074 64.802 3.2113 90.526 2.9741 35.859-7.6638 66.384-45.915 86.087-23.521 13.246-52.063 15.948-77.633 17.9"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(13)}" (click)="toothClicked.emit(13)">
    <path d="m1124.4 993.83c-32.102-7.5722-50.849-14.474-78.125-28.761-7.5625-3.9611-16.656-8.1582-20.207-9.3267-10.479-3.448-27.862-12.865-37.827-20.492-28.664-21.939-41.603-55.228-39.695-102.13 1.0828-26.618 8.4505-52.474 21.042-73.846 2.8042-4.7596 7.5786-14.534 10.61-21.721 12.337-29.252 24.115-42.104 42.533-46.414 5.2795-1.2353 40.62-2.4697 79.795-2.7872 34.205-0.2772 56.946 13.271 79.34 47.268 17.11 25.975 26.197 47.893 31.85 76.824 1.7517 8.9656 4.6098 23.595 6.3513 32.51 6.0922 31.187 6.4793 65.38 0.9699 85.666-2.7416 10.095-8.2347 20.87-13.753 26.978-8.7301 9.6632-39.153 28.275-55.304 33.834-7.8926 2.7164-21.284 3.8789-27.579 2.3941z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m996.27 901.82c2.5683-58.387 7.4764-113.78 34.851-140.43 27.087-28.376 61.938-48.948 109.63-56.61"/>
      <path d="m1163 756.56c32.326 125.75 46.34 155.43-38.134 77.487"/>
      <path d="m1087.7 877.92c71.171 86.132 23.964 73.393-52.681 36.069"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(12)}" (click)="toothClicked.emit(12)">
    <path d="m1325.6 793.12c-5.8437-0.88569-20.763-4.1455-33.154-7.2439-12.681-3.1711-23.998-5.3528-25.889-4.9912-6.6211 1.2657-19.874-4.1409-35.511-14.487-29.045-19.217-40.804-36.362-56.493-82.359-4.4115-12.934-4.7136-17.117-2.1201-29.355 1.7299-8.1632 8.4669-22.664 12.917-27.803 7.051-8.1424 43.13-32.573 87.75-59.421 27.712-16.674 38.522-21.448 61.875-27.324 11-2.7681 24.219-6.4912 29.375-8.2736 11.726-4.0533 24.556-5.367 28.868-2.9557 5.9707 3.3393 43.205 42.862 49.574 52.621 8.2719 12.675 10.056 18.293 12.222 38.479 2.8925 26.966 2.4345 52.745-1.2881 72.5-1.7187 9.1212-3.1305 18.056-3.1372 19.855-0.03 8.1367-17.577 36.422-31.152 50.218-13.254 13.47-38.996 28.411-53.694 31.166-8.8687 1.6622-26.915 1.3824-40.142-0.62234z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m1235.4 756.75c-17.903-27.841-46.563-50.338-11.796-104.34 31.892-24.886 56.85-32.567 84.607-47.192 23.893-12.029 47.604-23.695 64.714-22.244 71.249-6.84 41.916 10.182 61.166 15.677"/>
      <path d="m1384.9 631.28c6.099 31.904 11.782 64.635 29.116 74.214"/>
      <path d="m1344 763.93c2.5232-27.17-37.09-38.665-69.69-52.769"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(11)}" (click)="toothClicked.emit(11)">
    <path d="m1575 713.24c-36.195-13.947-53.669-31.788-68.67-70.113-3.2292-8.25-15.353-34.406-26.941-58.125-14.475-29.627-21.471-45.335-22.351-50.186-1.543-8.5043 0.1987-53.12 2.3919-61.272 2.9885-11.109 9.1206-19.227 17.964-23.783 9.5589-4.9244 35.1-12.288 66.138-19.067 17.307-3.7801 39.062-8.8388 48.343-11.241 33.91-8.7785 55.855-10.298 92.5-6.4069 15.492 1.6452 25.634 3.7055 50.605 10.28 34.982 9.2106 38.689 10.951 51.001 23.948 9.8986 10.449 15.001 21.505 15.023 32.551 0.014 7.2583-1.0592 11.129-8.5431 30.802l-8.5596 22.5-44.215 67.112c-24.318 36.911-47.134 70.398-50.701 74.415-10.55 11.88-19.469 18.806-32.736 25.422-14.435 7.1978-29.407 11.283-51.928 14.168-19.66 2.5187-20.232 2.4991-29.322-1.0035z" fill="#fff" stroke-width="1.25"/>
    <path d="m1522 634.23-15.185-126.76c8.2366 0.0216-9.2676-25.591 21.649-37.012 112.9-43.966 178.14-11.738 196.56-2.0716s34.4 36.102 30.867 63.874-11.264 34.442-16.506 48.233" fill="none" stroke="#000" stroke-linecap="round" stroke-width="10"/>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(21)}" (click)="toothClicked.emit(21)">
    <path d="m2024.2 714.86c-30.899-3.9558-48.245-9.4295-64.837-20.46-16.725-11.119-23.694-20.146-71.423-92.523l-44.513-67.5-8.6117-22.5c-7.9052-20.654-8.6101-23.269-8.5916-31.875 0.028-13.184 3.9241-21.127 16.543-33.726 5.3279-5.3199 11.937-10.81 14.687-12.201s19.062-6.1811 36.25-10.645c25.462-6.613 34.954-8.4937 51.25-10.153 34.759-3.5399 56.457-2.1199 86.25 5.6445 7.5625 1.9709 28.938 6.9418 47.5 11.047 32.599 7.2087 61.288 15.384 70.867 20.195 6.9174 3.474 13.126 10.568 16.138 18.439 2.2489 5.8773 2.8929 11.42 3.9389 33.901 0.8807 18.927 0.8513 29.026-0.099 34.149-0.9462 5.0991-7.8928 20.608-23.236 51.875-12.037 24.531-23.908 49.945-26.378 56.476-7.1818 18.985-17.332 35.571-27.662 45.201-6.7791 6.3198-21.995 15.941-31.407 19.86-17.132 7.1319-17.773 7.2158-36.664 4.7973z" fill="#fff" stroke-width="1.25"/>
    <path d="m2105 634.7 15.185-126.76c-8.2366 0.0216 9.2676-25.591-21.649-37.012-112.9-43.966-178.14-11.738-196.56-2.0716-18.414 9.6667-34.4 36.102-30.867 63.874 3.5328 27.772 11.264 34.442 16.506 48.233" fill="none" stroke="#000" stroke-linecap="round" stroke-width="10"/>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(22)}" (click)="toothClicked.emit(22)">
    <path d="m2257.5 793.59c-6.2181-1.4437-24.955-10.941-34.873-17.676-10.611-7.2055-22.292-19.656-31.348-33.412-13.881-21.085-13.41-19.632-20.261-62.5-2.1842-13.667-1.611-40.063 1.3843-63.75 2.1848-17.277 3.8749-22.331 11.313-33.83 6.9311-10.714 45.742-51.183 51.156-53.341 5.3966-2.1515 14.72-0.91374 30.569 4.0584 7.3201 2.2965 21.04 6.1576 30.489 8.5803s21.824 6.5815 27.5 9.2419c18.998 8.9043 93.994 55.746 109.07 68.124 14.231 11.684 21.836 26.636 22.831 44.889 0.5631 10.328 0.338 11.551-4.7027 25.542-8.2397 22.87-13.336 33.956-20.696 45.016-15.611 23.461-49.41 46.61-68.172 46.69-3.9423 0.0168-16.748 2.4299-29.375 5.5356-12.312 3.0283-26.431 6.122-31.375 6.875-10.667 1.6245-36.438 1.599-43.511-0.043z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m2391.5 757.23c17.903-27.841 46.563-50.338 11.796-104.34-31.892-24.886-56.85-32.567-84.607-47.192-23.893-12.029-47.604-23.695-64.714-22.244-71.249-6.84-41.916 10.182-61.166 15.677"/>
      <path d="m2242.1 631.75c-6.099 31.904-11.782 64.635-29.116 74.214"/>
      <path d="m2282.9 764.41c-2.5232-27.17 37.09-38.665 69.69-52.769"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(23)}" (click)="toothClicked.emit(23)">
    <path d="m2477.5 992.67c-15.289-4.3968-49.344-24.73-58.077-34.676-5.2902-6.0253-10.976-17.728-14.118-29.059-2.311-8.3343-2.6497-12.907-2.6526-35.804 0-24.528 0.2623-27.603 4.0463-46.875 2.2274-11.344 5.2026-26.531 6.6116-33.75 5.462-27.982 15.04-51.237 31.054-75.396 16.385-24.719 30.139-36.807 49.956-43.904l10.68-3.8249 44.919 0.29187c24.705 0.16053 48.049 0.86868 51.875 1.5737 20.109 3.7054 31.953 16.195 45.321 47.791 2.538 5.9987 6.8463 14.728 9.5741 19.399 16.556 28.347 24.674 64.439 21.606 96.057-1.7906 18.456-3.9985 29.011-8.3812 40.066-10.63 26.815-31.279 45.956-64.453 59.749-7.1283 2.9637-19.148 8.525-26.71 12.358-20.049 10.163-42.203 19.349-51.703 21.439-4.5635 1.004-12.478 2.9671-17.588 4.3624-11.164 3.0484-21.827 3.1158-31.96 0.20198z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m2630.7 902.29c-2.5682-58.387-7.4764-113.78-34.85-140.43-27.087-28.376-61.938-48.948-109.63-56.61"/>
      <path d="m2464 757.04c-32.326 125.75-46.34 155.43 38.134 77.487"/>
      <path d="m2539.3 878.39c-71.171 86.132-23.964 73.393 52.681 36.069"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(24)}" (click)="toothClicked.emit(24)">
    <path d="m2678.6 1221.2c-4.8956-0.6006-11.364-1.9482-14.375-2.9948-4.645-1.6146-7.6507-1.6914-19.849-0.5069-26.632 2.586-54.815-0.1653-77.5-7.5659-5.8437-1.9063-15.688-4.4376-21.875-5.6249-33.582-6.4443-49.966-25-60.26-68.248-4.0426-16.985-4.6103-21.303-4.6011-35 0.012-17.612 2.0054-27.223 10.467-50.46 5.486-15.065 8.6224-19.89 17.482-26.894 10.898-8.6142 26.22-15.15 40.466-17.262 16.151-2.3936 32.427-6.5271 45.324-11.511 12.821-4.9543 36.11-19.359 45.792-28.322 17.457-16.162 39.311-21.378 67.379-16.08 25.919 4.8919 67.888 22.336 84.96 35.313 16.442 12.498 26.296 27.303 30.717 46.147 3.147 13.414 3.1292 59.216-0.03 76.237-8.0532 43.396-25.863 72.786-56.047 92.49-23.414 15.285-60.03 23.718-88.051 20.281z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m2666.2 955.65c-0.3668 8.6217-6.6303 17.259 14.723 25.824 36.978 29.984 34.475 52.18 26.054 73.208-17.121 32.529-11.016 47.931 6.1789 55.156 53.328 51.726 41.182 62.338 55.407 89.51"/>
      <path d="m2732.3 1172.7c-49.839-7.0662-89.963-26.773-101.08-84.218-5.8792-33.262 13.377-52.248 39.479-67.345"/>
      <path d="m2551.8 1037.8c65.132 6.5321 86.084 23.247 77.701 43.762 0.1753 54.123-16.99 41.28-29.157 58.697"/>
      <path d="m2659.9 1176.4c-9.2429-12.045-6.148-24.122-1.8189-36.202"/>
      <path d="m2606 1022.2c22.738 20.908 25.033 31.662 25.108 41.314"/>
      <path d="m2531 1017.2c-15.967 35.621-0.074 64.802-3.2113 90.526-2.9741 35.859 7.6638 66.384 45.915 86.087 23.521 13.246 52.063 15.948 77.633 17.9"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(25)}" (click)="toothClicked.emit(25)">
    <path d="m2731.2 1498.7c-3.4375-0.5195-10.75-2.6189-16.25-4.6654s-18.156-5.5379-28.125-7.7587c-29.707-6.618-42.85-12.971-52.753-25.499-18.231-23.063-29.13-72.047-23.002-103.37 2.5805-13.191 10.818-30.347 19.444-40.498 7.9273-9.3284 23.805-22.653 42.84-35.95 43.616-30.47 72.152-40.391 129.54-45.04 19.902-1.6119 20.836-1.5779 35 1.2716 60.207 12.112 93.085 39.379 100.8 83.592 3.1085 17.825 2.2295 33.888-3.3013 60.331l-2.7168 12.989-14.397 17.636c-27.454 33.63-41.538 47.392-61.861 60.449-16.583 10.654-27.863 14.766-43.231 15.759-14.737 0.9526-26.731 3.4175-33.575 6.9005-8.8383 4.4977-31.969 6.3398-48.411 3.8553z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-linecap="round" stroke-width="10">
      <path d="m2798.9 1235.8c12.39 14.373 23.91 27.897 19.286 43.381 5.3953 17.92 5.38 23.185 6.2582 29.792 7.6607 14.232 8.234 17.893 8.5059 21.104-0.2558 24.689-4.4056 30.171-8.3548 36.642-11.158 32.676-0.9417 39.567 5.7666 50.689 6.797 11.017 16.294 5.5656 19.814 36.568"/>
      <path d="m2726.2 1459.9c47.478-16.442 50.494-41.655 42.073-69.124 5.0031-44.202-12.994-69.905-25.614-99.933"/>
      <path d="m2699.1 1310.7c19.725 2.3905 39.445 3.0111 59.188 12.521"/>
      <path d="m2790.5 1282.3c-21.525 18.478-28.193 33.2-35.086 47.979"/>
      <path d="m2822 1455.4c-9.0761-9.2572-25.766-23.056-53.635-43.522"/>
      <path d="m2746.9 1492.5c-37.319-25.388-87.158-4.1284-103.66-80.763-2.9171-24.435-19.642-53.276 18.679-109.53"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(26)}" (click)="toothClicked.emit(26)">
    <path d="m2840.3 1923.7c-7.6354-1.2854-21.269-5.7778-28.446-9.3731-15.396-7.7123-38.999-29.34-50.048-45.857-13.212-19.753-41.85-54.178-69.657-83.735-24.729-26.285-33.489-42.686-34.918-65.377-0.9007-14.305 2.2186-27.684 10.26-44.005 8.8819-18.027 22.116-34.526 52.425-65.361 27.973-28.458 32.673-33.628 50.001-55 10.112-12.472 14.289-15.832 39.895-32.093 57.015-36.206 95.654-45.598 137.78-33.49 19.784 5.6859 41.384 17.56 61.759 33.951 5.1563 4.148 10.613 7.9287 12.126 8.4017 5.8627 1.8321 21.347 13.143 27.654 20.201 11.062 12.378 16.97 27.114 21.262 53.029 0.74 4.4688 2.7463 14.875 4.4585 23.125 5.9248 28.549 7.1346 51.726 3.9488 75.653-5.9023 44.328-23.447 79.498-56.95 114.16-22.854 23.644-47.637 42.24-81.223 60.947-7.8914 4.3952-22.516 13.788-32.5 20.874-31.077 22.056-46.575 27.528-67.83 23.95z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m3005.5 1629.6c20.799-96.421-29.392-85.75-56.625-109.71-47.313-11.376-86.945-39.078-166.15 17.336-58.991 82.088-41.355 128.17-48.809 186.04-3.6421 55.256 12.948 88.839 35.48 102.72 5.6074 29.806 4.2833 58.33 53.742 84.367 42.09 37.53 71.672 5.2489 102.77-18.579 46.825-12.586 54.346-43.351 53.296-86.055 95.291-41.441 42.007-119.7 26.3-176.13z"/>
      <g stroke-linecap="round">
        <path d="m2820.8 1539.9c9.6886 11.374 22.62 12.934 25.47 45.004-4.3796 32.876 13.677 65.692 31.244 98.51 18.166 17.678 12.269 65.598 17.163 99.955-17.796 16.962-35.636 17.152-53.48 15.633l-33.725 29.666"/>
        <path d="m2987.3 1642.3c-12.074 13.086-21.09 28.2-54.859 26.904-19.255-2.4641-38.508-3.7069-57.724 8.6018"/>
        <path d="m2774.6 1654.1c36.901 6.3441 91.442 3.8098 101.47 23.678"/>
        <path d="m2966.5 1798.7c-37.577 17.006-53.495-0.8439-73.272-12.484"/>
        <path d="m2935.7 1850.9-17.038-52.068"/>
      </g>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(27)}" (click)="toothClicked.emit(27)">
    <path d="m2881.2 2301.1c-35.574-3.9232-69.035-20.926-90.206-45.837-21.29-25.051-37.536-58.555-50.311-103.76-10.247-36.257-9.9217-58.762 1.1864-82.102 5.2376-11.006 32.679-50.292 46.558-66.656 20.706-24.412 48.138-45.524 75.83-58.36 4.4314-2.0541 13.994-7.3164 21.25-11.694s22.018-12.318 32.805-17.646c60.337-29.801 108.82-36.364 150.06-20.314 30.565 11.896 58.628 40.609 79.138 80.976 7.3688 14.502 7.8212 15.824 12.431 36.304 7.772 34.533 5.712 64.343-5.6881 82.31l-3.9151 6.1707 1.5536 16.594c2.0618 22.023 0.9754 50.314-2.4307 63.298-4.8704 18.565-10.985 27.971-32.676 50.267-10.702 11-20.51 21.786-21.796 23.969-2.5321 4.2967-23.366 18.684-36.696 25.341-11.172 5.5792-31.376 12.109-44.589 14.411-6.1875 1.078-26.719 2.7898-45.625 3.804-18.906 1.0142-39.719 2.3926-46.25 3.0632-13.945 1.4317-26.756 1.3866-40.625-0.1429z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m3096.5 1949.1c-54.627-118.08-144.01-24.203-230.84 54.126-17.769 21.355-40.331 34.771-47.695 73.365-26.765 68.05 39.607 106.8 70.824 153.34s63.449 69.736 119.85 51.799c131.9-48.621 136.94-112.38 116.43-179.18 41.655-98.874 13.44-104.08 2.4703-132.4-10.344-5.7086-20.679-8.4043-31.041-21.046z"/>
      <g stroke-linecap="round">
        <path d="m2886.8 2004.6c13.265 7.2095 22.831 7.0668 32.413 6.9574 25.59-3.7984 36.343 9.8041 39.521 32.291 0.34 14.139-3.4362 29.662 18.406 36.572 18.82 17.164 9.4315 25.007 7.1344 35.193 0.5113 15.982 1.0089 26.708 1.5007 35.207 0.4317 57.37-15.283 56.14-25.153 76.122-5.9468 6.4783-13.298 12.493-8.3916 22.557l-11.242 9.8887"/>
        <path d="m2973.9 1936.7c-5.3748 11.751-10.6 23.502-19.626 35.263-10.022 7.4989-14.256 23.63-16.791 42.298"/>
        <path d="m3074 1960.4c-18.958 0.8084-37.95 1.5819-42.209 17.012-24.017 17.77-48.106 35.594-74.504 55.125"/>
        <path d="m3109.6 2088.5-54.911 7.1861c-20.655 8.9116-42.291 14.904-70.419 1.5928"/>
        <path d="m3001.6 2260.6-14.118-12.639c3.9094-36.139-3.2606-41.559-14.18-36.583"/>
        <path d="m2984.3 2121.2-29.585-2.7395c-13.122 9.051-26.253 14.436-39.385 19.822"/>
      </g>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(28)}" (click)="toothClicked.emit(28)">
    <path d="m2989.4 2671.2c-28.564-2.6632-77.802-10.802-94.168-15.565-31.687-9.2225-51.632-20.879-67.893-39.68-4.0101-4.6363-10.333-16.034-21.178-38.178-13.226-27.004-16.165-34.142-20.332-49.375-6.5231-23.848-8.1501-33.965-8.0903-50.307 0.038-10.381 0.8356-16.966 3.256-26.875 1.7631-7.2187 3.5706-16.348 4.0168-20.286 2.5105-22.164 22.16-41.354 70.638-68.989 4.4688-2.5474 11.95-7.605 16.624-11.239 25.435-19.774 67.376-33.933 118.38-39.963 8.5938-1.0161 25.469-4.0963 37.5-6.8448 41.534-9.4884 64.023-12.167 85.256-10.155 23.199 2.1982 47.746 11.047 61.944 22.33 22.224 17.662 37.525 54.771 40.219 97.54 2.8792 45.713-3.9664 83.448-21.964 121.07-5.0695 10.598-7.3579 17.294-9.2349 27.02-5.3058 27.494-12.05 41.675-26.82 56.39-10.602 10.564-22.812 18.497-39.4 25.602-5.1562 2.2084-15 6.7233-21.875 10.033-15.166 7.3012-32.233 12.802-48.077 15.495-14.662 2.4922-43.027 3.4445-58.798 1.974z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m3173.7 2363c53.366 75.737 16.802 109.1-5.2429 149.31 5.9989 68.163 4.2056 120.57-41.955 114.19-68.055 48.493-117.69 22.464-171.79 14.534-7.76-12.313-8.2527-28.284-29.666-33.725 0 0-47.657-102.22-53.831-118.17-6.174-15.946-11.154-45.511 20.924-77.52 0 0 49.63-51.864 81.517-66.411 15.168-6.9198 33.028-4.8332 49.274-8.5797 33.305-7.6807 98.517-28.427 98.517-28.427 31.247-6.9064 50.68 9.278 52.256 54.793z"/>
      <g stroke-linecap="round">
        <path d="m2954.5 2569.2c-2.0361-1.3498 33.876-24.229 59.066-33.958 12.881-4.9747 20.583-20.451 23.855-33.865 6.7346-27.609-20.003-62.635-11.489-84.477 8.5145-21.842 26.413-22.824 42.176-29.688 10.552-4.595 16.817-0.7629 33.784-7.1308"/>
        <path d="m2975.1 2391.7c22.45 6.6093 50.963 12.192 52.172 22.399" stroke-linejoin="round"/>
        <g>
          <path d="m3099.4 2509.6c-9.0774-4.5257-20.762-9.9916-35.226-5.5416-14.464 4.4501-22.015 5.8375-26.779-6.9721"/>
          <path d="m2976.8 2501.5c21.143-0.867 44.258 11.821 61.938-12.838"/>
          <path d="m3024.8 2374.4c-5.0957 12.605-7.8121 24.728 1.1569 34.482"/>
        </g>
      </g>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(38)}" (click)="toothClicked.emit(38)">
    <path d="m3005 3178.6c-17.393-4.1206-36.072-6.3531-61.25-7.3204-21.172-0.8134-29.801-1.6542-37.5-3.6539-19.877-5.163-45.243-18.994-53.627-29.241-2.3508-2.8732-6.6702-9.7239-9.5987-15.224s-6.3683-11.513-7.644-13.363c-3.9779-5.7678-7.424-21.764-7.4887-34.762-0.047-9.3387 0.7657-15.079 3.8026-26.875 2.1239-8.25 5.3985-22.875 7.2769-32.5 3.1888-16.34 3.4252-19.82 3.5652-52.5l0.15-35 4.1095-11.25c9.8753-27.034 20.692-40.666 45.072-56.806 6.5271-4.3209 13.558-9.2151 15.625-10.876s8.2613-5.9883 13.766-9.6168c5.5047-3.6284 13.661-9.5539 18.125-13.168 9.356-7.5741 22.692-14.311 32.24-16.288 14.348-2.9701 26.268-1.3506 58.121 7.8967 10.86 3.1527 21 5.7321 22.535 5.7321 4.2904 0 20.114 8.0306 33.97 17.24 23.827 15.837 42.253 36.89 54.381 62.135 7.3486 15.296 11.085 27.039 16.109 50.625 10.474 49.173 10.182 66.862-1.5935 96.532-2.3809 5.9988-5.0155 14.155-5.8546 18.125-2.9123 13.778-15.597 33.175-37.988 58.088-24.596 27.367-46.645 47.125-57.827 51.817-4.724 1.9823-40.392 2.1679-48.478 0.2522z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2935.8 2848.9c34.992-52.16 74.046-28.97 113.81 7.4607 22.605 27.15 29.591 55.762 26.947 85.275-0.9732 17.725-8.6827 29.706-25.774 33.688 11.279 6.3051 15.787 18.056 15.605 33.58l-15.282 89.696c-14.57 36.961-26.142 36.765-38.667 48.377-6.6838 3.3823-13.452 6.6808-22.393 7.8173-12.367-4.1705-24.73-6.7262-37.179-42.144 10.526-3.3744-33.194-20.191-1.002-53.446 10.369-11.34-24.689-48.095-4.5157-78.437 10.324-33.385 20.156-62.232 28.252-75.074-21.795-3.5448-15.29-23.36-22.506-35.286"/>
      <path d="m3001.5 2898.7-25.844 6.9642"/>
      <path d="m2899.9 2974c13.696 6.1675 25.55 16.664 45.71 7.6389"/>
      <path d="m2983.8 3084.1c-16.106-2.4766-28.159-10.658-32.835-29.224"/>
      <path d="m3013.7 2974.6c-21.387-4.1252-44.935-16.801-62.08-4.1478"/>
      <path d="m2946.1 3143.7c-13.621-0.4232-26.494 0.7307-42.252-4.1998-22.521-25.157-30.599-25.192-44.922-36.089-2.6946-0.8148-31.079-30.374-10.541-74.973 11.341-22.822 14.092-30.706 9.3586-47.438 11.134-39.018 5.0973-47.802 7.5713-71.572"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(37)}" (click)="toothClicked.emit(37)">
    <path d="m2954.4 3620.4c-20.844-8.0127-35.108-11.217-53.895-12.107-11.72-0.5553-14.859-1.237-26.875-5.8372-7.4829-2.8647-17.656-6.4935-22.607-8.064-20.147-6.3912-29.083-15.146-41.072-40.243-4.5446-9.513-9.8678-21.599-11.829-26.858-4.377-11.735-10.948-38.068-11.99-48.055-0.9484-9.0811 2.0421-30.435 6.9523-49.643 5.1136-20.004 6.8402-33.858 6.8843-55.241 0.03-14.42-0.4676-20.393-2.1534-25.864-4.0548-13.16 0.4737-38.008 10.436-57.261 2.668-5.1562 5.7636-12.469 6.8792-16.25 7.5951-25.743 18.092-39.293 44.968-58.047 28.982-20.223 33.815-23.287 42.277-26.8 16.304-6.7681 21.064-7.1767 47.119-4.0441 12.667 1.5229 32.875 3.5557 44.906 4.5172 25.991 2.0772 30.212 3.1387 53.79 13.528 38.61 17.014 49.406 23.653 71.542 43.995 20.022 18.398 26.85 27.674 34.525 46.895 9.0901 22.766 12.63 43.291 16.486 95.581 4.5944 62.304 2.1666 85.853-12.022 116.61-2.6985 5.8494-6.83 11.886-11.548 16.875-4.0405 4.272-10.577 12.115-14.526 17.429-3.949 5.3138-9.811 11.992-13.026 14.839-11.023 9.7624-34.885 24.695-57.721 36.122-5.8437 2.9241-16.25 8.5495-23.125 12.501-27.704 15.924-40.932 21.013-56.875 21.881-8.9427 0.4871-10.211 0.1894-27.5-6.457z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2961.9 3597.1c-14.527-6.9312-14.688-19.036-58.661-15.364-10.636-1.3773-21.268-1.5802-31.939-16.296l-23.314-14.594-19.873-17.19c-15.506-16.856-19.102-35.066-13.935-54.274 2.724-21.309 7.0793-47.003 3.3151-50.871-2.0472-13.212 2.0305-34.634 6.7476-56.914-2.1644-28.756-22.562-24.75 4.0349-105.18 1.7323-17.147 10.796-25.916 27.518-25.934 13.609-2.6793 17.267-8.9504 34.424-22.504 12.194-7.2158 18.5-14.416 45.633-21.671 20.697 2.6219 41.368-4.259 62.103 12.768l36.292 32.664c29.262 24.254 32.395 37.702 34.616 50.772 8.1316 19.519 4.9553 39.068 1.8776 58.616-0.6887 16.66-6.424 30.455-23.176 37.992 8.0463 9.5862 17.076 14.189 20.809 45.635 8.5892 54.608-4.6169 68.605-13.556 90.553-17.462 25.377-34.941 44.488-52.414 65.655"/>
      <path d="m2913.6 3253.3c15.72 10.717 28.044 27.411 34.625 54.22-2.8279 20.966-7.6838 37.902-12.789 54.344-4.2983 22.676-16.15 21.401-10.144 76.751 10.77 10.449 13.433 18.22 10.408 24.111-19.472 41.49-12.684 78.134 18.392 110.3"/>
      <path d="m2884.1 3523.2c9.5711-20.787 20.473-40.913 48.138-52.712 3.8175-11.768 16.805-16.442 41.348-12.177"/>
      <path d="m3013.1 3384.9c-39.142-3.2572-73.528-3.7561-85.322 8.8441"/>
      <path d="m2829.5 3408.7c30.622-8.5033 51.773-25.251 99.088-19.225"/>
      <path d="m2840.6 3370.7 42.3 22.303"/>
      <path d="m3009.4 3290.1c-28.085 5.5333-47.718 11.044-62.026 16.542"/>
      <path d="m2972.2 3239.3c-11.145 21.868-25.094 43.744-23.104 65.578"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(36)}" (click)="toothClicked.emit(36)">
    <path d="m2883.1 4075c-21.957-2.8497-37.759-6.3741-54.375-12.127-18.641-6.4545-82.849-35.952-94.013-43.191-13.318-8.6346-34.216-29.912-43.257-44.043-3.959-6.1875-10.079-15.75-13.6-21.25-8.4561-13.209-11.865-21.889-13.398-34.112-2.7815-22.183 5.8193-61.52 18.095-82.763 7.3214-12.669 14.708-28.652 18.486-40 5.497-16.511 7.3002-28.269 8.2429-53.75 0.7592-20.521 1.3231-25.444 3.5663-31.138 3.5636-9.0449 9.8257-18.264 19.369-28.516 4.337-4.659 13.693-15.123 20.792-23.254 13.514-15.479 17.936-18.948 34.627-27.16 12.575-6.1871 24.828-8.9658 32.834-7.4464 4.3749 0.8303 12.053 0.1852 32.006-2.6889 16.756-2.4137 28.962-3.562 33.75-3.175 7.9226 0.6405 45.833 9.007 60.232 13.292 4.6851 1.3945 12.379 3.1302 17.097 3.8571 9.2949 1.432 14.474 3.6928 27.123 11.84 12.619 8.128 23.504 19.108 38.767 39.109l13.961 18.293 21.857 50.056c12.021 27.531 23.522 53.911 25.557 58.623 11.736 27.176 12.232 48.796 2.254 98.308-2.7016 13.406-5.4935 28.138-6.2042 32.736-2.8712 18.579-17.74 50.14-28.743 61.014-9.5977 9.4842-77.951 55.054-90.025 60.018-17.68 7.2689-26.394 8.9982-47.5 9.4265-13.745 0.2789-24.642-0.2904-37.5-1.9592z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2756.1 3670.1c-10.632 22.154-27.187 44.324-27.412 66.451-5.3711 44.656-19.83 75.757-34.203 106.99-8.4017 21.772-21.433 37.916-10.126 83.648 8.0248 28.683 15.496 57.626 57.081 70.54l71.635 31.709c20.408 9.6026 48.11 8.2006 77.597 4.1072 28.709-10.318 57.446-9.8725 86.076-50.226 6.1615-12.056 9.7793-29.165 6.7408-59.501"/>
      <path d="m2997.2 3901.3c13.812 0.1574 24.538-4.5079 22.337-29.369-3.2018-47.213 3.2818-95.42-21.058-140.46 0.043-26.224 1.6722-53.512-18.277-66.332-26.407-19.264-39.045-52.369-94.938-41.993-27.594 6.1214-45.937 6.4274-55.17 1.0065"/>
      <path d="m2831.9 3675c10.255 25.894 16.554 50.288 41.594 81.788-10.186 33.711-24.756 45.798-39.506 57-3.6766 9.5172-9.6289 4.8623-6.7521 55.19-3.6083 48.287-35.077 64.901-53.195 96.691"/>
      <path d="m2937.7 3890.3c-36.253-17.662-72.522-41.063-108.71-32.474"/>
      <path d="m2738.3 3789.9c26.617 41.055 58.958 38.302 89.779 47.179"/>
      <path d="m2791.6 3733.8c21.4 24.118 42.624 48.289 41.587 79.202"/>
      <path d="m2979.5 3715.2c-24.598 29.084-58.277 45.525-105.92 42.519"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(35)}" (click)="toothClicked.emit(35)">
    <path d="m2740.4 4340.7c-0.3285-0.3286-11.15-1.4533-24.048-2.4994-32.175-2.6095-42.24-6.0271-62.646-21.271-16.051-11.991-45.04-43.472-50.794-55.161l-4.1402-8.4099-0.8515-32.593c-0.5048-19.319-1.6809-38.042-2.8879-45.971-1.6459-10.813-1.7838-15.41-0.7197-23.974 2.1724-17.482 5.6827-25.022 17.417-37.413 12.07-12.746 25.678-21.838 60.857-40.66 37.157-19.881 63.593-25.986 90.235-20.838 7.778 1.5031 15.699 4.8935 41.1 17.593l31.465 15.731 16.66 17.095c31.823 32.654 40.488 50.596 44.881 92.933 3.3851 32.623-5.5642 66.604-22.452 85.252-3.3399 3.6881-8.4742 10.014-11.41 14.058-14.548 20.041-24.434 25.399-65.288 35.382-11.177 2.7313-28.281 6.4013-38.009 8.1553-19.774 3.5656-18.558 3.4028-19.369 2.5919z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2752.8 4063.2c18.43 3.7066 40.751 6.4503 48.4 47.287-0.1565 17.76 5.5443 31.594 12.186 44.796 8.0215 42.584-12.934 70.774-31.635 100.08-14.52 24.505-32.489 26.956-49.914 32.889-19.003 10.044-33.26 3.31-43.153-18.853-6.0507-12.649-7.3022-31.329-21.64-33.564-6.4761-11.423-7.3336-20.99-6.9755-30.154 8.1532-15.307 1.5443-36.475-1.0087-56.032 33.459-1.6818 41.683-28.467 45.534-59.602 15.568-5.8959 30.507-7.9595 48.206-26.85z"/>
      <path d="m2712.8 4251.2c-19.846-17.975-19.485-49.498-19.178-80.985 12.194-52.799 34.313-78.04 69.654-66.562"/>
      <path d="m2674.7 4217.7c1.5583-27.086 9.8072-39.223 20.57-45.744"/>
      <path d="m2724.4 4088.3-2.523 24.145"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(34)}" (click)="toothClicked.emit(34)">
    <path d="m2578.5 4594.5c-9.3519-2.1365-40.187-14.151-52.27-20.366-23.83-12.258-41.014-24.623-58.686-42.228-19.803-19.73-28.92-29.536-30.782-33.112-1.0963-2.1055-1.7047-9.4462-1.8647-22.5-0.1307-10.656-1.2124-26.125-2.4038-34.375-3.6206-25.071-2.1055-35.388 10.28-70l7.1566-20 13.023-11.875c17.65-16.094 25.637-22.193 34.635-26.448 12.864-6.0828 21.319-7.1318 65.074-8.0744 39.97-0.861 40.264-0.8486 49.369 2.079 17.718 5.6971 39.268 23.621 66.002 54.897 22.144 25.906 39.161 60.945 42.489 87.485 1.4732 11.748 0.7954 18.009-4.9301 45.541-12.394 59.6-28.668 81.111-69.988 92.511-27.897 7.6964-51.686 9.9886-67.105 6.466z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2670.8 4374.6c-11.858 16.676-26.576 36.308-34.361 46.642-4.1824 7.3794-0.6541 12.577-1.6609 24.142 4.9861 30.018-4.3225 28.074-12.841 34.516-8.6923 6.7408-17.613 13.027-24.075 24.201-7.4145 22.416-17.946 17.432-27.532 20.762l-39.637 7.0003c-31.446 9.4664-36.391-5.0371-53.464-5.8945"/>
      <path d="m2486.6 4476c-2.0667 11.446 5.3603 24.224-11.119 33.65"/>
      <path d="m2572 4488.7-73.506-87.739c-15.446-18.666-27.261-23.183-28.412 13.868"/>
      <path d="m2583.9 4410.2c-7.2005-22.108-27.458-44.181-62.242-66.217 13.472 4.7005 26.909 9.5081 44.83 0.7447 21.243-10.412 31.365 1.564 43.988 8.5056"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(33)}" (click)="toothClicked.emit(33)">
    <path d="m2362.5 4798.1c-14.394-2.6553-30.37-10.835-41.763-21.383-12.367-11.45-50.408-56.155-53.624-63.019-1.4374-3.0671-4.397-7.9226-6.577-10.79-3.599-4.734-3.9561-6.0119-3.8819-13.892 0.1151-12.233 3.003-22.64 22.89-82.486 12.297-37.005 18.743-54.6 21.508-58.71 12.663-18.82 41.283-23.904 96.856-17.206 34.844 4.2 44.092 6.317 58.342 13.356 23.156 11.438 36.442 29.692 46.353 63.685l4.5092 15.466-2.0299 43.75c-2.2909 49.373-3.36 59.469-7.8062 73.72-8.9204 28.592-27.159 45.835-56.026 52.969-21.901 5.4124-61.61 7.7014-78.75 4.5396z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2477.5 4603.6c-16.964 53.735-27.587 115.36-79.845 125.21-40.639 18.533-82.192 48.38-116.41-12.626"/>
      <path d="m2300.1 4686.8c20.66 12.282 42.138 26.697 44.807-7.876 3.7465-36.035-11.83-52.645-18.309-78.401"/>
      <path d="m2441.3 4602.8c-4.8328-21.501-20.658-37.893-55.292-45.545"/>
      <path d="m2423.3 4651.1c-11.752 11.211-23.558 2.0862-35.43-32.666-6.1749-26.423-17.12-33.55-27.697-42.169"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(32)}" (click)="toothClicked.emit(32)">
    <path d="m2145.5 4889.3c-12.479-2.2593-28.686-8.1969-38.244-14.011-9.2037-5.599-35.021-27.33-39.598-33.332-1.7937-2.3517-5.0161-9.5136-7.1609-15.915-3.3776-10.082-3.7907-12.702-3.0861-19.579 0.8836-8.6246 9.2593-32.171 21.718-61.054 8.5547-19.833 20.014-54.205 23.96-71.866 5.6009-25.069 19.915-40.14 39.841-41.947 9.3148-0.8446 22.016 2.7742 30.434 8.6709 3.6789 2.5773 13.252 13.399 25.745 29.103 15.426 19.39 24.674 29.557 41.214 45.306 19.157 18.242 21.918 21.403 27.157 31.091 7.5305 13.928 8.9476 21.612 6.9345 37.605-0.83 6.5944-3.1486 18.703-5.1523 26.909-8.5984 35.212-22.394 55.053-46.706 67.178-23.576 11.757-52.753 16.24-77.056 11.841z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m2188.9 4685.4c22.073 32.126 83.283 64.15 14.046 96.515"/>
      <path d="m2137.3 4719.1-15.323 74.178c-9.964 35.708-20.06 21.005-30.172 0.079"/>
      <path d="m2267.5 4747.2c-1.5757 26.184 0.8647 54.936-31.736 61.29-27.723 9.5553-62.502 19.129-73.201 28.64-45.888 25.342-50.168 12.075-68.934 12.249"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(31)}" (click)="toothClicked.emit(31)">
    <path d="m1976.9 4923c-21.628-4.7554-30.919-5.7701-61.875-6.758-46.148-1.4727-54.625-3.7611-69.399-18.733-16.549-16.772-21.439-32.855-16.402-53.955 4.6945-19.669 6.7576-23.167 38.991-66.118 34.491-45.959 50.647-63.884 62.286-69.104 9.977-4.4745 22.45-6.2628 31.263-4.4822 17.42 3.5196 23.793 11.473 40.51 50.562 14.633 34.215 20.414 44.054 34.244 58.284 12.809 13.179 15.967 21.2 15.931 40.466-0.035 19.015-3.4718 37.064-8.4323 44.293-9.2219 13.438-22.618 22.215-39.309 25.752-10.294 2.1814-17.177 2.1301-27.807-0.2071z" fill="#fff" stroke-width="1.25"/>
    <path d="m2040.3 4885.7c-17.114 2.9-33.318 8.4556-52.579 2.7239l-31.028 2.6675c-21.838 3.2325-40.613 0.2991-60.347-0.7041-29.916-1.5657-60.736-2.703-52.645-22.276" fill="none" stroke="#000" stroke-width="10"/>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(41)}" (click)="toothClicked.emit(41)">
    <path d="m1627.6 4923.6c-19.452-2.8346-35.283-12.364-45.044-27.114-2.4759-3.7412-4.0097-8.7316-5.7219-18.616-3.2943-19.018-3.1737-33.675 0.3726-45.284 2.5089-8.2131 3.8824-10.384 11.957-18.901 15.756-16.618 19.666-23.106 34.627-57.445 5.5707-12.786 12.183-26.921 14.694-31.411 10.839-19.382 25.857-25.707 48.414-20.391 4.4594 1.0511 10.366 3.2376 13.125 4.8589 9.9199 5.8288 28.642 27.402 59.643 68.724 30.599 40.787 30.91 41.264 34.662 53.125 4.55 14.382 6.5453 26.321 5.5434 33.169-1.1841 8.0937-9.1661 22.769-17.084 31.41-10.196 11.127-20.544 16.039-38.998 18.511-8.5788 1.1494-18.216 1.5805-25.625 1.1462-15.522-0.9097-38.267 1.2556-61.733 5.8769-10.391 2.0463-19.454 3.6542-20.142 3.5731-0.6875-0.081-4.5984-0.6353-8.6909-1.2316z" fill="#fff" stroke-width="1.25"/>
    <path d="m1586.7 4885.3c17.114 2.9 33.318 8.4556 52.579 2.7239l31.028 2.6675c21.838 3.2325 40.613 0.2991 60.347-0.7041 29.916-1.5657 60.736-2.703 52.645-22.276" fill="none" stroke="#000" stroke-width="10"/>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(42)}" (click)="toothClicked.emit(42)">
    <path d="m1449 4889.9c-11.482-1.2577-30.813-6.3245-40.537-10.625-9.4886-4.1963-21.484-12.991-28.435-20.847-15.358-17.358-29.598-63.545-27.874-90.409 0.5334-8.3113 1.3814-11.01 6.6873-21.281 5.7555-11.142 7.3001-12.943 29.243-34.11 18.04-17.402 27.316-27.613 41.917-46.145 14.629-18.568 20.17-24.666 25.213-27.746 24.432-14.923 50.016-7.8056 62.889 17.496 1.9263 3.7862 4.9813 13.068 6.789 20.625 4.6562 19.467 13.441 45.471 23.222 68.741 4.6235 11 11.299 27.694 14.834 37.098 5.6791 15.107 6.4278 18.208 6.4278 26.625 0 5.7664-0.6977 10.876-1.7678 12.945-0.9723 1.8802-2.167 5.5461-2.6548 8.1465-1.2692 6.7654-8.2915 14.961-24.354 28.424-17.492 14.661-20.795 16.878-32.558 21.857-18.992 8.0387-39.914 11.301-59.043 9.2051z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m1438.1 4684.9c-22.073 32.126-83.283 64.15-14.046 96.515"/>
      <path d="m1489.7 4718.6 15.323 74.178c9.964 35.708 20.06 21.005 30.172 0.079"/>
      <path d="m1359.5 4746.7c1.5757 26.184-0.8647 54.936 31.736 61.29 27.723 9.5553 62.502 19.129 73.201 28.64 45.888 25.342 50.168 12.075 68.934 12.25"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(43)}" (click)="toothClicked.emit(43)">
    <path d="m1227.5 4798.6c-19.887-1.0536-38.791-3.9018-48.837-7.3583-27.481-9.4548-43.54-28.099-51.116-59.345-2.6057-10.747-3.363-19.242-5.4001-60.582l-2.3693-48.082 3.4963-12.063c9.2629-31.958 18.325-47.601 34.717-59.927 16.087-12.097 31.896-16.701 73.029-21.27 43.463-4.8277 69.066-2.5822 84.867 7.4432 10.581 6.7139 11.889 9.5855 32.12 70.531 25.453 76.676 26.684 83.818 16.742 97.135-2.798 3.7478-5.5147 8.2205-6.0371 9.9392-1.4227 4.6816-43.116 53.208-53.458 62.22-15.149 13.2-32.936 20.474-53.378 21.829-3.7812 0.2506-14.75 0.039-24.375-0.4714z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m1149.5 4603.1c16.964 53.735 27.587 115.36 79.845 125.21 40.639 18.533 82.192 48.38 116.41-12.626"/>
      <path d="m1326.9 4686.3c-20.66 12.282-42.138 26.697-44.807-7.876-3.7465-36.035 11.83-52.645 18.309-78.401"/>
      <path d="m1185.7 4602.3c4.8328-21.501 20.658-37.893 55.292-45.545"/>
      <path d="m1203.7 4650.6c11.752 11.211 23.558 2.0862 35.43-32.666 6.1749-26.423 17.12-33.55 27.697-42.169"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(44)}" (click)="toothClicked.emit(44)">
    <path d="m1015.3 4594.9c-5.3568-0.6518-17.451-3.142-26.875-5.5338-33.896-8.6022-49.204-19.709-61.389-44.541-7.3811-15.042-10.655-25.928-17.056-56.715-4.1002-19.72-4.5674-23.741-3.9128-33.68 1.9715-29.936 19.807-66.212 48.62-98.887 29.882-33.888 48.568-47.318 71.035-51.055 8.4087-1.3984 82.349 1.6876 90.396 3.7729 14.846 3.847 23.447 9.2884 44.702 28.281l15.781 14.101 6.9686 18.951c12.932 35.169 14.383 44.82 10.829 72.069-1.3855 10.625-2.4771 26.289-2.4797 35.583 0 11.343-0.5554 17.921-1.7435 20.764-0.9564 2.289-11.784 14.273-24.062 26.631-30.788 30.988-48.671 42.894-89.566 59.632-25.704 10.52-40.715 13.124-61.247 10.626z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m956.15 4374.1c11.858 16.676 26.577 36.308 34.361 46.642 4.1824 7.3794 0.65413 12.577 1.6609 24.142-4.9862 30.018 4.3225 28.074 12.841 34.516 8.6923 6.7408 17.613 13.027 24.075 24.201 7.4145 22.416 17.946 17.432 27.532 20.762l39.637 7.0003c31.446 9.4664 36.391-5.0371 53.464-5.8945"/>
      <path d="m1140.4 4475.5c2.0667 11.446-5.3603 24.224 11.119 33.65"/>
      <path d="m1055 4488.2 73.506-87.739c15.446-18.666 27.261-23.183 28.412 13.868"/>
      <path d="m1043.1 4409.7c7.2005-22.108 27.458-44.181 62.242-66.217-13.472 4.7005-26.909 9.5081-44.83 0.7447-21.243-10.412-31.365 1.564-43.988 8.5056"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(45)}" (click)="toothClicked.emit(45)">
    <path d="m854.17 4335.1c-41.777-8.6161-64.969-16.283-76.227-25.2-2.7161-2.1512-8.3909-8.4833-12.611-14.071-4.2197-5.588-10.3-13.07-13.512-16.628-7.2261-8.004-15.158-23.873-18.948-37.907-2.4953-9.2412-2.8604-13.474-2.8029-32.5 0.0564-18.658 0.54632-24.138 3.3319-37.262 5.6625-26.68 15.298-42.907 41.583-70.025l16.236-16.75 29.891-14.955c33.914-16.968 42.414-19.756 60.228-19.756 23.73 0 40.626 5.2129 73.66 22.726 43.155 22.879 62.896 38.186 71.271 55.264 7.2455 14.775 8.3757 26.303 5.0958 51.973-1.2234 9.5747-1.9917 25.024-1.9917 40.05 0 37.17-1.7849 41.782-26.407 68.229-31.435 33.765-49.515 44.961-77.424 47.944-18.465 1.9735-40.331 3.8208-44.368 3.7482-2.0222-0.036-14.175-2.2313-27.006-4.8775z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m874.2 4062.7c-18.43 3.7066-40.751 6.4503-48.4 47.287 0.15647 17.76-5.5443 31.594-12.186 44.796-8.0215 42.584 12.933 70.774 31.635 100.08 14.52 24.505 32.489 26.956 49.914 32.889 19.003 10.044 33.26 3.31 43.153-18.853 6.0507-12.649 7.3022-31.329 21.64-33.564 6.4761-11.423 7.3337-20.99 6.9755-30.154-8.1532-15.307-1.5443-36.475 1.0088-56.032-33.459-1.6818-41.683-28.467-45.534-59.602-15.568-5.8959-30.507-7.9595-48.206-26.85z"/>
      <path d="m914.23 4250.8c19.846-17.975 19.485-49.498 19.178-80.984-12.194-52.8-34.313-78.04-69.654-66.562"/>
      <path d="m952.25 4217.2c-1.5582-27.086-9.8072-39.223-20.57-45.744"/>
      <path d="m902.58 4087.8 2.523 24.145"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(46)}" (click)="toothClicked.emit(46)">
    <path d="m692.5 4076.7c-8.3092-1.3482-23.845-5.7522-32.699-9.2696-12.64-5.0211-85.618-53.79-92.941-62.11-2.8538-3.2421-7.1063-9.3235-9.4498-13.514-5.1564-9.2205-16.785-38.156-16.785-41.767 0-1.4253-2.8514-17.062-6.3365-34.748-7.6554-38.85-9.0023-56.847-5.6487-75.473 2.4319-13.506 2.7226-14.242 32.51-82.309l22.154-50.625 12.627-16.875c6.9448-9.2812 16.608-20.853 21.473-25.716 14.758-14.748 33.371-25.809 45.796-27.214 3.8221-0.4322 12.822-2.4688 20-4.5256 7.1779-2.0569 19.941-5.1425 28.362-6.8569s18.348-3.9997 22.058-5.0785c7.3682-2.1419 11.119-1.8728 45.755 3.283 11.87 1.7669 19.579 2.2706 25.992 1.6983 10.903-0.9731 18.565 0.5929 31.259 6.3888 18.311 8.3604 23.184 12.206 41.262 32.564 25.65 28.886 28.826 32.862 33.317 41.707 5.4526 10.74 6.8543 18.59 6.8082 38.125-0.0793 33.585 7.8449 61.63 25.783 91.25 3.0512 5.0382 7.2164 14.809 9.8188 23.033 13.199 41.712 12.501 68.902-2.3575 91.786-3.1892 4.9119-10.265 15.801-15.724 24.198-11.787 18.132-26.17 33.13-41.644 43.426-6.1323 4.0803-24.039 13.199-42.265 21.522-53.789 24.565-69.635 29.818-103.46 34.3-18.196 2.4106-48.624 3.9408-55.66 2.7992z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m870.92 3669.6c10.632 22.154 27.187 44.324 27.412 66.451 5.3711 44.656 19.83 75.757 34.203 106.99 8.4017 21.772 21.433 37.916 10.126 83.648-8.0248 28.683-15.496 57.626-57.081 70.54l-71.635 31.709c-20.408 9.6026-48.11 8.2006-77.597 4.1072-28.709-10.318-57.446-9.8725-86.076-50.226-6.1614-12.056-9.7793-29.165-6.7408-59.501"/>
      <path d="m629.8 3900.9c-13.812 0.1574-24.538-4.5079-22.337-29.369 3.2017-47.213-3.2819-95.42 21.057-140.46-0.0426-26.224-1.6722-53.512 18.277-66.332 26.407-19.264 39.045-52.369 94.938-41.993 27.594 6.1214 45.937 6.4274 55.17 1.0065"/>
      <path d="m795.05 3674.6c-10.255 25.894-16.554 50.288-41.594 81.788 10.186 33.711 24.757 45.798 39.506 57 3.6766 9.5172 9.6289 4.8623 6.7521 55.19 3.6083 48.287 35.077 64.901 53.195 96.691"/>
      <path d="m689.31 3889.8c36.253-17.662 72.522-41.063 108.71-32.474"/>
      <path d="m888.71 3789.5c-26.617 41.055-58.958 38.302-89.779 47.179"/>
      <path d="m835.41 3733.3c-21.4 24.118-42.624 48.289-41.587 79.202"/>
      <path d="m647.53 3714.7c24.598 29.084 58.277 45.525 105.92 42.519"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(47)}" (click)="toothClicked.emit(47)">
    <path d="m640.62 3626.2c-14.97-1.7092-24.786-5.8236-54.284-22.752-9.5749-5.495-19.402-10.823-21.837-11.841-7.8551-3.2821-34.561-18.87-44.675-26.076-12.855-9.159-16.7-12.872-25.766-24.886-4.1505-5.5-10.534-13.094-14.186-16.875-12.896-13.354-22.919-40.834-25.427-69.714-1.2748-14.681-1.0885-20.181 2.4644-72.786 3.136-46.432 8.4383-71.865 19.935-95.625 6.7639-13.978 12.913-21.748 29.185-36.875 22.147-20.589 35.023-28.533 74.102-45.719 21.307-9.3703 29.744-11.424 53.602-13.052 11.006-0.7506 30.53-2.6912 43.387-4.3124 21.84-2.754 23.934-2.8247 31.875-1.0758 16.045 3.5339 35.576 14.929 70.741 41.272 14.705 11.016 25.16 26.518 31.454 46.637 1.828 5.8438 4.7255 13.023 6.4387 15.953 7.3165 12.515 13.035 33.538 12.946 47.594-0.0245 3.8926-0.85945 10.452-1.8555 14.578-3.8821 16.078-1.4563 54.023 5.0088 78.35 3.8218 14.38 5.3247 22.227 6.8814 35.926 1.0722 9.4357 0.84942 12.444-1.9486 26.314-4.2672 21.153-11.037 40.784-21.605 62.649-6.9477 14.374-10.226 19.671-15.476 25-8.3016 8.4278-12.293 10.442-40.671 20.524-19.445 6.9082-24.085 8.1138-31.25 8.1201-9.8403 0.01-29.554 2.881-38.231 5.5705-3.3369 1.0343-12.899 4.3665-21.25 7.4049-18.374 6.6852-19.317 6.867-29.558 5.6977z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m665.08 3596.6c14.527-6.9312 14.688-19.036 58.661-15.364 10.636-1.3773 21.269-1.5802 31.939-16.296l23.314-14.594 19.873-17.189c15.506-16.856 19.102-35.066 13.935-54.274-2.7241-21.309-7.0794-47.003-3.3151-50.871 2.0472-13.212-2.0306-34.634-6.7476-56.914 2.1643-28.756 22.562-24.75-4.035-105.18-1.7323-17.147-10.796-25.916-27.518-25.934-13.609-2.6793-17.267-8.9504-34.424-22.504-12.194-7.2158-18.5-14.416-45.633-21.671-20.697 2.6219-41.368-4.259-62.103 12.768l-36.293 32.664c-29.261 24.254-32.395 37.702-34.616 50.772-8.1316 19.519-4.9552 39.068-1.8776 58.616 0.68869 16.661 6.424 30.455 23.176 37.992-8.0462 9.5862-17.076 14.189-20.809 45.635-8.5892 54.607 4.617 68.605 13.556 90.553 17.462 25.377 34.941 44.488 52.414 65.655"/>
      <path d="m713.39 3252.8c-15.719 10.717-28.045 27.411-34.625 54.22 2.8279 20.966 7.6838 37.902 12.789 54.344 4.2984 22.676 16.15 21.401 10.144 76.751-10.77 10.449-13.433 18.22-10.408 24.111 19.472 41.49 12.684 78.134-18.392 110.3"/>
      <path d="m742.86 3522.7c-9.5711-20.787-20.473-40.913-48.138-52.712-3.8175-11.768-16.805-16.442-41.348-12.177"/>
      <path d="m613.91 3384.4c39.142-3.2572 73.528-3.7561 85.322 8.8441"/>
      <path d="m797.47 3408.2c-30.622-8.5033-51.773-25.251-99.088-19.225"/>
      <path d="m786.36 3370.2-42.3 22.303"/>
      <path d="m617.6 3289.6c28.085 5.5333 47.718 11.044 62.026 16.542"/>
      <path d="m654.81 3238.9c11.145 21.868 25.094 43.744 23.104 65.578"/>
    </g>
  </a>
  <a class='tooth' [ngClass]="{'selectedTooth': selectedTeethArray?.includes(48)}" (click)="toothClicked.emit(48)">
    <path d="m572.5 3177.1c-12.044-5.6241-30.505-22.073-54.918-48.932-23.781-26.164-35.426-43.704-39.481-59.466-1.2348-4.8005-4.2148-13.916-6.6222-20.256-8.6395-22.754-10.194-32.731-8.2731-53.086 1.3346-14.139 6.9817-44.465 11.232-60.317 8.3939-31.307 24.301-58.761 45.321-78.223 16.01-14.823 43.999-31.683 55.865-33.653 3.0938-0.5136 13.219-3.151 22.5-5.8609 23.23-6.7826 32.221-8.5132 44.23-8.5132 17.754 0 29.786 4.9828 50.321 20.838 6.9716 5.3829 16.083 12.037 20.247 14.787 26.22 17.314 37.977 26.175 45.47 34.268 9.7612 10.543 17.689 25.145 23.407 43.116l3.9409 12.384-1.3288 19.003c-2.0109 28.758 1.4209 58.254 11.182 96.112 4.3555 16.892 4.8322 28.194 1.8131 42.982-2.1366 10.465-1.8882 9.8876-12.471 29.01-10.362 18.722-17.36 25.219-38.358 35.612-22.24 11.007-32.982 13.419-61.578 13.827-22.931 0.3271-34.165 1.5532-57.498 6.2756-21.233 4.2973-45.909 4.3387-55.002 0.092z" fill="#fff" stroke-width="1.25"/>
    <g fill="none" stroke="#000" stroke-width="10">
      <path d="m691.17 2848.4c-34.992-52.16-74.046-28.97-113.81 7.4607-22.605 27.15-29.591 55.762-26.947 85.275 0.9732 17.725 8.6828 29.706 25.774 33.688-11.279 6.305-15.787 18.056-15.605 33.58l15.282 89.696c14.57 36.961 26.142 36.765 38.667 48.377 6.6839 3.3823 13.452 6.6808 22.393 7.8173 12.367-4.1705 24.73-6.7262 37.179-42.144-10.526-3.3744 33.194-20.191 1.002-53.446-10.369-11.34 24.689-48.095 4.5157-78.437-10.324-33.385-20.156-62.232-28.252-75.074 21.795-3.5449 15.29-23.36 22.506-35.286"/>
      <path d="m625.53 2898.3 25.844 6.9643"/>
      <path d="m727.05 2973.5c-13.696 6.1675-25.55 16.664-45.71 7.639"/>
      <path d="m643.14 3083.7c16.106-2.4766 28.159-10.658 32.835-29.224"/>
      <path d="m613.26 2974.1c21.387-4.1252 44.935-16.801 62.08-4.1478"/>
      <path d="m680.92 3143.2c13.621-0.4232 26.494 0.7307 42.253-4.1998 22.521-25.157 30.599-25.192 44.922-36.089 2.6946-0.8148 31.079-30.374 10.541-74.973-11.341-22.822-14.092-30.706-9.3586-47.438-11.134-39.018-5.0973-47.802-7.5713-71.572"/>
    </g>
  </a>
  <g>
    <g transform="translate(2156.6 3313.9)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(2639.2 2755.5)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(2861.4 2312)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(2950.8 1882.4)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(2950.8 1403.3)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(2542.8 3408.7)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(2573.6 3410.7)">
      <path d="m502.04 972.17 23.071-1.9531c1.709 11.23 5.6762 19.674 11.902 25.33 6.2255 5.6559 13.733 8.4839 22.522 8.4838 10.579 1e-4 19.531-3.9876 26.855-11.963 7.3241-7.9752 10.986-18.555 10.986-31.738-1.1e-4 -12.532-3.5198-22.42-10.559-29.663-7.0395-7.2428-16.256-10.864-27.649-10.864-7.0801 1e-4 -13.468 1.6074-19.165 4.8218-5.6967 3.2146-10.173 7.3853-13.428 12.512l-20.63-2.6855 17.334-91.919h88.989v20.996h-71.411l-9.6436 48.096c10.742-7.4869 22.013-11.23 33.813-11.23 15.625 1.2e-4 28.808 5.4119 39.551 16.235 10.742 10.824 16.113 24.74 16.113 41.748-1.3e-4 16.195-4.7202 30.192-14.16 41.992-11.475 14.486-27.14 21.728-46.997 21.728-16.276 0-29.561-4.5572-39.856-13.672-10.295-9.1146-16.174-21.2-17.639-36.255z"/>
    </g>
    <g transform="translate(2647.7 3287)">
      <path d="m774.39 567.25-21.851 1.709c-1.9532-8.6262-4.7202-14.892-8.3008-18.799-5.9408-6.2661-13.265-9.3993-21.973-9.3994-6.9988 1.6e-4 -13.143 1.9533-18.433 5.8594-6.9174 5.0457-12.37 12.411-16.357 22.095-3.9877 9.6844-6.0629 23.478-6.2256 41.382 5.2897-8.0565 11.759-14.038 19.409-17.944 7.6497-3.9061 15.666-5.8593 24.048-5.8594 14.648 1.1e-4 27.12 5.3916 37.415 16.174 10.294 10.783 15.442 24.719 15.442 41.809-1.3e-4 11.231-2.4212 21.668-7.2632 31.311-4.8422 9.6436-11.495 17.029-19.958 22.156-8.4636 5.127-18.066 7.6904-28.809 7.6904-18.311 0-33.244-6.7342-44.8-20.203-11.556-13.468-17.334-35.665-17.334-66.589-1e-5 -34.586 6.3883-59.733 19.165-75.439 11.149-13.672 26.164-20.508 45.044-20.508 14.079 1.8e-4 25.614 3.9471 34.607 11.841 8.9924 7.894 14.384 18.799 16.174 32.715zm-89.722 77.148c-4e-5 7.5684 1.6072 14.811 4.8218 21.729 3.2145 6.9174 7.7107 12.187 13.489 15.808 5.7779 3.6214 11.841 5.4321 18.188 5.4321 9.2773 1e-5 17.253-3.7435 23.926-11.23 6.6731-7.487 10.01-17.659 10.01-30.518-1.1e-4 -12.37-3.296-22.115-9.8877-29.236-6.5919-7.1207-14.893-10.681-24.902-10.681-9.9284 1e-4 -18.351 3.5605-25.269 10.681-6.9174 7.1208-10.376 16.459-10.376 28.015z"/>
    </g>
    <g transform="translate(1090.8 1445.9)">
      <path d="m2304.5 1955.3v-21.118h115.84v17.09c-11.393 12.126-22.685 28.239-33.875 48.34-11.19 20.101-19.836 40.772-25.94 62.012-4.3946 14.974-7.2022 31.372-8.4229 49.194h-22.583c0.2441-14.079 3.0111-31.087 8.3008-51.025 5.2897-19.938 12.878-39.164 22.766-57.678 9.8876-18.514 20.406-34.118 31.555-46.814z"/>
    </g>
    <g transform="translate(2029.8 2870)">
      <path d="m1394.2 113.67c-9.1147-3.3365-15.869-8.0972-20.264-14.282-4.3946-6.1848-6.5918-13.59-6.5918-22.217 0-13.021 4.6793-23.966 14.038-32.837 9.3587-8.8703 21.81-13.305 37.354-13.306 15.625 1.8e-4 28.198 4.5371 37.72 13.611 9.5214 9.074 14.282 20.121 14.282 33.142-1e-4 8.3009-2.177 15.523-6.5307 21.667-4.354 6.1443-10.966 10.885-19.836 14.221 10.986 3.5808 19.348 9.3588 25.085 17.334 5.7372 7.9753 8.6058 17.497 8.606 28.564-2e-4 15.3-5.4119 28.158-16.235 38.574-10.824 10.417-25.065 15.625-42.725 15.625-17.66-1e-5 -31.901-5.2287-42.725-15.686-10.824-10.457-16.235-23.498-16.235-39.124 0-11.637 2.9501-21.383 8.8501-29.236 5.9001-7.8531 14.303-13.204 25.208-16.052zm-4.3946-37.231c0 8.4637 2.7262 15.381 8.1787 20.752 5.4525 5.3712 12.532 8.0567 21.24 8.0566 8.4634 1e-4 15.401-2.6651 20.813-7.9956 5.4116-5.3303 8.1175-11.861 8.1176-19.592-1e-4 -8.0565-2.7873-14.831-8.3618-20.325-5.5746-5.493-12.512-8.2396-20.813-8.2397-8.3822 1.62e-4 -15.34 2.6857-20.874 8.0566-5.5339 5.3712-8.3008 11.8-8.3008 19.287zm-7.0801 82.642c0 6.2663 1.4852 12.329 4.4556 18.188 2.9703 5.8594 7.3852 10.396 13.245 13.611 5.8594 3.2145 12.166 4.8218 18.921 4.8218 10.498 2e-5 19.165-3.3773 26.001-10.132 6.8358-6.7545 10.254-15.34 10.254-25.757-1e-4 -10.579-3.5198-19.328-10.559-26.245-7.0394-6.9172-15.849-10.376-26.428-10.376-10.335 9e-5 -18.901 3.4181-25.696 10.254-6.7953 6.836-10.193 15.381-10.193 25.635z"/>
    </g>
    <g transform="translate(2278.6 3313.9)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(-269.87 1760.9)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(-244.18 2240)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(-159.87 2669.6)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(21.251 3113.1)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(218.12 3408.7)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(498.82 3671.5)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(847.36 3795.1)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(1168.4 3860.7)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
    <g transform="translate(731.84 3313.9)">
      <path d="m302.16 1630.1 21.973-2.9297c2.5228 12.451 6.8156 21.423 12.878 26.916 6.0628 5.4932 13.448 8.2398 22.156 8.2398 10.335 0 19.063-3.5808 26.184-10.742 7.1207-7.1615 10.681-16.032 10.681-26.611-1e-4 -10.091-3.296-18.412-9.8877-24.963-6.5919-6.5511-14.974-9.8266-25.146-9.8267-4.1504 1e-4 -9.3181 0.8139-15.503 2.4414l2.4414-19.287c1.4648 0.1629 2.6448 0.2442 3.54 0.2441 9.3586 1e-4 17.782-2.4413 25.269-7.3242 7.4869-4.8827 11.23-12.41 11.23-22.583-9e-5 -8.0565-2.7263-14.73-8.1787-20.02-5.4526-5.2896-12.492-7.9344-21.118-7.9346-8.545 2e-4 -15.666 2.6857-21.362 8.0567-5.6966 5.3712-9.3588 13.428-10.986 24.17l-21.973-3.9063c2.6855-14.73 8.789-26.143 18.311-34.241 9.5214-8.0972 21.362-12.146 35.522-12.146 9.7656 2e-4 18.758 2.0957 26.978 6.2866 8.2193 4.1913 14.506 9.9082 18.86 17.151 4.3537 7.243 6.5306 14.933 6.5308 23.071-1.2e-4 7.7312-2.0753 14.771-6.2256 21.118-4.1505 6.3477-10.295 11.393-18.433 15.137 10.579 2.4415 18.799 7.5074 24.658 15.198 5.8592 7.6905 8.7889 17.314 8.7891 28.87-1.2e-4 15.625-5.6967 28.87-17.09 39.734-11.393 10.864-25.798 16.296-43.213 16.296-15.706 0-28.748-4.6793-39.124-14.038-10.376-9.3587-16.296-21.484-17.761-36.377z"/>
    </g>
    <g transform="translate(358.12 3408.7)">
      <path d="m455.81 1319v-42.847h-77.637v-20.142l81.665-115.97h17.944v115.97h24.17v20.142h-24.17v42.847zm0-62.988v-80.688l-56.03 80.688z"/>
    </g>
  </g>
</svg>
