import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CashRegisterInfo } from '../../models/cash-register.model';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/cash-register';
  }

  public getCashRegisterInfoByDateAndAddress(date: Date, addressId: number): Observable<CashRegisterInfo[]> {
    const queryParams = this.getDateAddressBuildParams(date, addressId);
    const urlWithParams = `${this.apiUrl}${queryParams.length > 0 ? '?' + queryParams : ''}`;
    return this.http.get<CashRegisterInfo[]>(urlWithParams);
  }

  private getDateAddressBuildParams(date: Date, addressId: number) {
    const dateQuery = date ? `date=${date.toISOString()}` : `date=${new Date().toISOString()}`;
    const addressQuery = addressId ? `addressId=${addressId}` : '';
    return [dateQuery, addressQuery].filter(param => !!param).join('&');
  }

  public cashRegisterLockToggle(id: number, shouldLock: boolean, finalAmount: number) {
    return this.http.post<CashRegisterInfo>(this.apiUrl + `/toggleLock/${id}`, { shouldLock, finalAmount });
  }
}

