<div class="calendar-custom-tooltip" *ngIf='appointment'>
  <h5 class="title">{{appointment.patient.name}} {{appointment.patient.lastName}} {{appointment.startDateTime ? '' : '(Brez termina)'}}</h5>
  <div class="info">
    <div class="key-value">
      <div class="key">
        Doktor
      </div>
      <div class="value">
        {{appointment.doctor ? (appointment.doctor.userInfo.name + ' ' + appointment.doctor.userInfo.lastName) : '/'}}
      </div>
    </div>
    <div class="key-value">
      <div class="key">
        Datum
      </div>
      <div class="value">
        {{appointment.startDateTime ? (appointment.startDateTime | date:"HH:mm dd.MM.yyyy") : '/'}}
      </div>
    </div>
    <div class="key-value">
      <div class="key">
        Trajanje
      </div>
      <div class="value">
        {{appointment.duration ? (appointment.duration + ' min') : '/'}}
      </div>
    </div>
    <div class="key-value">
      <div class="key">
        Lokacija
      </div>
      <div class="value">
        {{appointment.clinicLocation ? appointment.clinicLocation.name : '/'}}
      </div>
    </div>
    <div class="key-value">
      <div class="key">
        Status
      </div>
      <div class="value">
        {{appointment.appointmentStatus ? appointment.appointmentStatus.name : '/'}}
      </div>
    </div>
    <div></div>
  </div>
  <div class="comments" *ngIf="appointment?.appointmentHasComment">
    <h5 class="comments-title">Opombe</h5>
    <h5 *ngIf="!appointment?.appointmentHasComment?.length">/</h5>
    <div *ngFor="let appointmentHasComment of appointment?.appointmentHasComment" class="comment">
      <app-comment-card [comment]="appointmentHasComment?.comment" [editMode]="false"></app-comment-card>
    </div>
  </div>
</div>
