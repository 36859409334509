import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  PatientOfferedAction,
  PatientOfferedActionStatus,
} from '../../models/patient-offered-action.model';
import { teethEnumTranslation } from '../../utils/teeth.utils';
import { DropdownItem } from '../dropdown-search/dropdown-search.component';
import { ToastService } from '../../services/other-services/toast.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { PatientAction } from '../../models/patient-action.model';
import { SelectPatientActionDialogComponent } from '../../shared-modules/patient-pages/patient-info-pages/patient-therapy-page/select-patient-action-dialog/select-patient-action-dialog.component';
import {
  catchError,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { PatientOfferedActionService } from '../../services/api-services/patient-offered-action.service';
import { StoreService } from '../../services/api-services/store.service';
import { ToothColor } from '../../models/tooth-color.model';
import {
  TechnicianAction,
  TeethImprint,
} from '../../models/technician-action.model';

@Component({
  selector: 'app-add-technician-action-dialog',
  templateUrl: './add-technician-action-dialog.component.html',
  styleUrls: ['./add-technician-action-dialog.component.scss'],
})
export class AddTechnicianActionDialogComponent implements OnDestroy {
  teethImprint = TeethImprint;
  poasToAdd$: BehaviorSubject<PatientOfferedAction[]> = new BehaviorSubject([]);
  selectedPoaFilter: DropdownItem = undefined;
  commentFormControl = new FormControl<string | null>(null);
  public toothColorFormControl = new UntypedFormControl(null);
  public imprintFormControl = new FormControl<TeethImprint>(
    TeethImprint.analog
  );
  patientActions: PatientAction[];
  patientId: number;
  loadPatientOfferedActions$ = new Subject<void>();
  patientOfferedActions$ = this.loadPatientOfferedActions$.pipe(
    startWith(''),
    switchMap(() =>
      this.patientOfferedActionService
        .getByPatientId(this.patientId)
        .pipe(catchError(() => of([])))
    ),
    map((poas: PatientOfferedAction[]) =>
      poas.filter(
        (poa) =>
          poa?.status === PatientOfferedActionStatus.open &&
          (!poa?.technicianActionId ||
            poa?.technicianActionId === this.data?.technicianAction?.id)
      )
    ),
    shareReplay(1)
  );
  allPoasDropdown$: Observable<DropdownItem[]> = combineLatest([
    this.poasToAdd$,
    this.patientOfferedActions$,
  ]).pipe(
    map(([poasToAdd, poas]) => {
      const filteredMappedItems = poas
        ?.filter(
          (poa) =>
            !poasToAdd?.some((a) => a?.id === poa?.id) &&
            (!poasToAdd?.length ||
              poasToAdd?.[0]?.patientActionId === poa?.patientActionId)
        )
        .map((poa) => ({
          id: poa?.id,
          name: `${poa?.patientAction?.name} (${teethEnumTranslation(
            poa?.teeth
          )})`,
        }));
      return (filteredMappedItems
        ? [...filteredMappedItems]
        : []
      ).sort((a, b) => a.name.localeCompare(b.name));
    })
  );
  private readonly destroy$ = new Subject<void>();
  constructor(
    private dialog: MatDialog,
    private storeService: StoreService,
    private toastService: ToastService,
    private patientOfferedActionService: PatientOfferedActionService,
    private dialogRef: MatDialogRef<AddTechnicianActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientActions: PatientAction[];
      technicianOrderId: number;
      patientId: number;
      toothColors: ToothColor[];
      technicianAction: TechnicianAction;
    }
  ) {
    this.patientActions = data.patientActions;
    this.patientId = data.patientId;
    this.loadPatientOfferedActions$.next();

    if (this.data?.technicianAction) {
      this.toothColorFormControl.setValue(
        this.data?.technicianAction?.toothColorId
      );
      this.imprintFormControl.setValue(this.data?.technicianAction?.imprint);
      this.commentFormControl.setValue(this.data?.technicianAction?.comment);
      this.poasToAdd$.next(this.data.technicianAction.patientOfferedActions);
    }
  }

  public add(): void {
    if (!this.poasToAdd$.value?.length) {
      this.toastService.warning('Izberi vsaj eno akcijo!');
      return;
    }
    if (
      this.poasToAdd$.value?.[0].patientAction?.isColorMandatory &&
      !this.toothColorFormControl?.value
    ) {
      this.toastService.warning('Izberi barvo!');
      return;
    }
    if (
      !this.poasToAdd$.value?.[0].patientAction?.onlyTechnician &&
      !this.imprintFormControl?.value
    ) {
      this.toastService.warning('Izberi Otisak!');
      return;
    }
    const technicianAction: TechnicianAction = {
      id: this.data?.technicianAction?.id ?? undefined,
      technicianOrderId: this.data.technicianOrderId,
      toothColorId: this.poasToAdd$.value?.[0]?.patientAction?.isColorMandatory
        ? this.toothColorFormControl.value
        : undefined,
      toothColor: undefined,
      comment: this.commentFormControl.value,
      imprint: !this.poasToAdd$.value?.[0]?.patientAction?.onlyTechnician
        ? this.imprintFormControl.value
        : undefined,
      patientOfferedActions: this.poasToAdd$.value,
      technicianOrderFaze: [],
    };
    this.dialogRef.close(technicianAction);
  }

  addNewPatientOfferedAction(patientOfferedActions: PatientOfferedAction[]) {
    const id = this.selectedPoaFilter?.id;
    if (!id) {
      this.toastService.warning('Morate izabrati storitvu za dodati!');
      return;
    }
    this.selectedPoaFilter = undefined;
    const poaToAdd = patientOfferedActions.find((poa) => poa?.id === id);
    this.poasToAdd$.next([...this.poasToAdd$.value, poaToAdd]);
  }

  createNewPoa() {
    const dialogRef = this.dialog.open(SelectPatientActionDialogComponent, {
      width: '40rem',
      autoFocus: false,
      disableClose: true,
      data: { patientActions: this.patientActions, patientId: this.patientId },
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((a) => !!a),
        switchMap((patientOfferedActions: PatientOfferedAction[]) =>
          this.patientOfferedActionService
            .add(patientOfferedActions)
            .pipe(withLatestFrom(patientOfferedActions))
        )
      )
      .subscribe(() => {
        this.loadPatientOfferedActions$.next();
        this.storeService.dispatchRefreshTeethOverview();
      });
  }

  removePoa(poaId: number) {
    const poasToAdd = this.poasToAdd$.value.filter((poa) => poa?.id !== poaId);
    this.poasToAdd$.next(poasToAdd);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
