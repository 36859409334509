import { Pipe, PipeTransform } from '@angular/core';
import { ReceiptStatus } from '../models/receipt.model';

@Pipe({
  name: 'priceInversionStornoReceipts',
})
export class PriceInversionStornoReceiptsPipe implements PipeTransform {
  transform(price: number, status: ReceiptStatus): number {
    if (status && ReceiptStatus.canceled === status) { return -price; }
    return price;
  }
}
