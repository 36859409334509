<mat-dialog-content>
  <h2>Otkaži</h2>

  <div class="overview-info">
    <mat-form-field class='comment'>
      <mat-label>Opomba</mat-label>
      <textarea matInput [formControl]="commentForm"></textarea>
    </mat-form-field>

    <div class="actionButtons">
      <div></div>
      <div>
        <button class='back' mat-button [mat-dialog-close]="false">Nazaj</button>
      </div>
      <div>
        <button class="cancel" mat-button (click)='cancel()'>Spremeni</button>
      </div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>

