import { Pipe, PipeTransform } from '@angular/core';
import { TeethEnum } from '../models/patient-offered-action.model';
import { generateTeethArrayString } from '../utils/teeth.utils';

@Pipe({
  name: 'teethEnumArrayToString',
})
export class TeethToStringPipe implements PipeTransform {
  transform(teeth: TeethEnum[]): string {
    return generateTeethArrayString(teeth);
  }
}
