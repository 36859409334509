import { Pipe, PipeTransform } from '@angular/core';
import { TeethEnum } from '../models/patient-offered-action.model';
import { teethEnumTranslation } from '../utils/teeth.utils';

@Pipe({
  name: 'teethName',
})
export class TeethNamePipe implements PipeTransform {
  transform(teeth: TeethEnum | string): string {
    return teethEnumTranslation(teeth);
  }
}
