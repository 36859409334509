import { Injectable } from '@angular/core';
import { LocalStorageService } from '../api-services/local-storage.service';
import {
  ADMIN,
  ADMINISTRATOR,
  AGENT,
  ASSISTANT,
  DOCTOR,
  TECHNICIAN,
  TECHNICIAN_WORKER
} from '../../utils/constants/user-type.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private storageService: LocalStorageService) {
  }

  get userType() {
    return this.storageService.getUserType();
  }

  get isAdmin() {
    return ADMIN === this.userType;
  }

  get isAgent() {
    return AGENT === this.userType;
  }

  get isAssistant() {
    return ASSISTANT === this.userType;
  }

  get isAdministrator() {
    return ADMINISTRATOR === this.userType;
  }

  get isDoctor() {
    return DOCTOR === this.userType;
  }

  get isTechnician() {
    return TECHNICIAN === this.userType;
  }

  get isTechnicianWorker() {
    return TECHNICIAN_WORKER === this.userType;
  }
}
