import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PatientCallStatus } from '../../models/patient-call-status.model';

@Injectable({
  providedIn: 'root',
})
export class PatientCallStatusService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/patient-call-status';
  }

  public getAll(): Observable<PatientCallStatus[]> {
    return this.http.get<PatientCallStatus[]>(this.apiUrl);
  }
}
