import { BillActionBasic, BillBasic } from './bill.model';
import { PaymentMethod } from './payment-method.model';
import { PatientAction } from './patient-action.model';
import { DiscountValueOptions } from '../utils/constants/constants';

export interface ReceiptAddress {
  id?: number;
  clinicId?: number;
  receiptIdPrefix: string;
  content: string;
}

export interface ReceiptHeader {
  companyName: string;
  address: string;
  email: string;
  phone: string;
  logo: string;
}

export interface ReceiptFooter {
  registrationNumber: string;
  taxNumber: string;
  bankAccount: string;
}

export interface InvoiceReceiptInfo {
  invoiceInfo: string;
  receiptInfo: string;
}

export interface ReceiptHeaderFooter {
  id?: number;
  type: string;
  content: string;
}

export interface Receipt extends ReceiptBasic {
  receiptActions?: ReceiptAction[];
  advanceUsed?: boolean;
}

export enum ReceiptStatus {
  paid = 'paid',
  pending_payment = 'pending-payment',
  canceled = 'canceled',
}

export interface ReceiptAction extends BillActionBasic {
  receiptId?: number;
  receipt?: Receipt;
  discountType?: DiscountValueOptions;
}

export interface ReceiptClientIdentification {
  id?: number;
  receiptId?: number;
  patientId: number;
  uniqueCitizenNumber: string;
  taxNumber: string;
}

export interface PaymentMethodChange {
  paymentMethodId: number;
  dueDate?: Date;
  uniqueCitizenNumber: string;
  taxNumber: string;
}

export interface AdvanceReceipt extends ReceiptBasic {
  patientActionId: number;
  patientAction?: PatientAction;
  startingBalance: number;
  remainingBalance?: number;
  isFromMinimax?: boolean;
}

export interface ReceiptBasic extends BillBasic {
  dueDate?: Date;
  paymentMethod?: PaymentMethod;
  paymentMethodId: number;
  receiptClientIdentification?: ReceiptClientIdentification;
  comment?: string;
  paidAt?: Date;
  cancelledAt?: Date;
  refundMethod?: PaymentMethod;
  refundMethodId?: number;
  isFullyCancelled?: boolean;
}

export interface AdvanceReceiptUsage {
  id: number;
  advanceReceiptId: number;
  advanceReceipt: AdvanceReceipt;
  receiptId: number;
  receipt: Receipt;
  remainingBalance: number;
  usedBalance: number;
  createdAt: Date;
}

export interface ReceiptPagination {
  mixedReceipts: (Receipt | AdvanceReceipt)[];
  count: number;
  unusedAdvancesSum: number;
}
