import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SetFormDateService {
  constructor() {
  }

  public getDateString(date: Date): string {
    const month = date.getMonth() + 1;
    const monthTens = Math.floor(month / 10);
    const dateTens = Math.floor(date.getDate() / 10);
    return date.getFullYear() + '-' + monthTens.toString() + (month - monthTens * 10) + '-' + dateTens.toString() + (date.getDate() - dateTens * 10);
  }

  public getMonthDateString(date: Date): string {
    const month = date.getMonth() + 1;
    const monthTens = Math.floor(month / 10);
    return date.getFullYear() + '-' + monthTens.toString() + (month - monthTens * 10);
  }

  public getHourString(date: Date): string {
    let hours = date.getHours().toString();
    if (hours.toString().length === 1) {
      hours = '0' + hours;
    }
    let minutes = date.getMinutes().toString();
    if (minutes.length === 1) {
      minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
  }
}
