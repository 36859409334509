<mat-dialog-content>
  <div class="progress" *ngIf="loadingProgress" style="margin-bottom: 12px">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
         [style]="'width: ' + loadingProgress +'%'">{{ loadingProgress }}%
    </div>
  </div>


  <h2 *ngIf="!loadingProgress">{{ data.msg }}</h2>

  <div id="form" *ngIf="!loadingProgress">
    <input type="file" (change)="fileChangeEvent($event)" accept="image/*" />

    <div id="imageCroppingContainer">
      <img referrerPolicy="no-referrer" [src]="croppedImage" />

      <image-cropper
        id="cropper"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="5 / 2"
        format="png"
        (imageCropped)="imageCropped($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
  </div>

  <div id="actionButtons" *ngIf="!loadingProgress">
    <div></div>
    <div></div>
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" (click)="save()">{{ data.actionText }}</button>
    </div>
  </div>
</mat-dialog-content>
