import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../services/other-services/toast.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PaymentMethod } from '../../models/payment-method.model';
import { ADVANCE_HANDLING_AFTER_CANCELLATION } from '../../utils/constants/constants';
import { ReceiptAction } from '../../models/receipt.model';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss'],
})
export class CancelDialogComponent {
  readonly ADVANCE_HANDLING_AFTER_CANCELLATION =
    ADVANCE_HANDLING_AFTER_CANCELLATION;
  private reverseMapping: { [key: string]: string } = {};

  fg = this.fb.group({
    refundMethod: [null, Validators.required],
    comment: ['', Validators.required],
    advanceHandling: [''],
    actions: this.fb.group({}),
  });

  today = new Date();
  allChecked = true;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CancelDialogComponent>,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      availablePaymentMethods: PaymentMethod[];
      showCancellationOptions: boolean;
      receiptActions: ReceiptAction[];
    },
  ) {
    if (data.showCancellationOptions) {
      this.fg.get('advanceHandling').addValidators(Validators.required);
      Object.keys(ADVANCE_HANDLING_AFTER_CANCELLATION).forEach((key) => {
        this.reverseMapping[
          ADVANCE_HANDLING_AFTER_CANCELLATION[
            key as keyof typeof ADVANCE_HANDLING_AFTER_CANCELLATION
          ]
        ] = key;
      });
    }
    data.receiptActions.forEach((a) => {
      this.addFormControls(a);
    });
  }

  addFormControls(action: ReceiptAction): void {
    const formControl = new FormControl<boolean>(true);
    this.actionsFormGroup.addControl(`${action.id}`, formControl);
  }

  public cancel(): void {
    if (this.fg.invalid) {
      this.toastService.warning('Za preklic morate vnesti sve podatke');
      return;
    }
    const selectedIds: number[] = [];
    Object.keys(this.actionsFormGroup.controls).forEach((controlName) => {
      const control = this.actionsFormGroup.get(controlName);
      if (control.value) {
        selectedIds.push(+controlName); // Assuming controlName is the ID and converting it to a number
      }
    });
    this.dialogRef.close({
      comment: this.fg.get('comment').value?.trim(),
      refundMethodId: this.fg.get('refundMethod')?.value as number,
      advanceHandling: this.fg.get('advanceHandling').value?.trim(),
      actionsToCancel: selectedIds,
    });
  }

  getEnumKeyForValue(enumValue: ADVANCE_HANDLING_AFTER_CANCELLATION) {
    return this.reverseMapping[enumValue];
  }

  someComplete(): boolean {
    return (
      Object.values(this.actionsFormGroup.controls).filter(
        (control) => control.value,
      ).length > 0 && !this.allChecked
    );
  }

  updateIndeterminateState() {
    this.allChecked = Object.values(this.actionsFormGroup.controls).every(
      (control) => control.value,
    );
  }

  setAll(checked: boolean): void {
    this.allChecked = checked;
    Object.values(this.actionsFormGroup.controls).forEach((control) => {
      control.setValue(checked);
    });
  }

  get actionsFormGroup(): FormGroup {
    return this.fg.get('actions') as FormGroup;
  }
}
