import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReceiptAddress } from '../../../models/receipt.model';
import { maxAmountValidator } from '../../../utils/validators/max-amount.validator';

@Component({
  selector: 'app-cash-deposit-form-dialog',
  templateUrl: './cash-deposit-form-dialog.component.html',
  styleUrls: ['./cash-deposit-form-dialog.component.scss'],
})
export class CashDepositFormDialogComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: string;
      address: ReceiptAddress;
      date: string;
      maxAmount: number;
    }
  ) {
    this.form = this.fb.group({
      user: [{ value: data.user, disabled: true }, Validators.required],
      address: [
        { value: data.address?.content ?? '', disabled: true },
        Validators.required,
      ],
      date: [{ value: data.date, disabled: true }, Validators.required],
      amount: [null, [Validators.required, maxAmountValidator(data.maxAmount)]],
    });
  }
}
