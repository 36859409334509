import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime',
})
export class MinutesToTimePipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    const hours = Math.floor(value / 60);
    const minutes = value - hours * 60;

    const hoursAsStr = hours < 10 ? '0' + hours : hours.toString();
    const minutesAsStr = minutes < 10 ? '0' + minutes : minutes.toString();

    return `${hoursAsStr}:${minutesAsStr}`;
  }
}
