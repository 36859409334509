<h4 mat-dialog-title>{{ title }}</h4>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="patientForm" (ngSubmit)="save()" novalidate>
    <div class="inputs">
      <div class="double">
        <div class="form-group">
          <label for="nameInput">Ime*</label>
          <input type="text"
                 [class]="'form-control ' + ( patientForm.get('name').touched && patientForm.get('name').hasError('required') ? 'is-invalid' : '')"
                 id="nameInput" placeholder="Ime" formControlName="name">
          <div class="invalid-feedback text-danger" *ngIf="patientForm.get('name').hasError('required')">
            Polje je obvezno!
          </div>
        </div>
        <div class="form-group">
          <label for="lastNameInput">Priimek*</label>
          <input type="text"
                 [class]="'form-control ' + ( patientForm.get('lastName').touched && patientForm.get('lastName').hasError('required') ? 'is-invalid' : '')"
                 id="lastNameInput" placeholder="Priimek" formControlName="lastName">
          <div class="invalid-feedback text-danger" *ngIf="patientForm.get('lastName').hasError('required')">
            Polje je obvezno!
          </div>
        </div>
      </div>

      <div class='one-double'>

        <div class="form-group">
          <label for="countryInput">Država</label>
          <select id="countryInput" class="custom-select" formControlName="country">
            <option *ngFor="let country of COUNTRIES"
                    [value]="country">{{ country }}
            </option>
          </select>
        </div>

        <div>
          <ng-container *ngIf='patientForm.controls.country.value === "Slovenia"; else noSlovenia'>
            <div class="form-group">
              <label for="postCodeInput">Poštna Številka i Mesto</label>
              <app-dropdown-search [(selected)]='dropdownPostCode' [list]='SLOVENIAN_PLZ_DROPDOWN' [markAsTouched]='dropdownMarkAsTouched'></app-dropdown-search>
            </div>
          </ng-container>

          <ng-template #noSlovenia>
            <div class='double'>
              <div class="form-group">
                <label for="postCodeInput">Poštna Številka</label>
                <input type="text"
                       class="form-control"
                       id="postCodeInput" placeholder="Poštna Številka" formControlName="postCode">
              </div>

              <div class="form-group">
                <label for="cityInput">Mesto</label>
                <input type="text"
                       class="form-control"
                       id="cityInput" placeholder="Mesto" formControlName="city">
              </div>
            </div>
          </ng-template>

        </div>
      </div>

      <div class="double">
        <div class="form-group">
          <label for="addressInput">Naslov</label>
          <input type="text"
                 class="form-control"
                 id="addressInput" placeholder="Naslov" formControlName="address">
        </div>
        <div class="form-group">
          <label for="emailInput">Email</label>
          <input type="email"
                 class="form-control"
                 id="emailInput" placeholder="Email" formControlName="email">
        </div>
      </div>

      <div class="triple">
        <div class="form-group">
          <label for="nameInput">Telefon</label>
          <input type="tel"
                 class="form-control"
                 id="phoneInput" placeholder="Telefon" formControlName="phone">
        </div>


        <div class="form-group">
          <label for="birthdayInput">Datum rojstva</label>
          <input type="date"
                 class="form-control"
                 id="birthdayInput" placeholder="Datum rojstva" formControlName="birthday">
        </div>

        <div class="form-group">
          <label for="genderInput">Spol</label>
          <select id="genderInput" class="custom-select" formControlName="gender">
            <option value="">Izberi spol...</option>
            <option value="M">Moški</option>
            <option value="F">Ženska</option>
          </select>
        </div>

        <div class="form-group">
          <label for="idCardNumberInput">Številka osebne kartice</label>
          <input type="text"
                 class="form-control"
                 id="idCardNumberInput" placeholder="Število osebne kartice" formControlName="idCardNumber">
        </div>

        <div class="form-group">
          <label for="agentInput">Agent</label>
          <select id="agentInput" class="custom-select" formControlName="agent">
            <option value="">Izberi agenta...</option>
            <option *ngFor="let agent of data.agents"
                    [value]="agent.id">{{ agent.userInfo.name }} {{ agent.userInfo.lastName }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="creationDateInput">Datum povpraševanja</label>
          <input type="date"
                 class="form-control"
                 id="creationDateInput" placeholder="Datum povpraševanja" formControlName="creationDate">
        </div>

        <div class="form-group">
          <label for="mediaInput">Medij</label>
          <select id="mediaInput" class="custom-select" formControlName="media">
            <option value="">Izberi medij...</option>
            <option *ngFor="let media of data.medias" [value]="media.id">{{ media.name }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="patientCategoryInput">Kategorija</label>
          <select id="patientCategoryInput" class="custom-select" formControlName="patientCategory">
            <option value="">Izberi kategorijo...</option>
            <option *ngFor="let category of data.patientCategories" [value]="category.id">{{ category.name }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="canCallInput">Dovoli klice</label>
          <select id="canCallInput" class="custom-select" formControlName="canCall">
            <option [value]="true">Da</option>
            <option [value]="false">Ne</option>
          </select>
        </div>

        <div class="form-group">
          <label for="minimaxCode">Sifra stranke</label>
          <input type="text"
                 class="form-control"
                 id="minimaxCode" placeholder="Sifra" formControlName="minimaxCode">
        </div>
      </div>
    </div>

    <div id="actionButtons">
      <div></div>
      <div></div>
      <div>
        <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
      </div>
      <div>
        <button class="btn btn-primary" type="submit">{{ actionText }}</button>
      </div>
    </div>
  </form>
</mat-dialog-content>