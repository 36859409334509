<main>


  <div *ngIf='isLoading' style='text-align: center; padding-top: 100px'>
    <div class='spinner-border text-primary spinner' role='status' style='width: 100px; height: 100px'>
      <span class='sr-only'></span>
    </div>
  </div>

  <ng-container *ngIf='users$ | async as users'>
    <ng-container *ngIf='!isLoading'>
      <div>
        <div id='toolbar'>
          <h3 style='padding-top: 0.5rem'>{{ title }}</h3>

          <div id='actions' *ngIf='!isReadonly'>
            <button mat-icon-button (click)='addUser()'>
              <mat-icon>add</mat-icon>
            </button>

            <button
              mat-icon-button
              (click)='editUser()'
              [disabled]='(selectedUsers$ | async).size !== 1'>
              <mat-icon>edit</mat-icon>
            </button>

            <button
              mat-icon-button
              [disabled]='(selectedUsers$ | async).size !== 1'
              (click)='deleteUser()'>
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

        <div class='user-card head'
             [style]="isFiveColumns() ? 'grid-template-columns: 3rem 1fr 1fr 1fr 1fr 6rem' : ''">
          <div class='cell'>
            <mat-checkbox #allCheckbox
                          class='select-all-checkbox'
                          [ngStyle]="{'display': isReadonly ? 'none' : 'block'}"
                          (change)='toggleAll(allCheckbox.checked, users)'>
            </mat-checkbox>
          </div>
          <div class='cell'>Ime</div>
          <div class='cell'>Email</div>
          <div class='cell'>Telefon</div>
          <div *ngIf='isDoctor()' class='cell'>Tip cenika</div>
          <div *ngIf='isTechnician()' class='cell'>Logo</div>
          <div class='cell'>Barva</div>
        </div>
      </div>

      <div id='userList'>
        <div *ngFor='let user of users; let i = index'>
          <div [class]="'user-card ' + (i % 2 !== 0 ? 'odd' : '')"
               [style]="isFiveColumns() ? 'grid-template-columns: 3rem 1fr 1fr 1fr 1fr 6rem' : ''">

            <div class='cell'>
              <mat-checkbox class='select-all-checkbox'
                            [ngStyle]="{'display': isReadonly ? 'none' : 'block'}"
                            (change)='toggleUser(user)'
                            [checked]='(selectedUsers$ | async).has(user)'>
              </mat-checkbox>
            </div>

            <div class='cell' (click)='toggleUser(user)'>
              {{ user.userInfo.name + ' ' + user.userInfo.lastName }}
            </div>
            <div class='cell' (click)='toggleUser(user)'>
              {{ user.userInfo.email }}
            </div>
            <div class='cell' (click)='toggleUser(user)'>
              {{ user.userInfo.phone }}
            </div>
            <div *ngIf='isDoctor()' class='cell' (click)='toggleUser(user)'>
              {{ user.actionTypeDoctorPriceType.name }}
            </div>
            <ng-container *ngIf='isTechnician()'>
              <div *ngIf='user.technicianLogo?.fileInfo?.googleDriveId' class='cell' (click)='toggleUser(user)'>
                <img referrerPolicy="no-referrer" [src]='GOOGLE_DRIVE_IMG_URL + user.technicianLogo?.fileInfo?.googleDriveId' alt='ni slike' />
              </div>
              <div *ngIf='!user.technicianLogo' class='cell' (click)='toggleUser(user)'>
                Ni slike.
              </div>
            </ng-container>
            <div class='cell' (click)='toggleUser(user)'
                 [style]="'margin: 0.5rem 0.5rem 0.5rem 0; height: 1em; background-color: ' + user.userInfo.color">
            </div>

          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>


</main>
