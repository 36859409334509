import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../../models/agent.model';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AgentService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/agent';
  }

  public get(agentId: number): Observable<Agent> {
    return this.http.get<Agent>(this.apiUrl + `/${agentId}`);
  }

  public add(agent: User): Observable<number> {
    return this.http.post<number>(this.apiUrl, agent);
  }

  public update(agent: User): Observable<Agent> {
    return this.http.patch<Agent>(this.apiUrl, agent);
  }

  public delete(agentId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${agentId}`);
  }

  public getByClinicId(clinicId: number): Observable<Agent[]> {
    return this.http.get<Agent[]>(this.apiUrl + `/by-clinic-id/${clinicId}`);
  }
}
