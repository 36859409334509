<mat-dialog-content>
  <h2>Kreiranje posega brez termina</h2>

  <form [formGroup]='fg' (ngSubmit)='save()'>
    <div *ngIf='doctors$ | async as doctors'>
      <mat-form-field appearance='fill' class='full-width'>
        <mat-label>Doktor</mat-label>
        <mat-select
          (openedChange)='fg.controls.doctorFilter.reset()'
          [formControl]='fg.controls.doctorId'
        >
          <mat-option [disabled]='true'
          ><input
            (keydown)='$event.stopPropagation()'
            type='text'
            placeholder='Search'
            aria-label='Doktor'
            matInput
            [formControl]='fg.controls.doctorFilter'
          />
          </mat-option>
          <mat-option *ngFor='let doctor of doctors' [value]='doctor?.id'>
            {{ doctor?.userInfo?.name }} {{ doctor?.userInfo?.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance='fill' class='full-width'>
        <mat-label>Trajanje</mat-label>
        <mat-select
          formControlName='duration'
        >
          <mat-option *ngFor='let option of minuteOptions' [value]='option'>
            {{ option | minutesToTime }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class='full-width'>
        <mat-label>Opomba</mat-label>
        <textarea matInput class='form-control' formControlName='remark' placeholder='Opomba'></textarea>
      </mat-form-field>
    </div>
    <div *ngIf='locations$ | async as locations'>
      <mat-form-field appearance='fill'>
        <mat-label>Lokacija</mat-label>
        <mat-select [formControl]='fg.controls.locationId'>
          <mat-option *ngFor='let loc of locations' [value]='loc.id'>
            {{ loc.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div id='actionButtons'>
      <div></div>
      <div></div>
      <div>
        <button mat-button [mat-dialog-close]='undefined'>Prekliči</button>
      </div>
      <div>
        <button id='add' mat-button>Dodaj</button>
      </div>
    </div>
  </form>

</mat-dialog-content>
