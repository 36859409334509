<mat-dialog-content>
  <div class='container'>

    <h2>Dodaj Akcijo</h2>


    <div class='header' *ngIf='patientOfferedActions$ | async as patientOfferedActions'>
      <app-dropdown-search
        [(selected)]='selectedPoaFilter'
        placeholder='Naziv'
        [list]='allPoasDropdown$ | async'
        [doNotShowError]='true'>
      </app-dropdown-search>
      <button class='btn btn-primary' (click)='addNewPatientOfferedAction(patientOfferedActions)'>
        <mat-icon>add</mat-icon>
      </button>
    </div>

    <div class='content'>
      <div *ngFor='let poa of poasToAdd$ | async' class='poa'>
        <button
          style='margin: auto;'
          type='button'
          mat-icon-button
          (click)='removePoa(poa?.id)'
        >
          <mat-icon>delete</mat-icon>
        </button>
        <b style='margin: auto auto auto 0;'>{{ poa.patientAction?.name }} ({{ poa.teeth | teethName }})</b>
      </div>


      <ng-container *ngIf='this.poasToAdd$ | async as selectedPoas'>
        <div class='double'>
          <div class='form-group' *ngIf='selectedPoas?.[0]?.patientAction && !selectedPoas?.[0]?.patientAction?.onlyTechnician'>
            <label for='imprintInput'>Otisak</label>
            <select id='imprintInput'
                    [class]="'form-control ' + ( imprintFormControl.touched && imprintFormControl.hasError('required') ? 'is-invalid' : '')"
                    [formControl]='imprintFormControl'>
              <option value='{{teethImprint.analog}}'>Analogni</option>
              <option value='{{teethImprint.digital}}'>Digitalni</option>
            </select>
            <div class='invalid-feedback text-danger' *ngIf="imprintFormControl.hasError('required')">
              Polje je obvezno!
            </div>
          </div>


          <div class='form-group' *ngIf='selectedPoas?.[0]?.patientAction?.isColorMandatory'>
            <label for='toothColorInput'>Barva zoba</label>
            <select id='toothColorInput'
                    [class]="'form-control ' + ( toothColorFormControl.touched && toothColorFormControl.hasError('required') ? 'is-invalid' : '')"
                    [formControl]='toothColorFormControl'>
              <option [value]='null'>Izberi barvo zoba...</option>
              <option *ngFor='let color of data.toothColors; let i = index'
                      [ngValue]='color.id'>{{ color?.name ?? '/' }}
              </option>
            </select>
            <div class='invalid-feedback text-danger' *ngIf="toothColorFormControl.hasError('required')">
              Polje je obvezno!
            </div>
          </div>
        </div>

        <mat-form-field class='comment'>
          <mat-label>Opomba</mat-label>
          <textarea matInput [formControl]='commentFormControl'></textarea>
        </mat-form-field>
      </ng-container>
    </div>
    <div class='actionButtons'>
      <button class='btn btn-primary' (click)='createNewPoa()'>
        Kreiraj nove storitve u terapiji
      </button>
      <div>
        <button class='back' mat-button [mat-dialog-close]='false'>Nazaj</button>
      </div>
      <div>
        <button class='edit' mat-button (click)='add()'>Spremeni</button>
      </div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>
