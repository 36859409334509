<mat-dialog-content>
  <div class='header'>
    <h2>Kreiranje {{ data.entity + 'a' }}</h2>
    <app-dropdown-search *ngIf='RECEIPT === data.type'
                         [(selected)]='selectedPoaFilter'
                         placeholder='Naziv'
                         [list]='allPoasDropdown'
                         [doNotShowError]='true'></app-dropdown-search>
    <button *ngIf='RECEIPT === data.type' class='btn btn-primary'
            (click)='addNewPatientOfferedAction()'>
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <form [formGroup]='form' (ngSubmit)='createBillAndCloseDialog()'>
    <div class='proforma-invoice-actions'>
      <div class='table-container'>
        <table *ngIf='patientOfferedActions'>
          <thead>
          <tr class='offered-actions'>
            <th scope='col'>Naziv</th>
            <th scope='col' class='margin-auto'>Količina</th>
            <th scope='col' class='margin-auto'>Cijena</th>
            <th scope='col' class='margin-auto'>Popust</th>
          </tr>
          </thead>

          <mat-divider></mat-divider>

          <tr class='offered-actions'>
            <td>Popust za storitve</td>
            <td></td>
            <td></td>
            <td style='width: 80%' class='margin-auto'>
              <mat-form-field class='discount-form-field'>
                <input matInput formControlName='discountForAll' type='number'>
                <mat-icon class='percent-suffix' matSuffix>{{ this.discountOptionControl.value }}</mat-icon>
              </mat-form-field>
            </td>
            <td class='margin-auto' style='margin-bottom: 0.5em; width: 80%'>
              <mat-form-field style='width: 100%'>
                <select matNativeControl required formControlName='discountOption'>
                  <option *ngFor='let option of DISCOUNT_OPTIONS' [value]='option.value'>{{ option?.symbol }}</option>
                </select>
              </mat-form-field>
            </td>
          </tr>

          <mat-divider></mat-divider>


          <ng-container *ngFor='let poaGroup of patientOfferedActions | groupPatientOfferedActions | keyvalue'>
            <tr class='font-weight-bold'>
              <td>
                {{ poaGroup.key }}
              </td>
            </tr>
            <ng-container *ngFor='let safi of poaGroup.value | formatActionsForInvoice: expandedSafiIds'>
              <tr class='offered-actions open'>
                <td class='margin-auto name'>
                  <button mat-icon-button type='button' (click)='expandCollapse(safi)'>
                    <mat-icon class='collapse-button'
                              [ngClass]="{'rotate': safi.isExpanded}"> keyboard_arrow_down
                    </mat-icon>
                  </button>
                  {{ safi.name }} <b>{{ ' (' + (safi.poas | poasToTeethName) + ')' }}</b>
                </td>
                <td class='margin-auto'>{{ safi.amount }}</td>
                <td class='margin-auto'>€ {{ safi.price | number: '.2-2': 'sl' }}</td>
                <td style='width: 80%' class='margin-auto'>
                  <mat-form-field class='discount-form-field'>
                    <input matInput [formControlName]='safi.id'
                           min='0' max='100' [type]="'number'">
                    <mat-icon class='percent-suffix' matSuffix>{{ this.discountOptionControl.value }}</mat-icon>
                  </mat-form-field>
                </td>
                <td>
                  <div class='amount-div'>
                    €  {{ ((getPriceForSinglePatientAction(safi.amount, safi.price, safi.id)).toFixed(2)  | number: '.2-2': 'sl') }}
                  </div>
                </td>
              </tr>
              <ng-container *ngIf='safi.poas?.length && safi.isExpanded'>
                <tr class='offered-actions open' *ngFor='let poa of (safi.poas | sortPoaOnTeeth)'>
                  <td class='margin-auto name'>
                    <button
                      type='button'
                      mat-icon-button
                      (click)='removePoa(poa?.id)'
                    >
                      <mat-icon>delete</mat-icon>
                    </button>
                    {{ safi.name }} <b>{{ ' (' + (poa?.teeth | teethName) + ')' }}</b></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>

          <mat-divider style='margin-top: 1em'></mat-divider>

          <tr class='offered-actions'>
            <td class='font-weight-bold'>Skupaj</td>
            <td></td>
            <td class='margin-auto'>
              <div class='font-weight-bold'>€ {{ patientOfferedActions | invoiceSum | number: '.2-2': 'sl' }}</div>
            </td>
            <td></td>
            <td>
              <div class='font-weight-bold amount-div'>€ {{ sumWithDiscount?.toFixed(2) | number: '.2-2': 'sl' }}</div>
            </td>
          </tr>

          <tr class='offered-actions offered-actions-rabat'>
            <td>Skupaj s rabatom</td>
            <td></td>
            <td style='justify-content: center; display: flex'>
              <mat-form-field style='width: 60%'>
                <input matInput type='number' class='discount' id='discount' min='0' max='100'
                       formControlName='discount'>
                <mat-icon class='percent-suffix' matSuffix>percent</mat-icon>
                <mat-error>Invalid</mat-error>
              </mat-form-field>
            <td></td>
            <td>
              <div class='amount-div'>€ {{ finalSum?.toFixed(2) | number: '.2-2': 'sl' }}</div>
            </td>
          </tr>

          <mat-divider></mat-divider>

          <div class='advances'>
            <tr class='offered-actions offered-actions-advance' *ngIf='PROFORMA_INVOICES !== data.type'>
              <td class='margin-auto name'>
                <p>Avansni račun</p>
                <mat-error *ngIf="advanceUsedControl.hasError('maxLimit'); else max">Vrednost ne sme biti večja od od
                  zneska računa
                </mat-error>
                <ng-template #max>
                  <mat-error *ngIf="advanceUsedControl.hasError('max')">Vrednost je večja od avansa</mat-error>
                </ng-template>
                <mat-error *ngIf="advanceUsedControl.hasError('min')">Vrednost ne sme biti manjša od 0</mat-error>
              </td>
              <td></td>
              <td style='justify-content: center; display: flex'>
                <button class='btn btn-light' type='button' style='margin-top: 0.5rem;' (click)='setMaxAdvance()'>Max
                  Avans
                </button>
              </td>
              <td></td>
              <td>
                <mat-form-field style='display: block' class='amount-div'>
                  <input matInput type='number'
                         class='advanceUsed' id='advanceUsed'
                         min='0' [max]='advanceAvailable'
                         formControlName='advanceUsed'>
                  <mat-icon class='euro' matPrefix>euro</mat-icon>
                </mat-form-field>
              </td>
            </tr>

            <tr class='extra-advance-checkbox' *ngIf='treatmentPlanAdvance'>
              <td>
                <mat-checkbox formControlName='treatmentPlanSelected'>
                  Iskoristi Celostni načrt zdravljenja
                </mat-checkbox>
              </td>
              <td>
                <div class='amount' *ngIf='trtPlanSelectedControl.value'>
                  €  {{ trtPlanUsed.value?.toFixed(2)  | number: '.2-2': 'sl' }}
                </div>
              </td>
            </tr>
            <tr class='extra-advance-checkbox' *ngIf='visualisationPreparationAdvance'>
              <td>
                <mat-checkbox formControlName='visualisationPreparationSelected'>
                  Iskoristi Priprava vizualizacije za terapijo z nevidnim zobnim aparatom
                </mat-checkbox>
              </td>
              <td>
                <div class='amount' *ngIf='visPrepSelectedControl.value'>
                  €  {{ visPrepUsed.value?.toFixed(2)  | number: '.2-2': 'sl' }}
                </div>
              </td>
            </tr>
          </div>

          <mat-divider></mat-divider>

          <tr class='offered-actions'>
            <td class='font-weight-bold'>Za plačilo</td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class='font-weight-bold amount-div'>
                € {{ finalSum?.toFixed(2) | subtractAdvancePaidAmount:totalAdvancesUsed() |  number: '.2-2': 'sl' }}
              </div>
            </td>
          </tr>

        </table>
      </div>
    </div>

    <div class='footer-form-controls payment-footer' style='margin-bottom: 1em;'>
      <mat-form-field>
        <mat-label>Lokacija</mat-label>
        <select matNativeControl required formControlName='receiptAddress'>
          <option *ngFor='let val of data.receiptAddresses' [ngValue]='val'>{{ val?.content }}</option>
        </select>
        <mat-error>Polje je obavezno</mat-error>
      </mat-form-field>
      <ng-container *ngIf='RECEIPT === data.type'>
        <mat-form-field>
          <mat-label>Način plačila</mat-label>
          <select matNativeControl required formControlName='paymentMethod' #paymentSelect>
            <option *ngFor='let method of relevantPaymentMethods' [value]='method.id'>{{ method.name }}</option>
          </select>
          <mat-error>Polje je obavezno</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Datum plačila</mat-label>
          <input matInput required [min]='today' [matDatepicker]='picker' formControlName='dueDate' />
          <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="getDueDateControl().hasError('required'); else dateInPast">Polje je obavezno</mat-error>
          <ng-template #dateInPast>
            <mat-error *ngIf="getDueDateControl().hasError('matDatepickerMin')">
              Datum mora biti u budućnosti
            </mat-error>
          </ng-template>
        </mat-form-field>
      </ng-container>
    </div>

    <div class='footer-form-controls identification-footer' *ngIf='showAdditionalFields'>
      <mat-form-field class='holiday-title' *ngIf='clientIdentificationNeeded'>
        <mat-label>EMŠO</mat-label>
        <input matInput required
               formControlName='uniqueCitizenNumber'
               type='number' maxlength='13' minlength='13'>
        <mat-error *ngIf="getUniqueCitizenNumberControl().hasError('required'); else digitLengthUcnError">Polje je
          obavezno
        </mat-error>
        <ng-template #digitLengthUcnError>
          <mat-error *ngIf="getUniqueCitizenNumberControl().hasError('digitLength')">EMŠO treba da ima 13 brojeva
          </mat-error>
        </ng-template>
      </mat-form-field>

      <mat-form-field class='holiday-title' *ngIf='clientIdentificationNeeded'>
        <mat-label>Davčno številko</mat-label>
        <input matInput required
               formControlName='taxNumber'
               type='number' maxlength='8' minlength='8'>
        <mat-error *ngIf="getTaxNumberControl().hasError('required'); else digitLengthTaxError">Polje je obavezno
        </mat-error>
        <ng-template #digitLengthTaxError>
          <mat-error *ngIf="getTaxNumberControl().hasError('digitLength')">Davčno številko treba da ima 8 brojeva
          </mat-error>
        </ng-template>
      </mat-form-field>

      <mat-checkbox *ngIf='isUserAdmin'
                    class='example-margin' style='width: fit-content'
                    (click)='noClientIdentificationNeeded()'>
        Bez Davčne i EMŠO
      </mat-checkbox>
    </div>


    <div id='actionButtons'>
      <div></div>
      <div>
        <button class='btn btn-light' id='cancel' [mat-dialog-close]='undefined'>Prekliči</button>
      </div>
      <div>
        <button [disabled]='!patientOfferedActions?.length' class='btn btn-primary' id='add' type='submit'>Kreiraj {{ data.entity }}</button>
      </div>
    </div>

    <br/>

  </form>
</mat-dialog-content>
