import { Pipe, PipeTransform } from '@angular/core';
import {
  PatientOfferedAction,
  PatientOfferedActionStatus,
} from '../models/patient-offered-action.model';

@Pipe({
  name: 'isActiveOfferClosable',
})
export class IsActiveTherapyClosablePipe implements PipeTransform {
  transform(offeredActions: PatientOfferedAction[]): boolean {
    return !offeredActions?.some(
      (oa) => oa.status === PatientOfferedActionStatus.open
    );
  }
}
