<mat-card matRipple [matRippleDisabled]="!isClickable"  class="card" [ngClass]="{'card__clickable': isClickable}" appearance="outlined">
  <mat-card-header *ngIf="showHeader">
    <mat-card-title>{{title}}</mat-card-title>
  </mat-card-header>
  <hr *ngIf="showHeader"/>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-footer class="card__footer">
  </mat-card-footer>
</mat-card>
