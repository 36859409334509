import { Patient } from './patient.model';
import { ClinicLocation } from './clinic-location.model';
import { Doctor } from './doctor.model';
import { AppointmentType } from './appointment-type.model';
import { AppointmentStatus } from './appointment-status.model';
import { Comment } from './comment.model';
import { PatientOfferedAction } from './patient-offered-action.model';

export class Appointment {
  public id: number;
  public patientId: number;
  public patient: Patient;
  public doctorId: number;
  public doctor: Doctor;
  public clinicLocationId: number;
  public clinicLocation: ClinicLocation;
  public startDateTime: Date;
  public duration: number;
  public remark: string;
  public appointmentStatusId: number;
  public appointmentStatus: AppointmentStatus;
  public appointmentTypeId: number;
  public appointmentType: AppointmentType;
  public clinicId: number;
  public createdAt: Date;
  public appointmentHasComment: AppointmentHasComment[];
  public confirmationHash: string;
  public patientOfferedActions?: PatientOfferedAction[];
}

export class AppointmentHasComment {
  id: number;
  appointmentId: number;
  commentId: number;
  appointment?: Appointment;
  comment?: Comment;
}
