import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '../../models/appointment.model';

@Pipe({
  name: 'countAppointments',
})
export class CountAppointmentsPipe implements PipeTransform {
  transform(appointments: Appointment[], statusId?: number): number {
    return appointments.filter((a) =>
      statusId ? a.appointmentStatusId === statusId : true
    ).length;
  }
}
