<mat-dialog-content>
  <div class='container'>

    <h2>{{ action.patientAction?.name }}</h2>

    <div class='content'>
      <form [formGroup]='form'>
        <div *ngFor='let price of action.technicianPrices' class='price'>
          <b style='margin: auto auto auto 0;'>{{ price?.name }}</b>
          <mat-form-field
            style='margin: auto 0 auto 0;'

          >
            <mat-label>{{ price.name }}</mat-label>
            <input type="number" step="0.01" min="0"
                   matInput
                   formControlName='{{price.technicianId}}-price'
            >
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class='actionButtons'>
      <div></div>
      <div>
        <button class='back' mat-button [mat-dialog-close]='false'>Nazaj</button>
      </div>
      <div>
        <button class='edit' mat-button (click)='update()'>Spremeni</button>
      </div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>