import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserInfoService } from '../../services/api-services/user-info.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../services/other-services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-update-password-form-dialog',
  templateUrl: './update-password-form-dialog.component.html',
  styleUrls: ['./update-password-form-dialog.component.scss'],
})
export class UpdatePasswordFormDialogComponent implements OnInit {
  public loading: boolean;

  public oldPasswordFormControl: UntypedFormControl;
  public newPasswordFormControl: UntypedFormControl;
  public repeatNewPasswordFormControl: UntypedFormControl;

  public oldPasswordHide: boolean;
  public newPasswordHide: boolean;
  public repeatNewPasswordHide: boolean;

  constructor(
    private dialogRef: MatDialogRef<UpdatePasswordFormDialogComponent>,
    private userInfoService: UserInfoService,
    private toast: ToastService
  ) {
    this.loading = false;

    this.oldPasswordFormControl = new UntypedFormControl('', Validators.required);
    this.newPasswordFormControl = new UntypedFormControl('', Validators.required);
    this.repeatNewPasswordFormControl = new UntypedFormControl(
      '',
      Validators.required
    );

    this.oldPasswordHide = true;
    this.newPasswordHide = true;
    this.repeatNewPasswordHide = true;

    this.repeatNewPasswordFormControl.valueChanges.subscribe((val) => {
      if (this.newPasswordFormControl.value !== val) {
        this.repeatNewPasswordFormControl.setErrors({ notEqual: true });
      } else {
        this.repeatNewPasswordFormControl.setErrors(null);
      }
    });
  }

  ngOnInit(): void {}

  public save(): void {
    this.oldPasswordFormControl.markAllAsTouched();
    this.newPasswordFormControl.markAllAsTouched();
    this.repeatNewPasswordFormControl.markAllAsTouched();
    if (
      !this.oldPasswordFormControl.valid ||
      !this.newPasswordFormControl.valid ||
      !this.repeatNewPasswordFormControl.valid
    ) {
      this.oldPasswordFormControl.markAsTouched();
      this.newPasswordFormControl.markAsTouched();
      this.repeatNewPasswordFormControl.markAsTouched();
      return;
    }

    if (
      this.newPasswordFormControl.value !==
      this.repeatNewPasswordFormControl.value
    ) {
      this.repeatNewPasswordFormControl.setErrors({ notEqual: true });
      return;
    } else {
      this.repeatNewPasswordFormControl.setErrors(null);
    }

    this.loading = true;

    this.userInfoService
      .updateMyPassword(
        this.oldPasswordFormControl.value,
        this.newPasswordFormControl.value
      )
      .subscribe(
        () => {
          this.toast.success('Geslo je bilo posodobljeno!');
          this.dialogRef.close();
        },
        (e: HttpErrorResponse) => {
          if (e.status === 401) {
            this.toast.error('Staro geslo je bilo napačno!');
          }
          this.loading = false;
        }
      );
  }
}
