import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientCall } from '../../models/patient-call.model';
import { PatientCallStatusService } from '../../services/api-services/patient-call-status.service';
import { PatientCallStatus } from '../../models/patient-call-status.model';
import { ClinicLocation } from '../../models/clinic-location.model';
import { ClinicService } from '../../services/api-services/clinic.service';
import { LocalStorageService } from '../../services/api-services/local-storage.service';
import { Admin } from '../../models/admin.model';
import { Agent } from '../../models/agent.model';
import { Doctor } from '../../models/doctor.model';
import { Assistant } from '../../models/assistant.model';
import { PartOfDay } from '../../models/paty-of-day.model';
import { PartOfDayService } from '../../services/api-services/part-of-day.service';
import { SetFormDateService } from '../../services/other-services/set-form-date.service';

@Component({
  selector: 'app-patient-call-form-dialog',
  templateUrl: './patient-call-form-dialog.component.html',
  styleUrls: ['./patient-call-form-dialog.component.scss'],
})
export class PatientCallFormDialogComponent implements OnInit {
  public title: string;
  public actionText: string;
  public clinicLocations: ClinicLocation[];

  public patientCallForm: FormGroup;

  public admins: Admin[];
  public agents: Agent[];
  public doctors: Doctor[];
  public assistants: Assistant[];

  constructor(
    private dialogRef: MatDialogRef<PatientCallFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientCall: PatientCall;
      partsOfDay: PartOfDay[];
      clinicLocations: ClinicLocation[];
      patientCallStatuses: PatientCallStatus[];
    },
    private patientCallStatusService: PatientCallStatusService,
    private clinicService: ClinicService,
    private storageService: LocalStorageService,
    private partOfDayService: PartOfDayService,
    private setFormDateService: SetFormDateService,
    private fb: FormBuilder
  ) {
    this.title = this.data.patientCall
      ? 'Spreminjanje klica'
      : 'Dodajanje klica';

    this.actionText = this.data.patientCall ? 'Shrani' : 'Dodaj';

    this.patientCallForm = this.fb.group({
      authorUserInfo: new UntypedFormControl('', Validators.required),
      partOfDay: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      status: new UntypedFormControl('', Validators.required),
      clinicLocation: new UntypedFormControl('', Validators.required),
      content: new UntypedFormControl(''),
      isUrgent: new UntypedFormControl('', Validators.required),
    });
  }

  public ngOnInit(): void {
    if (this.data.patientCall) {
      const orderDate = new Date(this.data.patientCall.date);
      this.patientCallForm
        .get('date')
        .setValue(this.setFormDateService.getDateString(orderDate));
      this.patientCallForm
        .get('content')
        .setValue(this.data.patientCall.content);
      this.patientCallForm
        .get('authorUserInfo')
        .setValue(this.data.patientCall.authorUserInfoId);
      this.patientCallForm
        .get('status')
        .setValue(this.data.patientCall.patientCallStatusId);
      this.patientCallForm
        .get('clinicLocation')
        .setValue(this.data.patientCall.clinicLocationId);
      this.patientCallForm
        .get('partOfDay')
        .setValue(this.data.patientCall.partOfDayId);
      this.patientCallForm
        .get('isUrgent')
        .setValue(this.data.patientCall.isUrgent);
    } else {
      this.patientCallForm
        .get('date')
        .setValue(this.setFormDateService.getDateString(new Date()));
      this.patientCallForm.get('partOfDay').setValue(6);
      this.patientCallForm.get('isUrgent').setValue(false);
      this.patientCallForm
        .get('authorUserInfo')
        .setValue(this.storageService.getUserId());
      this.patientCallForm.get('status').setValue(3);
      this.patientCallForm.get('clinicLocation').setValue(7);
    }
  }

  saveAndAddNew(): void {
    this.saveAndCloseDialog(true);
  }

  save(): void {
    this.saveAndCloseDialog(false);
  }

  private saveAndCloseDialog(shouldAddNew: boolean): void {
    this.patientCallForm.markAllAsTouched();
    if (
      !this.patientCallForm.valid ||
      this.patientCallForm.get('clinicLocation').value === -1 ||
      this.patientCallForm.get('status').value === -1 ||
      this.patientCallForm.get('partOfDay').value === -1
    ) {
      return;
    }

    let patientCall = new PatientCall();

    if (this.data.patientCall) {
      patientCall = this.data.patientCall;
    }

    patientCall.patientCallStatusId = parseInt(
      this.patientCallForm.get('status').value,
      10
    );
    patientCall.clinicLocationId = parseInt(
      this.patientCallForm.get('clinicLocation').value,
      10
    );
    patientCall.date = this.patientCallForm.get('date').value;
    patientCall.content = this.patientCallForm.get('content').value;
    patientCall.authorUserInfoId = parseInt(
      this.patientCallForm.get('authorUserInfo').value,
      10
    );
    patientCall.partOfDayId = parseInt(
      this.patientCallForm.get('partOfDay').value,
      10
    );
    patientCall.isUrgent =
      this.patientCallForm.get('isUrgent').value === 'true';

    this.dialogRef.close({ patientCall, shouldAddNew });
  }
}
