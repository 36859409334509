import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/api-services/local-storage.service';
import { Patient } from '../../models/patient.model';
import { Router } from '@angular/router';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ToastService } from '../../services/other-services/toast.service';
import { OpenPatientsService } from '../../services/api-services/open-patients.service';

@Component({
  selector: 'app-open-patients-view',
  templateUrl: './open-patients-view.component.html',
  styleUrls: ['./open-patients-view.component.scss'],
})
export class OpenPatientsViewComponent implements OnInit {
  public patients: Patient[];

  constructor(
    private bottomSheetRef: MatBottomSheetRef<OpenPatientsViewComponent>,
    private storageService: LocalStorageService,
    private router: Router,
    private toast: ToastService,
    private openPatientsService: OpenPatientsService
  ) {}

  ngOnInit(): void {
    const patients = this.storageService.getOpenPatients();

    this.patients = patients ? patients : [];
  }

  public openPatient(patientId: number): void {
    this.router
      .navigate([`/${this.storageService.getUserType().toLowerCase()}/patients/${patientId}/contact-info`])
      .then();
    this.bottomSheetRef.dismiss();
  }

  public removePatient(patientId: number): void {
    let openPatients = this.storageService.getOpenPatients();
    openPatients = openPatients ? openPatients : [];

    openPatients.splice(
      openPatients.findIndex((p) => p.id === patientId),
      1
    );

    this.storageService.setOpenPatients(openPatients);
    this.toast.success('Pacient je odpet!');
    this.openPatientsService.triggerNumberOfOpenPatientsChanged(
      openPatients.length
    );

    this.patients.splice(
      this.patients.findIndex((p) => p.id === patientId),
      1
    );
  }
}
