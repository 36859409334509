<mat-dialog-content>
  <h3>Uredi</h3>

  <p>{{ msg }}</p>

  <div id="btnGroup">
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" [mat-dialog-close]="EDIT_RECURRING_SWT.DELETE">Izbrisati sve</button>
    </div>
    <div>
      <button class="btn btn-primary" [mat-dialog-close]="EDIT_RECURRING_SWT.ADD_EXCEPTION">Izbrisati samo za {{data?.eventDate | date: 'dd.MM.yyyy'}}</button>
    </div>
    <div>
      <button class="btn btn-primary" [mat-dialog-close]="EDIT_RECURRING_SWT.ADD_END_DATE">Konec {{data?.eventDate | date: 'dd.MM.yyyy'}}</button>
    </div>
  </div>
</mat-dialog-content>
