<ng-container *ngIf='notifications'>
  <button class='notifications-button__new' *ngIf='hasUnopenedNotification; else normalNotificationsButton' mat-button [matMenuTriggerFor]="notificationMenu"><mat-icon>notifications_active</mat-icon></button>
  <ng-template #normalNotificationsButton>
    <button class='notifications-button' mat-button [matMenuTriggerFor]="notificationMenu"><mat-icon>notifications</mat-icon></button>
  </ng-template>
  <mat-menu class='notification-window' #notificationMenu="matMenu">
    <button [class]="'notification'"
            [ngClass]='{ notification__unseen: !notification?.seen }' mat-menu-item *ngFor='let notification of notifications' (click)='clickOnNotification(notification)'><mat-icon *ngIf='notification?.redirectLink'>launch</mat-icon>{{notification?.message}}</button>
    <ng-container *ngIf='notificationsPage'>
      <button class='notification__load-more' mat-menu-item *ngIf='notifications?.length === notificationsPage * NOTIFICATION_PAGE_AMOUNT' (click)='$event.stopPropagation(); loadMoreNotifications(notifications?.length, notificationsPage)'>Load more...</button>
    </ng-container>
    <div *ngIf='!notifications?.length'>
      No notifications
    </div>
  </mat-menu>
</ng-container>
