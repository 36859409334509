import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { PatientOfferedAction } from '../../models/patient-offered-action.model';

@Component({
  selector: 'app-edit-appointment-action-dialog',
  templateUrl: './edit-appointment-action-dialog.component.html',
  styleUrls: ['./edit-appointment-action-dialog.component.scss'],
})
export class EditAppointmentActionDialogComponent {
  commentForm = new FormControl<string>('');
  toothPlaneForm = new FormControl<string>('');
  constructor(
    private dialogRef: MatDialogRef<EditAppointmentActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      poa: PatientOfferedAction;
    }
  ) {
    this.commentForm.setValue(this.data.poa?.appointmentComment ?? '');
    this.toothPlaneForm.setValue(this.data.poa?.appointmentToothPlane ?? '');
  }

  public update(): void {
    this.dialogRef.close({
      comment: this.commentForm.value?.trim(),
      toothPlane: this.toothPlaneForm.value?.trim(),
    });
  }
}
