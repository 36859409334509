import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from '../../models/patient.model';
import { Agent } from '../../models/agent.model';
import { Media } from '../../models/media.model';
import { PatientCategory } from '../../models/patient-category.model';
import { ToastService } from '../../services/other-services/toast.service';
import { COUNTRIES } from '../../utils/constants/countries';
import {
  SLOVENIAN_PLZ_CITY_MAP,
  SLOVENIAN_PLZ_DROPDOWN,
} from '../../utils/constants/slovenia-cities';
import { DropdownItem } from '../dropdown-search/dropdown-search.component';

@Component({
  selector: 'app-patient-form-dialog',
  templateUrl: './patient-form-dialog.component.html',
  styleUrls: ['./patient-form-dialog.component.scss'],
})
export class PatientFormDialogComponent implements OnInit {
  SLOVENIAN_PLZ_DROPDOWN = SLOVENIAN_PLZ_DROPDOWN;
  COUNTRIES = COUNTRIES;
  public title: string;
  public actionText: string;
  public dropdownPostCode: DropdownItem;

  public patientForm: FormGroup;
  dropdownMarkAsTouched = false;
  constructor(
    private dialogRef: MatDialogRef<PatientFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patient: Patient;
      agents: Agent[];
      medias: Media[];
      patientCategories: PatientCategory[];
    },
    private fb: FormBuilder,
    private toastService: ToastService,
  ) {
    this.title = this.data.patient
      ? 'Spreminjanje pacienta'
      : 'Dodajanje pacienta';

    this.actionText = this.data.patient ? 'Shrani' : 'Dodaj';

    this.patientForm = this.fb.group({
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      address: new FormControl(),
      country: new FormControl('Slovenia', Validators.required),
      city: new FormControl(),
      postCode: new FormControl(),
      phone: new FormControl(),
      email: new FormControl('', [Validators.email]),
      birthday: new FormControl(''),
      creationDate: new FormControl(''),
      gender: new FormControl(''),
      agent: new FormControl(''),
      media: new FormControl(''),
      patientCategory: new FormControl(''),
      idCardNumber: new FormControl(''),
      canCall: new FormControl(''),
      minimaxCode: new FormControl(''),
    });
  }

  public ngOnInit(): void {
    this.patientForm.get('gender').setValue('M');

    if (this.data.patient) {
      this.patientForm.get('name').setValue(this.data.patient.name);
      this.patientForm.get('lastName').setValue(this.data.patient.lastName);
      this.patientForm.get('address').setValue(this.data.patient.address);
      this.patientForm.get('postCode').setValue(this.data.patient.postCode);
      this.patientForm.get('city').setValue(this.data.patient.city);
      this.patientForm.get('email').setValue(this.data.patient.email);
      this.patientForm.get('phone').setValue(this.data.patient.phone);
      if (this.data.patient.country) {
        this.patientForm.get('country').setValue(this.data.patient.country);
      }
      if (
        this.patientForm.get('country').value === 'Slovenia' &&
        this.data.patient.postCode
      ) {
        this.dropdownPostCode = {
          id: this.data.patient.postCode,
          name: `${this.data.patient.postCode} ${
            SLOVENIAN_PLZ_CITY_MAP[this.data.patient.postCode]
          }`,
        };
      }

      if (this.data.patient.birthday) {
        const orderDate = new Date(this.data.patient.birthday);
        const month = orderDate.getMonth() + 1;
        const monthTens = Math.floor(month / 10);
        const date = orderDate.getDate();
        const dateTens = Math.floor(date / 10);
        const asStr =
          orderDate.getFullYear() +
          '-' +
          monthTens.toString() +
          (month - monthTens * 10) +
          '-' +
          dateTens.toString() +
          (date - dateTens * 10);
        this.patientForm.get('birthday').setValue(asStr);
      }

      const orderDate2 = new Date(this.data.patient.creationDate ?? new Date());
      const month2 = orderDate2.getMonth() + 1;
      const monthTens2 = Math.floor(month2 / 10);
      const date2 = orderDate2.getDate();
      const dateTens2 = Math.floor(date2 / 10);
      const asStr2 =
        orderDate2.getFullYear() +
        '-' +
        monthTens2.toString() +
        (month2 - monthTens2 * 10) +
        '-' +
        dateTens2.toString() +
        (date2 - dateTens2 * 10);
      this.patientForm.get('creationDate').setValue(asStr2);

      this.patientForm.get('canCall').setValue(this.data.patient.canCall);
      if (this.data.patient.id) {
        this.patientForm.get('gender').setValue(this.data.patient.gender);
      }
      this.patientForm
        .get('idCardNumber')
        .setValue(this.data.patient.idCardNumber);
      this.patientForm
        .get('agent')
        .setValue(
          !this.data.patient.agentId || isNaN(this.data.patient.agentId)
            ? ''
            : this.data.patient.agentId,
        );
      this.patientForm
        .get('media')
        .setValue(
          !this.data.patient.mediaId || isNaN(this.data.patient.mediaId)
            ? ''
            : this.data.patient.mediaId,
        );
      this.patientForm
        .get('patientCategory')
        .setValue(
          !this.data.patient.patientCategoryId ||
            isNaN(this.data.patient.patientCategoryId)
            ? ''
            : this.data.patient.patientCategoryId,
        );
    } else {
      const orderDate = new Date(new Date());
      const month = orderDate.getMonth() + 1;
      const monthTens = Math.floor(month / 10);
      const date = orderDate.getDate();
      const dateTens = Math.floor(date / 10);
      const asStr =
        orderDate.getFullYear() +
        '-' +
        monthTens.toString() +
        (month - monthTens * 10) +
        '-' +
        dateTens.toString() +
        (date - dateTens * 10);
      this.patientForm.get('creationDate').setValue(asStr);

      this.patientForm.get('canCall').setValue(true);
    }
  }

  public save(): void {
    this.dropdownMarkAsTouched = true;
    this.patientForm.markAllAsTouched();
    if (!this.patientForm.valid) {
      return;
    }
    if (
      this.patientForm.get('address').value === null &&
      this.patientForm.get('country').value === null &&
      this.patientForm.get('phone').value === null &&
      this.patientForm.get('email').value === ''
    ) {
      this.toastService.warning(
        'Vnesite telefonsko številko, email, državo ali naslov pacienta.',
        'Obrazec potrebuje vsaj en kontaktni podatek',
        6000,
      );
      return;
    }

    let patient = new Patient();

    if (this.data.patient && this.data.patient.id) {
      patient = this.data.patient;
    }

    patient.name = this.patientForm.get('name').value;
    patient.name =
      patient.name[0].toUpperCase() +
      patient.name.substring(1, patient.name.length);
    patient.lastName = this.patientForm.get('lastName').value;
    patient.lastName =
      patient.lastName[0].toUpperCase() +
      patient.lastName.substring(1, patient.lastName.length);
    patient.country = this.patientForm.get('country').value;
    if (patient.country === 'Slovenia') {
      patient.postCode = this.dropdownPostCode?.id.toString();
      patient.city = SLOVENIAN_PLZ_CITY_MAP[this.dropdownPostCode?.id];
    } else {
      patient.city = this.patientForm.get('city').value;
      patient.postCode = this.patientForm.get('postCode').value;
    }
    patient.address = this.patientForm.get('address').value
      ? this.patientForm.get('address').value
      : null;
    patient.email = this.patientForm.get('email').value
      ? this.patientForm.get('email').value
      : null;
    patient.phone = this.patientForm.get('phone').value
      ? this.patientForm.get('phone').value
      : null;
    patient.birthday = this.patientForm.get('birthday').value
      ? this.patientForm.get('birthday').value
      : null;
    patient.creationDate = this.patientForm.get('creationDate').value
      ? this.patientForm.get('creationDate').value
      : null;
    patient.gender = this.patientForm.get('gender').value;
    const agentId = parseInt(this.patientForm.get('agent').value, 10);
    patient.agentId = agentId;
    patient.agent = this.data.agents.find((a) => a.id === agentId);
    const mediaId = parseInt(this.patientForm.get('media').value, 10);
    patient.mediaId = mediaId;
    patient.media = this.data.medias.find((m) => m.id === mediaId);
    const patientCategoryId = parseInt(
      this.patientForm.get('patientCategory').value,
      10,
    );
    patient.patientCategoryId = patientCategoryId;
    patient.patientCategory = this.data.patientCategories.find(
      (c) => c.id === patientCategoryId,
    );
    patient.idCardNumber = this.patientForm.get('idCardNumber').value
      ? this.patientForm.get('idCardNumber').value
      : null;

    patient.canCall = this.patientForm.get('canCall').value === true;
    patient.minimaxCode = this.patientForm.get('minimaxCode').value;

    this.dialogRef.close(patient);
  }
}
