import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActionTypeDoctorPriceType } from '../../models/action-type-doctor-price-type.model';

@Injectable({
  providedIn: 'root',
})
export class ActionTypeDoctorPriceTypeService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/action-type-doctor-price-type';
  }

  public add(
    actionTypeDoctorPriceType: ActionTypeDoctorPriceType
  ): Observable<number> {
    return this.http.post<number>(this.apiUrl, actionTypeDoctorPriceType);
  }

  public update(
    actionTypeDoctorPriceType: ActionTypeDoctorPriceType
  ): Observable<ActionTypeDoctorPriceType> {
    return this.http.patch<ActionTypeDoctorPriceType>(
      this.apiUrl,
      actionTypeDoctorPriceType
    );
  }

  public deleteMany(
    actionTypeDoctorPriceTypeIds: number[]
  ): Observable<boolean> {
    let parameters = '?';
    actionTypeDoctorPriceTypeIds.forEach((a, i) => {
      parameters += 'actionTypeDoctorPriceTypeIds=' + a;
      if (i !== actionTypeDoctorPriceTypeIds.length - 1) {
        parameters += '&';
      }
    });
    return this.http.delete<boolean>(this.apiUrl + '/many' + parameters);
  }

  public getByClinicId(
    clinicId: number
  ): Observable<ActionTypeDoctorPriceType[]> {
    return this.http.get<ActionTypeDoctorPriceType[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}`
    );
  }
}
