import { Pipe, PipeTransform } from '@angular/core';
import { DoctorAnalyticsOverview } from '../../models/action-type-doctor-price.model';

@Pipe({
  name: 'sumDoctorCosts',
})
export class SumDoctorCostsPipe implements PipeTransform {
  transform(doctorAnalyticsOverviews: DoctorAnalyticsOverview[]): number {
    return doctorAnalyticsOverviews
      .map((a) => a.totalPrice)
      .reduce((totalPrice, price) => totalPrice + price, 0);
  }
}
