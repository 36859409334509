<mat-dialog-content>
  <h2>{{ title }}</h2>

  <form [formGroup]="commentForm">
    <div class="form-group">
      <label for="contentInput">Vsebina</label>
      <textarea id="contentInput" class="form-control" formControlName="content" placeholder="Vsebina"></textarea>
    </div>
    <div class="double">
      <label class="form-check-label" for="isSummaryInput">Shrani v povzetek</label>
      <input type="checkbox" class="checkbox" id="isSummaryInput" formControlName="isSummary">
    </div>
  </form>

  <div id="actionButtons">
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" (click)="save()">{{ actionText }}</button>
    </div>
  </div>
</mat-dialog-content>