<mat-dialog-content>
  <main>
    <div id="toolbar">
      <h4>Išči pacienta</h4>
      <div>
        <div class="form-group">
          <input type="search"
                 autocomplete="off"
                 class="form-control"
                 (keydown)="searchOnKeyPressed($event)"
                 id="searchInput" placeholder="Ime" [formControl]="searchFormControl">
        </div>
        <button class="btn btn-light" id="search" (click)="search()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>

    <div id="patientsTable" class="unselectable">
      <div class="patient-card head">
        <div>Ime</div>
        <div>Naslov</div>
        <div>Telefon</div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div id="patientsList">
        <div *ngFor="let patient of patients; let i = index">
          <div
            [class]="'patient-card ' + (i % 2 !== 0 ? 'odd' : '')"
            (click)="selectPatient(patient)"
          >
            <div class='data'>{{ patient.name + ' ' + patient.lastName }}</div>
            <div class='data'>{{ patient.address }}</div>
            <div class='data'>{{ patient.phone }}</div>
          </div>
        </div>

        <div
          id="loadingContainer"
          *ngIf="loading"
        >
          <mat-spinner></mat-spinner>
        </div>


        <div class="btn-container">
          <button
            class="btn btn-primary"
            (click)="loadMore()"
            style="margin-bottom: 24px"
            *ngIf="patients.length !== 0 && patients.length % perPage === 0"
          >
            Naloži več rezultatov
          </button>
        </div>
      </div>
    </div>
  </main>
  <div id="actionButtons">
    <button mat-button [mat-dialog-close]="false">Prekliči</button>
  </div>
</mat-dialog-content>