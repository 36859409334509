import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedActionStatus } from '../models/patient-offered-action.model';
import { ProformaInvoiceAction } from '../models/proforma-invoice.model';

@Pipe({
  name: 'canCreateInvoice',
})
export class CanCreateInvoicePipe implements PipeTransform {
  transform(proformaInvoiceActions: ProformaInvoiceAction[]): boolean {
    return !proformaInvoiceActions.some(
      (pia) =>
        pia.patientOfferedAction?.status !== PatientOfferedActionStatus.open
    );
  }
}
