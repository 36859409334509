import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '../../services/other-services/toast.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-comment-confirmation-dialog',
  templateUrl: './comment-confirmation-dialog.component.html',
  styleUrls: ['./comment-confirmation-dialog.component.scss'],
})
export class CommentConfirmationDialogComponent {
  commentForm = new FormControl<string>('', Validators.required);
  constructor(
    private dialogRef: MatDialogRef<CommentConfirmationDialogComponent>,
    private toastService: ToastService
  ) {}

  public cancel(): void {
    if (this.commentForm.invalid) {
      this.toastService.warning('Morate vnesti opombo!');
      return;
    }
    this.dialogRef.close(this.commentForm.value?.trim());
  }
}
