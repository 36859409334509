export class UserInfo {
  public id?: number;
  public name: string;
  public lastName: string;
  public email: string;
  public type?: string;
  public phone: string;
  public password: string;
  public color: string;
  public operatorTaxNumber: string;
}
