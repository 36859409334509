import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Admin } from '../../models/admin.model';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/admin';
  }

  public add(admin: User): Observable<number> {
    return this.http.post<number>(this.apiUrl, admin);
  }

  public getMyClinicId(): Observable<number> {
    return this.http.get<number>(this.apiUrl + `/my-clinic-id`);
  }

  public update(admin: User): Observable<Admin> {
    return this.http.patch<Admin>(this.apiUrl, admin);
  }

  public delete(adminId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${adminId}`);
  }

  public getByClinicId(clinicId: number): Observable<Admin[]> {
    return this.http.get<Admin[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}`
    );
  }
}
