import { Pipe, PipeTransform } from '@angular/core';
import {
  PatientOfferedAction,
  PoaAppointmentView,
} from '../models/patient-offered-action.model';

@Pipe({
  name: 'poaAppointmentView',
})
export class PoaAppointmentViewPipe implements PipeTransform {
  transform(poas: PatientOfferedAction[]): PoaAppointmentView[] {
    const result: PoaAppointmentView[] = [];
    const resultMap = new Map<number, PatientOfferedAction[]>();
    poas.forEach((poa) => {
      if (resultMap.has(poa?.patientActionId)) {
        resultMap.get(poa?.patientActionId).push(poa);
      } else {
        resultMap.set(poa?.patientActionId, [poa]);
      }
    });

    resultMap.forEach((actions, actionId) => {
      const actionsToGroup = actions.filter(
        (a) =>
          (!a.appointmentComment || a.appointmentComment.trim() === '') &&
          (!a.appointmentToothPlane || a.appointmentToothPlane.trim() === '')
      );
      const actionsToBeAlone = actions.filter(
        (a) =>
          (a.appointmentComment && a.appointmentComment.trim() !== '') ||
          (a.appointmentToothPlane && a.appointmentToothPlane.trim() !== '')
      );
      if (actionsToGroup?.length) {
        const poaAppointmentView = {
          appointmentComment: actionsToGroup?.[0]?.appointmentComment,
          appointmentToothPlane: actionsToGroup?.[0]?.appointmentToothPlane,
          teeth: [],
          patientActionName: actionsToGroup?.[0]?.patientAction?.name,
          patientActionId: actionId,
        };
        poaAppointmentView.teeth.push(...actionsToGroup.map((a) => a.teeth));
        result.push(poaAppointmentView);
      }

      result.push(
        ...actionsToBeAlone.map((atba) => ({
          appointmentComment: atba?.appointmentComment,
          appointmentToothPlane: atba?.appointmentToothPlane,
          teeth: [atba?.teeth],
          patientActionName: atba?.patientAction?.name,
          patientActionId: actionId,
        }))
      );
    });
    return result;
  }
}
