import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DoctorService } from '../../services/api-services/doctor.service';
import { Appointment } from '../../models/appointment.model';
import { ClinicLocationService } from '../../services/api-services/clinic-location.service';
import { combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { filterUserInfo } from '../../utils/calendar-working-hours.utils';

@Component({
  selector: 'app-create-appointment-without-termin-dialog',
  templateUrl: './create-appointment-without-termin-dialog.component.html',
  styleUrls: ['./create-appointment-without-termin-dialog.component.scss'],
})
export class CreateAppointmentWithoutTerminDialogComponent {
  fg = this.fb.group({
    doctorId: new UntypedFormControl(undefined, Validators.required),
    doctorFilter: new FormControl<string | null>(null),
    locationId: new UntypedFormControl(undefined, Validators.required),
    duration: new UntypedFormControl(15, Validators.required),
    remark: new UntypedFormControl(''),
  });
  public minuteOptions: number[] = [];
  readonly doctors$ = combineLatest([
    this.doctorService.getByClinicId(this.data.clinicId),
    this.fg.controls.doctorFilter.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([doctors, filterValue]) =>
      doctors.filter((doctor) => filterUserInfo(doctor?.userInfo, filterValue))
    )
  );
  readonly locations$ = this.clinicLocationService.getByClinicId(
    this.data.clinicId
  );
  constructor(
    private doctorService: DoctorService,
    private clinicLocationService: ClinicLocationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clinicId: number;
      patientId: number;
      appointmentTypeId: number;
    },
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<
      CreateAppointmentWithoutTerminDialogComponent
    >
  ) {
    for (let i = 0; i < 24; ++i) {
      this.minuteOptions.push((i + 1) * 15);
      if ((i * 15) % 60 === 0) {
        this.minuteOptions.push(i * 15 + 20);
      }
    }
  }

  public save(): void {
    if (this.fg.invalid) {
      return;
    }
    const appointment = new Appointment();
    appointment.appointmentStatusId = 1; // naročen
    appointment.clinicLocationId = this.fg.controls.locationId.value;
    appointment.doctorId = this.fg.controls.doctorId.value;
    appointment.patientId = this.data.patientId;
    appointment.appointmentTypeId = this.data.appointmentTypeId;
    appointment.duration = this.fg.controls.duration.value;
    appointment.remark = this.fg.controls.remark.value;
    this.dialogRef.close(appointment);
  }
}
