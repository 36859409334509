import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Assistant } from '../../models/assistant.model';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AssistantService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/assistant';
  }

  public add(assistant: User): Observable<number> {
    return this.http.post<number>(this.apiUrl, assistant);
  }

  public update(assistant: User): Observable<Assistant> {
    return this.http.patch<Assistant>(this.apiUrl, assistant);
  }

  public delete(assistantId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${assistantId}`);
  }

  public getByClinicId(clinicId: number): Observable<Assistant[]> {
    return this.http.get<Assistant[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}`
    );
  }

  public getMyClinicId(): Observable<number> {
    return this.http.get<number>(this.apiUrl + `/my-clinic-id`);
  }
}
