<mat-dialog-content>
  <h4>FDI vabilo za {{ data.patient.name ?? '' }} {{ data.patient.lastName ?? '' }}</h4>

  <span *ngIf='data?.patient?.email?.length'>Pacientov mail: {{ data.patient.email ?? '' }}</span>

  <form [formGroup]='emailForm' (ngSubmit)='sendInvitation()'>
    <div class='inputs'>
      <div class='form-group'>
        <label for='emailInput'>Email</label>
        <input type='email'
               class='form-control'
               id='emailInput' placeholder='Email' formControlName='email'>
      </div>
    </div>
    <div id='actionButtons'>
      <div></div>
      <ng-container *ngIf='!data?.patient?.email?.length; else sendToPatient'>
        <div></div>
      </ng-container>
      <ng-template #sendToPatient>
        <div>
          <button class='btn btn-primary' type='button' (click)='sendInvitationToPatient()'>Poslati pacientu</button>
        </div>
      </ng-template>
      <div>
        <button class='btn btn-light' [mat-dialog-close]='false'>Prekliči</button>
      </div>
      <div>
        <button class='btn btn-primary' type='submit'>Poslati na vpisani email</button>
      </div>
    </div>
  </form>
</mat-dialog-content>