<main>
  <div id="head">
    <h4>Filtri</h4>
    <div class="filter_icons">
      <button mat-button (click)="clearAll()">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-button [mat-menu-trigger-for]="filters">
        <mat-icon>add</mat-icon>
      </button>
      <mat-menu #filters="matMenu">
        <button
          mat-menu-item
          [disabled]="onlyOpen"
          (click)="toggleOnlyOpenFilter()"
        >
          Samo odprti
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="doctorsMenu"
          [disabled]="displayedDoctors.length === 0 || disabledFilters.indexOf(0)>-1"
        >
          Doktorji
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="clinicLocationsMenu"
          [disabled]="displayedClinicLocations.length === 0 || disabledFilters.indexOf(1)>-1"
        >
          Lokacije
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="agentsMenu"
          [disabled]="displayedAgents.length === 0 || disabledFilters.indexOf(2)>-1"
        >
          Agenti
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="appointmentTypesMenu"
          [disabled]="displayedAppointmentTypes.length === 0 || disabledFilters.indexOf(3)>-1"
        >
          Tipi termina
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="appointmentStatusesMenu"
          [disabled]="displayedAppointmentStatuses.length === 0 || disabledFilters.indexOf(4)>-1"
        >
          Statusi posega
        </button>
        <button
          mat-menu-item
          [matMenuTriggerFor]="actionTypesMenu"
          [disabled]="displayedActionTypes.length === 0 || disabledFilters.indexOf(6)>-1"
        >
          Storitve
        </button>
      </mat-menu>
      <mat-menu #doctorsMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let doctor of displayedDoctors"
          (click)="addDoctorFilter(doctor.id)"
        >
          {{ doctor.userInfo.name }} {{ doctor.userInfo.lastName }}
        </button>
      </mat-menu>
      <mat-menu #clinicLocationsMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let location of displayedClinicLocations"
          (click)="addClinicLocationFilter(location.id)"
        >
          {{ location.name }}
        </button>
      </mat-menu>
      <mat-menu #agentsMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let agent of displayedAgents"
          (click)="addAgentFilter(agent.id)"
        >
          {{ agent.userInfo.name }} {{ agent.userInfo.lastName }}
        </button>
      </mat-menu>
      <mat-menu #appointmentTypesMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let type of displayedAppointmentTypes"
          (click)="addAppointmentTypeFilter(type.id)"
        >
          {{ type.name }}
        </button>
      </mat-menu>
      <mat-menu #appointmentStatusesMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let status of displayedAppointmentStatuses"
          (click)="addAppointmentStatusFilter(status.id)"
        >
          {{ status.name }}
        </button>
      </mat-menu>
      <mat-menu #actionTypesMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let actionType of displayedActionTypes"
          (click)="addActionTypeFilter(actionType.id)"
        >
          {{ actionType.name }}
        </button>
      </mat-menu>
    </div>
  </div>
  <div id="body">
    <div
      id="noFilters"
      *ngIf="
        filteredDoctors.length === 0 &&
        filteredClinicLocations.length === 0 &&
        filteredAgents.length === 0 &&
        filteredAppointmentTypes.length === 0 &&
        filteredAppointmentStatuses.length === 0 &&
        filteredActionTypes.length === 0 &&
        !onlyOpen
      "
    >
      Ni izbranih filtrov.
    </div>

    <div *ngIf="onlyOpen">
      <h3>Samo odprti termini</h3>
      <mat-divider></mat-divider>
      <div class="filter">
        <div class="name">Da</div>
        <div style="text-align: right">
          <button mat-icon-button (click)="toggleOnlyOpenFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="filteredDoctors.length !== 0">
      <h3>Doktorji</h3>
      <mat-divider></mat-divider>

      <div *ngFor="let doctor of filteredDoctors" class="filter">
        <div class="name">
          {{ doctor.userInfo.name }} {{ doctor.userInfo.lastName }}
        </div>
        <div style="text-align: right">
          <button mat-icon-button (click)="removeDoctorFilter(doctor.id)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="filteredClinicLocations.length !== 0">
      <h3>Lokacije</h3>
      <mat-divider></mat-divider>

      <div *ngFor="let location of filteredClinicLocations" class="filter">
        <div class="name">
          {{ location.name }}
        </div>
        <div style="text-align: right">
          <button
            mat-icon-button
            (click)="removeClinicLocationFilter(location.id)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="filteredAgents.length !== 0">
      <h3>Agenti</h3>
      <mat-divider></mat-divider>
      <div *ngFor="let agent of filteredAgents" class="filter">
        <div class="name">
          {{ agent.userInfo.name }} {{ agent.userInfo.lastName }}
        </div>
        <div style="text-align: right">
          <button mat-icon-button (click)="removeAgentFilter(agent.id)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="filteredAppointmentTypes.length !== 0">
      <h3>Tip termina</h3>
      <mat-divider></mat-divider>
      <div *ngFor="let type of filteredAppointmentTypes" class="filter">
        <div class="name">
          {{ type.name }}
        </div>
        <div style="text-align: right">
          <button
            mat-icon-button
            (click)="removeAppointmentTypeFilter(type.id)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="filteredAppointmentStatuses.length !== 0">
      <h3>Status posega</h3>
      <mat-divider></mat-divider>
      <div *ngFor="let status of filteredAppointmentStatuses" class="filter">
        <div class="name">
          {{ status.name }}
        </div>
        <div style="text-align: right">
          <button
            mat-icon-button
            (click)="removeAppointmentStatusFilter(status.id)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="filteredActionTypes.length !== 0">
      <h3>Storitve</h3>
      <mat-divider></mat-divider>
      <div
        *ngFor="let actionType of filteredActionTypes"
        class="filter"
      >
        <div class="name">
          {{ actionType.name }}
        </div>
        <div style="text-align: right">
          <button
            mat-icon-button
            (click)="removeActionTypeFilter(actionType.id)"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</main>
