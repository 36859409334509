import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserTypeService } from '../../services/authorization/user.type.service';
import { ToastService } from '../../services/other-services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { UserTranslations } from '../../utils/constants/users-eng-to-slo.translate';
import { DOCTORS, TECHNICIANS } from '../../utils/constants/pages.constants';
import { GOOGLE_DRIVE_IMG_URL } from '../../utils/constants/constants';
import { UserEditDialogComponent } from './user-edit-dialog/user-edit-dialog.component';
import { LocalStorageService } from '../../services/api-services/local-storage.service';
import { ActionTypeDoctorPriceTypeService } from '../../services/api-services/action-type-doctor-price-type.service';

@Component({
  selector: 'app-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss'],
})
export class UserOverviewComponent implements OnInit, OnDestroy {
  protected readonly GOOGLE_DRIVE_IMG_URL = GOOGLE_DRIVE_IMG_URL;
  private destroy$ = new Subject<void>();

  private loadUsers$ = new BehaviorSubject<void>(null);
  users$: Observable<User[]> = this.getUsers();
  private selectedUsersSubject$ = new BehaviorSubject<Set<User>>(new Set());
  selectedUsers$ = this.selectedUsersSubject$.asObservable();
  private actionTypeDoctorPriceTypes$ = this.getActionTypeDoctorPriceTypes();

  isReadonly: boolean;
  isLoading = true;
  title: string;
  private type: string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userTypeService: UserTypeService,
    private toast: ToastService,
    private authorizationService: AuthorizationService,
    private actionTypeDoctorPriceTypeService: ActionTypeDoctorPriceTypeService,
    private storageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.initialSetup();

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onUrlChange();
    });
  }

  private initialSetup() {
    this.type = this.route.snapshot.paramMap.get('type');
    this.title = UserTranslations.get(this.type);
    this.isReadonly =
      !this.authorizationService.isAdmin &&
      !this.authorizationService.isTechnician;
  }

  private getUsers(): Observable<User[]> {
    return this.loadUsers$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap((_) => this.userTypeService.getUsers(this.type)),
      tap((_) => (this.isLoading = false)),
      takeUntil(this.destroy$)
    );
  }

  private getActionTypeDoctorPriceTypes() {
    return this.actionTypeDoctorPriceTypeService.getByClinicId(
      this.storageService.getClinicId()
    );
  }

  addUser(): void {
    this.openUserFormDialog()
      .afterClosed()
      .pipe(
        take(1),
        filter((v) => !!v),
        tap(() => (this.isLoading = true)),
        switchMap((user) => this.userTypeService.addUser(user, this.type)),
        tap(() => (this.isLoading = false))
      )
      .subscribe(
        (userId) => {
          this.toast.success('Korisnik je bil dodan!');
          this.loadUsers$.next();
        },
        (error) => {
          this.toast.error(error.error);
        }
      );
  }

  private openUserFormDialog(user?: User) {
    return this.dialog.open(UserEditDialogComponent, {
      width: '35rem',
      autoFocus: false,
      disableClose: true,
      data: {
        user: user ? user : null,
        type: this.type,
        availableActionTypeDoctorPriceTypes: this.isDoctor()
          ? this.actionTypeDoctorPriceTypes$
          : null,
      },
    });
  }

  editUser(): void {
    const userToEdit = this.selectedUsersSubject$.value.values().next().value;
    this.openUserFormDialog(userToEdit)
      .afterClosed()
      .pipe(
        take(1),
        filter((v) => !!v),
        tap(() => (this.isLoading = true)),
        switchMap((user) => this.userTypeService.updateUser(user, this.type)),
        tap(() => (this.isLoading = false))
      )
      .subscribe((_) => {
        this.toast.success('Korisnik je bil posodobljen!');
        this.loadUsers$.next();
        this.toggleAll(false);
      });
  }

  deleteUser(): void {
    const userToDelete = this.selectedUsersSubject$.value.values().next().value;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '35rem',
      autoFocus: false,
      disableClose: true,
      data: {
        msg: `izbrisati korisnika ${userToDelete.userInfo.name} ${userToDelete.userInfo.lastName}?`,
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.userTypeService
          .deleteUser(userToDelete.id, this.type)
          .subscribe(() => {
            this.toast.success('Korisnik je bil izbrisan!');
            this.loadUsers$.next();
            this.toggleAll(false);
          });
      }
    });
  }

  toggleUser(user: User) {
    const selectedUsers = this.selectedUsersSubject$.value;
    if (selectedUsers.has(user)) {
      selectedUsers.delete(user);
    } else {
      selectedUsers.add(user);
    }
    this.selectedUsersSubject$.next(selectedUsers);
  }

  toggleAll(isChecked: boolean, users?: User[]) {
    if (isChecked) {
      this.selectedUsersSubject$.next(new Set(users));
    } else {
      this.selectedUsersSubject$.next(new Set());
    }
  }

  private onUrlChange() {
    this.initialSetup();
    this.loadUsers$.next();
    this.selectedUsersSubject$.next(new Set());
  }

  isFiveColumns() {
    return this.isDoctor() || this.isTechnician();
  }

  isDoctor() {
    return DOCTORS === this.type;
  }

  isTechnician() {
    return TECHNICIANS === this.type;
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
