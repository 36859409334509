import { Pipe, PipeTransform } from '@angular/core';
import { MissingTooth } from '../models/missing-tooth.model';

@Pipe({
  name: 'missingTeethToString',
})
export class MissingTeethToStringPipe implements PipeTransform {
  transform(teeth: MissingTooth[]): string {
    return teeth?.map((mt) => mt.tooth).join(', ');
  }
}
