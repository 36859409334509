import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-teeth-selection',
  templateUrl: './action-teeth-selection.component.html',
  styleUrls: ['./action-teeth-selection.component.scss']
})
export class ActionTeethSelectionComponent {
  @Input() selectedTeethArray: number[];
  @Output() toothClicked = new EventEmitter<number>();
}
