import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';

@Pipe({
  name: 'groupPatientOfferedActions',
})
export class GroupPatientOfferedActionsPipe implements PipeTransform {
  transform(arr: PatientOfferedAction[]): Map<string, PatientOfferedAction[]> {
    const resultMap = new Map<string, PatientOfferedAction[]>();
    arr.forEach((poa) => {
      if (resultMap.has(poa?.patientAction?.category)) {
        resultMap.get(poa?.patientAction?.category).push(poa);
      } else {
        resultMap.set(poa?.patientAction?.category, [poa]);
      }
    });
    return resultMap;
  }
}
