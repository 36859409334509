<div *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!loading">
  <h2>Sprememba gesla</h2>

  <div id="form">

    <div class="form-group">
      <label for="oldPasswordInput">Staro geslo</label>
      <input type="password"
             [class]="'form-control ' + (oldPasswordFormControl.touched && !oldPasswordFormControl.valid ? 'is-invalid' : '')"
             id="oldPasswordInput" placeholder="Stari geslo" [formControl]="oldPasswordFormControl">
      <div class="invalid-feedback text-danger" *ngIf="oldPasswordFormControl.hasError('required')">
        Polje je obvezno!
      </div>
    </div>

    <div class="form-group">
      <label for="newPasswordInput">Novo geslo</label>
      <input type="password"
             [class]="'form-control ' + (newPasswordFormControl.touched && !newPasswordFormControl.valid ? 'is-invalid' : '')"
             id="newPasswordInput" placeholder="Novo geslo" [formControl]="newPasswordFormControl">
      <div class="invalid-feedback text-danger" *ngIf="newPasswordFormControl.hasError('required')">
        Polje je obvezno!
      </div>
    </div>


    <div class="form-group">
      <label for="repeatNewPasswordInput">Ponovitev novega gesla</label>
      <input type="password"
             [class]="'form-control ' + (repeatNewPasswordFormControl.touched && !repeatNewPasswordFormControl.valid ? 'is-invalid' : '')"
             id="repeatNewPasswordInput" placeholder="Novo geslo" [formControl]="repeatNewPasswordFormControl">
      <div class="invalid-feedback text-danger" *ngIf="repeatNewPasswordFormControl.hasError('required')">
        Polje je obvezno!
      </div>
      <div class="invalid-feedback text-danger" *ngIf="repeatNewPasswordFormControl.hasError('notEqual')">
        Gesla se ne ujemata!
      </div>
    </div>
  </div>

  <div id="actionButtons">
    <div></div>
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">
        Prekliči
      </button>
    </div>
    <div>
      <button class="btn btn-primary" (click)="save()">Spremeni</button>
    </div>
  </div>
</div>
