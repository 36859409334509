<div class='card'>
  <div class='card-body'>
    <div class='title'>
      <h5 class='card-title font-weight-bold'>
        Poseg {{ index }}.
        {{ appointment.startDateTime ? '' : '(Brez termina)' }}
      </h5>
      <div class='buttons'>
        <button style='margin-right: 1rem; background-color: #007bff' class='btn btn-primary' (click)='createReceipt(appointment)'>
          <mat-icon>receipt</mat-icon>
        </button>
        <button class='btn' *ngIf='editMode && appointment | canEditAppointment' (click)='onEditClicked()'>
          <mat-icon>edit</mat-icon>
        </button>
        <button
          id='goToPatientButton'
          class='btn'
          *ngIf='!editMode'
          (click)='goToPatient($event)'
          matTooltip='Pojdi na pacienta'
        >
          <mat-icon>person</mat-icon>
        </button>
      </div>
    </div>
    <div class='info'>
      <div class='key-value'>
        <div class='key'>Doktor</div>
        <div class='value'>
          {{
            appointment.doctor
              ? appointment.doctor.userInfo.name +
              ' ' +
              appointment.doctor.userInfo.lastName
              : '/'
          }}
        </div>
      </div>
      <div class='key-value'>
        <div class='key'>Datum</div>
        <div class='value'>
          {{
            appointment.startDateTime
              ? (appointment.startDateTime | date: 'dd.MM.yyyy') +
              '&emsp;' +
              (appointment.startDateTime | date: 'HH:mm')
              : '/'
          }}
        </div>
      </div>
      <div class='key-value'>
        <div class='key'>Trajanje</div>
        <div class='value'>
          {{ appointment.duration ? appointment.duration + ' min' : '/' }}
        </div>
      </div>
      <div class='key-value'>
        <div class='key'>Lokacija</div>
        <div class='value'>
          {{
            appointment.clinicLocation ? appointment.clinicLocation.name : '/'
          }}
        </div>
      </div>
      <div class='key-value'>
        <div class='key'>Status</div>
        <div class='value'>
          {{
            appointment.appointmentStatus
              ? appointment.appointmentStatus.name
              : '/'
          }}
        </div>
      </div>
      <div class='key-value comment-wide'>
        <div class='key'>Opomba</div>
        <div class='value' style='white-space: pre-wrap'>
          {{ appointment.remark ? (appointment.remark | nlToBr) : '/' }}
        </div>
      </div>
    </div>
    <div class='other-info'>
      <div class='comments'>
        <div class='comments-title'>
          Opombe
          <button
            class='btn comments-button'
            (click)='addComment()'
            *ngIf='editMode'
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div *ngFor='let appointmentHasComment of appointment?.appointmentHasComment' class='comment'>
          <app-comment-card
            [comment]='appointmentHasComment.comment'
            (commentClicked)='onCommentClicked($event)'
            [editMode]='editMode'
          ></app-comment-card>
        </div>
      </div>
      <div *ngIf='appointment.appointmentTypeId === 1'>
        <div class='actions-head'>
          <div></div>
          <div>Storitev</div>
          <div>Zobje</div>
          <div>Ploskov</div>
          <div>
            Opomba
            <button
              class='btn actions-button'
              (click)='addAction(appointment)'
              *ngIf='editMode && appointment | canEditAppointment'
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf='!appointment?.patientOfferedActions?.length' class='no-actions'>
          Ni storitev
        </div>
        <div
          *ngFor='let action of appointment?.patientOfferedActions | poaAppointmentView'
          class='action'
          [matMenuTriggerFor]='(appointment | canEditAppointment) ? actionMenu : undefined '
        >
          <div>{{ action.teeth?.length }}x</div>
          <div>
            {{ action?.patientActionName }}
          </div>
          <div>
            {{ action.teeth ? (action.teeth | teethEnumArrayToString) : '/' }}
          </div>
          <div>
            {{ action?.appointmentToothPlane ? action?.appointmentToothPlane : '/' }}
          </div>
          <div>
            {{ action?.appointmentComment ? action?.appointmentComment : '/' }}
          </div>
          <mat-menu #actionMenu>
            <ng-container *ngFor='let tooth of action?.teeth | sortTeeth'>
              <div mat-menu-item
                   (click)='editAction(tooth, action.patientActionId,  appointment?.patientOfferedActions)'>
                Uredi {{ tooth | teethName }}
              </div>
              <div mat-menu-item
                   (click)='deleteAction(tooth, action.patientActionId,  appointment?.patientOfferedActions)'>
                Izbriši {{ tooth | teethName }}
              </div>
            </ng-container>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</div>
