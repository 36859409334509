import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientActionWithPrices } from '../../models/patient-action.model';
import { FormBuilder, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-action-doctor-price-dialog',
  templateUrl: './edit-action-doctor-price-dialog.component.html',
  styleUrls: ['./edit-action-doctor-price-dialog.component.scss'],
})
export class EditActionDoctorPriceDialogComponent {
  form = this.fb.group({});
  action: PatientActionWithPrices;
  constructor(
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<EditActionDoctorPriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      action: PatientActionWithPrices;
    }
  ) {
    this.action = this.data.action;
    this.action?.doctorPrices?.forEach((doctorPrice) =>
      this.form.addControl(
        `${doctorPrice?.actionTypeDoctorPriceTypeId}-price`,
        new UntypedFormControl(doctorPrice?.price, Validators.min(0))
      )
    );
  }

  public update(): void {
    if (this.form.invalid) {
      return;
    }
    const doctorPrices = this.action.doctorPrices
      .map((dPrice) => {
        const newPrice =
          Math.round(
            this.form.controls[`${dPrice.actionTypeDoctorPriceTypeId}-price`]
              .value * 100
          ) / 100;
        return newPrice !== dPrice.price
          ? {
              ...dPrice,
              price: newPrice,
            }
          : undefined;
      })
      .filter((p) => !!p);
    const newAction = { ...this.action, doctorPrices };
    this.dialogRef.close(newAction);
  }
}
