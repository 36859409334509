<h2>Uredi Akcijo</h2>

<div>
  <div>
    <b>{{ this.data.poa?.patientAction?.name }} {{ this.data.poa?.teeth | teethName }}</b>
  </div>
</div>
<div class='overview-info'>
  <mat-form-field class='comment'>
    <mat-label>Ploskov</mat-label>
    <input type='text'
           [placeholder]="'Ploskov'"
           matInput
           [formControl]='toothPlaneForm'
    >
  </mat-form-field>
  <mat-form-field class='comment'>
    <mat-label>Opomba</mat-label>
    <textarea matInput [formControl]='commentForm'></textarea>
  </mat-form-field>


  <div class='actionButtons'>
    <div></div>
    <div>
      <button class='back' mat-button [mat-dialog-close]='false'>Nazaj</button>
    </div>
    <div>
      <button class='edit' mat-button (click)='update()'>Spremeni</button>
    </div>
    <div></div>
  </div>
</div>

