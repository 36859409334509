import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Patient } from '../../models/patient.model';
import { ToastService } from '../../services/other-services/toast.service';

@Component({
  selector: 'app-fdi-invitation-form-dialog',
  templateUrl: './fdi-invitation-form-dialog.component.html',
  styleUrls: ['./fdi-invitation-form-dialog.component.scss'],
})
export class FdiInvitationFormDialogComponent {
  public emailForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<FdiInvitationFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patient: Patient;
    },
    private fb: FormBuilder,
    private toastService: ToastService
  ) {
    this.emailForm = this.fb.group({
      email: new FormControl('', [Validators.email]),
    });
  }

  public sendInvitation(): void {
    if (
      this.emailForm?.invalid ||
      !this.emailForm?.controls?.email?.value?.length
    ) {
      this.toastService.warning('Unesi validan e-mail!');
      return;
    }
    this.dialogRef.close(this.emailForm.controls.email.value);
  }

  public sendInvitationToPatient(): void {
    this.dialogRef.close(this.data.patient.email);
  }
}
