import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { User } from '../../../models/user.model';
import { UserInfo } from '../../../models/user-info.model';
import { DOCTORS, TECHNICIANS } from '../../../utils/constants/pages.constants';
import { ActionTypeDoctorPriceType } from '../../../models/action-type-doctor-price-type.model';
import { Observable } from 'rxjs';
import { GOOGLE_DRIVE_IMG_URL } from '../../../utils/constants/constants';
import { CroppedImageUploadDialogComponent } from '../../cropped-image-upload-dialog/cropped-image-upload-dialog.component';
import { filter, switchMap, take } from 'rxjs/operators';
import { FileInfoService } from '../../../services/api-services/file-info.service';
import { FileInfo } from '../../../models/file-info.model';

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss'],
})
export class UserEditDialogComponent implements OnInit {
  protected readonly GOOGLE_DRIVE_IMG_URL = GOOGLE_DRIVE_IMG_URL;

  fg = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(30)]],
    lastName: ['', [Validators.required, Validators.maxLength(30)]],
    titleBeforeName: ['', [Validators.maxLength(50)]],
    titleAfterName: ['', [Validators.maxLength(100)]],
    email: [
      '',
      [Validators.required, Validators.maxLength(100), Validators.email],
    ],
    password: ['', [Validators.maxLength(30)]],
    phone: ['', [Validators.maxLength(40)]],
    color: ['#4F61FF', [Validators.required]],
    operatorTaxNumber: [''],
    actionTypeDoctorPriceType: [null],
    logoFileInfo: [null],
    logoFileInfoId: [null],
  });

  title: string;
  actionText: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserEditDialogComponent>,
    private dialog: MatDialog,
    private fileInfoService: FileInfoService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: User;
      type: string;
      availableActionTypeDoctorPriceTypes: Observable<
        ActionTypeDoctorPriceType[]
      >;
    }
  ) {}

  ngOnInit(): void {
    this.title = this.data.user
      ? 'Spreminjanje koristika'
      : 'Dodajanje korisnika';
    this.actionText = this.data.user ? 'Shrani' : 'Dodaj';

    if (!this.data.user) {
      this.formControls.password.addValidators(Validators.required);
    } else {
      this.fg.patchValue({
        ...this.data.user,
        ...this.data.user.userInfo,
        actionTypeDoctorPriceType: this.data.user.actionTypeDoctorPriceTypeId,
        logoFileInfo: this.data.user.technicianLogo?.fileInfo,
        logoFileInfoId: this.data.user.technicianLogo?.fileInfoId,
      });
    }

    if (this.isDoctor()) {
      this.formControls.actionTypeDoctorPriceType.addValidators(
        Validators.required
      );
    }
  }

  isDoctor() {
    return DOCTORS === this.data.type;
  }

  isTechnician() {
    return TECHNICIANS === this.data.type;
  }

  save() {
    this.dialogRef.close(this.getUser());
  }

  private getUser() {
    const user: User = this.data.user
      ? this.data.user
      : { userInfo: new UserInfo() };
    user.userInfo = {
      ...user.userInfo,
      name: this.formControls.name.value,
      lastName: this.formControls.lastName.value,
      email: this.formControls.email.value,
      password: this.formControls.password.value
        ? this.formControls.password.value
        : null,
      phone: this.formControls.phone.value,
      color: this.formControls.color.value,
      operatorTaxNumber: this.formControls.operatorTaxNumber.value,
    };
    if (this.isDoctor()) {
      user.actionTypeDoctorPriceTypeId = +this.formControls
        .actionTypeDoctorPriceType.value;
      user.titleBeforeName = this.formControls.titleBeforeName.value;
      user.titleAfterName = this.formControls.titleAfterName.value;
    }
    if (this.isTechnician()) {
      user.technicianLogo = {
        fileInfo: !!this.formControls.logoFileInfo.value
          ? this.formControls.logoFileInfo.value
          : this.data.user.technicianLogo.fileInfo,
        fileInfoId: !!this.formControls.logoFileInfoId.value
          ? this.formControls.logoFileInfoId.value
          : this.data.user.technicianLogo.fileInfoId,
        technicianId: user.id,
      };
    }
    return user;
  }

  setTechnicianLogo() {
    const dialogRef = this.dialog.open(CroppedImageUploadDialogComponent, {
      width: '65rem',
      autoFocus: false,
      disableClose: true,
      data: {
        msg: 'Nastavi logo tehnika',
        actionText: 'Nastavi',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((res) => !!res),
        switchMap((fileInfoId) => {
          this.formControls.logoFileInfoId.setValue(fileInfoId);
          return this.fileInfoService.get(fileInfoId);
        })
      )
      .subscribe((fileInfo: FileInfo) => {
        if (fileInfo) {
          this.formControls.logoFileInfo.setValue(fileInfo);
        }
      });
  }

  get formControls() {
    return {
      name: this.fg.get('name') as FormControl,
      lastName: this.fg.get('lastName') as FormControl,
      titleBeforeName: this.fg.get('titleBeforeName') as FormControl,
      titleAfterName: this.fg.get('titleAfterName') as FormControl,
      email: this.fg.get('email') as FormControl,
      password: this.fg.get('password') as FormControl,
      phone: this.fg.get('phone') as FormControl,
      color: this.fg.get('color') as FormControl,
      operatorTaxNumber: this.fg.get('operatorTaxNumber') as FormControl,
      actionTypeDoctorPriceType: this.fg.get(
        'actionTypeDoctorPriceType'
      ) as FormControl,
      logoFileInfo: this.fg.get('logoFileInfo') as FormControl,
      logoFileInfoId: this.fg.get('logoFileInfoId') as FormControl,
    };
  }

  shouldShowLogo() {
    return (
      !!this.data.user?.technicianLogo?.fileInfo?.googleDriveId ||
      !!this.formControls.logoFileInfo.value
    );
  }

  getLogoUrl() {
    if (this.formControls.logoFileInfo.value) {
      return (
        GOOGLE_DRIVE_IMG_URL +
        (this.formControls.logoFileInfo.value as FileInfo).googleDriveId
      );
    } else {
      return (
        GOOGLE_DRIVE_IMG_URL +
        this.data.user.technicianLogo.fileInfo.googleDriveId
      );
    }
  }
}
