import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  AdvanceReceipt,
  AdvanceReceiptUsage,
  Receipt,
  ReceiptPagination,
} from '../../models/receipt.model';
import { ADVANCE_SEARCH_OPTION } from '../../utils/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class ReceiptService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/receipt';
  }

  public addReceipt(
    receipt: Receipt,
    advanceUsed: number,
    treatmentPlanAdvance: number,
    visualisationPrepAdvance: number
  ): Observable<number> {
    return this.http.post<number>(this.apiUrl, {
      receipt,
      advanceUsed,
      treatmentPlanAdvance,
      visualisationPrepAdvance,
    });
  }

  public addAdvanceReceipt(receipt: Receipt): Observable<number> {
    return this.http.post<number>(this.apiUrl + '/add-advance', receipt);
  }

  public editAdvanceReceipt(
    advanceReceipt: AdvanceReceipt
  ): Observable<number> {
    return this.http.post<number>(
      this.apiUrl + '/edit-advance',
      advanceReceipt
    );
  }

  public getPatientReceipts(
    patientId: number,
    page: number,
    pageSize?: number
  ): Observable<ReceiptPagination> {
    const pageSizeQuery = pageSize ? `pageSize=${pageSize}` : '';
    const pageQuery = page ? `page=${page}` : '';
    const queryParams = [pageSizeQuery, pageQuery]
      .filter((param) => !!param)
      .join('&');

    const apiUrl = `${this.apiUrl}/by-patient-id/all/${patientId}`;
    const urlWithParams = `${apiUrl}${
      queryParams.length > 0 ? '?' + queryParams : ''
    }`;

    return this.http.get<ReceiptPagination>(urlWithParams);
  }

  public getPatientAdvanceReceipts(
    patientId: number
  ): Observable<AdvanceReceipt[]> {
    return this.http.get<AdvanceReceipt[]>(
      this.apiUrl + `/by-patient-id/advance-receipt/${patientId}`
    );
  }

  public cancelReceipt(
    identificationNumber: string,
    comment: string,
    refundMethodId: number,
    advanceHandling: string,
    actionsToCancel: number[]
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.apiUrl + `/by-identification-number/cancel/${identificationNumber}`,
      {
        comment,
        refundMethodId,
        advanceHandling,
        actionsToCancel,
      }
    );
  }

  public changePaymentMethod(receipt: Receipt): Observable<number> {
    return this.http.post<number>(
      this.apiUrl + '/change-payment-method',
      receipt
    );
  }

  public changePaymentMethodForAdvance(
    receipt: AdvanceReceipt
  ): Observable<number> {
    return this.http.post<number>(
      this.apiUrl + '/change-payment-method-advance',
      receipt
    );
  }

  getAdvanceUsageForReceipts(
    receiptIds: number[]
  ): Observable<AdvanceReceiptUsage[]> {
    const url = `${this.apiUrl}/advance-receipt-usage?${this.buildQueryParams({
      receiptIds,
    })}`;
    return this.http.get<AdvanceReceiptUsage[]>(url);
  }

  private buildQueryParams(params: { [key: string]: any }): string {
    return Object.keys(params)
      .map((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
          return value
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  }

  public searchReceipts(
    isPageRequired: boolean = true,
    dateFrom: Date,
    dateTo: Date,
    patientId?: number,
    creatorId?: number,
    address?: string,
    page?: number,
    pageSize?: number,
    paymentMethodId?: number,
    advanceOption?: ADVANCE_SEARCH_OPTION
  ): Observable<ReceiptPagination> {
    let query = page ? `page=${page}` : isPageRequired ? 'page=0' : '';
    if (dateFrom) {
      query += `&dateFrom=${dateFrom.toISOString()}`;
    }
    if (dateTo) {
      query += `&dateTo=${dateTo.toISOString()}`;
    }
    if (patientId) {
      query += `&patientId=${patientId}`;
    }
    if (creatorId) {
      query += `&creatorId=${creatorId}`;
    }
    if (address) {
      query += `&address=${address}`;
    }
    if (pageSize) {
      query += `&pageSize=${pageSize}`;
    }
    if (paymentMethodId) {
      query += `&paymentMethodId=${paymentMethodId}`;
    }
    if (advanceOption) {
      query += `&advanceOptionId=${advanceOption}`;
    }

    return this.http.get<ReceiptPagination>(
      this.apiUrl + `/pagination?${query}`
    );
  }

  public getReceiptsForDateAndAddress(
    date: Date,
    address: string
  ): Observable<ReceiptPagination> {
    const dateQuery = date
      ? `date=${date.toISOString()}`
      : `date=${new Date().toISOString()}`;
    const addressQuery = address ? `address=${address}` : '';
    const queryParams = [dateQuery, addressQuery]
      .filter((param) => !!param)
      .join('&');

    const apiUrl = `${this.apiUrl}/cash-register`;
    const urlWithParams = `${apiUrl}${
      queryParams.length > 0 ? '?' + queryParams : ''
    }`;

    return this.http.get<ReceiptPagination>(urlWithParams);
  }

  public getPageNumberForReceipt(
    patientId: number,
    receiptId: string,
    pageSize: number
  ): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}/page/${patientId}/${receiptId}/${pageSize}`
    );
  }
}
