import { Component } from '@angular/core';
import { Patient } from '../../models/patient.model';
import { UntypedFormControl } from '@angular/forms';
import { PatientService } from '../../services/api-services/patient.service';
import { LocalStorageService } from '../../services/api-services/local-storage.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-patient-select-dialog',
  templateUrl: './patient-select-dialog.component.html',
  styleUrls: ['./patient-select-dialog.component.scss'],
})
export class PatientSelectDialogComponent {
  public patients: Patient[];
  public searchFormControl: UntypedFormControl;
  public pageLoaded: number;
  public perPage: number;
  public loading: boolean;
  public hasBeenSearched: boolean;

  constructor(
    private patientService: PatientService,
    private storageService: LocalStorageService,
    private dialogRef: MatDialogRef<PatientSelectDialogComponent>
  ) {
    this.patients = [];
    this.searchFormControl = new UntypedFormControl();
    this.pageLoaded = 0;
    this.perPage = 30;
    this.loading = false;
    this.hasBeenSearched = false;
  }

  public search(): void {
    if (this.searchFormControl.value) {
      this.hasBeenSearched = true;
      this.loading = true;
      this.pageLoaded = 0;
      this.patientService
        .getByClinicIdAndSearch(
          this.storageService.getClinicId(),
          this.searchFormControl.value,
          this.pageLoaded,
          this.perPage
        )
        .subscribe((patients: Patient[]) => {
          this.patients = patients;
          this.loading = false;
        });
    }
  }

  public searchOnKeyPressed(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  public selectPatient(patient: Patient): void {
    this.dialogRef.close(patient);
  }
  public loadMore() {
    this.loading = true;
    ++this.pageLoaded;
    this.patientService
      .getByClinicIdAndSearch(
        this.storageService.getClinicId(),
        this.searchFormControl.value,
        this.pageLoaded,
        this.perPage
      )
      .subscribe((patients: Patient[]) => {
        this.patients = this.patients.concat(patients);
        this.loading = false;
      });
  }
}
