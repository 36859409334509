import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PatientOfferedAction } from '../../models/patient-offered-action.model';
import { TreatmentInfoAction } from '../../models/treatment-info-action.model';

@Injectable({
  providedIn: 'root',
})
export class PatientOfferedActionService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/patient-offered-action';
  }

  public add(
    patientOfferedActions: PatientOfferedAction[]
  ): Observable<number> {
    return this.http.post<number>(this.apiUrl, patientOfferedActions);
  }

  public addToAppointment(
    patientOfferedActions: PatientOfferedAction[]
  ): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/add-to-appointment`,
      patientOfferedActions
    );
  }

  public updateAppointmentAction(
    patientOfferedAction: PatientOfferedAction
  ): Observable<PatientOfferedAction> {
    return this.http.patch<PatientOfferedAction>(
      `${this.apiUrl}/appointment-action`,
      patientOfferedAction
    );
  }

  public getByPatientId(patientId: number): Observable<PatientOfferedAction[]> {
    return this.http.get<PatientOfferedAction[]>(
      `${this.apiUrl}/by-patient-id/${patientId}`
    );
  }

  public getSelectedPatientOfferedActions(
    patientId: number
  ): Observable<PatientOfferedAction[]> {
    return this.http.get<PatientOfferedAction[]>(
      `${this.apiUrl}/selected/${patientId}`
    );
  }

  public getTreatmentInfoActions(
    patientId: number
  ): Observable<TreatmentInfoAction[]> {
    return this.http.get<TreatmentInfoAction[]>(
      `${this.apiUrl}/treatment-info-actions/${patientId}`
    );
  }

  public toggleSelection(patientId: number): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/toggle-selection/`,
      patientId
    );
  }

  public cancel(
    patientOfferedAction: PatientOfferedAction
  ): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/cancel`,
      patientOfferedAction
    );
  }

  public deletePatientOfferedAction(
    patientOfferedActionId: number
  ): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/delete/`,
      patientOfferedActionId
    );
  }

  public removePatientOfferedActionFromAppointment(
    patientOfferedActionId: number
  ): Observable<number> {
    return this.http.post<number>(
      `${this.apiUrl}/remove-from-appointment/`,
      patientOfferedActionId
    );
  }
}
