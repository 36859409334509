import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';
import { poasToTeethString } from '../utils/teeth.utils';

@Pipe({
  name: 'poasToTeethName',
})
export class MapPoasToTeethNamePipe implements PipeTransform {
  transform(poas: PatientOfferedAction[]): string {
    return poasToTeethString(poas);
  }
}
