import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  name: 'isToday',
})
export class IsTodayPipe implements PipeTransform {
  transform(day: Date): boolean {
    return isSameDay(new Date(day), new Date());
  }
}
