import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OpenPatientsService {
  public numberOfOpenPatientsChanged = new EventEmitter<number>();

  constructor() {}

  public triggerNumberOfOpenPatientsChanged(numOfOpenPatients: number): void {
    this.numberOfOpenPatientsChanged.emit(numOfOpenPatients);
  }
}
