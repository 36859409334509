import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '../../models/appointment.model';
import { APPOINTMENT_STATUS } from '../../utils/constants/constants';

@Pipe({
  name: 'sumAppointmentHours',
})
export class SumAppointmentHoursPipe implements PipeTransform {
  transform(appointments: Appointment[]): number {
    return appointments
      .filter((a) => APPOINTMENT_STATUS.ARRIVED === a.appointmentStatusId)
      .map((a) => a.duration)
      .reduce((totalTime, appTime) => totalTime + appTime, 0);
  }
}
