export const SLOVENIAN_PLZ: string[] = [
  '8341',
  '5270',
  '6280',
  '9253',
  '8253',
  '4275',
  '1382',
  '9231',
  '2234',
  '2345',
  '3256',
  '8259',
  '1223',
  '8283',
  '4260',
  '4273',
  '9265',
  '9222',
  '4263',
  '4264',
  '4265',
  '1353',
  '8294',
  '5230',
  '5295',
  '3314',
  '5223',
  '8280',
  '2354',
  '4243',
  '1351',
  '8250',
  '4210',
  '8321',
  '3255',
  '8276',
  '9261',
  '3000',
  '3001',
  '4207',
  '8263',
  '1380',
  '5282',
  '2236',
  '2215',
  '2326',
  '2282',
  '5273',
  '8251',
  '1413',
  '5253',
  '9232',
  '2393',
  '6275',
  '5274',
  '5262',
  '8340',
  '6271',
  '5210',
  '2253',
  '6215',
  '1233',
  '3224',
  '8257',
  '1423',
  '5263',
  '3204',
  '8211',
  '1356',
  '9223',
  '5212',
  '1431',
  '1262',
  '1273',
  '1331',
  '8350',
  '1230',
  '2252',
  '5294',
  '1319',
  '8343',
  '3222',
  '2370',
  '4203',
  '6221',
  '8361',
  '2343',
  '9208',
  '2313',
  '3213',
  '1274',
  '8254',
  '5275',
  '4204',
  '3303',
  '4224',
  '3263',
  '2272',
  '9250',
  '3342',
  '4282',
  '6272',
  '9264',
  '8332',
  '1384',
  '5242',
  '5251',
  '3302',
  '3231',
  '1290',
  '2288',
  '8362',
  '2311',
  '9205',
  '1354',
  '1372',
  '1430',
  '6225',
  '4276',
  '5280',
  '1292',
  '6250',
  '6251',
  '1295',
  '2259',
  '1411',
  '6310',
  '2222',
  '2221',
  '6254',
  '4270',
  '8261',
  '3273',
  '2223',
  '2256',
  '5214',
  '3233',
  '4246',
  '2351',
  '1241',
  '5213',
  '8258',
  '2362',
  '2325',
  '1412',
  '6253',
  '5222',
  '9227',
  '1330',
  '1338',
  '2276',
  '5211',
  '6223',
  '1218',
  '6000',
  '6001',
  '8282',
  '5296',
  '8311',
  '1336',
  '6256',
  '2394',
  '6240',
  '3260',
  '4000',
  '4001',
  '4280',
  '1281',
  '4294',
  '9206',
  '9242',
  '1301',
  '8296',
  '4245',
  '8262',
  '8270',
  '9263',
  '2318',
  '3270',
  '1219',
  '2230',
  '9220',
  '4248',
  '3261',
  '8273',
  '2372',
  '2341',
  '1270',
  '3202',
  '1000',
  '1001',
  '1231',
  '1261',
  '1260',
  '1002',
  '1210',
  '1211',
  '3333',
  '9240',
  '3215',
  '5231',
  '1358',
  '1370',
  '1434',
  '3223',
  '6219',
  '1318',
  '2324',
  '2344',
  '3334',
  '1225',
  '9202',
  '2322',
  '2321',
  '9243',
  '2229',
  '6273',
  '2000',
  '2001',
  '2206',
  '2281',
  '9221',
  '6242',
  '4211',
  '1215',
  '1234',
  '8330',
  '2392',
  '2204',
  '2275',
  '5291',
  '8233',
  '8216',
  '2382',
  '4281',
  '8230',
  '1251',
  '9226',
  '5216',
  '1221',
  '3330',
  '9000',
  '9001',
  '2366',
  '4202',
  '3331',
  '1357',
  '3203',
  '5000',
  '5001',
  '1385',
  '8000',
  '8001',
  '6243',
  '9233',
  '2317',
  '2312',
  '2270',
  '1316',
  '1337',
  '8222',
  '2361',
  '2231',
  '2211',
  '9203',
  '3301',
  '6330',
  '8255',
  '6257',
  '6232',
  '3225',
  '6276',
  '8312',
  '5243',
  '3254',
  '2273',
  '6216',
  '2381',
  '6244',
  '1414',
  '2286',
  '5272',
  '4244',
  '3241',
  '3257',
  '2363',
  '2208',
  '2257',
  '1355',
  '4223',
  '2319',
  '1272',
  '3313',
  '3232',
  '6320',
  '6230',
  '2331',
  '3312',
  '4205',
  '6255',
  '1352',
  '6258',
  '2391',
  '3262',
  '1276',
  '3253',
  '9207',
  '5297',
  '2250',
  '2323',
  '9201',
  '2327',
  '1433',
  '9252',
  '2360',
  '1235',
  '4240',
  '8274',
  '1381',
  '4283',
  '2390',
  '9246',
  '3332',
  '5292',
  '1310',
  '2364',
  '3272',
  '1314',
  '5215',
  '3250',
  '9262',
  '3252',
  '1373',
  '2342',
  '1282',
  '6333',
  '4227',
  '2352',
  '8333',
  '8281',
  '6224',
  '8290',
  '6210',
  '2214',
  '5283',
  '2380',
  '2310',
  '3210',
  '1216',
  '5232',
  '1317',
  '3335',
  '5250',
  '4229',
  '4225',
  '5281',
  '2241',
  '9245',
  '2277',
  '4267',
  '8256',
  '5224',
  '1242',
  '1332',
  '8342',
  '1386',
  '2205',
  '2289',
  '8322',
  '3206',
  '8351',
  '1313',
  '8293',
  '8331',
  '2353',
  '2233',
  '2235',
  '9244',
  '3264',
  '2258',
  '9204',
  '5261',
  '5290',
  '3311',
  '4208',
  '2212',
  '8297',
  '2373',
  '8310',
  '3230',
  '3271',
  '8232',
  '1296',
  '8275',
  '6281',
  '4220',
  '3211',
  '1291',
  '6274',
  '1293',
  '3240',
  '8220',
  '2315',
  '3341',
  '3327',
  '1275',
  '2383',
  '3201',
  '3325',
  '6222',
  '3220',
  '3304',
  '3221',
  '9251',
  '5220',
  '3326',
  '2371',
  '1420',
  '8231',
  '8210',
  '5252',
  '2254',
  '1222',
  '1236',
  '4290',
  '8295',
  '1311',
  '9224',
  '8323',
  '1252',
  '3320',
  '3322',
  '8212',
  '2274',
  '9225',
  '1315',
  '8213',
  '9241',
  '1312',
  '2284',
  '8344',
  '5271',
  '4212',
  '1294',
  '3205',
  '2255',
  '1217',
  '3212',
  '5293',
  '2232',
  '3305',
  '6217',
  '1360',
  '2365',
  '2367',
  '8292',
  '1410',
  '1303',
  '2283',
  '8272',
  '4201',
  '2242',
  '2201',
  '2316',
  '2314',
  '2213',
  '4247',
  '4206',
  '2285',
  '1432',
  '3214',
  '4209',
  '3310',
  '4228',
  '2287',
  '4226',
  '4274',
  '8360',
];

export const SLOVENIAN_PLZ_DROPDOWN = [
  { id: 8341, name: '8341 Adlešiči' },
  { id: 5270, name: '5270 Ajdovščina' },
  { id: 6280, name: '6280 Ankaran/Ancarano' },
  { id: 9253, name: '9253 Apače' },
  { id: 8253, name: '8253 Artiče' },
  { id: 4275, name: '4275 Begunje na Gorenjskem' },
  { id: 1382, name: '1382 Begunje pri Cerknici' },
  { id: 9231, name: '9231 Beltinci' },
  { id: 2234, name: '2234 Benedikt' },
  { id: 2345, name: '2345 Bistrica ob Dravi' },
  { id: 3256, name: '3256 Bistrica ob Sotli' },
  { id: 8259, name: '8259 Bizeljsko' },
  { id: 1223, name: '1223 Blagovica' },
  { id: 8283, name: '8283 Blanca' },
  { id: 4260, name: '4260 Bled' },
  { id: 4273, name: '4273 Blejska Dobrava' },
  { id: 9265, name: '9265 Bodonci' },
  { id: 9222, name: '9222 Bogojina' },
  { id: 4263, name: '4263 Bohinjska Bela' },
  { id: 4264, name: '4264 Bohinjska Bistrica' },
  { id: 4265, name: '4265 Bohinjsko jezero' },
  { id: 1353, name: '1353 Borovnica' },
  { id: 8294, name: '8294 Boštanj' },
  { id: 5230, name: '5230 Bovec' },
  { id: 5295, name: '5295 Branik' },
  { id: 3314, name: '3314 Braslovče' },
  { id: 5223, name: '5223 Breginj' },
  { id: 8280, name: '8280 Brestanica' },
  { id: 2354, name: '2354 Bresternica' },
  { id: 4243, name: '4243 Brezje' },
  { id: 1351, name: '1351 Brezovica pri Ljubljani' },
  { id: 8250, name: '8250 Brežice' },
  { id: 4210, name: '4210 Brnik - aerodrom' },
  { id: 8321, name: '8321 Brusnice' },
  { id: 3255, name: '3255 Buče' },
  { id: 8276, name: '8276 Bučka' },
  { id: 9261, name: '9261 Cankova' },
  { id: 3000, name: '3000 Celje' },
  { id: 3001, name: '3001 Celje - poštni predali' },
  { id: 4207, name: '4207 Cerklje na Gorenjskem' },
  { id: 8263, name: '8263 Cerklje ob Krki' },
  { id: 1380, name: '1380 Cerknica' },
  { id: 5282, name: '5282 Cerkno' },
  { id: 2236, name: '2236 Cerkvenjak' },
  { id: 2215, name: '2215 Ceršak' },
  { id: 2326, name: '2326 Cirkovce' },
  { id: 2282, name: '2282 Cirkulane' },
  { id: 5273, name: '5273 Col' },
  { id: 8251, name: '8251 Čatež ob Savi' },
  { id: 1413, name: '1413 Čemšenik' },
  { id: 5253, name: '5253 Čepovan' },
  { id: 9232, name: '9232 Črenšovci' },
  { id: 2393, name: '2393 Črna na Koroškem' },
  { id: 6275, name: '6275 Črni Kal' },
  { id: 5274, name: '5274 Črni Vrh nad Idrijo' },
  { id: 5262, name: '5262 Črniče' },
  { id: 8340, name: '8340 Črnomelj' },
  { id: 6271, name: '6271 Dekani' },
  { id: 5210, name: '5210 Deskle' },
  { id: 2253, name: '2253 Destrnik' },
  { id: 6215, name: '6215 Divača' },
  { id: 1233, name: '1233 Dob' },
  { id: 3224, name: '3224 Dobje pri Planini' },
  { id: 8257, name: '8257 Dobova' },
  { id: 1423, name: '1423 Dobovec' },
  { id: 5263, name: '5263 Dobravlje' },
  { id: 3204, name: '3204 Dobrna' },
  { id: 8211, name: '8211 Dobrnič' },
  { id: 1356, name: '1356 Dobrova' },
  { id: 9223, name: '9223 Dobrovnik/Dobronak' },
  { id: 5212, name: '5212 Dobrovo v Brdih' },
  { id: 1431, name: '1431 Dol pri Hrastniku' },
  { id: 1262, name: '1262 Dol pri Ljubljani' },
  { id: 1273, name: '1273 Dole pri Litiji' },
  { id: 1331, name: '1331 Dolenja vas' },
  { id: 8350, name: '8350 Dolenjske Toplice' },
  { id: 1230, name: '1230 Domžale' },
  { id: 2252, name: '2252 Dornava' },
  { id: 5294, name: '5294 Dornberk' },
  { id: 1319, name: '1319 Draga' },
  { id: 8343, name: '8343 Dragatuš' },
  { id: 3222, name: '3222 Dramlje' },
  { id: 2370, name: '2370 Dravograd' },
  { id: 4203, name: '4203 Duplje' },
  { id: 6221, name: '6221 Dutovlje' },
  { id: 8361, name: '8361 Dvor' },
  { id: 2343, name: '2343 Fala' },
  { id: 9208, name: '9208 Fokovci' },
  { id: 2313, name: '2313 Fram' },
  { id: 3213, name: '3213 Frankolovo' },
  { id: 1274, name: '1274 Gabrovka' },
  { id: 8254, name: '8254 Globoko' },
  { id: 5275, name: '5275 Godovič' },
  { id: 4204, name: '4204 Golnik' },
  { id: 3303, name: '3303 Gomilsko' },
  { id: 4224, name: '4224 Gorenja vas' },
  { id: 3263, name: '3263 Gorica pri Slivnici' },
  { id: 2272, name: '2272 Gorišnica' },
  { id: 9250, name: '9250 Gornja Radgona' },
  { id: 3342, name: '3342 Gornji Grad' },
  { id: 4282, name: '4282 Gozd Martuljek' },
  { id: 6272, name: '6272 Gračišče' },
  { id: 9264, name: '9264 Grad' },
  { id: 8332, name: '8332 Gradac' },
  { id: 1384, name: '1384 Grahovo' },
  { id: 5242, name: '5242 Grahovo ob Bači' },
  { id: 5251, name: '5251 Grgar' },
  { id: 3302, name: '3302 Griže' },
  { id: 3231, name: '3231 Grobelno' },
  { id: 1290, name: '1290 Grosuplje' },
  { id: 2288, name: '2288 Hajdina' },
  { id: 8362, name: '8362 Hinje' },
  { id: 2311, name: '2311 Hoče' },
  { id: 9205, name: '9205 Hodoš/Hodos' },
  { id: 1354, name: '1354 Horjul' },
  { id: 1372, name: '1372 Hotedršica' },
  { id: 1430, name: '1430 Hrastnik' },
  { id: 6225, name: '6225 Hruševje' },
  { id: 4276, name: '4276 Hrušica' },
  { id: 5280, name: '5280 Idrija' },
  { id: 1292, name: '1292 Ig' },
  { id: 6250, name: '6250 Ilirska Bistrica' },
  { id: 6251, name: '6251 Ilirska Bistrica - Trnovo' },
  { id: 1295, name: '1295 Ivančna Gorica' },
  { id: 2259, name: '2259 Ivanjkovci' },
  { id: 1411, name: '1411 Izlake' },
  { id: 6310, name: '6310 Izola/Isola' },
  { id: 2222, name: '2222 Jakobski Dol' },
  { id: 2221, name: '2221 Jarenina' },
  { id: 6254, name: '6254 Jelšane' },
  { id: 4270, name: '4270 Jesenice' },
  { id: 8261, name: '8261 Jesenice na Dolenjskem' },
  { id: 3273, name: '3273 Jurklošter' },
  { id: 2223, name: '2223 Jurovski Dol' },
  { id: 2256, name: '2256 Juršinci' },
  { id: 5214, name: '5214 Kal nad Kanalom' },
  { id: 3233, name: '3233 Kalobje' },
  { id: 4246, name: '4246 Kamna Gorica' },
  { id: 2351, name: '2351 Kamnica' },
  { id: 1241, name: '1241 Kamnik' },
  { id: 5213, name: '5213 Kanal' },
  { id: 8258, name: '8258 Kapele' },
  { id: 2362, name: '2362 Kapla' },
  { id: 2325, name: '2325 Kidričevo' },
  { id: 1412, name: '1412 Kisovec' },
  { id: 6253, name: '6253 Knežak' },
  { id: 5222, name: '5222 Kobarid' },
  { id: 9227, name: '9227 Kobilje' },
  { id: 1330, name: '1330 Kočevje' },
  { id: 1338, name: '1338 Kočevska Reka' },
  { id: 2276, name: '2276 Kog' },
  { id: 5211, name: '5211 Kojsko' },
  { id: 6223, name: '6223 Komen' },
  { id: 1218, name: '1218 Komenda' },
  { id: 6000, name: '6000 Koper - Capodistria' },
  { id: 6001, name: '6001 Koper - Capodistria - poštni predali' },
  { id: 8282, name: '8282 Koprivnica' },
  { id: 5296, name: '5296 Kostanjevica na Krasu' },
  { id: 8311, name: '8311 Kostanjevica na Krki' },
  { id: 1336, name: '1336 Kostel' },
  { id: 6256, name: '6256 Košana' },
  { id: 2394, name: '2394 Kotlje' },
  { id: 6240, name: '6240 Kozina' },
  { id: 3260, name: '3260 Kozje' },
  { id: 4000, name: '4000 Kranj' },
  { id: 4001, name: '4001 Kranj - poštni predali' },
  { id: 4280, name: '4280 Kranjska Gora' },
  { id: 1281, name: '1281 Kresnice' },
  { id: 4294, name: '4294 Križe' },
  { id: 9206, name: '9206 Križevci' },
  { id: 9242, name: '9242 Križevci pri Ljutomeru' },
  { id: 1301, name: '1301 Krka' },
  { id: 8296, name: '8296 Krmelj' },
  { id: 4245, name: '4245 Kropa' },
  { id: 8262, name: '8262 Krška vas' },
  { id: 8270, name: '8270 Krško' },
  { id: 9263, name: '9263 Kuzma' },
  { id: 2318, name: '2318 Laporje' },
  { id: 3270, name: '3270 Laško' },
  { id: 1219, name: '1219 Laze v Tuhinju' },
  { id: 2230, name: '2230 Lenart v Slovenskih goricah' },
  { id: 9220, name: '9220 Lendava/Lendva' },
  { id: 4248, name: '4248 Lesce' },
  { id: 3261, name: '3261 Lesično' },
  { id: 8273, name: '8273 Leskovec pri Krškem' },
  { id: 2372, name: '2372 Libeliče' },
  { id: 2341, name: '2341 Limbuš' },
  { id: 1270, name: '1270 Litija' },
  { id: 3202, name: '3202 Ljubečna' },
  { id: 1000, name: '1000 Ljubljana' },
  { id: 1001, name: '1001 Ljubljana - poštni predali' },
  { id: 1231, name: '1231 Ljubljana - Črnuče' },
  { id: 1261, name: '1261 Ljubljana - Dobrunje' },
  { id: 1260, name: '1260 Ljubljana - Polje' },
  { id: 1002, name: '1002 Ljubljana - poštni center' },
  { id: 1210, name: '1210 Ljubljana - Šentvid' },
  { id: 1211, name: '1211 Ljubljana - Šmartno' },
  { id: 3333, name: '3333 Ljubno ob Savinji' },
  { id: 9240, name: '9240 Ljutomer' },
  { id: 3215, name: '3215 Loče' },
  { id: 5231, name: '5231 Log pod Mangartom' },
  { id: 1358, name: '1358 Log pri Brezovici' },
  { id: 1370, name: '1370 Logatec' },
  { id: 1434, name: '1434 Loka pri Zidanem Mostu' },
  { id: 3223, name: '3223 Loka pri Žusmu' },
  { id: 6219, name: '6219 Lokev' },
  { id: 1318, name: '1318 Loški Potok' },
  { id: 2324, name: '2324 Lovrenc na Dravskem polju' },
  { id: 2344, name: '2344 Lovrenc na Pohorju' },
  { id: 3334, name: '3334 Luče' },
  { id: 1225, name: '1225 Lukovica' },
  { id: 9202, name: '9202 Mačkovci' },
  { id: 2322, name: '2322 Majšperk' },
  { id: 2321, name: '2321 Makole' },
  { id: 9243, name: '9243 Mala Nedelja' },
  { id: 2229, name: '2229 Malečnik' },
  { id: 6273, name: '6273 Marezige' },
  { id: 2000, name: '2000 Maribor' },
  { id: 2001, name: '2001 Maribor - poštni predali' },
  { id: 2206, name: '2206 Marjeta na Dravskem polju' },
  { id: 2281, name: '2281 Markovci' },
  { id: 9221, name: '9221 Martjanci' },
  { id: 6242, name: '6242 Materija' },
  { id: 4211, name: '4211 Mavčiče' },
  { id: 1215, name: '1215 Medvode' },
  { id: 1234, name: '1234 Mengeš' },
  { id: 8330, name: '8330 Metlika' },
  { id: 2392, name: '2392 Mežica' },
  { id: 2204, name: '2204 Miklavž na Dravskem polju' },
  { id: 2275, name: '2275 Miklavž pri Ormožu' },
  { id: 5291, name: '5291 Miren' },
  { id: 8233, name: '8233 Mirna' },
  { id: 8216, name: '8216 Mirna Peč' },
  { id: 2382, name: '2382 Mislinja' },
  { id: 4281, name: '4281 Mojstrana' },
  { id: 8230, name: '8230 Mokronog' },
  { id: 1251, name: '1251 Moravče' },
  { id: 9226, name: '9226 Moravske Toplice' },
  { id: 5216, name: '5216 Most na Soči' },
  { id: 1221, name: '1221 Motnik' },
  { id: 3330, name: '3330 Mozirje' },
  { id: 9000, name: '9000 Murska Sobota' },
  { id: 9001, name: '9001 Murska Sobota - poštni predali' },
  { id: 2366, name: '2366 Muta' },
  { id: 4202, name: '4202 Naklo' },
  { id: 3331, name: '3331 Nazarje' },
  { id: 1357, name: '1357 Notranje Gorice' },
  { id: 3203, name: '3203 Nova Cerkev' },
  { id: 5000, name: '5000 Nova Gorica' },
  { id: 5001, name: '5001 Nova Gorica - poštni predali' },
  { id: 1385, name: '1385 Nova vas' },
  { id: 8000, name: '8000 Novo mesto' },
  { id: 8001, name: '8001 Novo mesto - poštni predali' },
  { id: 6243, name: '6243 Obrov' },
  { id: 9233, name: '9233 Odranci' },
  { id: 2317, name: '2317 Oplotnica' },
  { id: 2312, name: '2312 Orehova vas' },
  { id: 2270, name: '2270 Ormož' },
  { id: 1316, name: '1316 Ortnek' },
  { id: 1337, name: '1337 Osilnica' },
  { id: 8222, name: '8222 Otočec' },
  { id: 2361, name: '2361 Ožbalt' },
  { id: 2231, name: '2231 Pernica' },
  { id: 2211, name: '2211 Pesnica pri Mariboru' },
  { id: 9203, name: '9203 Petrovci' },
  { id: 3301, name: '3301 Petrovče' },
  { id: 6330, name: '6330 Piran/Pirano' },
  { id: 8255, name: '8255 Pišece' },
  { id: 6257, name: '6257 Pivka' },
  { id: 6232, name: '6232 Planina' },
  { id: 3225, name: '3225 Planina pri Sevnici' },
  { id: 6276, name: '6276 Pobegi' },
  { id: 8312, name: '8312 Podbočje' },
  { id: 5243, name: '5243 Podbrdo' },
  { id: 3254, name: '3254 Podčetrtek' },
  { id: 2273, name: '2273 Podgorci' },
  { id: 6216, name: '6216 Podgorje' },
  { id: 2381, name: '2381 Podgorje pri Slovenj Gradcu' },
  { id: 6244, name: '6244 Podgrad' },
  { id: 1414, name: '1414 Podkum' },
  { id: 2286, name: '2286 Podlehnik' },
  { id: 5272, name: '5272 Podnanos' },
  { id: 4244, name: '4244 Podnart' },
  { id: 3241, name: '3241 Podplat' },
  { id: 3257, name: '3257 Podsreda' },
  { id: 2363, name: '2363 Podvelka' },
  { id: 2208, name: '2208 Pohorje' },
  { id: 2257, name: '2257 Polenšak' },
  { id: 1355, name: '1355 Polhov Gradec' },
  { id: 4223, name: '4223 Poljane nad Škofjo Loko' },
  { id: 2319, name: '2319 Poljčane' },
  { id: 1272, name: '1272 Polšnik' },
  { id: 3313, name: '3313 Polzela' },
  { id: 3232, name: '3232 Ponikva' },
  { id: 6320, name: '6320 Portorož/Portorose' },
  { id: 6230, name: '6230 Postojna' },
  { id: 2331, name: '2331 Pragersko' },
  { id: 3312, name: '3312 Prebold' },
  { id: 4205, name: '4205 Preddvor' },
  { id: 6255, name: '6255 Prem' },
  { id: 1352, name: '1352 Preserje' },
  { id: 6258, name: '6258 Prestranek' },
  { id: 2391, name: '2391 Prevalje' },
  { id: 3262, name: '3262 Prevorje' },
  { id: 1276, name: '1276 Primskovo' },
  { id: 3253, name: '3253 Pristava pri Mestinju' },
  { id: 9207, name: '9207 Prosenjakovci/Partosfalva' },
  { id: 5297, name: '5297 Prvačina' },
  { id: 2250, name: '2250 Ptuj' },
  { id: 2323, name: '2323 Ptujska Gora' },
  { id: 9201, name: '9201 Puconci' },
  { id: 2327, name: '2327 Rače' },
  { id: 1433, name: '1433 Radeče' },
  { id: 9252, name: '9252 Radenci' },
  { id: 2360, name: '2360 Radlje ob Dravi' },
  { id: 1235, name: '1235 Radomlje' },
  { id: 4240, name: '4240 Radovljica' },
  { id: 8274, name: '8274 Raka' },
  { id: 1381, name: '1381 Rakek' },
  { id: 4283, name: '4283 Rateče - Planica' },
  { id: 2390, name: '2390 Ravne na Koroškem' },
  { id: 9246, name: '9246 Razkrižje' },
  { id: 3332, name: '3332 Rečica ob Savinji' },
  { id: 5292, name: '5292 Renče' },
  { id: 1310, name: '1310 Ribnica' },
  { id: 2364, name: '2364 Ribnica na Pohorju' },
  { id: 3272, name: '3272 Rimske Toplice' },
  { id: 1314, name: '1314 Rob' },
  { id: 5215, name: '5215 Ročinj' },
  { id: 3250, name: '3250 Rogaška Slatina' },
  { id: 9262, name: '9262 Rogašovci' },
  { id: 3252, name: '3252 Rogatec' },
  { id: 1373, name: '1373 Rovte' },
  { id: 2342, name: '2342 Ruše' },
  { id: 1282, name: '1282 Sava' },
  { id: 6333, name: '6333 Sečovlje/Sicciole' },
  { id: 4227, name: '4227 Selca' },
  { id: 2352, name: '2352 Selnica ob Dravi' },
  { id: 8333, name: '8333 Semič' },
  { id: 8281, name: '8281 Senovo' },
  { id: 6224, name: '6224 Senožeče' },
  { id: 8290, name: '8290 Sevnica' },
  { id: 6210, name: '6210 Sežana' },
  { id: 2214, name: '2214 Sladki Vrh' },
  { id: 5283, name: '5283 Slap ob Idrijci' },
  { id: 2380, name: '2380 Slovenj Gradec' },
  { id: 2310, name: '2310 Slovenska Bistrica' },
  { id: 3210, name: '3210 Slovenske Konjice' },
  { id: 1216, name: '1216 Smlednik' },
  { id: 5232, name: '5232 Soča' },
  { id: 1317, name: '1317 Sodražica' },
  { id: 3335, name: '3335 Solčava' },
  { id: 5250, name: '5250 Solkan' },
  { id: 4229, name: '4229 Sorica' },
  { id: 4225, name: '4225 Sovodenj' },
  { id: 5281, name: '5281 Spodnja Idrija' },
  { id: 2241, name: '2241 Spodnji Duplek' },
  { id: 9245, name: '9245 Spodnji Ivanjci' },
  { id: 2277, name: '2277 Središče ob Dravi' },
  { id: 4267, name: '4267 Srednja vas v Bohinju' },
  { id: 8256, name: '8256 Sromlje' },
  { id: 5224, name: '5224 Srpenica' },
  { id: 1242, name: '1242 Stahovica' },
  { id: 1332, name: '1332 Stara Cerkev' },
  { id: 8342, name: '8342 Stari trg ob Kolpi' },
  { id: 1386, name: '1386 Stari trg pri Ložu' },
  { id: 2205, name: '2205 Starše' },
  { id: 2289, name: '2289 Stoperce' },
  { id: 8322, name: '8322 Stopiče' },
  { id: 3206, name: '3206 Stranice' },
  { id: 8351, name: '8351 Straža' },
  { id: 1313, name: '1313 Struge' },
  { id: 8293, name: '8293 Studenec' },
  { id: 8331, name: '8331 Suhor' },
  { id: 2353, name: '2353 Sv. Duh na Ostrem Vrhu' },
  { id: 2233, name: '2233 Sveta Ana v Slovenskih goricah' },
  { id: 2235, name: '2235 Sveta Trojica v Slovenskih goricah' },
  { id: 9244, name: '9244 Sveti Jurij ob Ščavnici' },
  { id: 3264, name: '3264 Sveti Štefan' },
  { id: 2258, name: '2258 Sveti Tomaž' },
  { id: 9204, name: '9204 Šalovci' },
  { id: 5261, name: '5261 Šempas' },
  { id: 5290, name: '5290 Šempeter pri Gorici' },
  { id: 3311, name: '3311 Šempeter v Savinjski dolini' },
  { id: 4208, name: '4208 Šenčur' },
  { id: 2212, name: '2212 Šentilj v Slovenskih goricah' },
  { id: 8297, name: '8297 Šentjanž' },
  { id: 2373, name: '2373 Šentjanž pri Dravogradu' },
  { id: 8310, name: '8310 Šentjernej' },
  { id: 3230, name: '3230 Šentjur' },
  { id: 3271, name: '3271 Šentrupert' },
  { id: 8232, name: '8232 Šentrupert' },
  { id: 1296, name: '1296 Šentvid pri Stični' },
  { id: 8275, name: '8275 Škocjan' },
  { id: 6281, name: '6281 Škofije' },
  { id: 4220, name: '4220 Škofja Loka' },
  { id: 3211, name: '3211 Škofja vas' },
  { id: 1291, name: '1291 Škofljica' },
  { id: 6274, name: '6274 Šmarje' },
  { id: 1293, name: '1293 Šmarje - Sap' },
  { id: 3240, name: '3240 Šmarje pri Jelšah' },
  { id: 8220, name: '8220 Šmarješke Toplice' },
  { id: 2315, name: '2315 Šmartno na Pohorju' },
  { id: 3341, name: '3341 Šmartno ob Dreti' },
  { id: 3327, name: '3327 Šmartno ob Paki' },
  { id: 1275, name: '1275 Šmartno pri Litiji' },
  { id: 2383, name: '2383 Šmartno pri Slovenj Gradcu' },
  { id: 3201, name: '3201 Šmartno v Rožni dolini' },
  { id: 3325, name: '3325 Šoštanj' },
  { id: 6222, name: '6222 Štanjel' },
  { id: 3220, name: '3220 Štore' },
  { id: 3304, name: '3304 Tabor' },
  { id: 3221, name: '3221 Teharje' },
  { id: 9251, name: '9251 Tišina' },
  { id: 5220, name: '5220 Tolmin' },
  { id: 3326, name: '3326 Topolšica' },
  { id: 2371, name: '2371 Trbonje' },
  { id: 1420, name: '1420 Trbovlje' },
  { id: 8231, name: '8231 Trebelno' },
  { id: 8210, name: '8210 Trebnje' },
  { id: 5252, name: '5252 Trnovo pri Gorici' },
  { id: 2254, name: '2254 Trnovska vas' },
  { id: 1222, name: '1222 Trojane' },
  { id: 1236, name: '1236 Trzin' },
  { id: 4290, name: '4290 Tržič' },
  { id: 8295, name: '8295 Tržišče' },
  { id: 1311, name: '1311 Turjak' },
  { id: 9224, name: '9224 Turnišče' },
  { id: 8323, name: '8323 Uršna sela' },
  { id: 1252, name: '1252 Vače' },
  { id: 3320, name: '3320 Velenje' },
  { id: 3322, name: '3322 Velenje' },
  { id: 8212, name: '8212 Velika Loka' },
  { id: 2274, name: '2274 Velika Nedelja' },
  { id: 9225, name: '9225 Velika Polana' },
  { id: 1315, name: '1315 Velike Lašče' },
  { id: 8213, name: '8213 Veliki Gaber' },
  { id: 9241, name: '9241 Veržej' },
  { id: 1312, name: '1312 Videm - Dobrepolje' },
  { id: 2284, name: '2284 Videm pri Ptuju' },
  { id: 8344, name: '8344 Vinica' },
  { id: 5271, name: '5271 Vipava' },
  { id: 4212, name: '4212 Visoko' },
  { id: 1294, name: '1294 Višnja Gora' },
  { id: 3205, name: '3205 Vitanje' },
  { id: 2255, name: '2255 Vitomarci' },
  { id: 1217, name: '1217 Vodice' },
  { id: 3212, name: '3212 Vojnik' },
  { id: 5293, name: '5293 Volčja Draga' },
  { id: 2232, name: '2232 Voličina' },
  { id: 3305, name: '3305 Vransko' },
  { id: 6217, name: '6217 Vremski Britof' },
  { id: 1360, name: '1360 Vrhnika' },
  { id: 2365, name: '2365 Vuhred' },
  { id: 2367, name: '2367 Vuzenica' },
  { id: 8292, name: '8292 Zabukovje' },
  { id: 1410, name: '1410 Zagorje ob Savi' },
  { id: 1303, name: '1303 Zagradec' },
  { id: 2283, name: '2283 Zavrč' },
  { id: 8272, name: '8272 Zdole' },
  { id: 4201, name: '4201 Zgornja Besnica' },
  { id: 2242, name: '2242 Zgornja Korena' },
  { id: 2201, name: '2201 Zgornja Kungota' },
  { id: 2316, name: '2316 Zgornja Ložnica' },
  { id: 2314, name: '2314 Zgornja Polskava' },
  { id: 2213, name: '2213 Zgornja Velka' },
  { id: 4247, name: '4247 Zgornje Gorje' },
  { id: 4206, name: '4206 Zgornje Jezersko' },
  { id: 2285, name: '2285 Zgornji Leskovec' },
  { id: 1432, name: '1432 Zidani Most' },
  { id: 3214, name: '3214 Zreče' },
  { id: 4209, name: '4209 Žabnica' },
  { id: 3310, name: '3310 Žalec' },
  { id: 4228, name: '4228 Železniki' },
  { id: 2287, name: '2287 Žetale' },
  { id: 4226, name: '4226 Žiri' },
  { id: 4274, name: '4274 Žirovnica' },
  { id: 8360, name: '8360 Žužemberk' },
];

export const SLOVENIAN_PLZ_CITY_MAP = {
  8341: 'Adlešiči',
  5270: 'Ajdovščina',
  6280: 'Ankaran/Ancarano',
  9253: 'Apače',
  8253: 'Artiče',
  4275: 'Begunje na Gorenjskem',
  1382: 'Begunje pri Cerknici',
  9231: 'Beltinci',
  2234: 'Benedikt',
  2345: 'Bistrica ob Dravi',
  3256: 'Bistrica ob Sotli',
  8259: 'Bizeljsko',
  1223: 'Blagovica',
  8283: 'Blanca',
  4260: 'Bled',
  4273: 'Blejska Dobrava',
  9265: 'Bodonci',
  9222: 'Bogojina',
  4263: 'Bohinjska Bela',
  4264: 'Bohinjska Bistrica',
  4265: 'Bohinjsko jezero',
  1353: 'Borovnica',
  8294: 'Boštanj',
  5230: 'Bovec',
  5295: 'Branik',
  3314: 'Braslovče',
  5223: 'Breginj',
  8280: 'Brestanica',
  2354: 'Bresternica',
  4243: 'Brezje',
  1351: 'Brezovica pri Ljubljani',
  8250: 'Brežice',
  4210: 'Brnik - aerodrom',
  8321: 'Brusnice',
  3255: 'Buče',
  8276: 'Bučka',
  9261: 'Cankova',
  3000: 'Celje',
  3001: 'Celje - poštni predali',
  4207: 'Cerklje na Gorenjskem',
  8263: 'Cerklje ob Krki',
  1380: 'Cerknica',
  5282: 'Cerkno',
  2236: 'Cerkvenjak',
  2215: 'Ceršak',
  2326: 'Cirkovce',
  2282: 'Cirkulane',
  5273: 'Col',
  8251: 'Čatež ob Savi',
  1413: 'Čemšenik',
  5253: 'Čepovan',
  9232: 'Črenšovci',
  2393: 'Črna na Koroškem',
  6275: 'Črni Kal',
  5274: 'Črni Vrh nad Idrijo',
  5262: 'Črniče',
  8340: 'Črnomelj',
  6271: 'Dekani',
  5210: 'Deskle',
  2253: 'Destrnik',
  6215: 'Divača',
  1233: 'Dob',
  3224: 'Dobje pri Planini',
  8257: 'Dobova',
  1423: 'Dobovec',
  5263: 'Dobravlje',
  3204: 'Dobrna',
  8211: 'Dobrnič',
  1356: 'Dobrova',
  9223: 'Dobrovnik/Dobronak',
  5212: 'Dobrovo v Brdih',
  1431: 'Dol pri Hrastniku',
  1262: 'Dol pri Ljubljani',
  1273: 'Dole pri Litiji',
  1331: 'Dolenja vas',
  8350: 'Dolenjske Toplice',
  1230: 'Domžale',
  2252: 'Dornava',
  5294: 'Dornberk',
  1319: 'Draga',
  8343: 'Dragatuš',
  3222: 'Dramlje',
  2370: 'Dravograd',
  4203: 'Duplje',
  6221: 'Dutovlje',
  8361: 'Dvor',
  2343: 'Fala',
  9208: 'Fokovci',
  2313: 'Fram',
  3213: 'Frankolovo',
  1274: 'Gabrovka',
  8254: 'Globoko',
  5275: 'Godovič',
  4204: 'Golnik',
  3303: 'Gomilsko',
  4224: 'Gorenja vas',
  3263: 'Gorica pri Slivnici',
  2272: 'Gorišnica',
  9250: 'Gornja Radgona',
  3342: 'Gornji Grad',
  4282: 'Gozd Martuljek',
  6272: 'Gračišče',
  9264: 'Grad',
  8332: 'Gradac',
  1384: 'Grahovo',
  5242: 'Grahovo ob Bači',
  5251: 'Grgar',
  3302: 'Griže',
  3231: 'Grobelno',
  1290: 'Grosuplje',
  2288: 'Hajdina',
  8362: 'Hinje',
  2311: 'Hoče',
  9205: 'Hodoš/Hodos',
  1354: 'Horjul',
  1372: 'Hotedršica',
  1430: 'Hrastnik',
  6225: 'Hruševje',
  4276: 'Hrušica',
  5280: 'Idrija',
  1292: 'Ig',
  6250: 'Ilirska Bistrica',
  6251: 'Ilirska Bistrica - Trnovo',
  1295: 'Ivančna Gorica',
  2259: 'Ivanjkovci',
  1411: 'Izlake',
  6310: 'Izola/Isola',
  2222: 'Jakobski Dol',
  2221: 'Jarenina',
  6254: 'Jelšane',
  4270: 'Jesenice',
  8261: 'Jesenice na Dolenjskem',
  3273: 'Jurklošter',
  2223: 'Jurovski Dol',
  2256: 'Juršinci',
  5214: 'Kal nad Kanalom',
  3233: 'Kalobje',
  4246: 'Kamna Gorica',
  2351: 'Kamnica',
  1241: 'Kamnik',
  5213: 'Kanal',
  8258: 'Kapele',
  2362: 'Kapla',
  2325: 'Kidričevo',
  1412: 'Kisovec',
  6253: 'Knežak',
  5222: 'Kobarid',
  9227: 'Kobilje',
  1330: 'Kočevje',
  1338: 'Kočevska Reka',
  2276: 'Kog',
  5211: 'Kojsko',
  6223: 'Komen',
  1218: 'Komenda',
  6000: 'Koper - Capodistria',
  6001: 'Koper - Capodistria - poštni predali',
  8282: 'Koprivnica',
  5296: 'Kostanjevica na Krasu',
  8311: 'Kostanjevica na Krki',
  1336: 'Kostel',
  6256: 'Košana',
  2394: 'Kotlje',
  6240: 'Kozina',
  3260: 'Kozje',
  4000: 'Kranj',
  4001: 'Kranj - poštni predali',
  4280: 'Kranjska Gora',
  1281: 'Kresnice',
  4294: 'Križe',
  9206: 'Križevci',
  9242: 'Križevci pri Ljutomeru',
  1301: 'Krka',
  8296: 'Krmelj',
  4245: 'Kropa',
  8262: 'Krška vas',
  8270: 'Krško',
  9263: 'Kuzma',
  2318: 'Laporje',
  3270: 'Laško',
  1219: 'Laze v Tuhinju',
  2230: 'Lenart v Slovenskih goricah',
  9220: 'Lendava/Lendva',
  4248: 'Lesce',
  3261: 'Lesično',
  8273: 'Leskovec pri Krškem',
  2372: 'Libeliče',
  2341: 'Limbuš',
  1270: 'Litija',
  3202: 'Ljubečna',
  1000: 'Ljubljana',
  1001: 'Ljubljana - poštni predali',
  1231: 'Ljubljana - Črnuče',
  1261: 'Ljubljana - Dobrunje',
  1260: 'Ljubljana - Polje',
  1002: 'Ljubljana - poštni center',
  1210: 'Ljubljana - Šentvid',
  1211: 'Ljubljana - Šmartno',
  3333: 'Ljubno ob Savinji',
  9240: 'Ljutomer',
  3215: 'Loče',
  5231: 'Log pod Mangartom',
  1358: 'Log pri Brezovici',
  1370: 'Logatec',
  1434: 'Loka pri Zidanem Mostu',
  3223: 'Loka pri Žusmu',
  6219: 'Lokev',
  1318: 'Loški Potok',
  2324: 'Lovrenc na Dravskem polju',
  2344: 'Lovrenc na Pohorju',
  3334: 'Luče',
  1225: 'Lukovica',
  9202: 'Mačkovci',
  2322: 'Majšperk',
  2321: 'Makole',
  9243: 'Mala Nedelja',
  2229: 'Malečnik',
  6273: 'Marezige',
  2000: 'Maribor',
  2001: 'Maribor - poštni predali',
  2206: 'Marjeta na Dravskem polju',
  2281: 'Markovci',
  9221: 'Martjanci',
  6242: 'Materija',
  4211: 'Mavčiče',
  1215: 'Medvode',
  1234: 'Mengeš',
  8330: 'Metlika',
  2392: 'Mežica',
  2204: 'Miklavž na Dravskem polju',
  2275: 'Miklavž pri Ormožu',
  5291: 'Miren',
  8233: 'Mirna',
  8216: 'Mirna Peč',
  2382: 'Mislinja',
  4281: 'Mojstrana',
  8230: 'Mokronog',
  1251: 'Moravče',
  9226: 'Moravske Toplice',
  5216: 'Most na Soči',
  1221: 'Motnik',
  3330: 'Mozirje',
  9000: 'Murska Sobota',
  9001: 'Murska Sobota - poštni predali',
  2366: 'Muta',
  4202: 'Naklo',
  3331: 'Nazarje',
  1357: 'Notranje Gorice',
  3203: 'Nova Cerkev',
  5000: 'Nova Gorica',
  5001: 'Nova Gorica - poštni predali',
  1385: 'Nova vas',
  8000: 'Novo mesto',
  8001: 'Novo mesto - poštni predali',
  6243: 'Obrov',
  9233: 'Odranci',
  2317: 'Oplotnica',
  2312: 'Orehova vas',
  2270: 'Ormož',
  1316: 'Ortnek',
  1337: 'Osilnica',
  8222: 'Otočec',
  2361: 'Ožbalt',
  2231: 'Pernica',
  2211: 'Pesnica pri Mariboru',
  9203: 'Petrovci',
  3301: 'Petrovče',
  6330: 'Piran/Pirano',
  8255: 'Pišece',
  6257: 'Pivka',
  6232: 'Planina',
  3225: 'Planina pri Sevnici',
  6276: 'Pobegi',
  8312: 'Podbočje',
  5243: 'Podbrdo',
  3254: 'Podčetrtek',
  2273: 'Podgorci',
  6216: 'Podgorje',
  2381: 'Podgorje pri Slovenj Gradcu',
  6244: 'Podgrad',
  1414: 'Podkum',
  2286: 'Podlehnik',
  5272: 'Podnanos',
  4244: 'Podnart',
  3241: 'Podplat',
  3257: 'Podsreda',
  2363: 'Podvelka',
  2208: 'Pohorje',
  2257: 'Polenšak',
  1355: 'Polhov Gradec',
  4223: 'Poljane nad Škofjo Loko',
  2319: 'Poljčane',
  1272: 'Polšnik',
  3313: 'Polzela',
  3232: 'Ponikva',
  6320: 'Portorož/Portorose',
  6230: 'Postojna',
  2331: 'Pragersko',
  3312: 'Prebold',
  4205: 'Preddvor',
  6255: 'Prem',
  1352: 'Preserje',
  6258: 'Prestranek',
  2391: 'Prevalje',
  3262: 'Prevorje',
  1276: 'Primskovo',
  3253: 'Pristava pri Mestinju',
  9207: 'Prosenjakovci/Partosfalva',
  5297: 'Prvačina',
  2250: 'Ptuj',
  2323: 'Ptujska Gora',
  9201: 'Puconci',
  2327: 'Rače',
  1433: 'Radeče',
  9252: 'Radenci',
  2360: 'Radlje ob Dravi',
  1235: 'Radomlje',
  4240: 'Radovljica',
  8274: 'Raka',
  1381: 'Rakek',
  4283: 'Rateče - Planica',
  2390: 'Ravne na Koroškem',
  9246: 'Razkrižje',
  3332: 'Rečica ob Savinji',
  5292: 'Renče',
  1310: 'Ribnica',
  2364: 'Ribnica na Pohorju',
  3272: 'Rimske Toplice',
  1314: 'Rob',
  5215: 'Ročinj',
  3250: 'Rogaška Slatina',
  9262: 'Rogašovci',
  3252: 'Rogatec',
  1373: 'Rovte',
  2342: 'Ruše',
  1282: 'Sava',
  6333: 'Sečovlje/Sicciole',
  4227: 'Selca',
  2352: 'Selnica ob Dravi',
  8333: 'Semič',
  8281: 'Senovo',
  6224: 'Senožeče',
  8290: 'Sevnica',
  6210: 'Sežana',
  2214: 'Sladki Vrh',
  5283: 'Slap ob Idrijci',
  2380: 'Slovenj Gradec',
  2310: 'Slovenska Bistrica',
  3210: 'Slovenske Konjice',
  1216: 'Smlednik',
  5232: 'Soča',
  1317: 'Sodražica',
  3335: 'Solčava',
  5250: 'Solkan',
  4229: 'Sorica',
  4225: 'Sovodenj',
  5281: 'Spodnja Idrija',
  2241: 'Spodnji Duplek',
  9245: 'Spodnji Ivanjci',
  2277: 'Središče ob Dravi',
  4267: 'Srednja vas v Bohinju',
  8256: 'Sromlje',
  5224: 'Srpenica',
  1242: 'Stahovica',
  1332: 'Stara Cerkev',
  8342: 'Stari trg ob Kolpi',
  1386: 'Stari trg pri Ložu',
  2205: 'Starše',
  2289: 'Stoperce',
  8322: 'Stopiče',
  3206: 'Stranice',
  8351: 'Straža',
  1313: 'Struge',
  8293: 'Studenec',
  8331: 'Suhor',
  2353: 'Sv. Duh na Ostrem Vrhu',
  2233: 'Sveta Ana v Slovenskih goricah',
  2235: 'Sveta Trojica v Slovenskih goricah',
  9244: 'Sveti Jurij ob Ščavnici',
  3264: 'Sveti Štefan',
  2258: 'Sveti Tomaž',
  9204: 'Šalovci',
  5261: 'Šempas',
  5290: 'Šempeter pri Gorici',
  3311: 'Šempeter v Savinjski dolini',
  4208: 'Šenčur',
  2212: 'Šentilj v Slovenskih goricah',
  8297: 'Šentjanž',
  2373: 'Šentjanž pri Dravogradu',
  8310: 'Šentjernej',
  3230: 'Šentjur',
  3271: 'Šentrupert',
  8232: 'Šentrupert',
  1296: 'Šentvid pri Stični',
  8275: 'Škocjan',
  6281: 'Škofije',
  4220: 'Škofja Loka',
  3211: 'Škofja vas',
  1291: 'Škofljica',
  6274: 'Šmarje',
  1293: 'Šmarje - Sap',
  3240: 'Šmarje pri Jelšah',
  8220: 'Šmarješke Toplice',
  2315: 'Šmartno na Pohorju',
  3341: 'Šmartno ob Dreti',
  3327: 'Šmartno ob Paki',
  1275: 'Šmartno pri Litiji',
  2383: 'Šmartno pri Slovenj Gradcu',
  3201: 'Šmartno v Rožni dolini',
  3325: 'Šoštanj',
  6222: 'Štanjel',
  3220: 'Štore',
  3304: 'Tabor',
  3221: 'Teharje',
  9251: 'Tišina',
  5220: 'Tolmin',
  3326: 'Topolšica',
  2371: 'Trbonje',
  1420: 'Trbovlje',
  8231: 'Trebelno',
  8210: 'Trebnje',
  5252: 'Trnovo pri Gorici',
  2254: 'Trnovska vas',
  1222: 'Trojane',
  1236: 'Trzin',
  4290: 'Tržič',
  8295: 'Tržišče',
  1311: 'Turjak',
  9224: 'Turnišče',
  8323: 'Uršna sela',
  1252: 'Vače',
  3320: 'Velenje',
  3322: 'Velenje',
  8212: 'Velika Loka',
  2274: 'Velika Nedelja',
  9225: 'Velika Polana',
  1315: 'Velike Lašče',
  8213: 'Veliki Gaber',
  9241: 'Veržej',
  1312: 'Videm - Dobrepolje',
  2284: 'Videm pri Ptuju',
  8344: 'Vinica',
  5271: 'Vipava',
  4212: 'Visoko',
  1294: 'Višnja Gora',
  3205: 'Vitanje',
  2255: 'Vitomarci',
  1217: 'Vodice',
  3212: 'Vojnik',
  5293: 'Volčja Draga',
  2232: 'Voličina',
  3305: 'Vransko',
  6217: 'Vremski Britof',
  1360: 'Vrhnika',
  2365: 'Vuhred',
  2367: 'Vuzenica',
  8292: 'Zabukovje',
  1410: 'Zagorje ob Savi',
  1303: 'Zagradec',
  2283: 'Zavrč',
  8272: 'Zdole',
  4201: 'Zgornja Besnica',
  2242: 'Zgornja Korena',
  2201: 'Zgornja Kungota',
  2316: 'Zgornja Ložnica',
  2314: 'Zgornja Polskava',
  2213: 'Zgornja Velka',
  4247: 'Zgornje Gorje',
  4206: 'Zgornje Jezersko',
  2285: 'Zgornji Leskovec',
  1432: 'Zidani Most',
  3214: 'Zreče',
  4209: 'Žabnica',
  3310: 'Žalec',
  4228: 'Železniki',
  2287: 'Žetale',
  4226: 'Žiri',
  4274: 'Žirovnica',
  8360: 'Žužemberk',
};
