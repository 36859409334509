import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';
import { BillActionBasic } from '../models/bill.model';

@Pipe({
  name: 'mapBabToPoa',
})
export class MapBillActionBasicToPoaPipe implements PipeTransform {
  transform(bab: BillActionBasic[]): PatientOfferedAction[] {
    return bab
      .map((b) => b?.patientOfferedAction);
  }
}
