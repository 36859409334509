import { Pipe, PipeTransform } from '@angular/core';
import { TeethType } from '../models/patient-action.model';

@Pipe({
  name: 'patientActionType',
})
export class PatientActionTypePipe implements PipeTransform {
  transform(patientActionType: TeethType): string {
    switch (patientActionType) {
      case TeethType.top_bottom:
        return 'Zgoraj-Spodaj';
      case TeethType.all:
        return 'Vsi';
      case TeethType.single:
      default:
        return 'Posamično';
    }
  }
}
