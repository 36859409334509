import { Pipe, PipeTransform } from '@angular/core';
import {
  InvoiceAction,
  SimplifiedActionForInvoice,
} from '../models/invoice.model';

@Pipe({
  name: 'formatInvoiceActionsForPrint',
})
export class FormatInvoiceActionsForPrintPipe implements PipeTransform {
  transform(poas: InvoiceAction[]): SimplifiedActionForInvoice[] {
    return formatInvoiceActionsForPrint(poas);
  }
}

export function formatInvoiceActionsForPrint(
  poas: InvoiceAction[]
): SimplifiedActionForInvoice[] {
  return [
    ...poas
      .reduce((accumulator, ia) => {
        if (accumulator.has(ia.patientOfferedAction.patientActionId)) {
          if (ia.patientOfferedAction.status !== 'open') {
            accumulator.get(ia.patientOfferedAction.patientActionId).status =
              ia.patientOfferedAction.status;
          }
          accumulator.get(ia.patientOfferedAction.patientActionId).amount =
            accumulator.get(ia.patientOfferedAction.patientActionId).amount + 1;
          accumulator
            .get(ia.patientOfferedAction.patientActionId)
            .poas.push(ia.patientOfferedAction);
        } else {
          accumulator.set(ia.patientOfferedAction.patientActionId, {
            id: ia.patientOfferedAction.patientActionId,
            name: ia.patientOfferedAction.patientAction.name,
            price: ia.currentPrice,
            discount: ia.discount,
            status: ia.patientOfferedAction.status,
            amount: 1,
            poas: [ia.patientOfferedAction],
          });
        }
        return accumulator;
      }, new Map<number, SimplifiedActionForInvoice>())
      .values(),
  ];
}
