import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-text-form-dialog',
  templateUrl: './text-form-dialog.component.html',
  styleUrls: ['./text-form-dialog.component.scss'],
})
export class TextFormDialogComponent implements OnInit {
  public textFormControl: UntypedFormControl;

  constructor(
    private dialogRef: MatDialogRef<TextFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      actionText;
      dataName: string;
      text: string;
      canBeNull: boolean;
      height: string;
    }
  ) {
    this.textFormControl = new UntypedFormControl(
      '',
      this.data.canBeNull ? Validators.required : []
    );
  }

  ngOnInit(): void {
    this.textFormControl.setValue(this.data.text);
  }

  public save(): void {
    if (!this.textFormControl.valid) {
      return;
    }
    this.dialogRef.close(this.textFormControl.value);
  }
}
