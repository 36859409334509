<mat-dialog-content>
  <h2>{{ title }}</h2>

  <form [formGroup]="patientCallForm" (ngSubmit)="save()" novalidate>
    <div class="double">
      <div class="form-group">
        <label for="dateInput">Datum</label>
        <input type="date"
               [class]="'form-control ' + (patientCallForm.get('date').touched && !patientCallForm.get('date').valid ? 'is-invalid' : '')"
               id="dateInput" placeholder="Datum" formControlName="date">
        <div class="invalid-feedback text-danger" *ngIf="patientCallForm.get('date').hasError('required')">
          Polje je obvezno!
        </div>
      </div>

      <div class="form-group">
        <label for="statusInput">Status klica</label>
        <select id="statusInput"
                [class]="'form-control ' + (patientCallForm.get('status').touched && !patientCallForm.get('status').valid ? 'is-invalid' : '')"
                formControlName="status">
          <option value="">Izberi status...</option>
          <option *ngFor="let status of data.patientCallStatuses" [value]="status.id">
            {{ status.name }}
          </option>
        </select>
        <div class="invalid-feedback text-danger" *ngIf="!patientCallForm.get('status').valid">
          Polje je obvezno!
        </div>
      </div>

      <div class="form-group">
        <label for="clinicLocationInput">Ordinacija zanimanja</label>
        <select id="clinicLocationInput"
                [class]="'form-control ' + (patientCallForm.get('clinicLocation').touched && !patientCallForm.get('clinicLocation').valid ? 'is-invalid' : '')"
                formControlName="clinicLocation">
          <option value="">Izberi lokacijo...</option>
          <option
            *ngFor="let location of data.clinicLocations"
            [value]="location.id">
            {{ location.name }}
          </option>
        </select>
        <div class="invalid-feedback text-danger" *ngIf="!patientCallForm.get('clinicLocation').valid">
          Polje je obvezno!
        </div>
      </div>

      <div class="form-group">
        <label for="partOfDayInput">Del dneva</label>
        <select id="partOfDayInput"
                [class]="'form-control ' + (patientCallForm.get('partOfDay').touched && !patientCallForm.get('partOfDay').valid ? 'is-invalid' : '')"
                formControlName="partOfDay">
          <option value="">Izberi del dneva...</option>
          <option *ngFor="let part of data.partsOfDay" [value]="part.id">
            {{ part.name }}
          </option>
        </select>
        <div class="invalid-feedback text-danger" *ngIf="!patientCallForm.get('partOfDay').valid">
          Polje je obvezno!
        </div>
      </div>

      <div class="form-group">
        <label for="isUrgentInput">Je nujen</label>
        <select id="isUrgentInput" class="custom-select" formControlName="isUrgent">
          <option [value]="false">
            Ne
          </option>
          <option [value]="true">
            Da
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label for="contentInput">Vsebina pogovora</label>
      <textarea id="contentInput"
                class="form-control"
                placeholder="Vsebina pogovora"
                formControlName="content"></textarea>
    </div>
  </form>

  <div id="actionButtons">
    <div>
      <button class="btn btn-primary save-and-add" (click)="saveAndAddNew()">Dodaj naslednji klic</button>
    </div>
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" (click)="save()">{{ actionText }}</button>
    </div>
  </div>
</mat-dialog-content>