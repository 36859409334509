<mat-dialog-content>
  <h2> Izvidi </h2>

  <form id="form" [formGroup]="form">

    <mat-form-field class="example-full-width">
      <label for="doctor-input">Doktor</label>
      <input type="text"
             id="doctor-input"
             matInput
             formControlName="doctor"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let doctor of filteredDoctors$ | async" [value]="doctor">
          {{ doctor.userInfo.name }} {{ doctor.userInfo.lastName }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>Polje je obavezno!</mat-error>
    </mat-form-field>


    <div class="top-margin">
      <label for="date-input">Datum</label>
      <input type="date" id="date-input"
             [class]="'form-control ' + (form.controls.date.touched && form.controls.date.hasError('required') ? 'is-invalid' : '')"
             formControlName="date">
      <div class="invalid-feedback text-danger" *ngIf="form.controls.date.hasError('required')">
        Polje je obvezno!
      </div>
    </div>

    <div class="top-margin">
      <label>Ordinacija</label>
      <mat-select id='clinicLocationInput' *ngIf='data.addressData | async as data'
                  class='form-control'
                  formControlName="address">
        <mat-option
          *ngFor='let address of data.addresses'
          [value]='address.id'>
          {{ address.content }}
        </mat-option>
      </mat-select>
    </div>

    <div class="top-margin">
      <label for="text-input">Izvidi</label>
      <textarea id="text-input"
                [class]="'form-control ' + (form.controls.text.touched && form.controls.text.hasError('required') ? 'is-invalid' : '')"
                placeholder='Izvidi' formControlName="text"></textarea>
      <div class="invalid-feedback text-danger" *ngIf="form.controls.text.hasError('required')">
        Polje je obvezno!
      </div>
    </div>
  </form>

  <div id="actionButtons">
    <div></div>
    <div></div>
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" (click)="save()" [disabled]="!this.form.valid">Shrani</button>
    </div>
  </div>
</mat-dialog-content>