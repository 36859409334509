import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Patient } from '../../models/patient.model';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private readonly currentlyOpenPatient$ = new BehaviorSubject<Patient>(
    undefined
  );
  private readonly teethOverviewRefresh$ = new Subject<void>();
  private readonly activeDoctorRefresh$ = new Subject<void>();

  public getCurrentlyOpenPatient(): Observable<Patient> {
    return this.currentlyOpenPatient$.asObservable();
  }

  public dispatchNewPatient(patient: Patient): void {
    this.currentlyOpenPatient$.next(patient);
  }

  public dispatchRemovePatient(): void {
    this.currentlyOpenPatient$.next(undefined);
  }

  public getRefreshTeethOverview(): Observable<void> {
    return this.teethOverviewRefresh$.asObservable();
  }

  public dispatchRefreshTeethOverview(): void {
    this.teethOverviewRefresh$.next();
  }

  public getRefreshActiveDoctor(): Observable<void> {
    return this.activeDoctorRefresh$.asObservable();
  }

  public dispatchRefreshActiveDoctor(): void {
    this.activeDoctorRefresh$.next();
  }
}
