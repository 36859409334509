import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Patient, PatientsPagination } from '../../models/patient.model';
import { DateClearOffsetPipe } from '../../pipes/date-clear-offset.pipe';
import { Appointment } from '../../models/appointment.model';
import { TechnicianOrder } from '../../models/technician-order.model';
import { TreatmentInfo } from '../../models/treatment-info.model';
import { Comment } from '../../models/comment.model';
import { PatientCall } from '../../models/patient-call.model';
import { PatientInterest } from '../../models/patient-interest.model';
import { PatientGeneralRemark } from '../../models/patient-general-remark.model';
import { LocalStorageService } from './local-storage.service';
import { PatientSearchFilter } from './patient-search-store.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    private dateClearOffsetPipe: DateClearOffsetPipe,
    private storageService: LocalStorageService
  ) {
    this.apiUrl = environment.apiUrl + '/patient';
  }

  public get(patientId: number): Observable<Patient> {
    return this.http.get<Patient>(this.apiUrl + `/${patientId}`);
  }

  public add(patient: Patient): Observable<number> {
    const patientCpy = { ...patient };
    if (patientCpy.birthday) {
      patientCpy.birthday = this.dateClearOffsetPipe.transform(
        patientCpy.birthday
      );
    }
    patientCpy.creationDate = this.dateClearOffsetPipe.transform(
      patientCpy.creationDate
    );
    patientCpy.agent = null;
    patientCpy.patientCategory = null;
    patientCpy.media = null;

    return this.http.post<number>(this.apiUrl, patientCpy);
  }

  public update(patient: Patient): Observable<Patient> {
    const patientCpy = { ...patient };

    if (patientCpy.birthday) {
      patientCpy.birthday = this.dateClearOffsetPipe.transform(
        patientCpy.birthday
      );
    }

    patientCpy.creationDate = this.dateClearOffsetPipe.transform(
      patientCpy.creationDate
    );

    patientCpy.media = null;
    patientCpy.agent = null;
    patientCpy.patientCategory = null;

    return this.http.patch<Patient>(this.apiUrl, patientCpy);
  }

  public doesExist(
    name: string,
    lastName: string,
    phone: string,
    address: string,
    email: string,
    idCardNumber: string,
    clinicId: number
  ): Observable<boolean> {
    const namePart = name ? '"' + name + '"' : 'null';
    const lastNamePart = lastName ? '"' + lastName + '"' : 'null';
    const phonePart = phone ? '"' + phone + '"' : 'null';
    const addressPart = address ? '"' + address + '"' : 'null';
    const emailPart = email ? '"' + email + '"' : 'null';
    const idCardNumberPart = idCardNumber ? '"' + idCardNumber + '"' : 'null';
    const clinicIdPart = clinicId ? '"' + clinicId.toString() + '"' : 'null';

    let json = '{ ';
    json += '"name": ' + namePart;
    json += ',"lastName": ' + lastNamePart;
    json += ',"phone": ' + phonePart;
    json += ',"address": ' + addressPart;
    json += ',"email": ' + emailPart;
    json += ',"idCardNumber": ' + idCardNumberPart;
    json += ',"clinicId": ' + clinicIdPart;
    json += ' }';

    const encodedParams = btoa(unescape(encodeURIComponent(json)));
    return this.http.get<boolean>(this.apiUrl + `/does-exist/${encodedParams}`);
  }

  public getByClinicIdAndSearch(
    clinicId: number,
    searchPattern: string,
    page: number,
    perPage: number
  ): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      this.apiUrl +
        `/by-clinic-id-and-search/${clinicId}/${searchPattern}?page=${page}&perPage=${perPage}`
    );
  }

  public getPatientTreatmentInfo(patientId: number): Observable<TreatmentInfo> {
    return this.http.get<TreatmentInfo>(
      this.apiUrl + `/${patientId}/treatment-info`
    );
  }

  public getPatientAppointments(patientId: number): Observable<Appointment[]> {
    return this.http.get<Appointment[]>(
      this.apiUrl + `/${patientId}/appointments`
    );
  }

  public getPatientGeneralRemarks(
    patientId: number
  ): Observable<PatientGeneralRemark[]> {
    return this.http.get<PatientGeneralRemark[]>(
      this.apiUrl + `/${patientId}/general-remarks`
    );
  }

  public getPatientComments(patientId: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.apiUrl + `/${patientId}/comments`);
  }

  public getPatientPatientCalls(patientId: number): Observable<PatientCall[]> {
    return this.http.get<PatientCall[]>(
      this.apiUrl + `/${patientId}/patient-calls`
    );
  }

  public getPatientTechnicianOrders(
    patientId: number
  ): Observable<TechnicianOrder[]> {
    return this.http.get<TechnicianOrder[]>(
      this.apiUrl + `/${patientId}/technician-orders`
    );
  }

  public getPatientConnections(patientId: number): Observable<Patient[]> {
    return this.http.get<Patient[]>(this.apiUrl + `/${patientId}/connections`);
  }

  public addPatientConnection(
    patientId: number,
    secondPatientId: number
  ): Observable<number> {
    return this.http.put<number>(
      this.apiUrl + `/${patientId}/connections/${secondPatientId}`,
      null
    );
  }
  public deletePatientConnection(
    patientId: number,
    secondPatientId: number
  ): Observable<any> {
    return this.http.delete<any>(
      this.apiUrl + `/${patientId}/connections/${secondPatientId}`
    );
  }

  public getPatientPatientInterests(
    patientId: number
  ): Observable<PatientInterest[]> {
    return this.http.get<Patient[]>(
      this.apiUrl + `/${patientId}/patient-interests`
    );
  }

  public addPatientInterest(
    patientId: number,
    patientInterestId: number
  ): Observable<number> {
    return this.http.put<number>(
      this.apiUrl + `/${patientId}/patient-interests/${patientInterestId}`,
      null
    );
  }
  public deletePatientPatientInterest(
    patientId: number,
    patientInterestId: number
  ): Observable<any> {
    return this.http.delete<any>(
      this.apiUrl + `/${patientId}/patient-interests/${patientInterestId}`
    );
  }

  public searchPatient(
    searchFilter: PatientSearchFilter
  ): Observable<PatientsPagination> {
    let queryFilters = `?page=${searchFilter?.page ?? 0}&perPage=30`;
    if (searchFilter?.sort) {
      queryFilters += `&field=${searchFilter?.sort.field}&order=${searchFilter?.sort.order}`;
    }
    if (searchFilter?.name) {
      queryFilters += `&name=${searchFilter?.name}`;
    }
    if (searchFilter?.address) {
      queryFilters += `&address=${searchFilter?.address}`;
    }
    if (searchFilter?.postCode) {
      queryFilters += `&postCode=${searchFilter?.postCode}`;
    }
    if (searchFilter?.phone) {
      queryFilters += `&phone=${searchFilter?.phone}`;
    }
    if (searchFilter?.email) {
      queryFilters += `&email=${searchFilter?.email}`;
    }
    if (searchFilter?.mediaId) {
      queryFilters += `&media=${searchFilter?.mediaId}`;
    }
    if (searchFilter?.agentId) {
      queryFilters += `&agent=${searchFilter?.agentId}`;
    }
    if (searchFilter?.categoryId) {
      queryFilters += `&category=${searchFilter?.categoryId}`;
    }
    if (searchFilter?.interventionStatusId) {
      queryFilters += `&interventionStatus=${searchFilter?.interventionStatusId}`;
    }
    if (searchFilter?.interventionDoctorId) {
      queryFilters += `&interventionDoctor=${searchFilter?.interventionDoctorId}`;
    }
    if (searchFilter?.examinationStatusId) {
      queryFilters += `&examinationStatus=${searchFilter?.examinationStatusId}`;
    }
    if (searchFilter?.examinationDoctorId) {
      queryFilters += `&examinationDoctor=${searchFilter?.examinationDoctorId}`;
    }
    if (searchFilter?.mainDoctorId) {
      queryFilters += `&mainDoctor=${searchFilter?.mainDoctorId}`;
    }
    if (searchFilter?.birthday) {
      queryFilters += `&birthday=${searchFilter?.birthday.toISOString()}`;
    }
    if (searchFilter?.creationDate) {
      queryFilters += `&creationDate=${searchFilter?.creationDate.toISOString()}`;
    }
    if (searchFilter?.examinationDate) {
      queryFilters += `&examinationDate=${searchFilter?.examinationDate.toISOString()}`;
    }
    if (searchFilter?.interventionDate) {
      queryFilters += `&interventionDate=${searchFilter?.interventionDate.toISOString()}`;
    }
    if (searchFilter?.announcedCallDate) {
      queryFilters += `&announcedCallDate=${searchFilter?.announcedCallDate.toISOString()}`;
    }
    if (searchFilter?.doneCallDate) {
      queryFilters += `&doneCallDate=${searchFilter?.doneCallDate.toISOString()}`;
    }
    return this.http.get<PatientsPagination>(
      this.apiUrl +
        `/by-clinic-id-and-filter/${this.storageService.getClinicId()}${queryFilters}`
    );
  }
}
