import { ToothColor } from './tooth-color.model';
import { TechnicianOrderFaze } from './technician-order-faze.model';
import { PatientOfferedAction } from './patient-offered-action.model';

export class TechnicianAction {
  public id: number;
  public technicianOrderId: number;
  public toothColorId: number;
  public toothColor: ToothColor;
  public comment: string;
  public imprint: TeethImprint;
  public patientOfferedActions: PatientOfferedAction[];
  public technicianOrderFaze: TechnicianOrderFaze[];
}

export enum TeethImprint {
  digital = 'digital',
  analog = 'analog',
}
