import { UserInfo } from '../models/user-info.model';
import { StaffWorkingTime } from '../models/staff-working-time.model';
import { CalendarEvent } from 'angular-calendar';
import {
  addDays,
  addMinutes,
  isAfter,
  isBefore,
  isEqual,
  subMinutes,
} from 'date-fns';

export const ordinationOffice = 'Ordinacija';
export const administrationOffice = 'Administracija';
export const allOffices = 'Sve';
export const REPEATING_NONE = 'NONE';
export const REPEATING_DAILY = 'DAILY';
export const REPEATING_WEEKLY = 'WEEKLY';
export const REPEATING_EACH_TWO_WEEKS = 'EACH_TWO_WEEKS';
export const REPEATING_MONTHLY = 'MONTHLY';
export const MONDAY = 'MO';
export const TUESDAY = 'TU';
export const WEDNESDAY = 'WE';
export const THURSDAY = 'TH';
export const FRIDAY = 'FR';
export const SATURDAY = 'SA';
export const NEVER = 'NEVER';
export const CHOSEN_DATE = 'CHOSEN_DATE';
export const SWT_ID_PREFIX = 'SWT|';
export const MEAL_TIME_ID_PREFIX = 'MEAL_TIME|';
export const FIRST_EXAMINATION_TIME_ID_PREFIX = 'FIRST_EXAMINATION_TIME|';
const SWT_MAIN_CALENDAR_CLASS = 'swt-event white-space-normal';
const SWT_WORKING_HOURS_CALENDAR_CLASS = 'white-space-normal';

export function createOfficesArray(numberOfOffices: number): string[] {
  const offices = [allOffices, administrationOffice];
  for (let i = 1; i <= numberOfOffices; i++) {
    offices.push(ordinationOffice + ' ' + i);
  }
  return offices;
}

export function filterUserInfo(
  userInfo: UserInfo,
  filterValue: string,
): boolean {
  if (!filterValue) {
    return true;
  }
  return (
    userInfo.name.trim().toLowerCase() +
    ' ' +
    userInfo.lastName.trim().toLowerCase()
  ).includes(filterValue.toLowerCase());
}

export function validateHours(
  starTime: string,
  endTime: string,
  title: string,
) {
  return (frm) => {
    const starTimeValue = frm.get(starTime).value;
    const endTimeValue = frm.get(endTime).value;

    if (!starTimeValue || !endTimeValue) {
      return null;
    }
    if (isStartBeforeEnd(starTimeValue, endTimeValue)) {
      return null;
    }
    return {
      invalidTimes: `Začetek ${title} ${starTimeValue} mora biti pred koncem ${endTimeValue}`,
    };
  };
}

function isStartBeforeEndOrEqual(startTime: string, endTime: string): boolean {
  return isStartBeforeEnd(startTime, endTime) || startTime === endTime;
}

function isStartBeforeEnd(startTime: string, endTime: string): boolean {
  const [startHourString, startMinString] = startTime.split(':');
  const [endHourString, endMinString] = endTime.split(':');
  const startHour = Number.parseInt(startHourString, 10);
  const startMin = Number.parseInt(startMinString, 10);
  const endHour = Number.parseInt(endHourString, 10);
  const endMin = Number.parseInt(endMinString, 10);
  return startHour < endHour || (startHour === endHour && startMin < endMin);
}

export function validateDates(startDateForm: string, endDateForm: string) {
  return (frm) => {
    const startDateValue = new Date(frm.get(startDateForm).value);
    const endDateValue = new Date(frm.get(endDateForm).value);

    if (isBefore(endDateValue, startDateValue)) {
      return {
        invalidDates: `Končni datum ${formatDateForPrint(
          endDateValue,
        )} ne sme biti pred začetnim datumom ${formatDateForPrint(
          startDateValue,
        )}`,
      };
    }
    return null;
  };
}

function formatDateForPrint(date: Date): string {
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
}

export function staffWorkingTimeToCalendarEvent(
  swtArray: StaffWorkingTime[],
  viewDate: Date,
  isOnlyDoctor: boolean = false,
): CalendarEvent[] {
  const calendarEvents = [];
  swtArray.forEach((swt) => {
    if (swt?.repeatingRuleFreq === REPEATING_NONE) {
      calendarEvents.push(
        ...staffWorkingTimeToCalendarEventForNonRecurrenceEvents(
          swt,
          isOnlyDoctor,
        ),
      );
    } else {
      calendarEvents.push(
        ...recurringSWTToCalendarEvents(swt, getMonday(viewDate), isOnlyDoctor),
      );
    }
  });
  return calendarEvents;
}

function staffWorkingTimeToCalendarEventForNonRecurrenceEvents(
  swt: StaffWorkingTime,
  isOnlyDoctor = false,
): CalendarEvent[] {
  const calendarEvents = [];
  const calendarEventInfo = {
    start: swt.startDateTime,
    end: swt.endDateTime,
    id: `${SWT_ID_PREFIX}${swt.id}`,
    draggable: false,
    meta: swt,
    cssClass: isOnlyDoctor
      ? SWT_MAIN_CALENDAR_CLASS
      : SWT_WORKING_HOURS_CALENDAR_CLASS,
  };
  calendarEvents.push(
    ...getEventsForSWT(
      calendarEventInfo as CalendarEvent,
      swt,
      swt.startDateTime,
      swt.endDateTime,
      isOnlyDoctor,
    ),
  );
  return calendarEvents;
}

export function createMonthlyRecurringRuleByDay(date: Date): string {
  return (
    getStringDayFromNumber(date.getDay()) +
    '(' +
    calculateOrderNumberOfDayInMonth(date) +
    ')'
  );
}

function recurringSWTToCalendarEvents(
  swt: StaffWorkingTime,
  monday: Date,
  isOnlyDoctor = false,
): CalendarEvent[] {
  const calendarEvents = [];
  for (let i = 0; i < 6; i++) {
    const date = addDays(monday, i);
    if (
      isDateBeforeIgnoringTime(date, swt.startDateTime) ||
      isDateAfterOrEqualIgnoringTime(date, swt.repeatingEnd) ||
      swt.staffWorkingTimeExceptions?.some((swte) =>
        isDateEqualIgnoringTime(date, new Date(swte?.exceptionDate)),
      ) ||
      REPEATING_NONE === swt.repeatingRuleFreq
    ) {
      continue;
    }
    if (REPEATING_DAILY === swt.repeatingRuleFreq) {
      calendarEvents.push(
        ...createCalendarEventsForDate(date, swt, isOnlyDoctor),
      );
    } else if (
      REPEATING_WEEKLY === swt.repeatingRuleFreq &&
      swt?.repeatingRuleByDay?.includes(getStringDayFromNumber(date.getDay()))
    ) {
      calendarEvents.push(
        ...createCalendarEventsForDate(date, swt, isOnlyDoctor),
      );
    } else if (
      REPEATING_EACH_TWO_WEEKS === swt.repeatingRuleFreq &&
      isRepeatingEachTwoWeeks(date, swt.startDateTime) &&
      swt?.repeatingRuleByDay?.includes(getStringDayFromNumber(date.getDay()))
    ) {
      calendarEvents.push(
        ...createCalendarEventsForDate(date, swt, isOnlyDoctor),
      );
    } else if (
      REPEATING_MONTHLY === swt.repeatingRuleFreq &&
      createMonthlyRecurringRuleByDay(date) === swt.repeatingRuleByDay
    ) {
      calendarEvents.push(
        ...createCalendarEventsForDate(date, swt, isOnlyDoctor),
      );
    }
  }
  return calendarEvents;
}

function isRepeatingEachTwoWeeks(
  dateToCheck: Date,
  swtStartDate: Date,
): boolean {
  const dateDifference = calculateDiff(dateToCheck, swtStartDate);
  if (dateDifference < 0) {
    return false;
  }
  return Math.floor(dateDifference / 7) % 2 === 0;
}

function calculateDiff(firstDate: Date, secondDate: Date): number {
  return Math.floor(
    (Date.UTC(
      firstDate.getFullYear(),
      firstDate.getMonth(),
      firstDate.getDate(),
    ) -
      Date.UTC(
        secondDate.getFullYear(),
        secondDate.getMonth(),
        secondDate.getDate(),
      )) /
      (1000 * 60 * 60 * 24),
  );
}

export function calculateOrderNumberOfDayInMonth(date: Date): number {
  return Math.floor((date.getDate() - 1) / 7) + 1;
}

export function getStringDayFromNumberInSlovenian(dayNumber: number): string {
  switch (dayNumber) {
    case 1:
      return 'ponedeljek';
    case 2:
      return 'torek';
    case 3:
      return 'sreda';
    case 4:
      return 'četrtek';
    case 5:
      return 'petek';
    case 6:
      return 'sobota';
  }
}

function getStringDayFromNumber(dayNumber: number): string {
  switch (dayNumber) {
    case 1:
      return MONDAY;
    case 2:
      return TUESDAY;
    case 3:
      return WEDNESDAY;
    case 4:
      return THURSDAY;
    case 5:
      return FRIDAY;
    case 6:
      return SATURDAY;
  }
}

function createCalendarEventsForDate(
  date: Date,
  swt: StaffWorkingTime,
  isOnlyDoctor = false,
): CalendarEvent[] {
  const start = new Date(date);
  start.setHours(swt.startDateTime.getHours());
  start.setMinutes(swt.startDateTime.getMinutes());
  const end = new Date(date);
  end.setHours(swt.endDateTime.getHours());
  end.setMinutes(swt.endDateTime.getMinutes());
  const calendarEventInfo = {
    start,
    end,
    id: `${SWT_ID_PREFIX}${swt.id}`,
    draggable: false,
    cssClass: isOnlyDoctor
      ? SWT_MAIN_CALENDAR_CLASS
      : SWT_WORKING_HOURS_CALENDAR_CLASS,
    meta: swt,
  };
  return getEventsForSWT(
    calendarEventInfo as CalendarEvent,
    swt,
    start,
    end,
    isOnlyDoctor,
  );
}

export function isDateAfterOrEqualIgnoringTime(
  firstDate: Date,
  secondDate: Date,
): boolean {
  if (!secondDate) {
    return false;
  }
  const d1 = getDateWithoutTime(firstDate);
  const d2 = getDateWithoutTime(secondDate);
  return isEqual(d1, d2) || isAfter(d1, d2);
}

function isDateBeforeIgnoringTime(firstDate: Date, secondDate: Date): boolean {
  const d1 = getDateWithoutTime(firstDate);
  const d2 = getDateWithoutTime(secondDate);
  return isBefore(d1, d2);
}

export function isDateEqualIgnoringTime(
  firstDate: Date,
  secondDate: Date,
): boolean {
  const d1 = getDateWithoutTime(firstDate);
  const d2 = getDateWithoutTime(secondDate);
  return isEqual(d1, d2);
}

export function isDateBetweenStartAndEndIgnoringTime(
  date: Date,
  startDate: Date,
  endDate: Date,
): boolean {
  const dateToCheckWithoutTime = getDateWithoutTime(date);
  const startWithoutTime = getDateWithoutTime(startDate);
  const endWithoutTime = getDateWithoutTime(endDate);
  return (
    isEqual(dateToCheckWithoutTime, startWithoutTime) ||
    isEqual(dateToCheckWithoutTime, endWithoutTime) ||
    (isBefore(dateToCheckWithoutTime, endWithoutTime) &&
      isAfter(dateToCheckWithoutTime, startWithoutTime))
  );
}

function getDateWithoutTime(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getTitleForDeletion(swt: StaffWorkingTime): string {
  let title;
  if (swt.doctor && !swt.assistant) {
    title = getTitle(
      swt.doctor.userInfo,
      swt.startDateTime,
      swt.endDateTime,
      swt,
    );
  } else if (!swt.doctor && swt.assistant) {
    title = getTitle(
      swt.assistant.userInfo,
      swt.startDateTime,
      swt.endDateTime,
      swt,
    );
  } else if (swt.doctor && swt.assistant) {
    title = `Dr ${swt.doctor.userInfo.name} ${
      swt.doctor.userInfo.lastName
    } ${getTitle(
      swt.assistant.userInfo,
      swt.startDateTime,
      swt.endDateTime,
      swt,
    )}`;
  } else {
    title = getTitle(
      swt.agent.userInfo,
      swt.startDateTime,
      swt.endDateTime,
      swt,
    );
  }
  if (swt.repeatingRuleFreq === REPEATING_NONE) {
    return title;
  } else {
    return title + ' Urniki se ponavlja ' + getTextForRecurrence(swt);
  }
}

function getTextForRecurrence(swt: StaffWorkingTime): string {
  if (swt.repeatingRuleFreq === REPEATING_MONTHLY) {
    return `Ponovitev vsak ${calculateOrderNumberOfDayInMonth(
      swt.startDateTime,
    )}.${getStringDayFromNumberInSlovenian(swt.startDateTime.getDay())} v mesecu.${swt.repeatingEnd ? ' Konec ' + swt.repeatingEnd.toDateString() + '.' : ''}`;
  }
  if (swt.repeatingRuleFreq === REPEATING_DAILY) {
    return `Dnevno.${getStringDayFromNumberInSlovenian(swt.startDateTime.getDay())} v mesecu.${swt.repeatingEnd ? ' Konec ' + swt.repeatingEnd.toDateString() + '.' : ''}`;
  }
  if (swt.repeatingRuleFreq === REPEATING_WEEKLY) {
    return `Tedensko. ${getTextForWeeklyRecurrence(swt)}.${swt.repeatingEnd ? ' Konec ' + swt.repeatingEnd.toDateString() + '.' : ''}`;
  }
  if (swt.repeatingRuleFreq === REPEATING_EACH_TWO_WEEKS) {
    return `Za 14 dni. ${getTextForWeeklyRecurrence(swt)}.${swt.repeatingEnd ? ' Konec ' + swt.repeatingEnd.toDateString() + '.' : ''}`;
  }
  return '';
}

function getTextForWeeklyRecurrence(swt: StaffWorkingTime): string {
  return `${swt.repeatingRuleByDay.includes('MO') ? ' Ponedeljek' : ''}${swt.repeatingRuleByDay.includes('TU') ? ' Torek' : ''}${swt.repeatingRuleByDay.includes('WE') ? ' Sreda' : ''}${swt.repeatingRuleByDay.includes('TH') ? ' Četrtek' : ''}${swt.repeatingRuleByDay.includes('FR') ? ' Petek' : ''}${swt.repeatingRuleByDay.includes('SA') ? ' Sobota' : ''}`;
}

/**
 * Set hours and minutes in date
 * @param date where hours and minutes are set
 * @param time in format HH:mm
 */
export function setHoursAndMinutes(date: Date, time: string): Date {
  const [hour, min] = time.split(':');
  const newDate = new Date(date.toDateString());
  newDate.setHours(Number.parseInt(hour, 10));
  newDate.setMinutes(Number.parseInt(min, 10));
  return newDate;
}

export function isSecondTimeInsideFirst(
  firstStart: string,
  firstEnd: string,
  secondStart: string,
  secondEnd: string,
): boolean {
  return !(
    isStartBeforeEndOrEqual(firstStart, secondStart) &&
    isStartBeforeEndOrEqual(secondEnd, firstEnd)
  );
}

export function getMonday(d: Date) {
  const currentDay = d.getDay();
  const diff = currentDay === 0 ? -6 : 1 - currentDay;
  const monday = addDays(d, diff);
  return new Date(monday.toDateString());
}

export function getSunday(d: Date) {
  const currentDay = d.getDay();
  const diff = currentDay === 0 ? 0 : 7 - currentDay;
  const sunday = addDays(d, diff);
  sunday.setHours(23);
  sunday.setMinutes(59);
  sunday.setSeconds(59);
  return sunday;
}

function getEventsForSWT(
  calendarEventInfo: CalendarEvent,
  swt: StaffWorkingTime,
  startDateTime: Date,
  endDateTime: Date,
  isOnlyDoctor = false,
): CalendarEvent[] {
  const calendarEvents = [];
  if (swt.doctor) {
    calendarEvents.push({
      ...calendarEventInfo,
      color: {
        secondary: swt.doctor.userInfo.color,
        primary: '#000000',
      },
      title: getTitle(swt.doctor.userInfo, startDateTime, endDateTime, swt),
    });
    if (isOnlyDoctor && swt?.mealStartTime && swt?.mealEndTime) {
      calendarEvents.push(
        createDoctorMealTimeCalendarEvent(swt, startDateTime, endDateTime),
      );
    }
    if (
      isOnlyDoctor &&
      swt?.firstExaminationStartTime &&
      swt?.firstExaminationEndTime
    ) {
      calendarEvents.push(
        createDoctorFirstExaminationTimeCalendarEvent(
          swt,
          startDateTime,
          endDateTime,
        ),
      );
    }
  }
  if (swt.assistant && !isOnlyDoctor) {
    const start = getAssistantStartHours(startDateTime, !swt.doctor);
    const end = getAssistantEndHours(endDateTime, !swt.doctor);
    calendarEvents.push({
      ...calendarEventInfo,
      start,
      end,
      color: {
        secondary: swt.assistant.userInfo.color,
        primary: '#000000',
      },
      title: getTitle(swt.assistant.userInfo, start, end, swt),
    });
  }
  if (swt.agent && !isOnlyDoctor) {
    calendarEvents.push({
      ...calendarEventInfo,
      color: {
        secondary: swt.agent.userInfo.color,
        primary: '#000000',
      },
      title: getTitle(swt.agent.userInfo, startDateTime, endDateTime, swt),
    });
  }
  return calendarEvents;
}

function createDoctorMealTimeCalendarEvent(
  swt: StaffWorkingTime,
  startDate: Date,
  endDate: Date,
): CalendarEvent {
  const start = new Date(startDate.toDateString());
  start.setHours(swt.mealStartTime.getHours());
  start.setMinutes(swt.mealStartTime.getMinutes());
  const end = new Date(endDate.toDateString());
  end.setHours(swt.mealEndTime.getHours());
  end.setMinutes(swt.mealEndTime.getMinutes());
  return {
    start,
    end,
    id: `${MEAL_TIME_ID_PREFIX}${swt.id}`,
    draggable: false,
    meta: swt,
    color: {
      secondary: '#d92525',
      primary: '#000000',
    },
    title: `Malica ${swt.doctor.userInfo.name} ${
      swt.doctor.userInfo.lastName
    }: ${swt.mealStartTime
      .toTimeString()
      .substring(0, 5)}-${swt.mealEndTime.toTimeString().substring(0, 5)}`,
  };
}

function createDoctorFirstExaminationTimeCalendarEvent(
  swt: StaffWorkingTime,
  startDate: Date,
  endDate: Date,
): CalendarEvent {
  const start = new Date(startDate.toDateString());
  start.setHours(swt.firstExaminationStartTime.getHours());
  start.setMinutes(swt.firstExaminationStartTime.getMinutes());
  const end = new Date(endDate.toDateString());
  end.setHours(swt.firstExaminationEndTime.getHours());
  end.setMinutes(swt.firstExaminationEndTime.getMinutes());
  return {
    start,
    end,
    id: `${FIRST_EXAMINATION_TIME_ID_PREFIX}${swt.id}`,
    draggable: false,
    meta: swt,
    color: {
      secondary: '#d9c125',
      primary: '#000000',
    },
    title: `Prvi Pregledi ${swt.doctor.userInfo.name} ${
      swt.doctor.userInfo.lastName
    }: ${swt.firstExaminationStartTime
      .toTimeString()
      .substring(0, 5)}-${swt.firstExaminationEndTime
      .toTimeString()
      .substring(0, 5)}`,
  };
}

function getTitle(
  userInfo: UserInfo,
  start: Date,
  end: Date,
  swt: StaffWorkingTime,
): string {
  let mealTimeTitle: string;
  let firstExaminationTimeTitle: string;
  const titleWithoutMealTime = `${getOfficeAbbr(swt.office)} ${
    swt.clinicLocation.name
  } ${userInfo.name} ${userInfo.lastName} ${start
    .toTimeString()
    .substring(0, 5)}-${end.toTimeString().substring(0, 5)}`;
  if (swt?.mealStartTime && swt?.mealEndTime) {
    mealTimeTitle = `Malica: ${swt.mealStartTime
      .toTimeString()
      .substring(0, 5)}-${swt.mealEndTime.toTimeString().substring(0, 5)}`;
  }
  if (swt?.firstExaminationStartTime && swt?.firstExaminationEndTime) {
    firstExaminationTimeTitle = `Prvi Pregledi: ${swt.firstExaminationStartTime
      .toTimeString()
      .substring(0, 5)}-${swt.firstExaminationEndTime
      .toTimeString()
      .substring(0, 5)}`;
  }
  return `${titleWithoutMealTime} ${mealTimeTitle ? mealTimeTitle : ''} ${
    firstExaminationTimeTitle ? firstExaminationTimeTitle : ''
  } ${swt.comment ? swt.comment : ''}`;
}

function getOfficeAbbr(office: string): string {
  if (office === administrationOffice) {
    return 'A';
  }
  return office.substring(ordinationOffice.length + 1);
}

function getAssistantStartHours(date: Date, withoutDoctor?: boolean): Date {
  if (withoutDoctor) {
    return date;
  }
  const newDate = subMinutes(date, 30);
  if (newDate.getDate() !== date.getDate()) {
    return new Date(new Date(date.getTime()).setMinutes(0));
  }
  return newDate;
}

function getAssistantEndHours(date: Date, withoutDoctor?: boolean): Date {
  if (withoutDoctor) {
    return date;
  }
  const newDate = addMinutes(date, 30);
  if (newDate.getDate() !== date.getDate()) {
    return new Date(new Date(date.getTime()).setMinutes(59));
  }
  return newDate;
}

export function overlappingTimes(
  firstStartTime: string,
  firstEndTime: string,
  secondStartTime: string,
  secondEndTime: string,
): boolean {
  return (
    isStartBeforeEnd(firstStartTime, secondEndTime) &&
    isStartBeforeEnd(secondStartTime, firstEndTime)
  );
}

export function isTimeBetween(
  time: string,
  startTime: string,
  endTime: string,
): boolean {
  return (
    (isStartBeforeEnd(startTime, time) || time === startTime) &&
    (isStartBeforeEnd(time, endTime) || time === endTime)
  );
}
