import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';
import { ProformaInvoiceAction } from '../models/proforma-invoice.model';
import { ReceiptAction } from '../models/receipt.model';

@Pipe({
  name: 'mapPiaToPoa',
})
export class MapPiaToPoaPipe implements PipeTransform {
  transform(arr: ProformaInvoiceAction[] | ReceiptAction[]): PatientOfferedAction[] {
    return arr.map((pia) => pia.patientOfferedAction);
  }
}
