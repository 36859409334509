import { Component, Input } from '@angular/core';
import { Appointment } from '../../../models/appointment.model';

@Component({
  selector: 'app-calendar-tooltip',
  templateUrl: './calendar-tooltip.component.html',
  styleUrls: ['./calendar-tooltip.component.scss'],
})
export class CalendarTooltipComponent {
  @Input() appointment: Appointment;
}
