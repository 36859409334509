import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedActionStatus } from '../models/patient-offered-action.model';
import { InvoiceAction } from '../models/invoice.model';

@Pipe({
  name: 'canCreateReceipt',
})
export class CanCreateReceiptPipe implements PipeTransform {
  transform(invoiceActions: InvoiceAction[]): boolean {
    return invoiceActions.some(
      (ia) =>
        ia.patientOfferedAction?.status === PatientOfferedActionStatus.open
    );
  }
}
