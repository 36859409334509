import {
  ACTION_TYPE_DOCTOR_PRICE_TYPES,
  ACTION_TYPES,
  ACTIVE_THERAPIES,
  ANALYTICS,
  CALENDAR,
  CASH_REGISTER,
  CHAT,
  HOLIDAYS,
  INVOICES,
  LOCATIONS,
  MEDIAS,
  PARTS_OF_DAY,
  PATIENT_ACTIONS_CATEGORIES,
  PATIENT_CATEGORIES,
  PATIENT_INTERESTS,
  PATIENTS,
  RECEIPT,
  RECEIPT_ADDRESS_CONFIG,
  RECEIPT_HEADER_FOOTER_CONFIG,
  SEARCH_RECEIPT_AND_INVOICE,
  TECHNICIAN as TECHNICIAN_PATH,
  TECHNICIAN_PHASES,
  USERS,
  WORKERS,
  WORKING_HOURS,
} from './pages.constants';

export const ADMIN = 'Admin';
export const ASSISTANT = 'Assistant';
export const AGENT = 'Agent';
export const ADMINISTRATOR = 'Administrator';
export const DOCTOR = 'Doctor';
export const TECHNICIAN = 'Technician';
export const TECHNICIAN_WORKER = 'TechnicianWorker';

export const RoutePermissions = {
  [CALENDAR]: [ADMIN, ASSISTANT, AGENT, ADMINISTRATOR, DOCTOR],
  [`${CALENDAR}/${WORKING_HOURS}`]: [
    ADMIN,
    ASSISTANT,
    AGENT,
    ADMINISTRATOR,
    DOCTOR,
  ],
  [`${CALENDAR}/${TECHNICIAN_PATH}`]: [TECHNICIAN, TECHNICIAN_WORKER],
  [PATIENTS]: [ADMIN, ASSISTANT, AGENT, ADMINISTRATOR, DOCTOR],
  [`${TECHNICIAN_PATH}/${PATIENTS}`]: [TECHNICIAN, TECHNICIAN_WORKER],
  [CHAT]: [
    ADMIN,
    ASSISTANT,
    AGENT,
    ADMINISTRATOR,
    DOCTOR,
    TECHNICIAN,
    TECHNICIAN_WORKER,
  ],
  [ACTIVE_THERAPIES]: [ADMIN, ASSISTANT, AGENT, ADMINISTRATOR, DOCTOR],
  [PATIENT_ACTIONS_CATEGORIES]: [ADMIN],
  [ACTION_TYPES]: [ADMIN],
  [ACTION_TYPE_DOCTOR_PRICE_TYPES]: [ADMIN],
  [TECHNICIAN_PHASES]: [ADMIN],
  [USERS]: [ADMIN, ASSISTANT, AGENT, ADMINISTRATOR, DOCTOR],
  [`${USERS}/${WORKERS}`]: [TECHNICIAN],
  [HOLIDAYS]: [ADMIN, ASSISTANT, AGENT, ADMINISTRATOR, DOCTOR],
  [LOCATIONS]: [ADMIN],
  [MEDIAS]: [ADMIN],
  [PARTS_OF_DAY]: [ADMIN],
  [PATIENT_INTERESTS]: [ADMIN],
  [PATIENT_CATEGORIES]: [ADMIN],
  [ANALYTICS]: [ADMIN],
  [`${SEARCH_RECEIPT_AND_INVOICE}/${RECEIPT}`]: [
    ADMIN,
    ASSISTANT,
    AGENT,
    ADMINISTRATOR,
    DOCTOR,
  ],
  [`${SEARCH_RECEIPT_AND_INVOICE}/${INVOICES}`]: [
    ADMIN,
    ASSISTANT,
    AGENT,
    ADMINISTRATOR,
    DOCTOR,
  ],
  [RECEIPT_ADDRESS_CONFIG]: [ADMIN, ASSISTANT, AGENT, ADMINISTRATOR, DOCTOR],
  [RECEIPT_HEADER_FOOTER_CONFIG]: [
    ADMIN,
    ASSISTANT,
    AGENT,
    ADMINISTRATOR,
    DOCTOR,
  ],
  [CASH_REGISTER]: [ADMIN, ASSISTANT, ADMINISTRATOR],
};
