import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from '../services/api-services/local-storage.service';
import { Appointment } from '../models/appointment.model';
import { isDateAfterOrEqualIgnoringTime } from '../utils/calendar-working-hours.utils';

@Pipe({
  name: 'canEditAppointment',
})
export class CanEditAppointmentPipe implements PipeTransform {
  transform(appointment: Appointment): boolean {
    return (
      !appointment ||
      isDateAfterOrEqualIgnoringTime(
        new Date(appointment?.startDateTime),
        new Date()
      ) ||
      'Admin' === this.storageService.getUserType()
    );
  }

  constructor(private storageService: LocalStorageService) {}
}
