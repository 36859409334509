import { Agent } from './agent.model';
import { Media } from './media.model';
import { PatientCategory } from './patient-category.model';

export class Patient {
  public id: number;
  public name: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public address: string;
  public country: string;
  public city: string;
  public postCode: string;
  public clinicId: number;
  public birthday: Date;
  public idCardNumber: string;
  public gender: string;
  public remark: string;
  public canCall: boolean;
  public appointmentPreferences: string;
  public agent: Agent;
  public agentId: number;
  public creationDate: Date;
  public mediaId: number;
  public media: Media;
  public patientCategoryId: number;
  public patientCategory: PatientCategory;
  minimaxCode: string;
}

export interface PatientsPagination {
  patientSearchResults: PatientSearchResult[];
  count: number;
}

export interface PatientSearchResult {
  id: number;
  name: string;
  address: string;
  postCode: string;
  phone: string;
  email: string;
  birthday: Date;
  creationDate: Date;
  mediaName: string;
  agentName: string;
  patientCategoryName: string;
  mainDoctorName: string;
  examinationDoctor: string;
  examinationStatus: string;
  examinationDate: Date;
  interventionDoctor: string;
  interventionStatus: string;
  interventionDate: Date;
  announcedCallDate: Date;
  doneCallDate: Date;
  activeTherapyPrice: number;
}

export interface PatientSearchSort {
  order: 'desc' | 'asc';
  field: string;
  nameToShow: string;
}
