import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nlToBr',
})
export class NlToBrPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    // Convert '\n' to <br> for HTML display
    return value.replace(/\\n/g, '\n');
  }
}
