import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayToDayName',
})
export class DayToDayNamePipe implements PipeTransform {
  transform(value: Date): string {
    switch (value.getDay()) {
      case 0:
        return 'Ned';
      case 1:
        return 'Pon';
      case 2:
        return 'Tor';
      case 3:
        return 'Sre';
      case 4:
        return 'Čet';
      case 5:
        return 'Pet';
      case 6:
        return 'Sob';
    }
  }
}
