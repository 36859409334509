import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PatientAction,
  PatientActionWithPrices,
} from '../../models/patient-action.model';

@Injectable({
  providedIn: 'root',
})
export class PatientActionService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/patient-action';
  }

  public add(patientAction: PatientAction): Observable<number> {
    return this.http.post<number>(this.apiUrl, patientAction);
  }

  public getByClinicId(clinicId: number): Observable<PatientAction[]> {
    return this.http.get<PatientAction[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}`
    );
  }

  public getByClinicIdPrices(
    clinicId: number
  ): Observable<PatientActionWithPrices[]> {
    return this.http.get<PatientActionWithPrices[]>(
      this.apiUrl + `/by-clinic-id-prices/${clinicId}`
    );
  }

  public updateActionDoctorPrices(
    actionDoctorPrices: PatientActionWithPrices
  ): Observable<PatientActionWithPrices> {
    return this.http.post<PatientActionWithPrices>(
      `${this.apiUrl}/update-action-doctor-prices`,
      actionDoctorPrices
    );
  }

  public updateActionTechnicianPrices(
    patientActionWithTechnicianPrices: PatientActionWithPrices
  ): Observable<PatientActionWithPrices> {
    return this.http.post<PatientActionWithPrices>(
      `${this.apiUrl}/update-action-technician-prices`,
      patientActionWithTechnicianPrices
    );
  }

  public delete(patientAction: number): Observable<any> {
    return this.http.delete<number>(this.apiUrl + `/${patientAction}`);
  }

  public getAdvanceReceiptPatientAction(): Observable<PatientAction[]> {
    return this.http.get<PatientAction[]>(
      this.apiUrl + '/advance-receipt-action'
    );
  }
}
