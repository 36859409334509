<div id="noPatients" *ngIf="patients.length === 0">Ni odprtih pacientov.</div>

<div *ngIf="patients.length !== 0" id="patientsList">
  <div *ngFor="let patient of patients; let i = index">
    <mat-card appearance="outlined" class="patient-card card" (click)="openPatient(patient.id)">
      <div style="padding-top: 0.6rem">{{ patient.name }}</div>
      <div style="text-align: right">
        <button mat-icon-button (click)="removePatient(patient.id)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>
