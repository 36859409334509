<mat-dialog-content>
  <h3>{{ data.title }}</h3>

  <div id="form">
    <div class="form-group">
      <label for="input">{{ data.dataName }}</label>
      <textarea id="input"
                [class]="'form-control ' + (textFormControl.touched && textFormControl.hasError('required') ? 'is-invalid' : '')"
                placeholder="{{data.dataName}}" [formControl]="textFormControl"></textarea>
      <div class="invalid-feedback text-danger" *ngIf="textFormControl.hasError('required')">
        Polje je obvezno!
      </div>
    </div>
  </div>

  <div id="actionButtons">
    <div></div>
    <div></div>
    <div>
      <button class="btn btn-light" [mat-dialog-close]="false">Prekliči</button>
    </div>
    <div>
      <button class="btn btn-primary" (click)="save()">{{ data.actionText }}</button>
    </div>
  </div>
</mat-dialog-content>