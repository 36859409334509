import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';

@Pipe({
  name: 'invoiceSum',
})
export class InvoiceSumPipe implements PipeTransform {
  transform(pia: PatientOfferedAction[]): number {
    return pia
      .map((p) => p?.patientAction?.price)
      .reduce((totalPrice, itemPrice) => totalPrice + itemPrice, 0);
  }
}
