import { ReceiptFooter, ReceiptHeader } from '../../models/receipt.model';
import {
  DiscountValueOptions,
  HEADER_FIELDS,
} from '../../utils/constants/constants';
import { Patient } from '../../models/patient.model';
import { BillActionBasic, BillBasic } from '../../models/bill.model';
import { groupPatientInvoiceActions } from '../../pipes/group-patient-invoice-actions.pipe';
import { formatInvoiceActionsForPrint } from '../../pipes/format--invoice-actions-for-print.pipe';
import { formatNumber } from '@angular/common';
import { sumWithDiscount } from '../../utils/receipt.utils';
import { SimplifiedActionForInvoice } from '../../models/invoice.model';
import { UserInfo } from '../../models/user-info.model';
import { PaymentMethod } from '../../models/payment-method.model';
import { generateTeethArrayString } from '../../utils/teeth.utils';

export const DEFAULT_STYLE = { font: 'Roboto' };
export const PDF_STYLES = {
  header: {
    fontSize: 18,
    bold: true,
    margin: [0, 0, 0, 10],
  },
  subheader: {
    fontSize: 16,
    bold: true,
    margin: [0, 10, 0, 5],
  },
  tableExample: {
    margin: [0, 5, 0, 15],
  },
  infoTable: {
    fontSize: 9,
    margin: [0, 0, 0, 15],
  },
  technicianOrderTable: {
    fontSize: 10,
  },
  mainTable: {
    fontSize: 10,
    alignment: 'right',
  },
};

export function getDocHeader(
  currentPage: number,
  pageCount: number,
  billId: string,
  type: 'PREDRAČUN' | 'RAČUN' | 'AVANS'
) {
  if (currentPage === 1) {
    return;
  }
  return {
    columns: [
      {
        text: `${type} ${billId}`,
        alignment: 'left',
        fontSize: 10,
        margin: [15, 15, 0, 0],
      },
      {
        text: `${currentPage}/${pageCount}`,
        fontSize: 10,
        alignment: 'right',
        margin: [0, 15, 15, 0],
      },
    ],
  };
}

export function getDocFooter(
  currentPage: number,
  pageCount: number,
  footer: ReceiptFooter
) {
  if (currentPage === pageCount) {
    return getFooter(footer);
  } else {
    return {
      text: `${currentPage}/${pageCount}`,
      alignment: 'right',
      fontSize: 10,
      margin: [0, 0, 15, 0],
    };
  }
}

function getFooter(footer: ReceiptFooter): any {
  return {
    color: '#555555',
    margin: [15, 0, 15, 0],
    table: {
      widths: ['*'],
      body: [
        [
          {
            columns: [
              {
                alignment: 'right',
                text: 'Matična številka: ',
                color: '#224852',
                bold: true,
                fontSize: 11,
              },
              {
                alignment: 'center',
                text: footer.registrationNumber,
                color: '#224852',
                fontSize: 11,
              },

              {
                alignment: 'right',
                text: 'Davčna številka: ',
                color: '#224852',
                bold: true,
                fontSize: 11,
              },
              {
                alignment: 'center',
                text: footer.taxNumber,
                color: '#224852',
                fontSize: 11,
              },
            ],
          },
        ],
        [
          {
            columns: [
              {
                alignment: 'right',
                text: 'Transakcijski račun:',
                color: '#224852',
                bold: true,
                fontSize: 11,
              },
              {
                alignment: 'center',
                text: footer.bankAccount,
                color: '#224852',
                fontSize: 11,
              },

              {
                alignment: 'left',
                text: 'odprt pri Delavski hranilnici.',
                color: '#224852',
                bold: true,
                fontSize: 11,
              },
            ],
          },
        ],
      ],
    },
    layout: {
      hLineWidth: (i, node) =>
        i === 0 || i === node.table.body.length ? 2 : 0,
      vLineWidth: () => 2,
      hLineColor: () => '#3AEFEF',
      vLineColor: () => '#3AEFEF',
      paddingLeft: () => 50,
      paddingRight: () => 50,
      paddingTop: () => 1,
      paddingBottom: () => 1,
    },
  };
}

export function getHeaderText(header: ReceiptHeader) {
  return HEADER_FIELDS.filter((f) => f !== 'logo').map((f) => ({
    text: header[f],
    fontSize: 9,
  }));
}

export function getInfos(
  patient: Patient,
  bill: BillBasic,
  type: 'PREDRAČUN' | 'RAČUN' | 'DOBROPIS' | 'AVANS'
) {
  return {
    columns: [
      {
        width: 250,
        type: 'none',
        margin: [-12, 0, 0, 0],
        ul: [
          {
            text: (patient.name ?? '') + ' ' + (patient.lastName ?? ''),
            fontSize: 9,
          },
          { text: patient.address ?? '', fontSize: 9 },
          {
            text: `${patient.postCode ?? ''} ${patient.city ?? ''}`,
            fontSize: 9,
          },
        ],
      },
      [
        {
          text: type,
          fontSize: '15',
          bold: true,
        },
        {
          style: 'infoTable',
          table: {
            body: [
              [
                { text: 'Številka:', bold: true },
                { text: bill.identificationNumber, bold: true },
              ],
              ['Kraj:', bill.address],
              ['Referenca:', getReferenceId(bill.identificationNumber)],
              [
                'Datum računa in opravljanja:',
                formatDateForPrint(new Date(bill.createdAt)),
              ],
              ['Zapade:', formatDateForPrint(new Date(bill.createdAt))],
            ],
          },
          layout: {
            hLineWidth: () => 0,
            vLineWidth: () => 0,
            paddingRight: () => 15,
            paddingLeft: () => 0,
            paddingTop: () => 1,
            paddingBottom: () => 0,
          },
        },
      ],
    ],
  };
}

export function getReferenceId(identificationNumber: string) {
  return `SI00 ${identificationNumber.replace(/-/g, '')}`;
}

export function formatDateForPrint(date: Date): string {
  date = new Date(date);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? '0' + day : day}.${
    month < 10 ? '0' + month : month
  }.${date.getFullYear()}`;
}

export function getMainTable(
  billActions: BillActionBasic[],
  bill: BillBasic,
  isReceiptCancelled?: boolean
) {
  const groupedActions = groupPatientInvoiceActions(billActions);
  return {
    style: 'mainTable',
    table: {
      widths: ['*', 38, 50, 42, 60, 32, 50],
      body: [
        [
          { text: 'Vrsta blaga oz.storitev', bold: true, alignment: 'left' },
          { text: 'Količina', bold: true, alignment: 'center' },
          { text: 'Cena', bold: true, alignment: 'center' },
          { text: 'DDV', bold: true, alignment: 'center' },
          { text: 'Cena z DDV', bold: true, alignment: 'center' },
          { text: 'Popust', bold: true, alignment: 'center' },
          { text: 'Vrednost EUR', bold: true, alignment: 'center' },
        ],
        ...getActionsTable(groupedActions, bill, isReceiptCancelled),
      ],
    },
    layout: {
      hLineWidth: (i, node) =>
        i === 0 ? 0 : i === 1 || i === node.table.body.length ? 2 : 0,
      vLineWidth: () => 0,
    },
  };
}

export function getActionsTable(
  groupedActions: Map<string, BillActionBasic[]>,
  bill: BillBasic,
  isReceiptCancelled: boolean
) {
  const rows = [];
  const keys = [...groupedActions.keys()].sort();
  for (const key of keys) {
    const value = groupedActions.get(key);
    rows.push([
      { text: key, bold: true, alignment: 'left' },
      {},
      {},
      {},
      {},
      {},
      {},
    ]);
    const formatted = formatInvoiceActionsForPrint(value);
    for (const siafv of formatted) {
      rows.push(
        getActionRow(siafv, isReceiptCancelled, bill.patientActionsDiscountType)
      );
    }
    const groupSum = formatNumber(
      value
        .map((bab) => bab.patientOfferedAction?.patientAction?.price)
        .reduce((totalPrice, itemPrice) => totalPrice + itemPrice, 0),
      'sl',
      '.2'
    );
    const groupDiscountSum = formatNumber(
      isReceiptCancelled
        ? -sumWithDiscount(value, bill)
        : sumWithDiscount(value, bill),
      'sl',
      '.2'
    );
    rows.push([
      {},
      {},
      { text: groupSum, bold: true },
      {},
      { text: groupSum, bold: true },
      {},
      { text: groupDiscountSum, bold: true },
    ]);
  }
  return rows;
}

export function getActionRow(
  siafv: SimplifiedActionForInvoice,
  isReceiptCancelled: boolean,
  discountType: DiscountValueOptions
) {
  const totalSum =
    siafv.amount * ((siafv.price * (100 - siafv.discount)) / 100);
  return [
    {
      text: `${siafv.name} (${generateTeethArrayString(
        siafv.poas?.map((poa) => poa.teeth)
      )})`,
      alignment: 'left',
    },
    isReceiptCancelled ? -siafv.amount : siafv.amount,
    formatNumber(siafv.price, 'sl', '.2'),
    '0,00 (0%)',
    formatNumber(siafv.price, 'sl', '.2'),
    {
      text: `${siafv.discount}${
        DiscountValueOptions.EURO === discountType ? '€' : '%'
      }`,
    },
    formatNumber(isReceiptCancelled ? -totalSum : totalSum, 'sl', '.2'),
  ];
}

export function getSumTable(
  billActions: BillActionBasic[],
  bill: BillBasic,
  isInvoice: boolean,
  isAdvanceUsed?: boolean,
  isReceiptCancelled?: boolean,
  paymentMethod?: PaymentMethod,
  isReceiptPaid?: boolean,
  refundMethod?: PaymentMethod
) {
  const priceWithoutDiscount = billActions
    .map((bab) => bab.patientOfferedAction?.patientAction?.price)
    .reduce((totalPrice, itemPrice) => totalPrice + itemPrice, 0);

  const priceWithDiscount = sumWithDiscount(billActions, bill);
  const discountSum = formatNumber(
    priceWithoutDiscount - priceWithDiscount,
    'sl',
    '.2-2'
  );
  const rabat = formatNumber(
    (priceWithDiscount * bill.discount) / 100,
    'sl',
    '.2-2'
  );
  const priceWithRabat = (priceWithDiscount * (100 - bill.discount)) / 100;
  const formattedPriceWithRabat = formatNumber(
    isReceiptCancelled ? -priceWithRabat : priceWithRabat,
    'sl',
    '.2-2'
  );

  const bodyContent = [
    [
      { text: 'Skupaj brez popusta:', bold: true },
      {},
      {
        text: formatNumber(
          isReceiptCancelled ? -priceWithoutDiscount : priceWithoutDiscount,
          'sl',
          '.2-2'
        ),
        bold: true,
      },
    ],
    [
      { text: 'Skupaj s popustom:', bold: true },
      {
        text: `(Popust: -${discountSum}${
          DiscountValueOptions.EURO === bill.patientActionsDiscountType
            ? '€'
            : '%'
        })`,
      },
      {
        text: formatNumber(
          isReceiptCancelled ? -priceWithDiscount : priceWithDiscount,
          'sl',
          '.2-2'
        ),
        bold: true,
      },
    ],
    [
      { text: `Rabat: ${bill.discount}%` },
      { text: `(Od: ${formatNumber(priceWithDiscount, 'sl', '.2-2')})` },
      { text: `${isReceiptCancelled ? '' : '-'}${rabat}`, bold: true },
    ],
    [
      { text: `Skupaj EUR:`, bold: true },
      {},
      { text: formattedPriceWithRabat, bold: true },
    ],
  ];

  const shouldShowPaidInfo =
    isReceiptPaid && !!paymentMethod && priceWithRabat > 0;
  if (!isAdvanceUsed) {
    const calc = (priceWithDiscount * (100 - bill.discount)) / 100;
    const finalSum = formatNumber(
      isReceiptCancelled ? -calc : calc,
      'sl',
      '.2-2'
    );

    bodyContent.push([
      { text: `Za plačilo EUR:`, bold: true },
      {},
      { text: finalSum, bold: true },
    ]);
    if (shouldShowPaidInfo) {
      bodyContent.push([
        {
          text: isReceiptCancelled && !!refundMethod ? 'Vračilo na' : 'Plačano',
          bold: true,
        },
        {
          text:
            isReceiptCancelled && !!refundMethod
              ? refundMethod.name
              : paymentMethod.name,
        },
        { text: finalSum, bold: true },
      ]);
    }
  }

  return {
    style: 'mainTable',
    table: {
      widths: ['*', 90, 90],
      body: bodyContent,
    },
    layout: {
      hLineWidth: (i, node) =>
        setSumTableLineWidth(
          i,
          node,
          isInvoice,
          isAdvanceUsed,
          shouldShowPaidInfo
        ),
      vLineWidth: () => 0,
    },
  };
}

function setSumTableLineWidth(
  i,
  node,
  isInvoice: boolean,
  isAdvanceUsed: boolean,
  shouldShowPaidInfo: boolean
) {
  if (isInvoice) {
    return i === node.table.body.length - 1 ? 2 : 0;
  }
  if (!isAdvanceUsed) {
    if (shouldShowPaidInfo) {
      return i === node.table.body.length - 1 ||
        i === node.table.body.length - 2
        ? 2
        : 0;
    }
    return i === node.table.body.length - 1 ? 2 : 0;
  }
  if (shouldShowPaidInfo) {
    return i === node.table.body.length - 1 || i === node.table.body.length - 2
      ? 2
      : 0;
  }
  return i === node.table.body.length ? 2 : 0;
}

export function getCreatedBy(creator: UserInfo) {
  return {
    text: `Pripravil/la: ${creator.name} ${creator.lastName}`,
    alignment: 'center',
    fontSize: 9,
    margin: [0, 20, 0, 5],
  };
}

export function getQRCodeText(
  patient: Patient,
  bill: BillBasic,
  bankAccount: string,
  type: 'Predračun' | 'Račun' | 'Avans',
  price: string
): string {
  const addressParts = bill.address.split(',');
  const address = `${addressParts[1].trim()}\n${addressParts[0].trim()}`;
  const qr = `UPNQR\n\n\n\n\n${patient.name} ${patient.lastName}\n${
    patient.address
  }\n${patient.postCode} ${patient.city}\n${price}\n\n\n\n${type}:${
    bill.identificationNumber
  }\n${formatDateForPrint(new Date(bill.createdAt))}\n${bankAccount
    .split(' ')
    .join('')}\n${getReferenceId(bill.identificationNumber).replace(
    ' ',
    ''
  )}\nLUX DENTAL d.o.o.\n${address}\n`;
  return `${qr}${qr.length < 100 ? '0' + qr.length : qr.length}`;
}
