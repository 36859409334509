import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '../shared-components/dropdown-search/dropdown-search.component';
import { UserInfo } from '../models/user-info.model';

@Pipe({
  name: 'userToDropdownItem',
})
export class UserToDropdownItemPipe implements PipeTransform {
  transform(users: { id: any; userInfo: UserInfo }[]): DropdownItem[] {
    return users.map((input) => userToDropdownItem(input));
  }
}

export function userToDropdownItem(user: {
  id: any;
  userInfo: UserInfo;
}): DropdownItem {
  return user
    ? {
        id: user.id,
        name: `${user?.userInfo?.name} ${user?.userInfo?.lastName}`,
      }
    : { id: -1, name: 'Izberi...' };
}
