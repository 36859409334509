import { Pipe, PipeTransform } from '@angular/core';
import { sumWithDiscount } from '../utils/receipt.utils';
import { BillActionBasic, BillBasic } from '../models/bill.model';

@Pipe({
  name: 'invoiceSumWithDiscount',
})
export class InvoiceSumWithDiscountPipe implements PipeTransform {
  transform(billActionBasics: BillActionBasic[], bill: BillBasic): number {
    return sumWithDiscount(billActionBasics, bill);
  }
}
