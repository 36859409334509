import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Technician } from '../../models/technician.model';
import { TechnicianOrder } from '../../models/technician-order.model';
import { DateClearOffsetPipe } from '../../pipes/date-clear-offset.pipe';
import { User } from 'src/app/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class TechnicianService {
  private readonly apiUrl: string;

  constructor(
    private http: HttpClient,
    private dateClearOffsetPipe: DateClearOffsetPipe
  ) {
    this.apiUrl = environment.apiUrl + '/technician';
  }

  public add(technician: User): Observable<number> {
    return this.http.post<number>(this.apiUrl, technician);
  }

  public update(technician: User): Observable<Technician> {
    return this.http.patch<Technician>(this.apiUrl, technician);
  }

  public delete(technicianId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${technicianId}`);
  }

  public getMyClinicId(): Observable<number> {
    return this.http.get<number>(this.apiUrl + `/my-clinic-id`);
  }

  public get(technicianId: number): Observable<Technician> {
    return this.http.get<Technician>(this.apiUrl + `/${technicianId}`);
  }

  public getMyTechnicianOrder(): Observable<TechnicianOrder[]> {
    return this.http.get<TechnicianOrder[]>(
      this.apiUrl + `/my-technician-orders`
    );
  }

  public getMyTechnicianOrderFazes(
    from: Date,
    to: Date
  ): Observable<TechnicianOrder[]> {
    from = this.dateClearOffsetPipe.transform(from);
    to = this.dateClearOffsetPipe.transform(to);
    return this.http.get<TechnicianOrder[]>(
      this.apiUrl +
        `/my-technician-order-fazes?from=${from.toISOString()}&to=${to.toISOString()}`
    );
  }

  public getMyUnassignedTechnicianOrderFazes(): Observable<TechnicianOrder[]> {
    return this.http.get<TechnicianOrder[]>(
      this.apiUrl + `/my-unassigned-technician-order-fazes`
    );
  }

  public canTechnicianViewPatient(patientId: number): Observable<boolean> {
    return this.http.get<boolean>(
      this.apiUrl + `/can-technician-view-patient/` + patientId
    );
  }

  public canTechnicianWorkerViewPatient(
    patientId: number
  ): Observable<boolean> {
    return this.http.get<boolean>(
      this.apiUrl + `/can-worker-view-patient/` + patientId
    );
  }
}
