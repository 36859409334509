import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AdvanceReceipt,
  Receipt,
  ReceiptAddress,
  ReceiptClientIdentification,
} from '../../../../models/receipt.model';
import { debounceTime, filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PatientOfferedAction } from '../../../../models/patient-offered-action.model';
import {
  ADVANCES,
  DISCOUNT_OPTIONS,
  DiscountSymbolOptions,
  DiscountValueOptions,
  PAYMENT_METHODS,
} from '../../../../utils/constants/constants';
import { BillActionBasic, BillBasic } from '../../../../models/bill.model';
import { PaymentMethod } from '../../../../models/payment-method.model';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import { digitLengthValidator } from '../../../../utils/validators/digits-length.validator';
import {
  PROFORMA_INVOICES,
  RECEIPT,
} from '../../../../utils/constants/pages.constants';
import { AuthorizationService } from '../../../../services/authorization/authorization.service';
import { SimplifiedActionForInvoice } from '../../../../models/invoice.model';
import { DropdownItem } from '../../../../shared-components/dropdown-search/dropdown-search.component';
import { teethEnumTranslation } from '../../../../utils/teeth.utils';
import { ToastService } from '../../../../services/other-services/toast.service';

type BillType = typeof PROFORMA_INVOICES | typeof RECEIPT;

@Component({
  selector: 'app-create-bill-dialog',
  templateUrl: './create-bill-dialog.component.html',
  styleUrls: ['./create-bill-dialog.component.scss'],
})
export class CreateBillDialogComponent implements OnInit, OnDestroy {
  protected readonly RECEIPT = RECEIPT;
  protected readonly PROFORMA_INVOICES = PROFORMA_INVOICES;
  protected readonly DISCOUNT_OPTIONS = DISCOUNT_OPTIONS;

  @ViewChild('paymentSelect') paymentSelect: ElementRef;

  readonly destroy$ = new Subject<void>();
  patientOfferedActions: PatientOfferedAction[];
  expandedSafiIds: number[] = [];
  sumWithDiscount = 0;
  finalSum = 0;
  showAdditionalFields: boolean;
  clientIdentificationNeeded: boolean;
  isPaymentSetWhenFinalSumZero = false;
  isUserAdmin: boolean;
  today = new Date();
  advanceAvailable: number;
  treatmentPlanAdvance: AdvanceReceipt = undefined;
  visualisationPreparationAdvance: AdvanceReceipt = undefined;
  selectedPoaFilter: DropdownItem = undefined;
  allPoasDropdown: DropdownItem[];
  relevantPaymentMethods: PaymentMethod[] = [];

  form: FormGroup = this.fb.group({
    discountOption: [this.getDefaultDiscountOption()],
    discountForAll: [0, discountValidator()],
    discount: [0, discountValidator()],
    receiptAddress: [null, Validators.required],
    paymentMethod: [null],
    dueDate: [null],
    uniqueCitizenNumber: [null],
    taxNumber: [null],
    advanceUsed: [0, maxLimitValidator(() => this.finalSum)],
    treatmentPlanSelected: [false],
    treatmentPlanUsed: [0],
    visualisationPreparationSelected: [false],
    visualisationPrepUsed: [0],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientOfferedActions: PatientOfferedAction[];
      receiptAddresses: ReceiptAddress[];
      patientId: number;
      entity: string;
      type: BillType;
      paymentMethods: PaymentMethod[];
      actionDiscountMap: Map<number, number>; // map to hold which discount is applied to which actionId. only provided from invoices
      globalDiscount: number;
      advances: AdvanceReceipt[];
      patientActionDiscountType: DiscountValueOptions;
      allPatientOfferedActions: PatientOfferedAction[];
    },
    public fb: FormBuilder,
    private datePipe: DatePipe,
    private toastService: ToastService,
    private dialogRef: MatDialogRef<CreateBillDialogComponent>,
    private authorizationService: AuthorizationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.relevantPaymentMethods = data?.paymentMethods;
    this.patientOfferedActions = data?.patientOfferedActions;
    this.recalculatePoasDropdown();
    registerLocaleData(localeSl);
    this.patchPatientOfferedActionsToFormGroup();
    this.updatePaymentTypeValidators();

    this.handleAdvances();
    this.isUserAdmin = this.authorizationService.isAdmin;
  }

  ngOnInit() {
    this.discountForAll.valueChanges
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((discount) => {
        this.setDiscountForAllPatientActions(
          this.patientOfferedActions,
          discount,
        );
        this.recalculateSums();
      });

    this.setupValueChangeDiscountSubscription(this.discount);
    this.setupValueChangeDiscountSubscription(this.discountOptionControl);

    if (PROFORMA_INVOICES !== this.data.type) {
      this.advanceUsedControl.valueChanges
        .pipe(debounceTime(500), startWith(''), takeUntil(this.destroy$))
        .subscribe((advance) =>
          this.valueChangeSubscriptionForAdvanceUsageControl(advance),
        );

      this.paymentMethodControl.valueChanges
        .pipe(
          debounceTime(500),
          startWith(this.paymentMethodControl.value),
          filter((f) => !!f),
          takeUntil(this.destroy$),
        )
        .subscribe((paymentId) =>
          this.updateValidatorsForClientIdentification(+paymentId),
        );

      this.setupValueChangeCheckboxesSubscription(
        this.trtPlanSelectedControl,
        this.visPrepSelectedControl,
        this.trtPlanUsed,
        this.visPrepUsed,
        this.treatmentPlanAdvance,
        this.visualisationPreparationAdvance,
      );
      this.setupValueChangeCheckboxesSubscription(
        this.visPrepSelectedControl,
        this.trtPlanSelectedControl,
        this.visPrepUsed,
        this.trtPlanUsed,
        this.visualisationPreparationAdvance,
        this.treatmentPlanAdvance,
      );
    }

    this.recalculateSums();
  }

  private getDefaultDiscountOption() {
    if (this.data.patientActionDiscountType) {
      return this.data.patientActionDiscountType;
    }
    return this.DISCOUNT_OPTIONS.find(
      (option) => DiscountSymbolOptions.PERCENT === option.symbol,
    ).value;
  }

  private setupValueChangeDiscountSubscription(control: AbstractControl) {
    control.valueChanges
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => {
          this.recalculateSums();
        });
      });
  }

  private setupValueChangeCheckboxesSubscription(
    listenerCheckboxControl: AbstractControl,
    otherCheckboxControl: AbstractControl,
    listenerValueControl: AbstractControl,
    otherValueControl: AbstractControl,
    listenerReceipt: AdvanceReceipt,
    otherReceipt: AdvanceReceipt,
  ) {
    listenerCheckboxControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((isSelected) => {
        if (isSelected) {
          listenerValueControl?.setValue(
            this.setMultiAdvanceMax(listenerReceipt),
          );
        } else {
          listenerValueControl.setValue(0);
          listenerValueControl.updateValueAndValidity();
          if (otherCheckboxControl.value) {
            otherValueControl?.setValue(this.setSingleAdvanceMax(otherReceipt));
          }
        }
        this.setMaxAdvance();
      });
  }

  private valueChangeSubscriptionForAdvanceUsageControl(advance: number) {
    if (this.totalAdvancesUsed() === this.finalSum) {
      this.doFinalSumIsZero();
      this.relevantPaymentMethods = this.data.paymentMethods;
    } else {
      this.relevantPaymentMethods = this.data.paymentMethods?.filter(
        (m) => m.id !== PAYMENT_METHODS.TRR_PLACENO,
      );
      this.doFinalSumIsNotZero();
    }
    this.cdr.detectChanges();

    setTimeout(() => {
      // make sure no payment method is selected after the relevantPaymentMethods rerender
      if (!this.paymentMethodControl.value) {
        const selectElement = this.paymentSelect.nativeElement;
        selectElement.selectedIndex = -1;
      }
    });
  }

  private doFinalSumIsZero() {
    this.isPaymentSetWhenFinalSumZero = true;
    this.paymentMethodControl.setValue(PAYMENT_METHODS.TRR_PLACENO, {
      emitEvent: false,
    });
    this.paymentMethodControl.disable({ emitEvent: false });
    this.setDueDateWhenTransfer();
    this.getDueDateControl().updateValueAndValidity({ emitEvent: false });
    this.clientVerificationWhenNotTransfer();
  }

  private doFinalSumIsNotZero() {
    this.clientVerificationWhenNotTransfer();
    this.getDueDateControl().reset();
    this.paymentMethodControl.reset();
    this.paymentMethodControl.enable();
  }

  private updateValidatorsForClientIdentification(paymentId: number) {
    if (PAYMENT_METHODS.TRR_NEPLACENO === paymentId) {
      this.showAdditionalFields = true;
      this.clientIdentificationNeeded = true;
      this.setValidatorsForClientIdentificationFields();
      this.setDueDateWhenTransfer();
    } else {
      this.clientVerificationWhenNotTransfer();
      this.getDueDateControl().setValue(
        this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      );
      this.getDueDateControl().disable();
    }

    this.updateClientIdentificationFields();
  }

  private clientVerificationWhenNotTransfer() {
    this.showAdditionalFields = false;
    this.clientIdentificationNeeded = false;
    this.clearValidatorsForClientIdentificationFields();
  }

  private clearValidatorsForClientIdentificationFields() {
    this.getUniqueCitizenNumberControl().clearValidators();
    this.getTaxNumberControl().clearValidators();
  }

  private setValidatorsForClientIdentificationFields() {
    this.getUniqueCitizenNumberControl().setValidators([
      Validators.required,
      digitLengthValidator(13),
    ]);
    this.getTaxNumberControl().setValidators([
      Validators.required,
      digitLengthValidator(8),
    ]);
  }

  private setDueDateWhenTransfer() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);
    const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.getDueDateControl().setValue(formattedDate);
    this.getDueDateControl().enable();
  }

  private updateClientIdentificationFields() {
    this.getUniqueCitizenNumberControl().updateValueAndValidity();
    this.getTaxNumberControl().updateValueAndValidity();
  }

  protected getUniqueCitizenNumberControl() {
    return this.form.get('uniqueCitizenNumber');
  }

  protected getTaxNumberControl() {
    return this.form.get('taxNumber');
  }

  protected getDueDateControl() {
    return this.form.get('dueDate');
  }

  protected get advanceUsedControl() {
    return this.form.get('advanceUsed');
  }

  protected get paymentMethodControl() {
    return this.form.get('paymentMethod');
  }

  protected get discountForAll() {
    return this.form.get('discountForAll');
  }

  protected get discountOptionControl() {
    return this.form.get('discountOption');
  }

  protected get discount() {
    return this.form.get('discount');
  }

  protected get trtPlanSelectedControl() {
    return this.form.get('treatmentPlanSelected');
  }

  protected get visPrepSelectedControl() {
    return this.form.get('visualisationPreparationSelected');
  }

  protected get trtPlanUsed() {
    return this.form.get('treatmentPlanUsed') as FormControl;
  }

  protected get visPrepUsed() {
    return this.form.get('visualisationPrepUsed') as FormControl;
  }

  private patchPatientOfferedActionsToFormGroup(): void {
    this.patientOfferedActions.forEach((action) => {
      const discount =
        this.data.actionDiscountMap?.get(action.patientActionId) ?? 0;
      const formControl = new FormControl<number>(discount, [
        discountValidator(),
      ]);
      this.form.addControl(`${action.patientActionId}`, formControl);

      formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
        // Defer the execution to ensure form state is updated
        setTimeout(() => {
          this.recalculateSums();
        });
      });
    });
    if (!!this.data.globalDiscount && this.data.globalDiscount > 0) {
      this.form.get('discount').setValue(this.data.globalDiscount);
    }
  }

  private recalculateSums() {
    this.sumWithDiscount = calculateWithDiscount(
      this.patientOfferedActions,
      this.form.value,
    );
    this.finalSum = Math.round(
      (this.sumWithDiscount * (100 - +this.form.value['discount'])) / 100,
    );
    this.setMaxAdvance();
  }

  private updatePaymentTypeValidators() {
    if (RECEIPT === this.data.type) {
      this.paymentMethodControl.setValidators([Validators.required]);
      this.getDueDateControl().setValidators([Validators.required]);
    } else {
      this.paymentMethodControl.clearValidators();
      this.getDueDateControl().clearValidators();
    }
    this.paymentMethodControl.updateValueAndValidity();
    this.getDueDateControl().updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createBillAndCloseDialog(): void {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.createBill());
  }

  private createBill(): any {
    // Mapping patient actions to BillActionBasic objects
    const actions: BillActionBasic[] = this.patientOfferedActions.map(
      (action) => ({
        discount: parseInt(
          this.form.controls[action.patientActionId]?.value ?? 0,
          10,
        ),
        patientOfferedActionId: action.id,
      }),
    );

    // Constructing the basic data structure for the bill
    const basicData: BillBasic = {
      patientId: this.data.patientId,
      discount: this.form.controls['discount'].value,
      patientActionsDiscountType: this.discountOptionControl.value,
      address: this.form.controls['receiptAddress'].value?.content,
      addressReceiptIdPrefix:
        this.form.controls['receiptAddress'].value?.receiptIdPrefix,
    };

    // Conditional logic for determining the return object
    const isProformaInvoice = PROFORMA_INVOICES === this.data.type;
    // if the bill is paid with the advance, then set the payment method to TRR placeno
    const paymentMethodId =
      +this.advanceUsedControl.value === +this.finalSum
        ? PAYMENT_METHODS.TRR_PLACENO
        : +this.paymentMethodControl.value;
    const dueDate = this.form.controls['dueDate'].value;

    // Constructing a receipt object when it's not a proforma invoice
    const receiptObject: Receipt = {
      ...basicData,
      receiptActions: actions,
      paymentMethodId,
      dueDate,
    };

    // Adding client identification for the receipt when additional fields are shown
    const receiptClientIdentification: ReceiptClientIdentification =
      this.showAdditionalFields && this.clientIdentificationNeeded
        ? {
            taxNumber: this.getTaxNumberControl().value.toString(),
            uniqueCitizenNumber:
              this.getUniqueCitizenNumberControl().value.toString(),
            patientId: this.data.patientId,
          }
        : null;

    return isProformaInvoice
      ? { ...basicData, invoiceActions: actions }
      : {
          receipt: {
            ...receiptObject,
            ...(receiptClientIdentification && { receiptClientIdentification }),
          },
          advanceUsed: +this.advanceUsedControl.value,
          treatmentPlanAdvance: this.trtPlanUsed.value ?? 0,
          visualisationPrepAdvance: this.visPrepUsed.value ?? 0,
        };
  }

  noClientIdentificationNeeded() {
    this.clientIdentificationNeeded = !this.clientIdentificationNeeded;
    this.clientIdentificationNeeded
      ? this.setValidatorsForClientIdentificationFields()
      : this.clearValidatorsForClientIdentificationFields();
    this.updateClientIdentificationFields();
  }

  setMaxAdvance() {
    let alreadyUsed = this.trtPlanSelectedControl.value
      ? this.trtPlanUsed.value
      : 0;
    alreadyUsed += this.visPrepSelectedControl.value
      ? this.visPrepUsed.value
      : 0;
    const unpaid =
      this.finalSum - alreadyUsed >= 0 ? this.finalSum - alreadyUsed : 0;

    this.advanceUsedControl.setValue(
      unpaid < this.advanceAvailable
        ? unpaid.toFixed(2)
        : this.advanceAvailable,
    );
  }

  setDiscountForAllPatientActions(
    pia: PatientOfferedAction[],
    discount: number,
  ) {
    pia.map((p) => {
      this.form.controls[p.patientActionId].setValue(discount ?? 0, {
        emitEvent: false,
      });
    });
  }

  getPriceForSinglePatientAction(amount: number, price: number, id: number) {
    if (DiscountValueOptions.PERCENT === this.discountOptionControl.value) {
      return amount * ((price * (100 - +this.form.value[id])) / 100);
    } else if (DiscountValueOptions.EURO === this.discountOptionControl.value) {
      const reducedPrice =
        price - +this.form.value[id] <= 0 ? 0 : price - +this.form.value[id];
      return amount * reducedPrice;
    }
  }

  handleAdvances() {
    if (this.data.advances) {
      const aggregatedBalances = this.data.advances.reduce((acc, item) => {
        if (acc[item.patientActionId]) {
          // If ID exists in the accumulator, add the price to it
          acc[item.patientActionId] += item.remainingBalance;
        } else {
          // If ID doesn't exist, initialize it with the price
          acc[item.patientActionId] = item.remainingBalance;
        }
        return acc;
      }, {});

      this.treatmentPlanAdvance = this.data.advances.find(
        (adv) => ADVANCES.TREATMENT_PLAN === adv.patientActionId,
      );
      if (this.treatmentPlanAdvance) {
        this.treatmentPlanAdvance.remainingBalance =
          aggregatedBalances[ADVANCES.TREATMENT_PLAN];
      }

      this.visualisationPreparationAdvance = this.data.advances.find(
        (adv) => ADVANCES.VISUALIZATION_PREP === adv.patientActionId,
      );
      if (this.visualisationPreparationAdvance) {
        this.visualisationPreparationAdvance.remainingBalance =
          aggregatedBalances[ADVANCES.VISUALIZATION_PREP];
      }

      this.advanceAvailable = aggregatedBalances[ADVANCES.ADVANCE] ?? 0;
    }
  }

  totalAdvancesUsed() {
    let totalAdvances = this.trtPlanSelectedControl.value
      ? this.trtPlanUsed.value
      : 0;
    totalAdvances += this.visPrepSelectedControl.value
      ? this.visPrepUsed.value
      : 0;
    totalAdvances += +this.advanceUsedControl.value;
    return totalAdvances;
  }

  setMultiAdvanceMax(advance: AdvanceReceipt) {
    const trtUsed = this.trtPlanUsed.value ?? 0;
    const prepUsed = this.visPrepUsed.value ?? 0;
    const totalAdvances = trtUsed + prepUsed;
    const finalWithAdvances = this.finalSum - totalAdvances;

    return advance?.remainingBalance <= finalWithAdvances
      ? advance.remainingBalance
      : finalWithAdvances;
  }

  setSingleAdvanceMax(advance: AdvanceReceipt) {
    return advance?.remainingBalance <= this.finalSum
      ? advance.remainingBalance
      : this.finalSum;
  }

  removePoa(id: number) {
    this.patientOfferedActions = this.patientOfferedActions.filter(
      (poa) => poa?.id !== id,
    );
    this.recalculateSums();
    this.recalculatePoasDropdown();
  }

  expandCollapse(safi: SimplifiedActionForInvoice) {
    safi.isExpanded = !safi.isExpanded;
    if (safi.isExpanded) {
      this.expandedSafiIds.push(safi?.id);
    } else {
      this.expandedSafiIds = this.expandedSafiIds.filter(
        (id) => id !== safi?.id,
      );
    }
  }

  addNewPatientOfferedAction() {
    const id = this.selectedPoaFilter?.id;
    if (!id) {
      this.toastService.warning('Morate izabrati terapijo za dodati!');
      return;
    }
    this.selectedPoaFilter = undefined;
    const poaToAdd = this.data.allPatientOfferedActions?.find(
      (poa) => poa?.id === id,
    );
    this.patientOfferedActions = [...this.patientOfferedActions, poaToAdd];
    const discount =
      this.data.actionDiscountMap?.get(poaToAdd?.patientActionId) ?? 0;
    const formControl = new FormControl<number>(discount, [
      discountValidator(),
    ]);
    this.form.addControl(`${poaToAdd?.patientActionId}`, formControl);

    formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      // Defer the execution to ensure form state is updated
      setTimeout(() => {
        this.recalculateSums();
      });
    });
    this.recalculateSums();
    this.recalculatePoasDropdown();
  }

  recalculatePoasDropdown() {
    const filteredMappedItems = this.data.allPatientOfferedActions
      ?.filter(
        (poa) => !this.patientOfferedActions?.some((a) => a?.id === poa?.id),
      )
      .map((poa) => ({
        id: poa?.id,
        name: `${poa?.patientAction?.name} (${teethEnumTranslation(
          poa?.teeth,
        )})`,
      }));
    this.allPoasDropdown = filteredMappedItems ? [...filteredMappedItems] : [];
  }
}

function discountValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValidDiscount =
      control.value === 0 ||
      control.value === '0' ||
      (!!control.value && control.value > 0 && control.value <= 100);
    return isValidDiscount
      ? null
      : { invalidDiscount: { value: control.value } };
  };
}

function calculateWithDiscount(
  pia: PatientOfferedAction[],
  discountFormGroup: FormGroup,
): number {
  return pia
    .map((p) => {
      const discountPerPatientAction =
        +discountFormGroup[p?.patientActionId] ||
        (discountFormGroup['discountForAll'] ?? 0);
      const discountOption = discountFormGroup['discountOption'];
      if (DiscountValueOptions.PERCENT === discountOption) {
        return (
          (p?.patientAction?.price * (100 - discountPerPatientAction)) / 100
        );
      } else if (DiscountValueOptions.EURO === discountOption) {
        const reducedPrice = p?.patientAction?.price - discountPerPatientAction;
        return reducedPrice <= 0 ? 0 : reducedPrice;
      }
    })
    .reduce((totalPrice, itemPrice) => totalPrice + itemPrice, 0);
}

export function maxLimitValidator(maxValueProvider: () => number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isTrtPlSelected = control.parent?.get('treatmentPlanSelected').value;
    const isVisPrepSelected = control.parent?.get(
      'visualisationPreparationSelected',
    ).value;
    const trtPlUsed = control.parent?.get('treatmentPlanUsed').value;
    const visPrepUsed = control.parent?.get('visualisationPrepUsed').value;

    let alreadyUsed = isTrtPlSelected ? trtPlUsed : 0;
    alreadyUsed += isVisPrepSelected ? visPrepUsed : 0;

    const maxValue = (maxValueProvider() - alreadyUsed).toFixed(2);
    const forbidden = control.value > maxValue;
    return forbidden
      ? { maxLimit: { value: control.value, max: maxValue } }
      : null;
  };
}
